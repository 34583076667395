import dayjs from "dayjs";
import React from "react";

export const PersonalInfo = ({member}) => {
    return (
        <div className="card c_grid c_yellow">
            <div className="card-body text-center px-0">
                <div className="circle">
                    <img className="rounded-circle" src="../assets/images/sm/avatar1.jpg" alt="fake_url" />
                </div>
                <h6 className="mt-3 mb-0">{member?.firstname} {member?.lastname}</h6>
                <span>{member?.role.display_name}</span><br />
                <span>{member?.coop_id}</span>
                <ul className="mt-3 list-unstyled d-flex justify-content-center">
                    </ul>
                {/* <div className="btn-group">
                    <button className="btn p-2 btn-success btn-sm" data-toggle="modal" data-target="#deposit-modal">Deposit</button>
                    <button className="btn p-2 btn-danger btn-sm" data-toggle="modal" data-target="#withdrawal-modal">Withdrawal</button>
                    <button className="btn p-2 btn-primary btn-sm">Form	</button>
                </div> */}
                <ul className="list-group text-left mb-3">
                    <li className="list-group-item">
                        <small className="text-muted">Gender: </small>
                        <p className="mb-0">{member?.gender}</p>
                    </li>
                    <li className="list-group-item">
                        <small className="text-muted">Address: </small>
                        <p className="mb-0">{member?.current_address}</p>
                    </li>
                    <li className="list-group-item">
                        <small className="text-muted">Date of birth: </small>
                        <p className="mb-0">{dayjs(member?.dob).format('DD MMM YYYY')}</p>
                    </li>
                    <li className="list-group-item">
                        <small className="text-muted">Date Joined: </small>
                        <p className="mb-0">{dayjs(member?.date_joined).format('DD MMM YYYY')}</p>
                    </li>
                </ul>
            </div>
        </div>
    )
}