import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import * as Yup from "yup";
// import { useDispatch } from "react-redux";
import alertify from "alertifyjs";

import { useGetAllCoaQuery, useSaveChargesMutation, useUpdateChargeMutation } from "../../../services/cooperative-setup.service";
import { Select } from "antd";
import { filterOption } from "../../../utils/helpers";
import Card from "../../common/Card";

const FormSchema = Yup.object().shape({
    name: Yup.string().required('Please enter the name'),
    amount: Yup.string().required('Please enter an amount'),
    posting_gl: Yup.string().required('Select GL Account'),
    status: Yup.string().required('Select status'),
});

const ChargeForm = ({charge, onCancel, coop}) => {
    const {data: postingGlData} = useGetAllCoaQuery(coop?.id);
    const [glAccounts, setGlAccounts] = useState([]);
    const [save, {isLoading, isSuccess, isError, data, error}] = useSaveChargesMutation();
    const [update, {
        isLoading: isUpdateLoading, 
        isSuccess: isUpdateSuccess, 
        isError: isUpdateError, 
        data: updateData, 
        error: updateError
    }] = useUpdateChargeMutation();

    const {
        control,
        reset,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(FormSchema),
        mode: 'onBlur'
    });

    useEffect(() => {
        if (postingGlData && postingGlData.status) {
          setGlAccounts(postingGlData.data.map(item => ({
            value: coop ? item.id : item.code, 
            label: `[${item.code}] ${item.name}`
        })));
        }
    }, [postingGlData]);

    useEffect(() => {
        if (charge) {
            resetForm(charge);
        }
    }, [charge]);


    const onSubmit = async (values) => {
        if (coop) values.cooperative_id = coop.id;

        if (charge) {
            await update(values);
        } else {
            await save(values);
        }
    }

    useEffect(() => {
        if(isSuccess || isUpdateSuccess) {
            resetForm();
            // show toast.
            alertify.success(data?.message || updateData?.message)
        }

        if (isError || isUpdateError) {
            alertify.error(error?.data?.message || updateError?.data?.message);
        }
    }, [isSuccess, isUpdateSuccess, isUpdateError, isError, data, updateData])

    const resetForm = (item) => {
        reset({
            name: item?.name || "",
            amount: item?.amount || "",
            posting_gl: item?.posting_gl || "",
            status: item?.status || "",
            id: item?.id || "",
        })
    }

    const doCancel = () => {
        resetForm();
        onCancel();
    }

    return (
        <Card 
            title={`${charge ? 'Edit' : 'Add New'} Charge`}
            loading={`${(isLoading || isUpdateLoading) ? 'active' : ''}`}
        >
            <form className="card-body dimmer-content" onSubmit={handleSubmit(onSubmit)}>
                <div className="row clearfix">
                    <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                            <label>Name <span className="form-required">*</span></label>
                            <Controller
                                name="name"
                                control={control}
                                render={({ field }) => (
                                    <input
                                        className={`form-control ${
                                        errors?.name ? "is-invalid" : ""
                                        }`}
                                        type="text"
                                        onChange={field.onChange}
                                        value={field.value}
                                        autoComplete="true"
                                    />
                                )}
                            />
                            {errors.name && <div className="invalid-feedback">{errors.name?.message}</div>}
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                            <label>Value <span className="form-required">*</span></label>
                            <Controller
                                name="amount"
                                control={control}
                                render={({ field }) => (
                                    <input
                                        className={`form-control ${
                                        errors?.amount ? "is-invalid" : ""
                                        }`}
                                        type="text"
                                        onChange={field.onChange}
                                        value={field.value}
                                        autoComplete="true"
                                    />
                                )}
                            />
                            {errors.amount && <div className="invalid-feedback">{errors.amount?.message}</div>}
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                            <label>Posting GL <span className="form-required">*</span></label>
                            <Controller
                                name="posting_gl"
                                control={control}
                                render={({ field }) => (
                                    <Select 
                                        showSearch
                                        className="customSelect mt-1"
                                        options={glAccounts} 
                                        placeholder="Select Gl Account"
                                        filterOption={filterOption}
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                    
                                )}
                            />
                            {errors.posting_gl && <div className="invalid-feedback">{errors.posting_gl?.message}</div>}
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                            <label>Status <span className="form-required">*</span></label>
                            <Controller
                                name="status"
                                control={control}
                                render={({ field }) => (
                                    <select 
                                        onChange={field.onChange}
                                        value={field.value}
                                        className={`form-control ${errors?.status ? "is-invalid" : ""}`}
                                    >
                                        <option value=''>Select Status</option>
                                        <option value='1'>Active</option>
                                        <option value='0'>Inactive</option>
                                    </select>
                                )}
                            />
                            {errors.status && <div className="invalid-feedback">{errors.status?.message}</div>}
                        </div>
                    </div>
                </div>
                    
                <div className="d-flex justify-content-between">
                    <button type="button" className="btn btn-round btn-default" onClick={doCancel}>
                        Cancel
                    </button>{' '}&nbsp;&nbsp;
                    <button type="submit" className="btn btn-round btn-primary">
                        Save
                    </button>
                </div>
                
            </form>
        </Card>
    )
}

export default ChargeForm;