import React from 'react'
import { useSelector } from 'react-redux'
import ProductCategory from './Category/ProductCategory';
import Product from './Product/Product';
import { useGetProductCategoriesQuery } from '../../../services/cooperative-setup.service';

const ProductSetup = () => {

   const {isFixNavbar: fixNavbar} = useSelector(state => state.settings);
    const {activeCoop} = useSelector(state => state.coop);

    const {data, isLoading, error} = useGetProductCategoriesQuery(activeCoop?.id);


    return (
        <>
            <div>
                <div>
                    <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
                        <div className="container-fluid">
                            <div className="d-flex justify-content-between align-items-center">
                                <ul className="nav nav-tabs page-header-tab">
                                    <li className="nav-item"><a className="nav-link active" id="Departments-tab" data-toggle="tab" href="#accounts-list">Product Categories</a></li>
                                    <li className="nav-item"><a className="nav-link" id="Departments-tab" data-toggle="tab" href="#new-group">Products</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="section-body mt-3">
                        <div className="container-fluid">
                            <div className="tab-content mt-3">
                                <div className="tab-pane fade show active" id="accounts-list" role="tabpanel">
                                    <ProductCategory coop={activeCoop} categories={data?.data} loading={isLoading} />
                                </div>
                                <div className="tab-pane fade" id="new-group" role="tabpanel">
                                    <Product coop={activeCoop} categories={data?.data} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ProductSetup