import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useLazyIncomeStatmentReportQuery } from '../../services/utility.service';
import { doDownload, formatNumber } from '../../utils/helpers';
import dayjs from 'dayjs';
import { DatePicker } from 'antd';
import { ViewCagtegoryBreakDown } from './Modals/ViewCategoryBreakDown';
import { INCOME_STATMENT } from '../../services/CONSTANTS';

const IncomeStatment = () => {
    const [incomes, setIncomes] = useState([]);
    const [expenses, setExpenses] = useState([]);
    const [selected, setSelected] = useState(null);
    const {token} = useSelector(state => state.user);
    const [isDownloading, setIsDownloading] = useState(false);
    
    const {isFixNavbar: fixNavbar} = useSelector(state => state.settings);
    const {activeCoop} = useSelector(state => state.coop);
    const [filterData, setFilterData] = useState({
        id: activeCoop?.id,
        start: '',
        end: '',
        download: false
    })
    const [getResults, {data, isFetching, isError}] = useLazyIncomeStatmentReportQuery();

    useEffect(() => {
        getResults(filterData);
    }, []);

    useEffect(() => {
        if(data) {
            const income = data.data.results.filter(group => group.name !== 'Operating Expenses');
            const expense = data.data.results.filter(group => group.name == 'Operating Expenses');

            setIncomes(income);
            setExpenses(expense)
        }
    }, [data]);


    const download = async () => {
        setIsDownloading(true);
        const url = `${process.env.REACT_APP_API_URL}${INCOME_STATMENT}?cooperative_id=${activeCoop?.id}&download=true&&start_date=${filterData.start}&end_date=${filterData.end}`
        await doDownload(url, `${activeCoop?.name}-income-statment-${dayjs().unix()}.xlsx`, token);
        setIsDownloading(false)
    }



    return (
        <div className="container-fluid">
            <div className={`section-body mt-3 ${fixNavbar ? "marginTop" : ""}`}>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <label>From</label>
                                <DatePicker
                                    className={`customInput w-full`} 
                                    name={'start'} 
                                    placeholder='Start Date'
                                    style={{width: '100%'}}
                                    value={filterData.start !== '' ? dayjs(filterData.start) : undefined} 
                                    onChange={(value) => setFilterData({...filterData, start: dayjs(value).format('YYYY-MM-DD')})}
                                />
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <label>To</label>
                                <DatePicker
                                    className={`customInput w-full`} 
                                    name={'start'} 
                                    placeholder='End Date'
                                    style={{width: '100%'}}
                                    value={filterData.end !== '' ? dayjs(filterData.end) : undefined} 
                                    onChange={(value) => setFilterData({...filterData, end: dayjs(value).format('YYYY-MM-DD')})}
                                />
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <label>&nbsp;</label>
                                <button className="btn btn-sm btn-primary btn-block" onClick={() => getResults(filterData)}>Search</button>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <label>&nbsp;</label>
                                <button className="btn btn-sm btn-secondary btn-block" onClick={download}>Download</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`card dimmer ${isFetching ? 'active' : '' }`}>
                    <div className="card-body dimmer-content">
                        <div className='loader' />
                        <div className="table-responsive">
                            <table className="table reporting-table report table-hover table-striped table-vcenter mb-0">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th>Amount (NGN)</th>
                                        <th>Amount (NGN)</th>
                                    </tr>
                                </thead>
                                
                                {incomes.map((group, i) => 
                                    <tbody key={`income-group-${i}`}>
                                        <tr >
                                            <td className='text-bold'>{group.name}</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        {
                                            group.sub_results?.map((category, i) => 
                                                <tr 
                                                    key={`income-cat-${i}`}
                                                    onClick={() => setSelected(category)}
                                                    data-toggle="modal"
                                                    data-target="#category_breakdown_modal"
                                                >
                                                    <td></td>
                                                    <td>
                                                        <a href='javascript:;'>{category.category_name}</a>
                                                    </td>
                                                    <td className=''>{formatNumber(category.previousAmount.balance)}</td>
                                                    <td className=''>{formatNumber(category.currentAmount.balance)}</td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                )}
                                <tr> <td colSpan={4}>&nbsp;</td> </tr>
                                <tr> <td colSpan={4}>&nbsp;</td> </tr>
                                {expenses.map((group, i) => 
                                    <tbody key={`expense-group-${i}`}>
                                        <tr >
                                            <td className='text-bold'>{group.name}</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        {
                                            group.sub_results?.map((category, i) => 
                                                <tr 
                                                    key={`expense-cat-${i}`}
                                                    onClick={() => setSelected(category)}
                                                    data-toggle="modal"
                                                    data-target="#category_breakdown_modal"
                                                >
                                                    <td></td>
                                                    <td >{category.category_name}</td>
                                                    <td className=''>{formatNumber(category.previousAmount.balance)}</td>
                                                    <td className=''>{formatNumber(category.currentAmount.balance)}</td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                )}
                                <tr> <td colSpan={4}>&nbsp;</td> </tr>
                                <tr> <td colSpan={4}>&nbsp;</td> </tr>
                                <tr> 
                                    <td className='text-bold'>Gross Profit</td> 
                                    <td>&nbsp;</td> 
                                    <td>{formatNumber(data?.data?.totals?.gross_profit?.previous_year)}</td>
                                    <td>{formatNumber(data?.data?.totals?.gross_profit?.current_year)}</td>
                                </tr>
                                <tr> 
                                    <td className='text-bold'>Net Profit</td> 
                                    <td>&nbsp;</td> 
                                    <td>{formatNumber(data?.data?.totals?.net_profit?.previous_year)}</td>
                                    <td>{formatNumber(data?.data?.totals?.net_profit?.current_year)}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <ViewCagtegoryBreakDown 
                name={selected?.category_name} 
                accountId={selected?.category_id} 
                cooperative_id={activeCoop?.id} 
                start={filterData?.start} 
                end={filterData?.end} 
            />
        </div>            
    )
}


export default IncomeStatment;