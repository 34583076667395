import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import alertify from "alertifyjs";
import { toggleDelete } from "../../redux/slices/ui.slice";
import { useDeleteItemMutation } from "../../services/setup.service";

export default function DeleteModal({ item }) {
    const dispatch = useDispatch();
    const [doDelete, {isLoading, isSuccess, isError, data, error}] = useDeleteItemMutation();
    
    useEffect(() => {
        if (isSuccess && data.status)  {
            handleClose();
            document.getElementById('close-modal').click();
        }

        if(isError) {
            alertify.error('Unable to delete item: ' + error.data.message);
        }

    }, [isSuccess, isError, error, data]);


    const handleClose = () => {
        dispatch(
          toggleDelete({
            open: false,
            url: null,
            title: null
          })        
        );
    }

    const deleteItem = () => {
        // console.log(data);
        doDelete(item.url);
    }

    return (
        <div className="modal fade" id="deleteModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className={`modal-content dimmer ${isLoading ? 'active' : ''}`}>
                    <div className="loader" />
                    
                    <div className="modal-body dimmer-content">
                        <div className="form-header text-center">
                            <h3>Delete {item?.title}</h3>
                            <p>Are you sure want to delete this item?</p>
                        </div>
                        <div className="modal-btn delete-action">
                            <div className="row">
                                <div className="col-6">
                                    <a 
                                        href="javascript:;" onClick={handleClose} 
                                        id="close-modal" data-dismiss="modal" className="btn btn-secondary btn-block">Cancel</a>
                                </div>
                                <div className="col-6">
                                    <a
                                        href="javascript:;"
                                        onClick={deleteItem}
                                        className="btn btn-primary btn-block"
                                    >
                                        {isLoading ? 'Deleting...' : 'Delete'}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
