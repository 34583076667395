import React, { useState } from "react";
import { useGetPositionsByElectionQuery } from "../../../services/election.service";
import { Tabs } from "antd";
import { PositionForm } from "./PositionForm";
import { PositionItem } from "./PositionItem";

export const ManagePositions = ({election, coopId}) => {
    const [position, setPosition] = useState(null);
    const [activeTab, setActiveTab] = useState("1");

    const {data, isLoading} = useGetPositionsByElectionQuery({
        coopId, 
        electionId: election?.id
    }, {
        skip: !election,
        refetchOnMountOrArgChange: true,
    });

    return (
        <div className="modal fade" id="positions_modal" tabIndex={-1} role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className={`modal-content dimmer ${isLoading ? 'active' : ''}`}>
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Manage Positions for {election?.name}</h5>
                        <button type="button" id="election-modal" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body dimmer-content">
                        <div className="loader" />
                        <Tabs 
                            activeKey={activeTab} 
                            items={[
                                {
                                    label: 'Positions',
                                    key: '1',
                                    children: (
                                        <div className="table-responsive todo_list">
                                            <table className="table table-hover table-striped table-vcenter mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>Description</th>
                                                        <th className="w150 text-right">Name</th>
                                                        <th className="w100"></th>
                                                        <th className="w80"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {data?.data.map((row, i) => <PositionItem key={`position-${i}` } item={row} onEdit={(val) => {
                                                        setPosition(val);
                                                        setActiveTab("2")
                                                    }} /> )}

                                                </tbody>
                                            </table>
                                        </div>
                                    )
                                }, {
                                    label: position ? 'Edit Position' : 'Add New',
                                    key: '2',
                                    children: (
                                        <PositionForm 
                                            item={position} 
                                            electionId={election?.id} 
                                            coopId={coopId} 
                                            onCancel={() => setPosition(null)} 
                                            onSave={() => setActiveTab('1')}
                                        />
                                    )
                                }
                            ]}
                            onChange={(key) => setActiveTab(key)}
                        />

                    </div>
                </div>
            </div>
        </div>
    )
}