import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateRole } from '../../redux/slices/user.slice';
class AdminLink extends Component {
	constructor() {
		super();

		this.onClick = this.onClick.bind(this);
	}

	onClick(e, id) {
		if (this.props.itemProps.hasSubMenu) {
			this.props.itemProps.toggleSubMenu(e)
		} else {

			this.props.itemProps.activateMe({
				newLocation: this.props.to,
				selectedMenuLabel: this.props.label,
			});
		}
		if (id === 57) {
			this.props.changeActiveMenu('report')
		} else if (id === 76) {
			this.props.changeActiveMenu('cooperative-admin')
		} else if (id === 111) {
			this.props.changeActiveMenu('eshop')
		}
	}
	render() {
		const { menuIcon, subMenuIcon, itemProps } = this.props;

		if (itemProps.id === 'admin-coop' || itemProps.id === 'system-setup' || itemProps.id === 'reports' || itemProps.id === 'e-shop-setup' ||
		itemProps.id === 'loan-savings' || itemProps.id === 'finance-reporting' || itemProps.id === 'coop-setup' || itemProps.id === 'my-profile' ||
		itemProps.id === 'product-stock' || itemProps.id === 'eshop-orders' || itemProps.id === 'eshop-reports'
		) {
			return (
				<span className="g_heading">
					{itemProps.label}
				</span>
			);
		} else {
			return (
				<span className={window.location.pathname === itemProps.to ? "active" : ""}>
					<NavLink to={`${itemProps.to}`} onClick={(e) => this.onClick(e, itemProps.id)} className={window.location.pathname === itemProps.to ? menuIcon : subMenuIcon}>
						{itemProps.children[0].props.className ? itemProps.children : itemProps.label}
					</NavLink>
				</span>
			);
		}
	}
};
const mapStateToProps = state => ({
	subMenuIcon: state.settings.isSubMenuIcon,
	menuIcon: state.settings.isMenuIcon
})

const mapDispatchToProps = dispatch => ({
	changeActiveMenu: (role) => dispatch(updateRole(role))
})
export default connect(mapStateToProps, mapDispatchToProps)(AdminLink);