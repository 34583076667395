import React from 'react'
import { useSelector } from 'react-redux';
import { ManageCoaCategory } from './ManageCoaCategory';
import { ManageCoaSubCategory } from './ManageCoaSubCategory';
import { ManageCOA } from './ManageCOA';

const ChartOfAccounts = () => {

   const {isFixNavbar: fixNavbar} = useSelector(state => state.settings);
   const {activeCoop} = useSelector(state => state.coop);

    return (
        <>
            <div>
                <div>
                    <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
                        <div className="container-fluid">
                            <div className="d-flex justify-content-between align-items-center">
                                <ul className="nav nav-tabs page-header-tab">
                                    <li className="nav-item"><a className="nav-link active" id="coa-categories-tab" data-toggle="tab" href="#coa-categories">Manage COA Categories</a></li>
                                    <li className="nav-item"><a className="nav-link" id="coa-sub-cat-tab" data-toggle="tab" href="#coa-sub-categories">Manage COA Sub Categories</a></li>
                                    <li className="nav-item"><a className="nav-link" id="coa-tab" data-toggle="tab" href="#coa">Manage Chart Of Accounts</a></li>
                                    {/* <li className="nav-item"><a className="nav-link" id="Departments-tab" data-toggle="tab" href="#new-ledger">Create New Ledger</a></li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="section-body mt-3">
                        <div className="container-fluid">
                            <div className="tab-content mt-3">
                                <div className="tab-pane fade show active" id="coa-categories" role="tabpanel">
                                    <ManageCoaCategory coop={activeCoop} />
                                </div>
                                <div className="tab-pane fade" id="coa-sub-categories" role="tabpanel">
                                    <ManageCoaSubCategory coop={activeCoop} />
                                </div>
                                <div className="tab-pane fade" id="coa" role="tabpanel">
                                    <ManageCOA coop={activeCoop} />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                
            </div>
        </>
    )
}
export default ChartOfAccounts