import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import * as Yup from "yup";
import alertify from "alertifyjs";

import { useGetAllCoaQuery, useSaveAssetTypeMutation, useUpdateAssetTypeMutation } from "../../../services/cooperative-setup.service";
import Card from "../../common/Card";
import { filterOption } from "../../../utils/helpers";
import { Select } from "antd";

const FormSchema = Yup.object().shape({
    name: Yup.string().required('Please select product type'),
    fixed_asset_gl: Yup.string().required('This field is required'),
    dep_expense_gl: Yup.string().required('This field is required'),
    accumulated_dep_gl: Yup.string().required('This field is required'),
    status: Yup.string().required('Select status'),
});

const AssetGLSetupForm = ({asset, coopId, onCancel}) => {
    const {data: coaData} = useGetAllCoaQuery(coopId, {skip: !coopId})

    const [save, {isLoading, isSuccess, isError, data, error}] = useSaveAssetTypeMutation();
    const [update, {
        isLoading: isUpdateLoading, 
        isSuccess: isUpdateSuccess, 
        isError: isUpdateError, 
        data: updateData, 
        error: updateError
    }] = useUpdateAssetTypeMutation();

    const {
        control,
        reset,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(FormSchema),
        mode: 'onBlur'
    });

    useEffect(() => {
        if(isSuccess || isUpdateSuccess) {
            resetForm();
            onCancel()
            // show toast.
            alertify.success(data?.message || updateData?.message)
        }

        if (isError || isUpdateError) {
            alertify.error(error?.data?.message || updateError?.data?.message);
        }
    }, [isSuccess, isUpdateSuccess, isUpdateError, isError, data, updateData]);

    useEffect(() => {
        if (asset) {
            resetForm(asset);
        }
    }, [asset]);

    const onSubmit = async (values) => {
        values.cooperative_id = coopId;

        if (asset) {
            values.id = asset.id;
            await update(values);
        } else {
            await save(values);
        }
    }

    const resetForm = (asset) => {
        reset({
            cooperative_id: coopId,
            name: asset?.name || "",
            fixed_asset_gl: asset?.fixed_asset_gl || "",
            dep_expense_gl: asset?.dep_expense_gl || "",
            accumulated_dep_gl: asset?.accumulated_dep_gl || "",
            status: asset?.status || ""
        })
    }

    const doCancel = () => {
        resetForm();
        onCancel();
    }

    return (
        <Card title={`${asset ? 'Add' : 'Edit'} Asset Type`} loading={isLoading || isUpdateLoading}>
            <form className="card-body dimmer-content" onSubmit={handleSubmit(onSubmit)}>
                <div className="row clearfix">
                    <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                            <label>Name <span className="form-required">*</span></label>
                            <Controller
                                name="name"
                                control={control}
                                render={({ field }) => (
                                    <input
                                        className={`form-control ${errors?.name ? "is-invalid" : ""}`}
                                        type="text"
                                        onChange={field.onChange}
                                        value={field.value}
                                        autoComplete="true"
                                        placeholder=""
                                    />
                                )}
                            />
                            {errors.name && <div className="invalid-feedback">{errors.name?.message}</div>}
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                            <label>Fixed Asset <span className="form-required">*</span></label>
                            <Controller
                                name="fixed_asset_gl"
                                control={control}
                                render={({ field }) => (
                                    <Select 
                                        showSearch
                                        className="customSelect mt-1"
                                        options={coaData?.data.map(coa => ({value: coa.id, label: coa.name}))} 
                                        placeholder="Select GL Account"
                                        filterOption={filterOption}
                                        value={field.value || asset?.fixed_asset_gl}
                                        onChange={field.onChange}
                                    />
                                )}
                            />
                        </div>
                        {errors.fixed_asset_gl && <div className="invalid-feedback">{errors.fixed_asset_gl?.message}</div>}
                    </div>
                    <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                            <label>Dep. Expense <span className="form-required">*</span></label>
                            <Controller
                                name="dep_expense_gl"
                                control={control}
                                render={({ field }) => (
                                    <Select 
                                        showSearch
                                        className="customSelect mt-1"
                                        options={coaData?.data.map(coa => ({value: coa.id, label: coa.name}))} 
                                        placeholder="Select GL Account"
                                        filterOption={filterOption}
                                        value={field.value || asset?.dep_expense_gl}
                                        onChange={field.onChange}
                                    />
                                )}
                            />
                        </div>
                        {errors.dep_expense_gl && <div className="invalid-feedback">{errors.dep_expense_gl?.message}</div>}
                    </div>
                    <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                            <label>Accu. Depreciation <span className="form-required">*</span></label>
                            <Controller
                                name="accumulated_dep_gl"
                                control={control}
                                render={({ field }) => (
                                    <Select 
                                        showSearch
                                        className="customSelect mt-1"
                                        options={coaData?.data.map(coa => ({value: coa.id, label: coa.name}))} 
                                        placeholder="Select GL Account"
                                        filterOption={filterOption}
                                        value={field.value || asset?.accumulated_dep_gl}
                                        onChange={field.onChange}
                                    />
                                )}
                            />
                        </div>
                        {errors.accumulated_dep_gl && <div className="invalid-feedback">{errors.accumulated_dep_gl?.message}</div>}
                    </div>
                    <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                            <label>Status <span className="form-required">*</span></label>
                            <Controller
                                name="status"
                                control={control}
                                render={({ field }) => (
                                    <select 
                                        onChange={field.onChange}
                                        value={field.value}
                                        className={`form-control ${errors?.status ? "is-invalid" : ""}`}
                                    >
                                        <option value=''>Select Status</option>
                                        <option value='1'>Active</option>
                                        <option value='0'>Inactive</option>
                                    </select>
                                )}
                            />
                            {errors.status && <div className="invalid-feedback">{errors.status?.message}</div>}
                        </div>
                    </div>
                </div>
                    
                <div className="d-flex justify-content-between">
                    <button type="button" className="btn btn-round btn-default" onClick={doCancel}>
                        Cancel
                    </button>{' '}&nbsp;&nbsp;
                    <button type="submit" className="btn btn-round btn-primary">
                        Save
                    </button>
                </div>
            </form>
        </Card>
    )
}

export default AssetGLSetupForm;