
import React from "react";
import { useGetAllLoansQuery } from "../../../../services/cooperative-setup.service";
import { useDispatch } from "react-redux";
import { toggleDelete } from "../../../../redux/slices/ui.slice";
import { DELETE_COOP_LOANS, DELETE_LOANS } from "../../../../services/CONSTANTS";
import { Tooltip } from "antd";

const LoansSetupList = ({onEdit, coop}) => {
    const {data, isLoading} = useGetAllLoansQuery(coop?.id);
    const dispatch = useDispatch();

    const doDelete = (e, item) => {
        // console.log(e.currentTarget)
        dispatch(
          toggleDelete({
            open: true,
            title: 'Loan Setup',
            url: coop ? `${DELETE_COOP_LOANS}?setup_id=${item.id}&cooperative_id=${coop.id}` : `${DELETE_LOANS}?setup_id=${item.id}`,
            // ele: JSON.stringify(e.currentTarget),
            parent: 'tr'
          })
        );
    }

    return (
        <div className={`card dimmer ${isLoading ? 'active' : ''}`}>
            <div className="loader" />
            <div className="card-header">Loan Setups</div>
            <div className="card-body p-0">
                <div className="table-responsive">
                    <table className="table table-striped table-vcenter table-hover mb-0">
                        <thead>
                            <tr>
                                <th>Type</th>
                                <th>Name</th>
                                <th>Tenure</th>
                                <th>Int. Rate</th>
                                <th>Penal</th>
                                <th>Upfront</th>
                                <th>Source</th>
                                <th>Charge</th>
                                <th>Calc. Method</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.data.map(row => 
                                <tr key={row}>
                                    <td>{row.product?.name}</td>
                                    <td>{row.name}</td>
                                    <td>{row.tenure} {row.tenure_type}</td>
                                    <td>
                                        <div>Min.: {row.min_int_rate}</div>
                                        <div>Max.: {row.max_int_rate}</div>
                                    </td>
                                    <td>{row.penal_charge}</td>
                                    <td>{row.upfront}%</td>
                                    <td>{row.source}</td>
                                    <td>
                                        <div>{row.charge?.name}</div> 
                                        <div>{row?.charge_amount} {row.charge_type === 'Percentage' ? '%' : ''}</div>
                                    </td>
                                    <td>{row.calculation_method?.name || 'Not Set'}</td>
                                    <td>
                                        {row.status === 1 && <span className="tag tag-success">Active</span>}
                                        {row.status === 0 && <span className="tag tag-danger">Inactive</span>}
                                    </td>
                                    <td>
                                        <Tooltip title="Edit">
                                            <button type="button" className="btn btn-icon" onClick={() => onEdit(row)}>
                                                <i className="icon-pencil text-info" />
                                            </button>
                                        </Tooltip>
                                        <Tooltip title="Delete">
                                            <button type="button" className="btn btn-icon" data-target="#deleteModal" data-toggle="modal" onClick={(e) => doDelete(e, row)}>
                                                <i className="icon-trash text-info" />
                                            </button>
                                        </Tooltip>
                                    </td>
                                </tr>
                            )}
                            
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default LoansSetupList;