import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { UsersTable } from './UsersTable';
import { useGetUsersQuery } from '../../../services/setup.service';
import { UserForm } from './UserForm';


const Users = () => {

    const {isFixNavbar: fixNavbar} = useSelector(state => state.settings);

    const {data, isLoading, isError} = useGetUsersQuery()
    const [user, setUser] = useState(null);

	return (
		<>
			<div>
				<div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
					<div className="container-fluid">
						<div className="d-flex justify-content-between align-items-center">
							<ul className="nav nav-tabs page-header-tab">
								<li className="nav-item">
									<a
										className="nav-link active"
										id="user-tab"
										data-toggle="tab"
										href="#user-list"
									>
										List
									</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" id="form-tab" data-toggle="tab" href="#user-add">
										Add New
									</a>
								</li>
							</ul>
							{/* <div className="header-action">
								<button type="button" className="btn btn-primary">
									<i className="fe fe-plus mr-2" />
									Add
								</button>
							</div> */}
						</div>
					</div>
				</div>
				<div className="section-body mt-3">
					<div className="container-fluid">
						<div className="tab-content mt-3">
							<div className="tab-pane fade show active" id="user-list" role="tabpanel">
								<div className={`card dimmer ${isLoading ? 'active' : ''}`}>
									<div className="card-header">
										<h3 className="card-title">User List</h3>
										<div className="card-options">
											<form>
												<div className="input-group">
													<input
														type="text"
														className="form-control form-control-sm"
														placeholder="Search something..."
														name="s"
													/>
													<span className="input-group-btn ml-2">
														<button className="btn btn-sm btn-default" type="submit">
															<span className="fe fe-search" />
														</button>
													</span>
												</div>
											</form>
										</div>
									</div>
									<div className="loader" />
									<div className="card-body dimmer-content">
										<UsersTable 
											data={data?.data} 
											onEdit={(val) => {
												setUser(val);
												document.getElementById('form-tab').click()
											}}
										/>
									</div>
								</div>
							</div>
							<div className="tab-pane fade" id="user-add" role="tabpanel">
								<UserForm user={user} onCancel={() => {
									setUser(null);
									document.getElementById('user-tab').click()
								}} />
							</div>
						</div>
					</div>
				</div>

			</div>
		</>
	);
}

export default Users;