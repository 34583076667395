import React from "react";
import { formatNumber } from "../../../utils/helpers";
import InitialsAvatar from 'react-initials-avatar';
import { Tooltip } from "antd";

export const DashboardRowItem = ({key, name, amount, productName, status, onClick, type}) => {
    const showDetails = () => {
        if (type === 'loan'){
            onClick();
        }
    }
    return (    
        <tr key={key} style={{cursor: 'pointer'}}>
            <td className="width45"  onClick={showDetails}>
                <span className="avatar avatar-green">
                    <InitialsAvatar name={name} />
                </span>
            </td>
            <td onClick={showDetails}>
                <p className="mb-0 text-truncate">{name}</p>
                <span className="text-muted font-13">{productName}</span>
            </td>
            <td className="text-right">
                <p className="mb-0">{formatNumber(amount)}</p>
                {status === 0 && 
                <div className="btn-group btn-sm">
                    <Tooltip title="Approve">
                        <button type="button" className="btn btn-sm py-0" onClick={onClick} data-target="#approveModal" data-toggle="modal">
                            <i className="icon-like text-info" />
                        </button>
                    </Tooltip>
                    <Tooltip title="Deny" >
                        <button type="button" 
                            className="btn btn-sm py-0"
                            onClick={onClick} data-target="#denyModal" data-toggle="modal"
                        >
                            <i className="icon-dislike text-danger" />
                        </button>
                    </Tooltip>
                </div>}
                {status === 1 && <span className="text-success font-13">Approved</span>}
                {status === 2 && <span className="text-danger font-13">Denied</span>}
            </td>
        </tr>
    )
}