
import React, { useState } from "react";
import ProductList from "./ProductList";
import ProductForm from "./ProductForm";
import { useGetProductsQuery } from "../../../../services/cooperative-setup.service";

const Product = ({coop, categories}) => {
    const [product, setProduct] = useState(null);
    const {data, isLoading, error} = useGetProductsQuery(coop?.id);

    return (
        <div className="row">
            <div className="col-md-8 col-sm-12">
                <ProductList coop={coop} loading={isLoading} data={data?.data} onEdit={(val) => setProduct(val)}  />
            </div>
            <div className="col-md-4 col-sm-12">
                <ProductForm categories={categories} coop={coop} product={product} onCancel={() => setProduct(null)} />
            </div>
        </div>
    )
}

export default Product;