import React from "react";
import { useGetLoanScheduleQuery, useGetLoanSuretyQuery } from "../../../services/member.service";
import { formatNumber } from "../../../utils/helpers";
import { Empty } from "antd";
import { useDispatch } from "react-redux";
import { updateSuretyResponse } from "../../../redux/slices/user.slice";

export const LoanDetails = ({loan}) => {
    const {data: suretyData} = useGetLoanSuretyQuery(loan?.id, {skip: !loan})
    const {data: scheduleData, isFetching} = useGetLoanScheduleQuery(loan?.id, {skip: !loan});
    const dispatch = useDispatch();

    return (
        <div className="modal fade" id="loan-details" tabIndex={-1} role="dialog" aria-labelledby="surety-list" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className={`modal-content dimmer ${isFetching ? 'active' : ''}`}>
                    <div className="modal-header">
                        <h5 className="modal-title" id="surety-list">Loan Details - {loan?.product.name}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                    </div>
                    <div className="modal-body">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <ul className="nav nav-tabs page-header-tab">
                                <li className="nav-item">
                                    <a
                                        className="nav-link active"
                                        id="schedule-tab"
                                        data-toggle="tab"
                                        href="#schedule"
                                    >
                                        Loan Schedule
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className="nav-link"
                                        id="surty-tab"
                                        data-toggle="tab"
                                        href="#surties"
                                    >
                                        Sureties
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="loader" />
                        <div className="tab-content dimmer-content">
                            <div className="tab-pane fade show active" id="schedule" role="tabpanel">
                                <div className="table-responsive">
                                    <table className="table table-stripped">
                                        <thead>
                                            <th>S/N</th>
                                            <th>Month</th>
                                            <th>Principal </th>
                                            <th>Interest </th>
                                            <th>Payment </th>
                                            <th>Balance </th>
                                            <th>Bal + Int </th>
                                            <th>Status</th>
                                        </thead>
                                        <tbody>
                                            {scheduleData?.data.map((schedule, i) => 
                                                <tr key={`schedule-${schedule.id}`}>
                                                    <td>{i + 1}</td>
                                                    <td>{schedule.payment_month}</td>
                                                    <td>{formatNumber(schedule.principal)}</td>
                                                    <td>{formatNumber(schedule.interest)}</td>
                                                    <td>{formatNumber(schedule.payment)}</td>
                                                    <td>{formatNumber(schedule.balance)}</td>
                                                    <td>{formatNumber(schedule.balance + schedule.interest)}</td>
                                                    
                                                    <td>
                                                        {schedule.is_paid === 0 &&  <span className="tag tag-danger">Not Paid</span>}
                                                        {schedule.is_paid === 1 &&  <span className="tag tag-success">Paid</span>}
                                                    </td>
                                                </tr>
                                            )}
                                            {scheduleData?.data.length === 0 && <tr><td colSpan={7}><Empty /></td></tr>}
                                            
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="surties" role="tabpanel">
                                
                                <div className="table-responsive">
                                    <table className="table table-stripped">
                                        <thead>
                                            <th>S/N</th>
                                            <th>Member</th>
                                            <th>Loan</th>
                                            <th>Amount</th>
                                            <th>Status</th>
                                            <th></th>
                                        </thead>
                                        <tbody>
                                            {suretyData?.data.map((surety, i) => 
                                            <tr key={`surety-${surety.id}`}>
                                                <td>{i + 1}</td>
                                                <td>{surety?.member.firstname} {surety.member.lastname}</td>
                                                <td>{loan?.product.name}</td>
                                                <td>{formatNumber(loan.loan_amount)}</td>
                                                <td>
                                                    {surety.status === 1 && <span className="tag tag-success">Approved</span>}
                                                    {surety.status === 0 && <span className="tag tag-warning">Pending</span>}
                                                    {surety.status === 2 && <span className="tag tag-danger">Denied</span>}
                                                </td>
                                                <td>
                                                    {surety.status === 0 &&
                                                    <>
                                                        <button 
                                                            class="btn btn-success approve tooltips" 
                                                            onClick={() => dispatch(updateSuretyResponse({type: 'approve', id: surety.id}))} 
                                                            data-toggle="modal" data-target="#confirmSuretyModal"
                                                        >
                                                            <i class="fa fa-check"></i>
                                                        </button> 
                                                        <button 
                                                            class="btn btn-danger deny tooltips"
                                                            onClick={() => dispatch(updateSuretyResponse({type: 'deny', id: surety.id}))} 
                                                            data-toggle="modal" data-target="#confirmSuretyModal"
                                                        >
                                                            <i class="fa fa-times"></i>
                                                        </button>
                                                    </>
                                                    }
                                                </td>
                                            </tr>)}
                                            {suretyData?.data.length === 0 && <tr><td colSpan={5}><Empty /></td></tr>}
                                            
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    )
}