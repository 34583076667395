import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from 'react-router-dom';
import { useGetDashboarDataQuery, useGetDashboarTileDataQuery } from "../../../services/utility.service";
import { formatNumber } from "../../../utils/helpers";
import { setActiveCoop } from "../../../redux/slices/cooperative.slice";
import { updateRole } from "../../../redux/slices/user.slice";
import alertify from "alertifyjs";
import { changeColors, resetColors } from "../../../redux/slices/ui.slice";

export const AdminDashboard = ({fixNavbar}) => {
    const dispatch = useDispatch();
    const navigate = useHistory();
    const {data: dashboardData} = useGetDashboarDataQuery();
    const {data: tileData} = useGetDashboarTileDataQuery();

    function changeActiveCoop(e) {
		dispatch(setActiveCoop(e));
		dispatch(updateRole('cooperative-admin'));
        if (e && e.colors) {
            const colors = JSON.parse(e.colors);
            console.log(colors)
		    dispatch(changeColors(JSON.parse(colors)));
        } else {
            dispatch(resetColors());
        }

        alertify.success(`You are now viewing data for ${e?.name || 'Admin'}.`);
        navigate.push('/members');
	}

    return (
        <div>
            <div className={`section-body ${fixNavbar ? "marginTop" : ""} mt-3`}>
                <div className="container-fluid">
                    <div className="row clearfix">
                        <div className="col-lg-12">
                            <div className={`section-body ${fixNavbar ? "mb-4 mt-3" : "mb-4"}`}>
                                <h4>Welcome Jason Porter!</h4>
                                {/* <small>
                                    Measure How Fast You’re Growing Monthly Recurring Revenue.{' '}
                                    <a href="fake_url">Learn More</a>
                                </small> */}
                            </div>
                        </div>
                    </div>
                    <div className="row clearfix">
                        <div className="col-6 col-md-4 col-xl-2">
                            <div className="card">
                                <div className="card-body ribbon">
                                    <div className="ribbon-box green">{tileData?.data.user}</div>
                                    <Link to="/setup/users" className="my_sort_cut text-muted">
                                        <i className="icon-users" />
                                        <span>Users</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-4 col-xl-2">
                            <div className="card">
                                <div className="card-body">
                                    <Link to="/setuo/voting" className="my_sort_cut text-muted">
                                        <i className="icon-like" />
                                        <span>Votings</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-4 col-xl-2">
                            <div className="card">
                                <div className="card-body ribbon">
                                    <div className="ribbon-box orange">{tileData?.data.store}</div>
                                    <Link 
                                        to="/e-shop/dashboard" 
                                        className="my_sort_cut text-muted"
                                        onClick={() => dispatch({type: 'UPDATE_ROLE', payload: 'eshop'})}
                                    >
                                        <i className="icon-basket" />
                                        <span>Store</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-6 col-md-4 col-xl-2">
                            <div className="card">
                                <div className="card-body">
                                    <Link to="/setup/chart-of-accounts" className="my_sort_cut text-muted">
                                        <i className="icon-calculator" />
                                        <span>GL Accounts</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-4 col-xl-2">
                            <div className="card">
                                <div className="card-body">
                                    <Link to="/reports/consolidated-report" className="my_sort_cut text-muted">
                                        <i className="icon-pie-chart" />
                                        <span>Report</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-4 col-xl-2">
                            <div className="card">
                                <div className="card-body">
                                    <Link to="#" className="my_sort_cut text-muted">
                                        <i className="icon-settings" />
                                        <span>Settings</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-body">
                <div className="container-fluid">
                    <div className="row clearfix">
                        <div className="col-12 col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Cooperative List</h3>
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table table-hover table-striped text-nowrap table-vcenter mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Code</th>
                                                    <th>Name</th>
                                                    <th>Active Members</th>
                                                    <th>Inactive Members</th>
                                                    <th>Total Savings</th>
                                                    <th>Status</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {dashboardData?.data.map(coop => 
                                                    <tr key={coop.id}>
                                                        <td>{coop.code}</td>
                                                        <td>{coop.name}</td>
                                                        <td>{coop.meta.activeMembers}</td>
                                                        <td>{coop.meta.inactiveMembers}</td>
                                                        <td>{formatNumber(coop.meta.totalSavings)}</td>
                                                        <td>
                                                            {coop.status === 1 && <span className="tag tag-success">Active</span>}
                                                            {coop.status === 0 && <span className="tag tag-danger">Inactive</span>}
                                                        </td>
                                                        <td>
                                                            <button type="button" onClick={() => changeActiveCoop(coop)} className="btn btn-primary"><i className="mdi mdi-export mr-2"></i>View Members</button>
                                                        </td>
                                                    </tr>
                                                )}
                                                
                                                
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}