import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useGetAllCoaQuery, useSaveJournalEntryMutation, useUpdateJournalEntryMutation } from "../../services/cooperative-setup.service";
import { DatePicker, Select } from "antd";
import { filterOption } from "../../utils/helpers";
import alertify from "alertifyjs";

export const JournalEntryForm = ({journal, onCancel, coopId}) => {
    const {data: glData} = useGetAllCoaQuery(coopId, {skip: !coopId});
    const [save, {isLoading, isSuccess, isError, data, error}] = useSaveJournalEntryMutation();
    const [update, {
        isLoading: isUpdating, 
        isSuccess: isUpdateSuccess, 
        isError: isUpdateError, 
        data: updateData,
        error: updateError
    }] = useUpdateJournalEntryMutation();

    const [formData, setFormData] = useState({
        cooperative_id: coopId,
        transactions: [
            {
                formatDate: undefined,
                date: "",
                description: "",
                gl_code: "",
                debit: 0,
                credit: 0
            },
            {
                formatDate: undefined,
                date: "",
                description: "",
                gl_code: "",
                debit: 0,
                credit: 0
            }
        ]
    });

    const addRow = () => {
        const newArr = [...formData.transactions];
        newArr.push(
            {
                formatDate: undefined,
                date: "",
                description: "",
                gl_code: "",
                debit: 0,
                credit: 0
            }
        )
        setFormData({...formData, transactions: newArr});
    }

    const removeRow = (i) => {
        const arr = [...formData.transactions];
        arr.splice(i, 1);
        setFormData({...formData, transactions: arr});
    }

    const handlChange = (field, value, index) => {
        const transactions = [...formData.transactions];
        transactions[index][field] = value;
        setFormData({...formData, transactions});
    }

    useEffect(() => {
        if (journal) {
            const transactions = journal.map(item => ({
                formatDate: dayjs(item.date),
                id: item.id,
                date: item.date,
                description: item.description,
                gl_code: item.gl_code,
                debit: item.debit,
                credit: item.credit
            }))
            setFormData({...formData, transactions});
        }
    }, [journal]);

    useEffect(() => {
        
        if(isSuccess || isUpdateSuccess) {
            // resetForm();
            onCancel()
            // show toast.
            alertify.success(data?.message || updateData?.message)
        }

        if (updateData && updateData.status) 
            document.getElementById('close-offset').click()


        if (isError || isUpdateError) {
            alertify.error(error?.data?.message || updateError?.data?.message);
        }
    }, [isSuccess, isUpdateSuccess, isUpdateError, isError, data, updateData]);

    const submit = () => {
        const payload = {...formData};

        if (journal) {
            payload.transaction_code = journal[0].transaction_code;
            update(payload);
        } else {
            save(payload);
        }
    }

    return (
        <div className="modal fade" id="journal-form" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className={`modal-dialog modal-lg`} role="document">
                <div className={`modal-content dimmer ${(isLoading || isUpdating) ? 'active' : ''}`}>
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">{journal ? `Edit` : `Add`} Journal Entry</h5>
                        <button type="button" id="close-offset" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                    </div>
                    <div className="loader" />
                    <div className="modal-body dimmer-content">
                        {formData.transactions.map((transaction, i) => (
                            <div className='row clearfix' key={`entry-${i}`}>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Date</label>
                                        <DatePicker
                                            className={`customInput w-full`} 
                                            name={'date_purchased'} 
                                            style={{width: '100%'}}
                                            value={transaction.formatDate} 
                                            onChange={(value) => handlChange('date', dayjs(value).format('YYYY-MM-DD'), i)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Description</label>
                                        <input 
                                            type="text"
                                            className="form-control" 
                                            placeholder="" 
                                            name="description"
                                            value={transaction.description}
                                            onChange={(e) => handlChange('description', e.target.value, i)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label>GL Code</label>
                                        <Select 
                                            showSearch
                                            className="customSelect mt-1"
                                            options={glData?.data.map(coa => ({value: coa.code, label: coa.name}))} 
                                            placeholder="Select GL Account"
                                            filterOption={filterOption}
                                            value={transaction.gl_code}
                                            onChange={(value) => handlChange('gl_code', value, i)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label>Debit</label>
                                        <input 
                                            type="number" 
                                            className="form-control" 
                                            placeholder="" 
                                            value={transaction.debit}
                                            onChange={(e) => handlChange('debit', e.target.value, i)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label className='d-flex justify-content-between align-items-center'>
                                            <span>Credit</span>
                                            {i > 1 && !journal &&

                                                <i 
                                                    className='fa fa-minus' 
                                                    onClick={() => removeRow(i)} 
                                                    style={{color: 'red', cursor: 'pointer'}} 
                                                />
                                            }
                                        </label>
                                        <input 
                                            type="number" 
                                            className="form-control" 
                                            placeholder="" 
                                            value={transaction.credit}
                                            onChange={(e) => handlChange('credit', e.target.value, i)}
                                        />
                                        
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="modal-footer d-flex justify-content-between">
                        <div className='align-items-start'>
                           {!journal && <button type="button" className="btn btn-info" onClick={addRow}>
                                <i className='fe fe-plus-circle' /> Add Row
                            </button>}
                        </div>
                        <div>
                            <button type="button" className="btn btn-secondary mr-2" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" onClick={submit}>Save changes</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}