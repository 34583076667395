// enviroments
const environment = {
  production: {
    API_BASE_URL: process.env.REACT_APP_API_URL,
  },
  development: {
    API_BASE_URL: process.env.REACT_APP_API_URL,
  },
};

const currentEnvironment = process.env.REACT_APP_PUBLIC_ENV || "development";
// const currentEnvironment = "production";

export default environment[currentEnvironment];
