import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { doDownload, formatNumber } from "../../utils/helpers";
import { useLazyGetTransactionHistoryQuery } from "../../services/member.service";
import { DatePicker, Empty } from 'antd';
import { Pagination } from "../Pagination/Pagination";
import { MEMBER_TRANSACTION_HISTORY } from "../../services/CONSTANTS";
var advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)

export const TransactionHistory = () => {
    const {isFixNavbar: fixNavbar} = useSelector(state => state.settings);
    const {activeCoop} = useSelector(state => state.coop);
    const {user, token} = useSelector(state => state.user);
    const [isDownloading, setIsDownloading] = useState(false);

    const [getTransactions, {data, isLoading}] = useLazyGetTransactionHistoryQuery();
    const [transactions, setTransactions] = useState([]);
    const [pagination, setPagination] = useState({
        total: 0,
        perPage: 0,
        currentPage: 1,
        first: 1,
        last: 1
    });

    useEffect(() => {
        if (user) {
            getTransactions({
                id: activeCoop?.id, 
                coopId: user?.coop_id, 
                page: 1
            })
        }
    }, [user]);

    useEffect(() => {
        if (data?.status) {
            setTransactions(data?.data?.data);

            setPagination({
                total: data?.data?.meta?.total,
                perPage: data?.data?.meta?.per_page,
                currentPage: data?.data?.meta?.current_page,
                first: data.data.meta?.first_page,
                last: data.data.meta?.last_page
            })
        }
    }, [data]);


    const download = async () => {
        setIsDownloading(true);
        const url = `${process.env.REACT_APP_API_URL}${MEMBER_TRANSACTION_HISTORY}?cooperative_id=${activeCoop?.id}&coop_id=${user?.coop_id}&download=true`
        await doDownload(url, `transaction-history-${dayjs().unix()}.xlsx`, token);
        setIsDownloading(false)
    }

    return (
        <>
            <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-3 col-md-4 col-sm-6">
                                    <div className="form-group">
                                        <select className="form-control show-tick">
                                            <option>Current Month</option>
                                            <option>Last Month</option>
                                            <option>Last 30 Days</option>
                                            <option>Current Year</option>
                                            <option>Last Year</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-3 col-sm-6">
                                    <DatePicker
                                        className={`customInput w-full`} 
                                        name={'start'} 
                                        placeholder='Start Date'
                                        style={{width: '100%'}}
                                    />
                                </div>
                                <div className="col-lg-2 col-md-3 col-sm-6">
                                    <DatePicker
                                        className={`customInput w-full`} 
                                        name={'start'} 
                                        placeholder='End Date'
                                        style={{width: '100%'}}
                                    />
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6">
                                    <button className="btn btn-sm btn-primary btn-block" onClick={() => getTransactions({
                id: activeCoop?.id, 
                coopId: user?.coop_id, 
                page: 1
            })}><i className="fe fe-search mr-2" />Search</button>
                                </div>
                                <div className="col-lg-2 col-md-3 col-sm-6">
                                    <button className="btn btn-sm btn-secondary btn-block" onClick={download}>
                                        {isDownloading ?  <i className="fa fa-spinner fa-spin mr-2" />:  <i className="fe fe-download mr-2" />}
                                        Download
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`section-body dimmer ${isLoading ? 'active' : ''}`}>
                <div className="loader" />
                <div className="container-fluid dimmer-content">
                    <div className="table-responsive" id="users">
                        <table className="table table-vcenter table_custom spacing5 border-style mb-0">
                            <thead>
                                <tr>
                                    <th>REFERENCE NO</th>
                                    <th>TYPE</th>
                                    <th>DESCRIPTION</th>
                                    <th>AMOUNT</th>
                                    <th>DATE</th>
                                    
                                </tr>
                            </thead>
                            <tbody>
                                {transactions.map(row => 
                                    <tr key={`transaction-${row.id}`}>
                                        <td>{row.reference_no}</td>
                                        <td>{row.type.name}</td>
                                        <td>{row.description}</td>
                                        <td><div className="font-15">{formatNumber(row.amount)}</div></td>
                                        <td>
                                            {dayjs(row.transaction_date).format('Do MMM YYYY, hh:mma')}
                                        </td>
                                
                                    </tr>
                                )}
                                {data?.data.meta.total === 0 && <tr><td colSpan={5}><Empty /></td></tr>}
                            </tbody>
                        </table>
                    </div>
                    {pagination.last > 1 && 
                        <Pagination 
                            {...pagination} 
                            changePage={(page) => {
                                getTransactions({
                                    coopId: user.coop_id,
                                    page,
                                    id: activeCoop?.id,
                                })
                            }} 
                        />
                    }
                </div>
            </div>
        </>
    )
}