import React, { useEffect } from "react";
import alertify from "alertifyjs";
import { useApproveSuretyRequestMutation, useDenySuretyRequestMutation } from "../../services/member.service";
import { useDispatch, useSelector } from "react-redux";
import { updateSuretyResponse } from "../../redux/slices/user.slice";

export default function SuretyResponseModal() {
    const [approveSurety, {isLoading: aIsLoading, isSuccess: aIsSuccess, isError: aIsError, data: aData, error: aError}] = useApproveSuretyRequestMutation();
    const [denySurety, {isLoading, isSuccess, isError, data, error}] = useDenySuretyRequestMutation();
    const {activeCoop} = useSelector(state => state.coop);
    const {suretyResponse} = useSelector(state => state.user);
    const dispatch = useDispatch();

    useEffect(() => {
        if (isSuccess && data.status)  {
            document.getElementById('close-surety-modal').click();
            alertify.success(data?.message);
        }

        if(isError) {
            alertify.error(error.data.message)
        }


    }, [isSuccess, isError, error, data]);

    useEffect(() => {
        if (aIsSuccess && aData.status)  {
            document.getElementById('close-surety-modal').click();
            alertify.success(aData?.message);
        }

        if(aIsError) {
            alertify.error(aError.data.message)
        }


    }, [aIsSuccess, aIsError, aError, aData]);


    const onConfirm = () => {
        if (suretyResponse?.type === 'approve') {
            approveSurety({coopId: activeCoop?.id, id: suretyResponse.id});
        } else {
            denySurety({coopId: activeCoop?.id, id: suretyResponse.id})
        }
    }

    return (
        <div className="modal fade" id="confirmSuretyModal" tabIndex={-1} role="dialog" aria-labelledby="confirmSuretyModal" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className={`modal-content dimmer ${isLoading || aIsLoading ? 'active' : ''}`}>
                    <div className="loader" />
                    
                    <div className="modal-body dimmer-content">
                        <div className="form-header text-center">
                            <h3>Confirm Surety Request</h3>
                            <p>Are you sure want to {suretyResponse?.type} this request?</p>
                        </div>
                        <div className="modal-btn delete-action">
                            <div className="row">
                                <div className="col-6">
                                    <a 
                                        href="javascript:;"
                                        id="close-surety-modal" 
                                        data-dismiss="modal" 
                                        className="btn btn-secondary btn-block"
                                        onClick={() => dispatch(updateSuretyResponse({type: null, id: null}))}
                                    >
                                        Cancel
                                    </a>
                                </div>
                                <div className="col-6">
                                    <a
                                        href="javascript:;"
                                        onClick={onConfirm}
                                        className="btn btn-primary btn-block"
                                    >
                                        {isLoading || aIsLoading ? 'Please Wait...' : 'Confirm'}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
