
import React from "react";
import { useSelector } from "react-redux";
import { UploadDividend } from "./UploadDividend";

const ComputeDividend = () => {
    const {isFixNavbar: fixNavbar} = useSelector(state => state.settings);

    return (
        <div>
            <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-3 col-md-3 col-sm-4">
                                    <div className="form-group">
                                        <input type="text" className="form-control" placeholder="SAvings Percentage" />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-4">
                                    <div className="input-icon mb-3">
                                        <input type="text" className="form-control" placeholder="Loan Paid" />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-4">
                                    <div className="input-icon mb-3">
                                        <input type="text" className="form-control" placeholder="Total Loans" />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-4">
                                    <div className="input-icon mb-3">
                                        <input type="text" className="form-control" placeholder="From" />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-4">
                                    <div className="input-icon mb-3">
                                        <input type="text" className="form-control" placeholder="X Variable Percentage" />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-4">
                                    <div className="input-icon mb-3">
                                        <input type="text" className="form-control" placeholder="Y Variable Percentage" />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-4">
                                    <div className="input-icon mb-3">
                                        <input type="text" className="form-control" placeholder="Dividend Amount" />
                                    </div>
                                </div>
                                
                                <div className="col-lg-3 col-md-3 col-sm-4">
                                    <div className="input-icon mb-3">
                                        <input type="text" className="form-control" placeholder="To" />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-4">
                                    <a href="fake_url" className="btn btn-sm btn-primary btn-block">Compute</a>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-4">
                                    <a href="fake_url" className="btn btn-sm btn-outline btn-block">Cancel</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-body mt-3">
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Details</h3>
                            <div className="card-options">
                                <form>
                                    <div className="input-group">

                                        <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#upload-dividend"><i className="fe fe-upload mr-2" />Upload Dividend</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table table-striped table-vcenter table-hover mb-0">
                                    <thead>
                                        <tr>
                                            <th>Coop ID</th>
                                            <th>Name</th>
                                            <th>Savings</th>
                                            <th>Calc. AMT</th>
                                            <th>Loan Paid</th>
                                            <th>Calc. Loan</th>
                                            <th>Total Loan</th>
                                            <th>Calc. Total Loan</th>
                                            <th>Div AMT</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="text-center">
                                            <td colSpan={9}>No Data</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <UploadDividend />
        </div>
    )
}

export default ComputeDividend;