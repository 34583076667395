
import { createSlice } from "@reduxjs/toolkit";


export const userSlice = createSlice({
  name: "user",
  initialState: { 
    isAuthenticated: false,
    user: null,
    token: null,
    role: 'admin',
    activeCoop: null,
    suretyResponse: {
      type: null,
      id: null,
    }
  },
  reducers: {
    updateUser: (state, { payload }) => {
      const userObj = payload;
      const newState = { ...state };
      Object.assign(newState, userObj);
      return newState;
    },
    logoutUser: (state) => {
      const newState = { ...state };
      newState.user = null;
      newState.token = null;
      newState.isAuthenticated = false;
      return newState;
    },
    updateRole: (state, { payload }) => {
      const newState = { ...state };
      newState.role = payload;
      return newState;
    },
    updateSuretyResponse: (state, {payload}) => {
      const newState = { ...state };
      newState.suretyResponse = payload;
      return newState;
    },
    setUser: (state, {payload}) => {
      const newState = { ...state };
      newState.user = payload;
      return newState;
    }
  },
});

export const { updateUser, logoutUser, updateRole, updateSuretyResponse, setUser } = userSlice.actions;
export default userSlice.reducer;
