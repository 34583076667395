import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useGetRolesQuery } from '../../../services/setup.service';
import { RolesTable } from './RolesTable';
import { RolesForm } from './RolesForm';

const AccessControl = () => {

    const {isFixNavbar: fixNavbar} = useSelector(state => state.settings);

    const {data, isLoading, isError} = useGetRolesQuery(undefined)
    const [role, setRole] = useState(null)

    return (
        <>
            <div>
                <div>
                    <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
                        <div className="container-fluid">
                            <div className="d-flex justify-content-between align-items-center">
                                <ul className="nav nav-tabs page-header-tab">
                                    <li className="nav-item"><a className="nav-link active" id="Departments-tab" data-toggle="tab" href="#Departments-list">All Roles</a></li>
                                    <li className="nav-item"><a className="nav-link" id="Departments-tab" data-toggle="tab" href="#Departments-grid">Role Access</a></li>
                                </ul>
                                <div className="header-action">
                                    <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#addRole">
                                        <i className="fe fe-plus mr-2" /> Add
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section-body mt-3">
                        <div className="container-fluid">
                            <div className="tab-content mt-3">
                                <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                    <div className={`card dimmer ${isLoading ? 'active' : ''}`}>
                                        <div className="card-header">
                                            <h3 className="card-title">Roles List</h3>
                                        </div>
                                        <div className="loader" />
                                        <div className="card-body dimmer-content">
                                            <RolesTable data={data?.data} onEdit={(val) => setRole(val)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="Departments-grid" role="tabpanel">
                                    <div className="row clearfix">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row clearfix">
                                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                                        <div className="form-group">
                                                            <select className="form-control show-tick">
                                                                <option>Select Role Type</option>
                                                                <option>Super Admin</option>
                                                                <option>Admin</option>
                                                                <option>Employee</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="col-12">
                                                        <hr className="mt-4" />
                                                        <h6>Module Permission</h6>
                                                        <div className="table-responsive">
                                                            <table className="table table-striped">
                                                                <thead>
                                                                    <tr>
                                                                        <th />
                                                                        <th>Read</th>
                                                                        <th>Write</th>
                                                                        <th>Delete</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Members</td>
                                                                        <td>
                                                                            <label className="custom-control custom-checkbox">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="custom-control-input"
                                                                                    name="example-checkbox1"
                                                                                    defaultValue="option1"
                                                                                    defaultChecked
                                                                                />
                                                                                <span className="custom-control-label">
                                                                                    &nbsp;
                                                                                </span>
                                                                            </label>
                                                                        </td>
                                                                        <td>
                                                                            <label className="custom-control custom-checkbox">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="custom-control-input"
                                                                                    name="example-checkbox1"
                                                                                    defaultValue="option1"
                                                                                    defaultChecked
                                                                                />
                                                                                <span className="custom-control-label">
                                                                                    &nbsp;
                                                                                </span>
                                                                            </label>
                                                                        </td>
                                                                        <td>
                                                                            <label className="custom-control custom-checkbox">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="custom-control-input"
                                                                                    name="example-checkbox1"
                                                                                    defaultValue="option1"
                                                                                    defaultChecked
                                                                                />
                                                                                <span className="custom-control-label">
                                                                                    &nbsp;
                                                                                </span>
                                                                            </label>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Loans</td>
                                                                        <td>
                                                                            <label className="custom-control custom-checkbox">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="custom-control-input"
                                                                                    name="example-checkbox1"
                                                                                    defaultValue="option1"
                                                                                    defaultChecked
                                                                                />
                                                                                <span className="custom-control-label">
                                                                                    &nbsp;
                                                                                </span>
                                                                            </label>
                                                                        </td>
                                                                        <td>
                                                                            <label className="custom-control custom-checkbox">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="custom-control-input"
                                                                                    name="example-checkbox1"
                                                                                    defaultValue="option1"
                                                                                />
                                                                                <span className="custom-control-label">
                                                                                    &nbsp;
                                                                                </span>
                                                                            </label>
                                                                        </td>
                                                                        <td>
                                                                            <label className="custom-control custom-checkbox">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="custom-control-input"
                                                                                    name="example-checkbox1"
                                                                                    defaultValue="option1"
                                                                                />
                                                                                <span className="custom-control-label">
                                                                                    &nbsp;
                                                                                </span>
                                                                            </label>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Savings</td>
                                                                        <td>
                                                                            <label className="custom-control custom-checkbox">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="custom-control-input"
                                                                                    name="example-checkbox1"
                                                                                    defaultValue="option1"
                                                                                    defaultChecked
                                                                                />
                                                                                <span className="custom-control-label">
                                                                                    &nbsp;
                                                                                </span>
                                                                            </label>
                                                                        </td>
                                                                        <td>
                                                                            <label className="custom-control custom-checkbox">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="custom-control-input"
                                                                                    name="example-checkbox1"
                                                                                    defaultValue="option1"
                                                                                />
                                                                                <span className="custom-control-label">
                                                                                    &nbsp;
                                                                                </span>
                                                                            </label>
                                                                        </td>
                                                                        <td>
                                                                            <label className="custom-control custom-checkbox">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="custom-control-input"
                                                                                    name="example-checkbox1"
                                                                                    defaultValue="option1"
                                                                                />
                                                                                <span className="custom-control-label">
                                                                                    &nbsp;
                                                                                </span>
                                                                            </label>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Setup</td>
                                                                        <td>
                                                                            <label className="custom-control custom-checkbox">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="custom-control-input"
                                                                                    name="example-checkbox1"
                                                                                    defaultValue="option1"
                                                                                    defaultChecked
                                                                                />
                                                                                <span className="custom-control-label">
                                                                                    &nbsp;
                                                                                </span>
                                                                            </label>
                                                                        </td>
                                                                        <td>
                                                                            <label className="custom-control custom-checkbox">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="custom-control-input"
                                                                                    name="example-checkbox1"
                                                                                    defaultValue="option1"
                                                                                    defaultChecked
                                                                                />
                                                                                <span className="custom-control-label">
                                                                                    &nbsp;
                                                                                </span>
                                                                            </label>
                                                                        </td>
                                                                        <td>
                                                                            <label className="custom-control custom-checkbox">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="custom-control-input"
                                                                                    name="example-checkbox1"
                                                                                    defaultValue="option1"
                                                                                    defaultChecked
                                                                                />
                                                                                <span className="custom-control-label">
                                                                                    &nbsp;
                                                                                </span>
                                                                            </label>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <button type="button" className="btn btn-primary">
                                                            Add
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn btn-secondary"
                                                            data-dismiss="modal"
                                                        >
                                                            CLOSE
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                {/* Modal */}
                <RolesForm role={role} />
            </div>
        </>
    )
}
export default AccessControl