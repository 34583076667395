import { Empty, Tooltip } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toggleDelete } from "../../../redux/slices/ui.slice";
import { DELETE_ASSET } from "../../../services/CONSTANTS";
import Card from "../../common/Card";
import { useGetAllAssetsQuery } from "../../../services/cooperative-setup.service";
import { formatNumber } from "../../../utils/helpers";
import ApproveDenyAsset from "../../common/ApproveDenyAsset";

export const AssetSetupTable = ({activeCoop, onEdit}) => {
    const dispatch = useDispatch();
    const [selectedItem, setSelected] = useState(null);
    const {data, isLoading} = useGetAllAssetsQuery(activeCoop?.id, {skip: !activeCoop})

    const doDelete = (e, item) => {
        dispatch(
          toggleDelete({
            open: true,
            title: 'Asset Type',
            url: `${DELETE_ASSET}?cooperative_id=${activeCoop.id}&id=${item.id}`,
            // ele: JSON.stringify(e.currentTarget),
            parent: 'tr'
          })
        );
    }


    return (
        <Card 
            loading={isLoading} 
            title={`Assets`} 
            headerOptions={
                <div className="card-options">
                    <button className="btn btn-info">
                        <i className="fa fa-file-excel-o" />
                        {" "}
                        Excel
                    </button>
                </div>
        }>
            <div className="card-body dimmer-content">
                <div className="table-responsive">
                    <table className="table table-hover table-striped table-vcenter text-nowrap mb-0">
                        <thead>
                            <tr>
                                <th>Asset Type</th>
                                <th>Asset Name</th>
                                <th>Initial Cost</th>
                                <th>Res. Value</th>
                                <th>Useful life</th>
                                <th>Calc. Method</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.data?.data.map(row => 
                                <tr key={`asset-${row.id}`}>
                                    <td>
                                    <span>{row.asset_type.name}</span>
                                    </td>
                                    <td>
                                        <span>{row.name}</span>
                                    </td>
                                    <td>
                                        <span>{formatNumber(row.initial_cost)}</span>
                                    </td>
                                    <td>
                                        <span>{formatNumber(row.residual_value)}</span>
                                    </td>
                                    <td>
                                        <span>{row.useful_life}</span>
                                    </td>
                                    <td>
                                        <span>{row.calculation_method.name}</span>
                                    </td>
                                    <td>
                                    {row.status === 0 && <span className='tag tag-warning'>Pending</span>}
                                            {row.status === 1 && <span className='tag tag-success'>Approved</span>}
                                            {row.status === 2 && <span className='tag tag-danger'>Denied</span>}
                                    </td>
                                    <td>
                                        <Tooltip title="Edit">
                                            <button type="button" className="btn btn-icon btn-sm btn-sm" onClick={() => onEdit(row)}>
                                                <i className="icon-pencil text-info" />
                                            </button>
                                        </Tooltip>
                                        {row.status === 0 &&
                                            <Tooltip title="Approve">
                                                <button type="button" className="btn btn-icon" onClick={() => setSelected({id: row.id, type: 'asset', action: 'approve'})} data-target="#assetModal" data-toggle="modal">
                                                    <i className="fe fe-check-circle text-success" />
                                                </button>
                                            </Tooltip> }
                                            {row.status === 0 &&
                                            <Tooltip title="Deny">
                                                <button type="button" className="btn btn-icon" onClick={() => setSelected({id: row.id, type: 'asset', action: 'deny'})} data-target="#assetModal" data-toggle="modal">
                                                    <i className="fe fe-x-circle text-warning" />
                                                </button>
                                            </Tooltip>}
                                        <Tooltip title="Delete">
                                            <button 
                                                type="button" 
                                                className="btn btn-icon btn-sm" 
                                                data-target="#deleteModal" data-toggle="modal" 
                                                onClick={(e) => doDelete(e, row)}
                                            >
                                                <i className="icon-trash text-danger" />
                                            </button>
                                        </Tooltip>
                                    </td>
                                </tr>
                            )}
                            {data?.data.length === 0 && <tr><td colSpan={8}><Empty /></td></tr>}
                        </tbody>
                    </table>
                </div>
            </div>
            <ApproveDenyAsset 
                id={selectedItem?.id} type={selectedItem?.type} action={selectedItem?.action} 
                coopId={activeCoop?.id} 
                title="Asset"
            />
        </Card>
    )
}