
import { createSlice } from "@reduxjs/toolkit";


export const settingSlice = createSlice({
  name: "settings",
  initialState: { 
    isDarkMode: false, isDarkHeader: false, isFixNavbar: false, isMinSidebar: false, isDarkSidebar: false, isIconColor: false, isGradientColor: false,
	isRtl: false,
	isFont: "font-montserrat",
	isSubMenuIcon: "list-a",
	isMenuIcon: "list-c",
	isBoxLayout: false,
	isStatistics: true,
	isFriendList: true,
	isbox: true,
	isbox2: true,
	isbox3: true,
	isbox4: true,
	isbox5: true,
	isbox6: true,
	isbox7: true,
	isbox8: true,
	isbox9: true,
	isbox10: true,
	isBoxClose: true,
	isBox2Close: true,
	isBox3Close: true,
	isBox4Close: true,
	isBox5Close: true,
	isBox6Close: true,
	isBox7Close: true,
	isBox8Close: true,
	isBox9Close: true,
	isBox10Close: true,
	isStatisticsClose: true,
	isFriendListClose: true,
	isToggleLeftMenu: false
  },
  reducers: {
    toggleNightMode: (state, { payload }) => {
      const newState = { ...state };
      newState.isDarkMode = payload;
      return newState;
    },
    toggleDarkHeader: (state, { payload }) => {
        const newState = { ...state };
        newState.isDarkHeader = payload;
        return newState;
    },
    toggleFixedNavbar: (state, { payload }) => {
        const newState = { ...state };
        newState.isFixNavbar = payload;
        return newState;
    },
    toggleMinSideBar: (state, { payload }) => {
        const newState = { ...state };
        newState.isMinSidebar = payload;
        return newState;
    },
    toggleDarkSidebar: (state, { payload }) => {
        const newState = { ...state };
        newState.isDarkSidebar = payload;
        return newState;
    },
    toggleGradientColor: (state, { payload }) => {
        const newState = { ...state };
        newState.isGradientColor = payload;
        return newState;
    },
    changeIconColor: (state, { payload }) => {
        const newState = { ...state };
        newState.isIconColor = payload;
        return newState;
    },
    toggleRTL: (state, { payload }) => {
        const newState = { ...state };
        newState.isRtl = payload;
        return newState;
    },
    changeFont: (state, { payload }) => {
        const newState = { ...state };
        newState.isFont = payload;
        return newState;
    },
    changeSubmenuIconColor: (state, { payload }) => {
        const newState = { ...state };
        newState.isSubMenuIcon = payload;
        return newState;
    },
    changeMenuIconColor: (state, { payload }) => {
        const newState = { ...state };
        newState.isMenuIcon = payload;
        return newState;
    },
    toggleBoxLayout: (state, { payload }) => {
        const newState = { ...state };
        newState.isBoxLayout = payload;
        return newState;
    },
    toggleLeftMenu: (state, { payload }) => {
        const newState = { ...state };
        newState.isToggleLeftMenu = payload;
        return newState;
    },
  },
});

export const { 
    toggleNightMode, toggleDarkHeader, toggleFixedNavbar, toggleRTL, changeFont,
    toggleMinSideBar, toggleDarkSidebar, changeIconColor, toggleGradientColor,
    changeSubmenuIconColor, changeMenuIconColor, toggleBoxLayout, toggleLeftMenu
} = settingSlice.actions;
export default settingSlice.reducer;
