import React, { Component, useState } from 'react'
import { connect, useSelector } from 'react-redux';
import { InvestmentTrackerForm } from './Modals/InvestmentTrackerForm';
import { useGetInvestmentTrackerQuery } from '../../services/utility.service';
import { formatNumber } from '../../utils/helpers';
import { CloseInvestmentTracker } from './Modals/CloseInvestmentTracker';

const InvestmentTracker = () => {

    const {isFixNavbar: fixNavbar} = useSelector(state => state.settings);
    const {activeCoop} = useSelector(state => state.coop);
    const [selected, setSelected] = useState(null);
    const {data, isLoading} = useGetInvestmentTrackerQuery({id: activeCoop?.id, page: 1}, {
        skip: !activeCoop
    });

    return (
        <>
            <div>
                <div className={`section-body mt-3 ${fixNavbar ? "marginTop" : ""}`}>
            
                    <div className="container-fluid">
                        <div className="d-flex justify-content-between align-items-center">
                            <ul className="nav nav-tabs page-header-tab">
                                {/* <li className="nav-item"><a className="nav-link active" id="Project-tab" data-toggle="tab" href="#Project-OnGoing">Polls</a></li>
                                <li className="nav-item"><a className="nav-link" id="Project-tab" data-toggle="tab" href="#Project-UpComing">Results</a></li> */}
                            </ul>
                            <div className="header-action d-md-flex">
                            
                                <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#investment_tracker_form">
                                    <i className="fe fe-plus mr-2" />Add
                                </button>
                            </div>
                        </div>
                        <div className={`card mt-4 dimmer ${isLoading ? 'active' : ''}`}>
                            <div className="dimmer-content">
                                <div className='loader' />
                                <div className="table-responsive">
                                    <table className="table table-hover table-bordered table-striped table-vcenter mb-0">
                                        <thead>
                                            <tr>
                                                <th>Invested With</th>
                                                <th>Invested Amount</th>
                                                <th>Expected Income</th>
                                                <th>Start Date</th>
                                                <th>Due Date</th>
                                                <th>Status</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data?.data?.data.map(row => 
                                                <tr key={`investment-tracker-${row.id}`}>
                                                    <td>{row.invested_with}</td>
                                                    <td>{formatNumber(row.investment_amount)}</td>
                                                    <td>{formatNumber(row.investment_income)}</td>
                                                    <td>{row.start_date}</td>
                                                    <td>{row.due_date}</td>
                                                    <td>
                                                        {row.status === 1 && <span className="tag tag-success">Open</span>}
                                                        {row.status === 2 && <span className="tag tag-danger">Closed</span>}
                                                    </td>
                                                    {/* <td class="d-inline-block text-truncate" style={{ maxWidth: 250}}>{row.comment}</td> */}
                                                    <td>
                                                        {row.status === 1 && 
                                                        <button 
                                                            type='button' 
                                                            onClick={() => setSelected(row)} 
                                                            data-toggle="modal" data-target="#close_investment_tracker" 
                                                            className='btn btn-sm btn-primary'
                                                        >
                                                            Close
                                                        </button>}
                                                    </td>
                                                </tr>
                                            )}
                                            {!data?.data?.data.length &&
                                                [...Array(5)].map(n => 
                                                <tr key={`investment-empty-${n}`}>
                                                    <td colSpan={6}></td>
                                                    
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <InvestmentTrackerForm cooperative_id={activeCoop?.id} />
            <CloseInvestmentTracker cooperative_id={activeCoop?.id} investment={selected} />
        </>
    )
}

export default InvestmentTracker;