import React from "react";
import { useDispatch } from "react-redux";
import { DELETE_ROLES } from "../../../services/CONSTANTS";
import { toggleDelete } from "../../../redux/slices/ui.slice";
import { Tooltip } from "antd";

export const RolesTable = ({data, onEdit}) => {
    const dispatch = useDispatch();

    const doDelete = (e, item) => {
        // console.log(e.currentTarget)
        dispatch(
          toggleDelete({
            open: true,
            title: 'Role',
            url: `${DELETE_ROLES}?role_id=${item.id}`,
            // ele: JSON.stringify(e.currentTarget),
            parent: 'tr'
          })
        );
      }
    return (
        <div className="table-responsive">
            <table className="table table-striped table-vcenter table-hover mb-0">
                <thead>
                    <tr>
                        <th>Role</th>
                        <th>Type</th>
                        <th>Active</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {data?.map((row, i) => 
                    <tr key={row.id}>
                        <td><div className="font-15">{row.display_name}</div></td>
                        <td>{row.belong_to}</td>
                        <td>
                            {row.status === 1 && <span className="tag tag-success">Active</span>}
                            {row.status === 0 && <span className="tag tag-danger">Inactive</span>}
                        </td>
                        <td>
                        <Tooltip title="Edit Role">
                            <button type="button" className="btn btn-icon" data-toggle="modal" data-target="#addRole" onClick={() => onEdit(row)} title="Edit">
                                <i className="icon-pencil text-info" />
                            </button>
                            </Tooltip>
                            <Tooltip title="Delete Role">
                            <button type="button" className="btn btn-icon" data-toggle="modal" data-target="#deleteModal" onClick={(e) => doDelete(e, row)} title="Delete">
                            <i className="icon-trash text-danger" />
                            </button>
                            </Tooltip>
                        </td>
                    </tr>
                    )}
                </tbody>
            </table>
        </div>
    )
}