import React from "react";
import { Link } from 'react-router-dom';
import Card from "../../common/Card";

import { useGetMemberDashboardQuery } from "../../../services/utility.service";
import { DashboardRowItem } from "./DashboardRowItem";
import { Empty } from "antd";
import { useHistory } from "react-router-dom";
import { formatNumber } from "../../../utils/helpers";
import dayjs from "dayjs";

export const MemberDashboard = ({fixNavbar, user, activeCoop}) => {
    const {data, isLoading} = useGetMemberDashboardQuery({coop_id: activeCoop?.id, userId: user?.coop_id}, {skip: !activeCoop});

    const navigate = useHistory();

    return (
        <div>
            <div className={`section-body ${fixNavbar ? "marginTop" : ""} mt-3`}>
                <div className="container-fluid">
                    <div className="row clearfix">
                        <div className="col-lg-4 col-md-12">
                            <div className="card employee-welcome-card flex-fill">
                                <div className="card-body">
                                    <div className="welcome-info">
                                        <div className="welcome-content">
                                        <h4>Welcome Back, {user?.firstname}</h4>
                                        <p>
                                            You have <span 
                                                style={{cursor: 'pointer'}} 
                                                onClick={() => navigate.push('/member/loans/surety')}>
                                                    {data?.data?.surties?.length || 0} surtey</span> requests,
                                        </p>
                                        </div>
                                        <div className="welcome-img">
                                        <img
                                            src="/assets/images/user.png"
                                            className="img-fluid"
                                            alt="User"
                                        />
                                        </div>
                                    </div>
                                    <div className="welcome-btn">
                                        <Link to="/member/my-profile" className="btn">
                                        View Profile
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <Card title="Surety Requests" customClass={`dimmer ${isLoading ? 'active' : ''}`} styles={{minHeight: '323px'}}>
                                <div className="loader" />
                                <table className="table dimmer-content card-table mt-2">
                                    <tbody>
                                    {!isLoading && data?.data?.sureties?.length > 0 ? 
                                        data?.data?.sureties.map((row, i) => 
                                            <DashboardRowItem
                                                key={`surety-${i}`}
                                                name={row.loan.member.firstname + ' '+ row.loan.member.lastname}
                                                productName={row.loan.product.name}
                                                amount={row.loan.total_amount}
                                                status={row.loan.status}
                                                onClick={() => {
                                                    // setSelected(row);
                                                    // setType('transaction')
                                                }}
                                                type="surety"
                                            />
                                        )
                                        :
                                            <tr><td colSpan={3}><Empty /></td></tr>
                                        }
                                    </tbody>
                                </table>
                            </Card>
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <Card title="Savings Overview" customClass={`dimmer ${isLoading ? 'active' : ''}`}>
                                    <div className="row text-center">
                                        <div className="col-lg-6 col-sm-12 border-right">
                                            <label className="mb-0 font-10">Balance </label>
                                            <h4 className="font-20 font-weight-bold">{formatNumber(data?.data?.savings_overview?.balance)}</h4>
                                        </div>
                                        <div className="col-lg-6 col-sm-12 border-right">
                                            <label className="mb-0 font-10">Last Payment</label>
                                            <h4 className="font-20 font-weight-bold">{formatNumber(data?.data?.savings_overview?.histories[0]?.amount)}</h4>
                                        </div>
                                    </div>
                                    <div className="loader" />
                                    <table className="table dimmer-content  table-striped mt-4 mb-0">
                                        <tbody>
                                        {!isLoading && data?.data?.savings_overview?.histories?.length > 0 ? 
                                            data?.data?.savings_overview?.histories.map((row, i) => 
                                            <tr key={`savings-${i}`}>
                                                <td>
                                                    <label className="d-block">{row.type.name} <span className="float-right">{row.reference_no}</span></label>
                                                    <div className="">
                                                        {formatNumber(row.amount)}
                                                        <span className="float-right">{dayjs(row.transaction_date).format('DD MMM, YYYY')}</span>
                                                    </div>
                                                </td>
                                            </tr>
                                            )
                                            :
                                            <tr><td colSpan={1}><Empty /></td></tr>
                                        }
                                        </tbody>
                                    </table>
                                {/* <div className="card-footer">
                                    <small>Measure How Fast You’re Growing Monthly Recurring Revenue. <a href="/#">Learn More</a></small>
                                </div> */}
                            </Card>
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <Card title={`Loans Overview`} customClass={`dimmer ${isLoading ? 'active' : ''}`}>
                                <div className="row text-center">
                                    <div className="col-lg-6 col-sm-12 border-right">
                                        <label className="mb-0 font-10">Total Loan</label>
                                        <h4 className="font-20 font-weight-bold">{formatNumber(data?.data?.loan_overview?.total_loan)}</h4>
                                    </div>
                                    <div className="col-lg-6 col-sm-12 border-right">
                                        <label className="mb-0 font-10">Active Loan</label>
                                        <h4 className="font-20 font-weight-bold">{formatNumber(data?.data?.loan_overview?.active_loan)}</h4>
                                    </div>
                                </div>
                                <div className="loader" />
                                <table className="table dimmer-content  table-striped mt-4 mb-0">
                                    <tbody>
                                    {!isLoading && data?.data?.loan_overview?.loans?.length > 0 ? 
                                        data?.data?.loan_overview?.loans.map((row, i) => 
                                            <tr key={`loans-${i}`}>
                                            <td>
                                                <label className="d-block">{row.product.name} <span className="float-right">Payment</span></label>
                                                <div className="">
                                                    {formatNumber(row.loan_amount)}
                                                    <span className="float-right">N35,000</span>
                                                </div>
                                            </td>
                                        </tr>
                                        )
                                        :
                                            <tr><td colSpan={1}><Empty /></td></tr>
                                        }
                                    </tbody>
                                </table>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    )
}