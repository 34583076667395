import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useGetBirthdayTemplateQuery, useUploadBirthdayTemplateMutation } from '../../../services/cooperative-setup.service';
import { Empty } from 'antd';
import alertify from "alertifyjs";
import { BIRTHDAY_TEMPLATE } from '../../../services/CONSTANTS';
import { toggleDelete } from '../../../redux/slices/ui.slice';

const BirthdayTemplate = () => {
    const {isFixNavbar: fixNavbar} = useSelector(state => state.settings);
    const [file, setFile] = useState(null);
    const dispatch = useDispatch();

    const {activeCoop} = useSelector(state => state.coop);

    const {data: templateData, isLoading: loadingTemplate} = useGetBirthdayTemplateQuery(activeCoop?.id, {
        skip: !activeCoop
    });

    const [uploadTemplate, {isLoading, isSuccess, isError, data: uploadData, error}] = useUploadBirthdayTemplateMutation();

    const submit = () => {
        if(!file) {
            alertify.error('Please select file');
            return;
        }
        const payload = new FormData();
        payload.append('cooperative_id', activeCoop?.id);
        payload.append('image_url', file);

        uploadTemplate(payload)
    }

    useEffect(() => {
        if (isSuccess) {
            if (uploadData.status) {
                alertify.success(uploadData.message);
            } else {
                alertify.error(uploadData.message);
            }
        }

        if(isError) {
            alertify.error(error?.data.message);
        }
    }, [isSuccess, isError, uploadData, isLoading]);

    const doDelete = (e, item) => {
        // console.log(e.currentTarget)
        dispatch(
          toggleDelete({
            open: true,
            title: 'Birthday Template',
            url: `${BIRTHDAY_TEMPLATE}?template_id=${item.id}`,
            // ele: JSON.stringify(e.currentTarget),
            parent: 'tr'
          })
        );
    }

    return (
        <>
            <div className={`section-body ${fixNavbar ? "marginTop" : ""} mt-3`}>
                <div className="container-fluid">
                    <div className="row row-cards">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="page-subtitle ml-0"></div>
                                    <div className="page-options d-flex">
                                        <div className="input-icon ml-2">
                                            <input 
                                                type="file" 
                                                className="form-control" 
                                                placeholder="Select template" 
                                                onChange={(e) => setFile(e.target.files[0])} 
                                            />
                                        </div>
                                        <button 
                                            type="button" 
                                            onClick={submit} 
                                            className="btn btn-primary ml-2"
                                            disabled={isLoading}
                                        >
                                            {isLoading ? 'Uploading...' : 'Upload New' }
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`row row-cards dimmer ${loadingTemplate ? 'active' : ''}`}>
                        {loadingTemplate && <div className='col-md-12 dimmer-content mt-5'><div className='loader' /></div>}
                        
                        {templateData?.data.length === 0 && <div className='col-md-12 mt-5'>
                            <Empty />
                        </div>}

                        {templateData?.data.map((row, i) => 
                            <div className="col-sm-6 col-lg-4" key={`template-${row.id}`}>
                                <div className="card p-3">
                                    <a href="fake_url" className="mb-3">
                                        <img src={row.image_url} alt="" className="rounded" />
                                    </a>
                                    <div className="d-flex align-items-center px-2">
                                        <div>
                                            <div>Template 0{`${i + 1}`}</div>
                                            {/* <small className="d-block text-muted">12 days ago</small> */}
                                        </div>
                                        <div className="ml-auto text-muted">
                                            <button type="button" className="btn btn-icon btn-sm" title="Delete" data-toggle="modal" data-target="#deleteModal" onClick={(e) => doDelete(e, row)}>
                                                <i className="icon-trash text-danger" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        
                    </div>
                </div>
            </div>

        </>
    )
}

export default BirthdayTemplate;