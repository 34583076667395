import React, { useEffect, useState } from "react"
import { SketchPicker } from 'react-color';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import * as Yup from "yup";
// import { useDispatch } from "react-redux";
import alertify from "alertifyjs";
import { useUpdateCooperativeMutation, useSaveCooperativeMutation } from "../../../services/cooperative-setup.service";

export const FormSchema = Yup.object().shape({
    name: Yup.string().required('Please enter the name'),
    code: Yup.string().required('Please enter a code'),
    address: Yup.string().required('Please enter a location'),
    phone: Yup.string().required('Please enter a location'),
    email: Yup.string()
      .email("Please enter a valid email address")
      .required("Email is required"),
});

export const CooperativeForm = ({coop, onCancel}) => {
    const [colors, setColors] = useState({
        primary_color: '',
        secondary_color: ''
    });
    const [logo, setLogo] = useState(null);
    // const dispatch = useDispatch();

    const [save, {isLoading, isSuccess, isError, data, error}] = useSaveCooperativeMutation();
    const [update, {
        isLoading: isUpdateLoading, 
        isSuccess: isUpdateSuccess, 
        isError: isUpdateError, 
        data: updateData, 
        error: updateError
    }] = useUpdateCooperativeMutation();

    const {
        control,
        setValue,
        reset,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(FormSchema),
        mode: 'onBlur'
    });

    useEffect(() => {
        if (coop) {
            reset({
                name: coop.name,
                code: coop.code,
                phone: coop.phone,
                email: coop.email,
                address: coop.location,
                website: coop.website,
                id: coop.id,
            })
            // if (coop.colors) {
            //     const coopColors = JSON.parse(coop.colors);
            //     console.log(coopColors?.primary_colors, JSON.parse(coopColors))
            //     setColors(JSON.parse(coopColors));
            // }
        }
    }, [coop]);

    const onSubmit = async (values) => {
        // console.log(values);
        // const payload = {...values};
        // payload.colors = JSON.stringify(colors);
        const formData = new FormData();
        formData.append('name', values.name)
        if (logo)
            formData.append('logo', logo)
        formData.append('code', values.code)
        formData.append('email', values.email)
        formData.append('phone', values.phone)
        formData.append('address', values.address)
        formData.append('website', values.website)
        formData.append('colors', JSON.stringify(colors))
        
        if (coop) {
            update({data: formData, id: coop.id});
        } else {
            save(formData);
        }
        // alertify.success("Saved successfully");
        // resetForm();
    }

    useEffect(() => {
        if(isSuccess || isUpdateSuccess) {
            resetForm();
            // show toast.
            alertify.success(data?.message || updateData?.message)
        }

        if (isError || isUpdateError) {
            alertify.error(error?.data?.message || updateError?.data?.message);
        }
    }, [isSuccess, isUpdateSuccess, isUpdateError, isError, data, updateData]);

    useEffect(() => {
        if(logo) {
            const imgPreview = document.getElementById('previewImg');

            const imgSrc = URL.createObjectURL(logo);

            imgPreview.src = imgSrc;
        }
    }, [logo]);

    const resetForm = () => {
        reset({
          name: "",
          code: "",
          email: "",
          phone: "",
          address: "",
          website: "",
          colors: "",
          id: "",
        })
    }

    const setImage = (e) => {
        const files = e.target.files;

        if (files.length) {
            setLogo(files[0]);
        } else {
            setLogo(null);
        }
    }

    const doCancel = () => {
        resetForm();
        onCancel()
    }
    
    return (
        <form className={`card dimmer ${(isLoading || isUpdateLoading) ? 'active' : ''}`} onSubmit={handleSubmit(onSubmit)}>
            <div className="loader" />
            <div className="card-body dimmer-content">
                <h3 className="card-title">Add New Cooperative</h3>
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <div className="row">
                            <div className="col-auto coop-logo">
                                {logo ?
                                    <img className="avatar avatar-xl" id="previewImg" src="" alt="avatar" />
                                :
                                coop && coop.logo ?
                                    <img className="avatar avatar-xl" src={coop.logo} alt="avatar" />
                                :
                                    <div className="avatar avatar-xl">
                                        <i className="fe fe-camera" />
                                    </div>
                                }
                                <div className="select-logo" onClick={() => document.getElementById('logo').click()}>
                                    <small className="">+ Add Logo</small>
                                </div>
                            </div>
                            <input type="file" id="logo" accept="image/*" style={{display: 'none'}} onChange={setImage} />
                            <div className="col">
                                <div className="form-group">
                                    <label className="form-label">Cooperative Name</label>
                                    <Controller
                                        name="name"
                                        control={control}
                                        render={({ field }) => (
                                            <input
                                              className={`form-control ${
                                                errors?.name ? "is-invalid" : ""
                                              }`}
                                              type="text"
                                              onChange={field.onChange}
                                              value={field.value}
                                              autoComplete="true"
                                            />
                                        )}
                                    />
                                    {errors.name && <div className="invalid-feedback">{errors.name?.message}</div>}

                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6">
                        <div className="form-group">
                            <label className="form-label">Cooperative Code</label>
                            <Controller
                                name="code"
                                control={control}
                                render={({ field }) => (
                                    <input
                                        className={`form-control ${
                                        errors?.code ? "is-invalid" : ""
                                        }`}
                                        type="text"
                                        onChange={field.onChange}
                                        value={field.value}
                                        autoComplete="true"
                                    />
                                )}
                            />
                            {errors.code && <div className="invalid-feedback">{errors.code?.message}</div>}

                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                            <label className="form-label">Email address</label>
                            <Controller
                                name="email"
                                control={control}
                                render={({ field }) => (
                                    <input
                                        className={`form-control ${
                                        errors?.email ? "is-invalid" : ""
                                        }`}
                                        type="text"
                                        onChange={field.onChange}
                                        value={field.value}
                                        autoComplete="true"
                                    />
                                )}
                            />
                            {errors.email && <div className="invalid-feedback">{errors.email?.message}</div>}

                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                            <label className="form-label">Phone Number</label>
                            <Controller
                                name="phone"
                                control={control}
                                render={({ field }) => (
                                    <input
                                        className={`form-control ${
                                        errors?.phone ? "is-invalid" : ""
                                        }`}
                                        type="text"
                                        onChange={field.onChange}
                                        value={field.value}
                                        autoComplete="true"
                                    />
                                )}
                            />
                            {errors.phone && <div className="invalid-feedback">{errors.phone?.message}</div>}

                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                            <label className="form-label">Cooperative Address</label>
                            <Controller
                                name="address"
                                control={control}
                                render={({ field }) => (
                                    <input
                                        className={`form-control ${
                                        errors?.address ? "is-invalid" : ""
                                        }`}
                                        type="text"
                                        onChange={field.onChange}
                                        value={field.value}
                                        autoComplete="true"
                                    />
                                )}
                            />
                            {errors.address && <div className="invalid-feedback">{errors.address?.message}</div>}
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                            <label className="form-label">Website</label>
                            <Controller
                                name="website"
                                control={control}
                                render={({ field }) => (
                                    <input
                                        className={`form-control ${
                                        errors?.website ? "is-invalid" : ""
                                        }`}
                                        type="text"
                                        onChange={field.onChange}
                                        value={field.value}
                                        autoComplete="true"
                                    />
                                )}
                            />
                            {errors.website && <div className="invalid-feedback">{errors.website?.message}</div>}

                        </div>
                    </div>
                    
                    <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                            <label className="form-label">Cooperative Primary Color</label>
                            <SketchPicker 
                                color={colors.primary_color}
                                onChangeComplete={(color) => {
                                    setColors({...colors, primary_color: color.hex});
                                }} 
                            />
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                            <label className="form-label">Cooperative Secondary Color</label>
                            <SketchPicker 
                                color={colors.secondary_color}
                                onChangeComplete={(color) => {
                                    setColors({...colors, secondary_color: color.hex});
                                }} 
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-footer d-flex justify-content-between">
                <button type="button" className="btn btn-default" onClick={doCancel}>Cancel</button>
                <button type="submit" className="btn btn-primary">Save</button>
            </div>
        </form>
    )
}