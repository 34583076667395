import React, { useEffect } from "react";
import { useGetCalcMethodsQuery } from "../../../services/utility.service";
import { useGetAllAssetTypesQuery, useSaveAssetMutation, useUpdateAssetMutation } from "../../../services/cooperative-setup.service";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import * as Yup from "yup";
import alertify from "alertifyjs";
import { filterOption } from "../../../utils/helpers";
import { DatePicker, Select } from "antd";
import Card from "../../common/Card";
import dayjs from "dayjs";

const FormSchema = Yup.object().shape({
    name: Yup.string().required('Please select product type'),
    asset_type_id: Yup.string().required('This field is required'),
    calculation_method_id: Yup.string().required('This field is required'),
    initial_cost: Yup.string().required('This field is required'),
    residual_value: Yup.string().required('This field is required'),
    useful_life: Yup.string().required('This field is required'),
    rate_of_depreciation: Yup.string().required('This field is required'),
    date_purchased: Yup.string().required('This is required'),
});

const AssetSetupForm = ({activeCoop, asset, onCancel}) => {
    const {data: calcData} = useGetCalcMethodsQuery(activeCoop?.id, {skip: !activeCoop})
    const {data: assetTypes} = useGetAllAssetTypesQuery(activeCoop?.id, {skip: !activeCoop})

    const [save, {isLoading, isSuccess, isError, data, error}] = useSaveAssetMutation();
    const [update, {
        isLoading: isUpdateLoading, 
        isSuccess: isUpdateSuccess, 
        isError: isUpdateError, 
        data: updateData, 
        error: updateError
    }] = useUpdateAssetMutation();

    const {
        control,
        reset,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(FormSchema),
        mode: 'onBlur'
    });

    useEffect(() => {
        if(isSuccess || isUpdateSuccess) {
            resetForm();
            onCancel()
            // show toast.
            alertify.success(data?.message || updateData?.message)
        }

        if (isError || isUpdateError) {
            alertify.error(error?.data?.message || updateError?.data?.message);
        }
    }, [isSuccess, isUpdateSuccess, isUpdateError, isError, data, updateData]);

    useEffect(() => {
        if (asset) {
            resetForm(asset);
        }
    }, [asset]);

    const onSubmit = async (values) => {
        values.cooperative_id = activeCoop?.id;
        values.date_purchased = dayjs(values.date_purchased).format('YYYY-MM-DD');
        
        if (asset) {
            values.id = asset.id;
            await update(values);
        } else {
            await save(values);
        }
    }

    const resetForm = (asset) => {
        reset({
            cooperative_id: activeCoop?.id,
            name: asset?.name || "",
            asset_type_id: asset?.asset_type_id || "",
            calculation_method_id: asset?.calculation_method_id || "",
            initial_cost: asset?.initial_cost || "",
            residual_value: asset?.residual_value || "",
            useful_life: asset?.useful_life || "",
            rate_of_depreciation: asset?.rate_of_depreciation || "",
            date_purchased: asset?.date_purchased || ""
        })
    }

    const doCancel = () => {
        resetForm();
        onCancel();
    }

    return (
        <Card title={`${asset ? 'Edit' : 'Add'} Asset`} loading={isLoading || isUpdateLoading} >
            <form className="card-body dimmer-content" onSubmit={handleSubmit(onSubmit)}>
                <div className="row clearfix">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                            <label>Asset Type <span className="form-required">*</span></label>
                            <Controller
                                name="asset_type_id"
                                control={control}
                                render={({ field }) => (
                                    <Select 
                                        showSearch
                                        className="customSelect mt-1"
                                        options={assetTypes?.data.map(coa => ({value: coa.id, label: coa.name}))} 
                                        placeholder="Select Asset Type"
                                        filterOption={filterOption}
                                        value={field.value || asset?.asset_type_id}
                                        onChange={field.onChange}
                                    />
                                )}
                            />
                        </div>
                        {errors.asset_type_id && <div className="invalid-feedback">{errors.asset_type_id?.message}</div>}
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                            <label>Name <span className="form-required">*</span></label>
                            <Controller
                                name="name"
                                control={control}
                                render={({ field }) => (
                                    <input
                                        className={`form-control ${errors?.name ? "is-invalid" : ""}`}
                                        type="text"
                                        onChange={field.onChange}
                                        value={field.value}
                                        autoComplete="true"
                                        placeholder=""
                                    />
                                )}
                            />
                            {errors.name && <div className="invalid-feedback">{errors.name?.message}</div>}
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                            <label>Calculation Method <span className="form-required">*</span></label>
                            <Controller
                                name="calculation_method_id"
                                control={control}
                                render={({ field }) => (
                                    <Select 
                                        showSearch
                                        className="customSelect mt-1"
                                        options={calcData?.data.map(coa => ({value: coa.id, label: coa.name}))} 
                                        placeholder="Select Calculation Method"
                                        filterOption={filterOption}
                                        value={field.value || asset?.asset_type_id}
                                        onChange={field.onChange}
                                    />
                                )}
                            />
                        </div>
                        {errors.calculation_method_id && <div className="invalid-feedback">{errors.calculation_method_id?.message}</div>}
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                            <label>Initial Cost (₦) <span className="form-required">*</span></label>
                            <Controller
                                name="initial_cost"
                                control={control}
                                render={({ field }) => (
                                    <input
                                        className={`form-control ${errors?.initial_cost ? "is-invalid" : ""}`}
                                        type="text"
                                        onChange={field.onChange}
                                        value={field.value}
                                        autoComplete="true"
                                        placeholder=""
                                    />
                                )}
                            />
                            {errors.initial_cost && <div className="invalid-feedback">{errors.initial_cost?.message}</div>}
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                            <label>Residual Value (₦) <span className="form-required">*</span></label>
                            <Controller
                                name="residual_value"
                                control={control}
                                render={({ field }) => (
                                    <input
                                        className={`form-control ${errors?.residual_value ? "is-invalid" : ""}`}
                                        type="text"
                                        onChange={field.onChange}
                                        value={field.value}
                                        autoComplete="true"
                                        placeholder=""
                                    />
                                )}
                            />
                            {errors.residual_value && <div className="invalid-feedback">{errors.residual_value?.message}</div>}
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                            <label>Useful life (years) <span className="form-required">*</span></label>
                            <Controller
                                name="useful_life"
                                control={control}
                                render={({ field }) => (
                                    <input
                                        className={`form-control ${errors?.useful_life ? "is-invalid" : ""}`}
                                        type="text"
                                        onChange={field.onChange}
                                        value={field.value}
                                        autoComplete="true"
                                        placeholder=""
                                    />
                                )}
                            />
                            {errors.useful_life && <div className="invalid-feedback">{errors.useful_life?.message}</div>}
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                            <label>Rate of Depreciation <span className="form-required">*</span></label>
                            <Controller
                                name="rate_of_depreciation"
                                control={control}
                                render={({ field }) => (
                                    <input
                                        className={`form-control ${errors?.rate_of_depreciation ? "is-invalid" : ""}`}
                                        type="text"
                                        onChange={field.onChange}
                                        value={field.value}
                                        autoComplete="true"
                                        placeholder=""
                                    />
                                )}
                            />
                            {errors.rate_of_depreciation && <div className="invalid-feedback">{errors.rate_of_depreciation?.message}</div>}
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                            <label>Date purchased <span className="form-required">*</span></label>
                            <Controller
                                name="date_purchased"
                                control={control}
                                render={({ field }) => (
                                    <DatePicker
                                        className={`customInput w-full`} 
                                        name={'date_purchased'} 
                                        style={{width: '100%'}}
                                        onChange={field.onChange} 
                                        value={field.value ? dayjs(field.value) : undefined} 
                                    />
                                )}
                            />
                            {errors.date_purchased && <div className="invalid-feedback">{errors.date_purchased?.message}</div>}
                        </div>
                    </div>
                </div>
                    
                <button type="submit" className="btn btn-round btn-primary">
                    Save
                </button>{' '}&nbsp;&nbsp;
                <button type="button" className="btn btn-round btn-default" onClick={doCancel}>
                    Cancel
                </button>
            </form>
        </Card>
    )
}

export default AssetSetupForm;