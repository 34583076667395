import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux"
import {
	darkModeAction, darkHeaderAction, fixNavbarAction,
	darkMinSidebarAction, darkSidebarAction, iconColorAction,
	gradientColorAction, rtlAction, fontAction,
    subMenuIconAction, menuIconAction, 
} from '../../redux/actions/settingsAction';
import { useLazySendRegistrationEmailQuery } from '../../services/utility.service';
import alertify from 'alertifyjs';

export const SettingsComponent = ({
    isOpenRightSidebar,
    toggleRightSidebar,
}) => {
    const [sendRegEmail, {data, isLoading, error}] = useLazySendRegistrationEmailQuery();
    const {activeCoop} = useSelector(state => state.coop);
    const {role} = useSelector(state => state.user);

    const dispatch = useDispatch();

    useEffect(() => {
        if (data && data.status) {
            alertify.success(data?.message);
            toggleRightSidebar()
        }

        if (error) 
            alertify.error(error.message);
    }, [data, error]);

    function handleDarkMode(e) {
		dispatch(darkModeAction(e.target.checked))
	}
	
    function handleFixNavbar(e) {
		dispatch(fixNavbarAction(e.target.checked))
	}
	
    function handleDarkHeader(e) {
		dispatch(darkHeaderAction(e.target.checked))
	}

	function handleMinSidebar(e) {
		dispatch(darkMinSidebarAction(e.target.checked))
	}

	function handleSidebar(e) {
		dispatch(darkSidebarAction(e.target.checked))
	}

	function handleIconColor(e) {
		dispatch(iconColorAction(e.target.checked))
	}
	
    function handleGradientColor(e) {
		dispatch(gradientColorAction(e.target.checked))
	}

	function handleRtl(e) {
		dispatch(rtlAction(e.target.checked))
	}
	
    function handleFont(e) {
		dispatch(fontAction(e))
	}

    function handleSubMenuIcon(e) {
		dispatch(subMenuIconAction(e))
	}
	
    function handleMenuIcon(e) {
		dispatch(menuIconAction(e))
	}

    function sendEmail() {
        sendRegEmail(activeCoop?.id);
    }

    return (
        <div id="rightsidebar" className={`right_sidebar ${isOpenRightSidebar && "open"}`}>
            <span className="p-3 settingbar float-right" onClick={toggleRightSidebar}>
                <i className="fa fa-close" />
            </span>
            <ul className="nav nav-tabs" role="tablist">
                <li className="nav-item">
                    <a className="nav-link active" data-toggle="tab" href="#Settings" aria-expanded="true">
                        Settings
                </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#activity" aria-expanded="false">
                        Activity
                </a>
                </li>
            </ul>
            <div className="tab-content">
                <div
                    role="tabpanel"
                    className="tab-pane vivify fadeIn active"
                    id="Settings"
                    aria-expanded="true"
                >
                    <div className="mb-4">
                        <h6 className="font-14 font-weight-bold text-muted">Font Style</h6>
                        <div className="custom-controls-stacked font_setting">
                            <label className="custom-control custom-radio custom-control-inline">
                                <input
                                    type="radio"
                                    className="custom-control-input"
                                    name="font"
                                    defaultChecked
                                    defaultValue="font-opensans"
                                    onChange={() => handleFont('font-opensans')}
                                />
                                <span className="custom-control-label">Open Sans Font</span>
                            </label>
                            <label className="custom-control custom-radio custom-control-inline">
                                <input
                                    type="radio"
                                    className="custom-control-input"
                                    name="font"
                                    defaultValue="font-montserrat"
                                    onChange={() => handleFont('font-montserrat')}
                                />
                                <span className="custom-control-label">Montserrat Google Font</span>
                            </label>
                            <label className="custom-control custom-radio custom-control-inline">
                                <input
                                    type="radio"
                                    className="custom-control-input"
                                    name="font"
                                    onChange={() => handleFont('font-roboto')}
                                />
                                <span className="custom-control-label">Robot Google Font</span>
                            </label>
                        </div>
                    </div>
                    <div className="mb-4">
                        <h6 className="font-14 font-weight-bold text-muted">Selected Menu Icon</h6>
                        <div className="custom-controls-stacked arrow_option">
                            <label className="custom-control custom-radio custom-control-inline">
                                <input
                                    type="radio"
                                    className="custom-control-input"
                                    name="marrow"
                                    defaultValue="arrow-a"
                                    onChange={() => handleMenuIcon('list-a')}
                                />
                                <span className="custom-control-label">A</span>
                            </label>
                            <label className="custom-control custom-radio custom-control-inline">
                                <input
                                    type="radio"
                                    className="custom-control-input"
                                    name="marrow"
                                    defaultValue="arrow-b"
                                    onChange={() => handleMenuIcon('list-b')}
                                />
                                <span className="custom-control-label">B</span>
                            </label>
                            <label className="custom-control custom-radio custom-control-inline">
                                <input
                                    type="radio"
                                    className="custom-control-input"
                                    name="marrow"
                                    defaultValue="arrow-c"
                                    defaultChecked
                                    onChange={() => handleMenuIcon('list-c')}
                                />
                                <span className="custom-control-label">C</span>
                            </label>
                        </div>

                        <h6 className="font-14 font-weight-bold mt-4 text-muted">SubMenu List Icon</h6>
                        <div className="custom-controls-stacked list_option">
                            <label className="custom-control custom-radio custom-control-inline">
                                <input
                                    type="radio"
                                    className="custom-control-input"
                                    name="listicon"
                                    defaultValue="list-a"
                                    defaultChecked
                                    onChange={() => handleSubMenuIcon('list-a')}
                                />
                                <span className="custom-control-label">A</span>
                            </label>
                            <label className="custom-control custom-radio custom-control-inline">
                                <input
                                    type="radio"
                                    className="custom-control-input"
                                    name="listicon"
                                    defaultValue="list-b"
                                    onChange={() => handleSubMenuIcon('list-b')}
                                />
                                <span className="custom-control-label">B</span>
                            </label>
                            <label className="custom-control custom-radio custom-control-inline">
                                <input
                                    type="radio"
                                    className="custom-control-input"
                                    name="listicon"
                                    defaultValue="list-c"
                                    onChange={() => handleSubMenuIcon('list-c')}
                                />
                                <span className="custom-control-label">C</span>
                            </label>
                        </div>
                    </div>
                    <div>
                        <h6 className="font-14 font-weight-bold mt-4 text-muted">General Settings</h6>
                        <ul className="setting-list list-unstyled mt-1 setting_switch">
                            <li>
                                <label className="custom-switch">
                                    <span className="custom-switch-description">Night Mode</span>
                                    <input
                                        type="checkbox"
                                        name="custom-switch-checkbox"
                                        className="custom-switch-input btn-darkmode"
                                        onChange={(e) => handleDarkMode(e)}
                                    />
                                    <span className="custom-switch-indicator" />
                                </label>
                            </li>
                            <li>
                                <label className="custom-switch">
                                    <span className="custom-switch-description">Fix Navbar top</span>
                                    <input
                                        type="checkbox"
                                        name="custom-switch-checkbox"
                                        className="custom-switch-input btn-fixnavbar"
                                        onChange={(e) => handleFixNavbar(e)}
                                    />
                                    <span className="custom-switch-indicator" />
                                </label>
                            </li>
                            <li>
                                <label className="custom-switch">
                                    <span className="custom-switch-description">Header Dark</span>
                                    <input
                                        type="checkbox"
                                        name="custom-switch-checkbox"
                                        className="custom-switch-input btn-pageheader"
                                        onChange={(e) => handleDarkHeader(e)}
                                    />
                                    <span className="custom-switch-indicator" />
                                </label>
                            </li>
                            <li>
                                <label className="custom-switch">
                                    <span className="custom-switch-description">Min Sidebar Dark</span>
                                    <input
                                        type="checkbox"
                                        name="custom-switch-checkbox"
                                        className="custom-switch-input btn-min_sidebar"
                                        onChange={(e) => handleMinSidebar(e)}
                                    />
                                    <span className="custom-switch-indicator" />
                                </label>
                            </li>
                            <li>
                                <label className="custom-switch">
                                    <span className="custom-switch-description">Sidebar Dark</span>
                                    <input
                                        type="checkbox"
                                        name="custom-switch-checkbox"
                                        className="custom-switch-input btn-sidebar"
                                        onChange={(e) => handleSidebar(e)}
                                    />
                                    <span className="custom-switch-indicator" />
                                </label>
                            </li>
                            <li>
                                <label className="custom-switch">
                                    <span className="custom-switch-description">Icon Color</span>
                                    <input
                                        type="checkbox"
                                        name="custom-switch-checkbox"
                                        className="custom-switch-input btn-iconcolor"
                                        onChange={(e) => handleIconColor(e)}
                                    />
                                    <span className="custom-switch-indicator" />
                                </label>
                            </li>
                            <li>
                                <label className="custom-switch">
                                    <span className="custom-switch-description">Gradient Color</span>
                                    <input
                                        type="checkbox"
                                        name="custom-switch-checkbox"
                                        className="custom-switch-input btn-gradient"
                                        onChange={(e) => handleGradientColor(e)}
                                    />
                                    <span className="custom-switch-indicator" />
                                </label>
                            </li>

                            <li>
                                <label className="custom-switch">
                                    <span className="custom-switch-description">RTL Support</span>
                                    <input
                                        type="checkbox"
                                        name="custom-switch-checkbox"
                                        className="custom-switch-input btn-rtl"
                                        onChange={(e) => handleRtl(e)}
                                    />
                                    <span className="custom-switch-indicator" />
                                </label>
                            </li>

                        </ul>
                    </div>
                    <hr />
                    {role === 'cooperative-admin' &&
                    <div className="form-group">
                        <label className="d-block">
                            Send Registration Email
                        </label>
                        {/* 
                        <div className="progress progress-sm">
                            <div
                                className="progress-bar"
                                role="progressbar"
                                aria-valuenow={77}
                                aria-valuemin={0}
                                aria-valuemax={100}
                                style={{ width: '77%' }}
                            />
                        </div> */}
                        <button disabled={isLoading} onClick={sendEmail} type="button" className="btn btn-primary btn-block mt-3">
                            {isLoading ?
                                <>
                                    <i className="mr-2 fa fa-spin spin" /> Sending
                                </> 
                            :
                                <>
                                    <i className="mr-2 fa fa-send" /> Send
                                </> 
                            }
                        </button>
                    </div>}
                </div>
                <div role="tabpanel" className="tab-pane vivify fadeIn" id="activity" aria-expanded="false">
                    <ul className="new_timeline mt-3">
                        <li>
                            <div className="bullet pink" />
                            <div className="time">11:00am</div>
                            <div className="desc">
                                <h3>Attendance</h3>
                                <h4>Computer Class</h4>
                            </div>
                        </li>
                        <li>
                            <div className="bullet pink" />
                            <div className="time">11:30am</div>
                            <div className="desc">
                                <h3>Added an interest</h3>
                                <h4>“Volunteer Activities”</h4>
                            </div>
                        </li>
                        <li>
                            <div className="bullet green" />
                            <div className="time">12:00pm</div>
                            <div className="desc">
                                <h3>Developer Team</h3>
                                <h4>Hangouts</h4>
                                <ul className="list-unstyled team-info margin-0 p-t-5">
                                    <li>
                                        <img src="/assets/images/xs/avatar1.jpg" alt="Avatar" />
                                    </li>
                                    <li>
                                        <img src="/assets/images/xs/avatar2.jpg" alt="Avatar" />
                                    </li>
                                    <li>
                                        <img src="/assets/images/xs/avatar3.jpg" alt="Avatar" />
                                    </li>
                                    <li>
                                        <img src="/assets/images/xs/avatar4.jpg" alt="Avatar" />
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <div className="bullet green" />
                            <div className="time">2:00pm</div>
                            <div className="desc">
                                <h3>Responded to need</h3>
                                <a href="#!">“In-Kind Opportunity”</a>
                            </div>
                        </li>
                        <li>
                            <div className="bullet orange" />
                            <div className="time">1:30pm</div>
                            <div className="desc">
                                <h3>Lunch Break</h3>
                            </div>
                        </li>
                        <li>
                            <div className="bullet green" />
                            <div className="time">2:38pm</div>
                            <div className="desc">
                                <h3>Finish</h3>
                                <h4>Go to Home</h4>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}