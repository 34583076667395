import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import * as Yup from "yup";
import alertify from "alertifyjs";
import { useSaveElectionMutation } from "../../../services/election.service";
import { DatePicker } from "antd";
import dayjs from "dayjs";

const FormSchema = Yup.object().shape({
    name: Yup.string().required('Please enter a name'),
    start_date: Yup.string().required('This field is required'),
    end_date: Yup.string().required('This field is required'),
    status: Yup.string().required('This field is required'),
});

export const ElectionForm = ({activeCoop, details, onCancel}) => {

    const [save, {isSuccess, isLoading, isError, data, error}] = useSaveElectionMutation()

    const [positions, setPositions] = useState([
        {name: "", description: ""}
    ]);

    const {
        control,
        reset,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(FormSchema),
        mode: 'onBlur'
    });

    useEffect(() => {
        if (details) {
            setValue('name', details.name);
            setValue('description', details.description);
            setValue('start_date', dayjs(details.start_date))
            setValue('end_date', dayjs(details.end_date))
            setValue('status', details.status);
            setValue('election_id', details.id);
        }
    }, [details]);

    const onSubmit = (values) => {
        const payload = {...values};
        
        if (details) payload.election_id = details.id;
        
        payload.cooperative_id = activeCoop?.id;
        
        if (!details) payload.positions = positions;

        payload.start_date = dayjs(values.start_date).format('YYYY-MM-DD');
        payload.end_date = dayjs(values.end_date).format('YYYY-MM-DD');
        save(payload);
    }

    const addRow = () => {
        setPositions([...positions, {name: "", description: ""}]);
    }

    const removeRow = (i) => {
        const arr = [...positions];
        arr.splice(i, 1);
        setPositions(arr);
    }

    const resetForm = () => {
        reset({})
        setPositions([
            {name: "", description: ""}
        ])
    }

    const doCancel = () => {
        resetForm();
        onCancel();
    }

    useEffect(() => {
        if(isSuccess) {
            resetForm();
            // show toast.
            alertify.success(data?.message);
            document.getElementById('election-modal').click()
        }

        if (isError) {
            alertify.error(error?.data?.message);
        }
    }, [isSuccess, isError, data]);

    const handleChange = (field, value, index) => {
        const newArr = [...positions];
        newArr[index][field] = value;
        setPositions(newArr);
    }

    return (
        <div className="modal fade" id="election_form" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <form onSubmit={handleSubmit(onSubmit)} className={`modal-content dimmer ${isLoading ? 'active' : ''}`}>
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">New Election</h5>
                        <button type="button" id="election-modal" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body dimmer-content">
                        <div className="loader" />
                        <div className="row clearfix">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Election Name</label>
                                    <Controller
                                        name="name"
                                        control={control}
                                        render={({ field }) => (
                                            <input
                                                className={`form-control ${
                                                errors?.name ? "is-invalid" : ""
                                                }`}
                                                type="text"
                                                onChange={field.onChange}
                                                value={field.value}
                                                autoComplete="true"
                                            />
                                        )}
                                    />
                                    {errors.name && <div className="invalid-feedback">{errors.name?.message}</div>}
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Description</label>
                                    <Controller
                                        name="description"
                                        control={control}
                                        render={({ field }) => (
                                            <textarea 
                                                type="text" 
                                                rows={4} 
                                                className="form-control" 
                                                placeholder="Description"
                                                onChange={field.onChange}
                                                defaultValue={field.value}
                                            >
                                                {field.value}
                                            </textarea>
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Start Date</label>
                                    <Controller
                                        name="start_date"
                                        control={control}
                                        render={({ field }) => (
                                            <DatePicker
                                                className={`customInput w-full`} 
                                                name={'start_date'} 
                                                style={{width: '100%'}}
                                                onChange={field.onChange} 
                                                // onBlur={onBlur} 
                                                value={field.value ? dayjs(field.value) : undefined} 
                                            />
                                        )}
                                    />
                                    {errors.start_date && <div className="invalid-feedback">{errors.start_date?.message}</div>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>End Date</label>
                                    <Controller
                                        name="end_date"
                                        control={control}
                                        render={({ field }) => (
                                            <DatePicker
                                                className={`customInput w-full`} 
                                                name={'end_date'} 
                                                style={{width: '100%'}}
                                                onChange={field.onChange} 
                                                // onBlur={onBlur} 
                                                value={field.value ? dayjs(field.value) : undefined} 
                                            />
                                        )}
                                    />
                                    {errors.end_date && <div className="invalid-feedback">{errors.end_date?.message}</div>}
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Status</label>
                                    <Controller
                                        name="status"
                                        control={control}
                                        render={({ field }) => (
                                            <select 
                                                onChange={field.onChange}
                                                value={field.value}
                                                className={`form-control ${errors?.status ? "is-invalid" : ""}`}
                                            >
                                                <option value=''>Select Status</option>
                                                <option value='1'>Active</option>
                                                <option value='0'>Inactive</option>
                                            </select>
                                        )}
                                    />
                                    {errors.status && <div className="invalid-feedback">{errors.status?.message}</div>}
                                </div>
                            </div>
                            {!details &&
                                <div className="col-md-12">
                                    <h5>Positions</h5>
                                    <hr />
                                </div>
                            }
                            {!details && positions.map((position, i) =>
                                <React.Fragment key={`position-${i}`}>
                                    <div className="col-md-5">
                                        <div className="form-group">
                                            <label>Name</label>
                                            <input
                                                className={`form-control`}
                                                type="text"
                                                autoComplete="true"
                                                value={position.name}
                                                onChange={(e) => handleChange('name', e.target.value, i)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Description</label>
                                            <input
                                                className={`form-control`}
                                                type="text"
                                                autoComplete="true"
                                                value={position.description}
                                                onChange={(e) => handleChange('description', e.target.value, i)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-1">
                                        {i === 0 ?
                                            <i 
                                                className='fa fa-plus' 
                                                onClick={() => addRow()} 
                                                style={{color: 'green', cursor: 'pointer'}} 
                                            />
                                            :
                                            <>
                                                
                                                <i 
                                                    className='fa fa-minus' 
                                                    onClick={() => removeRow(i)} 
                                                    style={{color: 'red', cursor: 'pointer'}} 
                                                />
                                            </>
                                        }
                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={doCancel} data-dismiss="modal">Close</button>
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </div>
                </form>
            </div>
        </div>

    )
}