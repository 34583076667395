import React from 'react'
import { useSelector } from 'react-redux';

const SalesReport = () => {
    const {isFixNavbar: fixNavbar} = useSelector(state => state.settings);

    return (
        <>
            <div>
                <div>
                    <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
                        <div className="container-fluid">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-4 col-sm-6">
                                            <div className="form-group">
                                                <select className="form-control show-tick">
                                                    <option>Current Month</option>
                                                    <option>Last Month</option>
                                                    <option>Last 30 Days</option>
                                                    <option>Current Year</option>
                                                    <option>Last Year</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-6">
                                            <div className="input-icon mb-3">
                                                <input type="text" className="form-control" placeholder="Start Date" />
                                                <span className="input-icon-addon"><i className="fa fa-calendar"></i></span>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-6">
                                            <div className="input-icon mb-3">
                                                <input type="text" className="form-control" placeholder="End Date" />
                                                <span className="input-icon-addon"><i className="fa fa-calendar"></i></span>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-6">
                                            <a href="fake_url" className="btn btn-sm btn-primary btn-block">Filter</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section-body mt-3">
                        <div className="container-fluid">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Sales Report</h3>
                                    <div className="card-options">
                                        <form>
                                            <div className="input-group">
                                                <span className="input-group-btn ml-2">
                                                    <button className="btn btn-icon" type="submit"><span className="fa fa-file-pdf-o" /> PDF</button>
                                                    <button className="btn btn-icon" type="submit"><span className="fa fa-file-excel-o" /> EXCEL</button>
                                                </span>

                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table table-striped table-vcenter table-hover mb-0">
                                            <thead>
                                                <tr>
                                                    <th>ORDER ID</th>
                                                    <th>DATE</th>
                                                    <th>TOTAL</th>
                                                    <th>DISCOUNT</th>
                                                    <th>DELIVERY CHARGE</th>
                                                    <th>PAYMENT TYPE</th>
                                                    <th>PAYMENT STATUS</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>#3262</td>
                                                    <td>17/02/2024</td>
                                                    <td>N40,0000</td>
                                                    <td>0</td>
                                                    <td>200</td>
                                                    <td>Transfer</td>
                                                    <td><span className='tag tag-success'>Paid</span></td>
                                                    <td>
                                                        <span className='badge badge-default p-2'>
                                                            View 
                                                            <i className='fa fa-eye ml-2' />
                                                        </span>
                                                    </td>
                                                </tr>
                                                
                                                <tr>
                                                    <td>#3262</td>
                                                    <td>17/02/2024</td>
                                                    <td>N40,0000</td>
                                                    <td>0</td>
                                                    <td>200</td>
                                                    <td>Transfer</td>
                                                    <td><span className='tag tag-danger'>Cancel</span></td>
                                                    <td>
                                                        <span className='badge badge-default p-2'>
                                                            View 
                                                            <i className='fa fa-eye ml-2' />
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>#3262</td>
                                                    <td>17/02/2024</td>
                                                    <td>N40,0000</td>
                                                    <td>0</td>
                                                    <td>200</td>
                                                    <td>Transfer</td>
                                                    <td><span className='tag tag-warning'>Refund</span></td>
                                                    <td>
                                                        <span className='badge badge-default p-2'>
                                                            View 
                                                            <i className='fa fa-eye ml-2' />
                                                        </span>
                                                    </td>
                                                </tr>
                                                
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                
            </div>
        </>
    )
}
export default SalesReport