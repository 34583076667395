import React from 'react'
import { useSelector } from 'react-redux';
import { useGetConsolidatedReportQuery } from '../../services/utility.service';
import Card from '../common/Card';
import { Empty } from 'antd';
import dayjs from 'dayjs';

export const ConsolidatedReport = () => {
    const {isFixNavbar: fixNavbar} = useSelector(state => state.settings);

    const {data, isLoading} = useGetConsolidatedReportQuery();


    return (
        <>
            <div className={`section-body ${fixNavbar ? "marginTop" : ""} mt-3`}>
                <div className="container-fluid">
                    <div className="row clearfix">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-4 col-sm-6">
                                            <div className="form-group">
                                                <select className="form-control show-tick">
                                                    <option>Current Month</option>
                                                    <option>Last Month</option>
                                                    <option>Last 30 Days</option>
                                                    <option>Current Year</option>
                                                    <option>Last Year</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-6">
                                            <div className="input-icon mb-3">
                                                <input type="text" className="form-control" placeholder="Start Date" />
                                                <span className="input-icon-addon"><i className="fa fa-calendar"></i></span>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-6">
                                            <div className="input-icon mb-3">
                                                <input type="text" className="form-control" placeholder="End Date" />
                                                <span className="input-icon-addon"><i className="fa fa-calendar"></i></span>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-6">
                                            <a href="fake_url" className="btn btn-sm btn-primary btn-block">Search</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <Card loading={isLoading}>
                                <div className="table-responsive">
                                    <table className="table table-hover table-striped table-vcenter mb-0">
                                        <thead>
                                            {/* <tr>
                                                <th colSpan={5}>Ticket Detail</th>
                                                <th colSpan={3}>Activity</th>
                                            </tr> */}
                                            <tr className="bg-primary">
                                                {/* <th className="w30">&nbsp;</th> */}
                                                <th>Code</th>
                                                <th>Name</th>
                                                <th>Active Member(s)</th>
                                                <th>Inactive Member(s)</th>
                                                <th>Total Savings</th>
                                                <th>Total Loans</th>
                                                <th>Paid Loans</th>
                                                <th>Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data?.data.map(row => 
                                                <tr key={`report-${row.id}`}>
                                                    {/* <td>
                                                        <label className="custom-control custom-checkbox">
                                                            <input type="checkbox" className="custom-control-input" name="example-checkbox1" defaultValue="option1" defaultChecked />
                                                            <span className="custom-control-label">&nbsp;</span>
                                                        </label>
                                                    </td> */}
                                                    <td><a href="/#">{row.code}</a></td>
                                                    <td><span>{row.name}</span></td>
                                                    <td><span>{row.meta.activeMembers}</span></td>
                                                    <td><span>{row.meta.inactiveMembers}</span></td>
                                                    <td><span>₦{row.meta.totalSavings}</span></td>
                                                    <td><span>₦{row.meta.totalLoans}</span></td>
                                                    <td><span>₦{row.meta.loansPaid}</span></td>
                                                    <td>
                                                        { dayjs(row.created_at).format('Do MMM YYYY, hh:mma') /* 22nd Sep 2016, 9:26pm */}
                                                    </td>
                                                </tr>
                                            )}

                                            {!isLoading && data?.data.lenght === 0 && <tr><td colSpan={8}><Empty /></td></tr>}
                                            
                                        </tbody>
                                    </table>
                                </div>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>

        </>

    )
}