import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { useGetPendingMembersQuery } from '../../services/member.service';
import { Empty } from 'antd';
import dayjs from 'dayjs';
import InitialsAvatar from 'react-initials-avatar';
import { RWebShare } from "react-web-share";
import ApproveDenyAsset from '../common/ApproveDenyAsset';

const PendingMembers = () => {
    const {isFixNavbar: fixNavbar} = useSelector(state => state.settings);
	const {activeCoop} = useSelector(state => state.coop);
    const [selectedItem, setSelected] = useState(null);

	const {data, isLoading} = useGetPendingMembersQuery(activeCoop?.id,  {skip: !activeCoop});

    const copyLink = async () => {
        try {
          await navigator.clipboard.writeText(`https://calicon.netlify.app/register?coop=${activeCoop?.code}`);
          alert("Link Copied");
          // console.log("Text copied to clipboard");
        } catch (err) {
          console.log("Failed to copy text");
        }
    };

    return (
        <>
            <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
                <div className="container-fluid">
                    
                    <div className="row clearfix">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-10 col-md-4 col-sm-6">
                                            <label>Copy and share this link to propective members</label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" readOnly placeholder={`https://calicon.netlify.app/register?coop=${activeCoop?.code}`} />
                                                <span className="input-group-append">
                                                    <button 
                                                        className="btn btn-primary" 
                                                        type="button"
                                                        onClick={copyLink}
                                                    >
                                                        <i className="fa fa-copy mr-5" /> 
                                                        Copy Link
                                                    </button>
                                                </span>
                                            </div>
                                        </div>
                                        
                                        <div className="col-lg-2 col-md-4 col-sm-6">
                                            <label>&nbsp;</label>
                                            <RWebShare
                                                data={{
                                                text: `Hi!, click on the link to register with ${activeCoop?.name}`,
                                                url: `https://calicon.netlify.app/register?coop=${activeCoop?.code}`,
                                                title: `${activeCoop?.name} Member Registration`,
                                                }}
                                                onClick={() => console.log("shared successfully!")}
                                            >
                                                <a href="javascript:;" className="btn btn-sm btn-primary btn-block p-2" >
                                                    <i className="fa fa-share-alt mr-5" />  Share Link
                                                </a>
                                            </RWebShare>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-body">
                <div className={`container-fluid dimmer ${isLoading ? 'active' : ''}`}>
                    <div className="table-responsive dimmer-content" style={{minHeight: '300px'}}>
                        <div className="loader" />
                        <table className="table table-vcenter table_custom spacing5 border-style mb-0">
                            <thead>
                                <tr>
                                    <th className="w40">#</th>
                                    <th>Name</th>
                                    <th>Designation</th>
                                    <th>Address</th>
                                    <th>Phone</th>
                                    <th>Email</th>
                                    <th>Date</th>
                                    <th className="w40" />
                                </tr>
                            </thead>
                            <tbody>
                                {data?.data.length === 0 && <tr><td colSpan={8}><Empty /></td></tr>}
                                {data?.data.map(row =>
                                    <tr key={`pending-${row.id}`}>
                                        <td>
                                            <span
                                                className="avatar avatar-blue"
                                                data-toggle="tooltip"
                                                data-original-title="Avatar Name"
                                            >
                                                <InitialsAvatar name={`${row.firstname} ${row.lastname}`} />
                                            </span>
                                        </td>
                                        <td>{row.firstname} {row.lastname}</td>
                                        <td>-</td>
                                        <td><span>{row.current_address}</span></td>
                                        <td>{row.contact_phone}</td>
                                        <td>{row.email}</td>
                                        <td>
                                            {dayjs(row.created_at).format('YYYY-MM-DD HH:mm:ss')}
                                        </td>
                                        <td>
                                            <div className="item-action dropdown">
                                                <a href="#" data-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-h" /></a>
                                                <div className="dropdown-menu dropdown-menu-right" x-placement="bottom-end" style={{ position: 'absolute', willChange: 'transform', top: 0, left: 0, transform: 'translate3d(18px, 25px, 0px)' }}>
                                                    <a href="#" className="dropdown-item">
                                                        <i className="dropdown-icon fa fa-eye" /> View Details 
                                                    </a>
                                                    <a href="#" className="dropdown-item" onClick={() => setSelected({id: row.id, type: 'pending-member', action: 'approve'})} data-target="#assetModal" data-toggle="modal">
                                                        <i className="dropdown-icon fa fa-thumbs-o-up" /> Approve 
                                                    </a>
                                                    <a href="#" className="dropdown-item" onClick={() => setSelected({id: row.id, type: 'pending-member', action: 'deny'})} data-target="#assetModal" data-toggle="modal">
                                                        <i className="dropdown-icon fa fa-thumbs-o-down" /> Deny
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <ApproveDenyAsset 
                id={selectedItem?.id} 
                type={selectedItem?.type} 
                action={selectedItem?.action} 
                coopId={activeCoop?.id} 
                title="Member"
            />
        </>
    )
}

export default PendingMembers;