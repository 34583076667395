import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import configureStore, {persistor} from './redux/store';
import { PersistGate } from "redux-persist/integration/react";

import './index.css';
import App from './App';
import "alertifyjs/build/css/alertify.css";
import "alertifyjs/build/css/themes/semantic.css";
import 'react-toastify/dist/ReactToastify.css';
// import 'react-initials-avatar/lib/ReactInitialsAvatar.css';

import * as serviceWorker from './serviceWorker';
// import registerServiceWorker from './registerServiceWorker';

ReactDOM.render(
    <Provider store={configureStore}>
        <PersistGate loading={null} persistor={persistor} >
            <App />
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);
// registerServiceWorker();
// ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
