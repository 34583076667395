import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useLoginMutation } from "../../services/auth.service";
import { useSelector } from 'react-redux';

const Login = ({history}) => {
	const [form, setForm] = useState({
		username: '',
		password: '',
	})
	const [errorMsg, setError] = useState('');

	const {isAuthenticated} = useSelector(state => state.user);

	const [loginMutation, { isLoading, isSuccess, isError, data, error }] = useLoginMutation();

	const onSubmit = (e) => {
		e.preventDefault();

		loginMutation({
			coop_id: form.username,
			password: form.password,
		})
	};

  	useEffect(() => {
		if (isError) setError(error?.data?.message);

		if (isSuccess && !data.status) setError(data?.message)

	}, [isSuccess, isError, error, data]);

	useEffect(() => {
		if (isAuthenticated) history.push('/dashboard')
	}, [isAuthenticated])


	return (
		<div className="auth">
			<div className="auth_left">
				<div className='auth-logo' style={{ position: 'absolute', top: 10, left: 50}}>
					<img src="../assets/images/logo.png" height={150} className='' />
				</div>

				<div className={`card ${errorMsg ? 'has-error' : ''}`}>
					{errorMsg && <div className="card-status bg-green"></div> }
					{/* <div className="text-center mb-2">
						<Link className="header-brand" to="/app/dashboard">
							<img src="../assets/images/calicon-logo.png" height={30} className='' />
						</Link>
					</div> */}
					{errorMsg && <div className="card-alert alert alert-danger mb-0">{errorMsg}</div>}
					<form className="card-body" onSubmit={onSubmit}>
						<div className="card-title">Login to your account</div>
						{/* <div className="form-group">
							<select className="custom-select">
								<option>HR Dashboard</option>
								<option>Project Dashboard</option>
								<option>Job Portal</option>
							</select>
						</div> */}
						<div className="form-group">
							<input
								type="text"
								className="form-control"
								id="username"
								placeholder=""
								name="username"
								value={form.username}
								onChange={(e) => setForm({...form, username: e.target.value})}
							/>
						</div>
						<div className="form-group">
							<label className="form-label">
								Password
								<Link className="float-right small" to="/forgotpassword">
									I forgot password
								</Link>
							</label>
							<input
								type="password"
								className="form-control"
								id="exampleInputPassword1"
								placeholder="Password"
								value={form.password}
								onChange={(e) => setForm({...form, password: e.target.value})}
							/>
						</div>
						<div className="form-group">
							<label className="custom-control custom-checkbox">
								<input type="checkbox" className="custom-control-input" />
								<span className="custom-control-label">Remember me</span>
							</label>
						</div>
						<div className="form-footer">
							<button className="btn btn-primary btn-block" type='submit'>
								{isLoading ? 
									<i className="fa fa-spinner fa-spin" />
								 : 'Click to login'
								}
							</button>
						</div>
					</form>
					
				</div>
			</div>
			<div className="auth_right">
				<div className="carousel slide" data-ride="carousel" data-interval={3000}>
					<div className="carousel-inner">
						<div className="carousel-item active">
							<img src="assets/images/slider1.svg" className="img-fluid" alt="login page" />
							<div className="px-4 mt-4">
								<h4>Fully Responsive</h4>
								<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
							</div>
						</div>
						<div className="carousel-item">
							<img src="assets/images/slider2.svg" className="img-fluid" alt="login page" />
							<div className="px-4 mt-4">
								<h4>Quality Code and Easy Customizability</h4>
								<p>There are many variations of passages of Lorem Ipsum available.</p>
							</div>
						</div>
						<div className="carousel-item">
							<img src="assets/images/slider3.svg" className="img-fluid" alt="login page" />
							<div className="px-4 mt-4">
								<h4>Cross Browser Compatibility</h4>
								<p>Overview We're a group of women who want to learn JavaScript.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default withRouter(Login);