import React, { useEffect, useState } from "react";
import { useGetAllSavingsProdQuery } from "../../../services/cooperative-setup.service";
import dayjs from "dayjs";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import * as Yup from "yup";
// import { useDispatch } from "react-redux";
import alertify from "alertifyjs";
import { DatePicker, Select } from "antd";
import { useAddNewSavingsPlanMutation } from "../../../services/member.service";
import { filterOption } from "../../../utils/helpers";


const FormSchema = Yup.object().shape({
    savings_product_id: Yup.string().required('Please select product type'),
    start_date: Yup.string().required('This field is required'),
    end_date: Yup.string().required('This field is required'),
    amount: Yup.string().required('This field is required'),
    credit_interest: Yup.string().required('This field is required'),
    frequency: Yup.string().required('Select frequency'),
});

export const AddNewSavings = ({coop_id, cooperative_id}) => {
    const [products, setProducts] = useState([]);

    const {data: productsData} = useGetAllSavingsProdQuery(cooperative_id, {skip: !cooperative_id});

    useEffect(() => {
        if (productsData && productsData.status) {
          setProducts(productsData.data.map(item => ({value: item.id, label: item?.product.name})));
        }
    }, [productsData]);

    const [save, {isLoading, isSuccess, isError, data, error}] = useAddNewSavingsPlanMutation();

    const {
        control,
        reset,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(FormSchema),
        mode: 'onBlur'
    });

    const onSubmit = (values) => {
        const payload = {...values};
        payload.start_date = dayjs(values.start_date).format('YYYY-MM-DD')
        payload.end_date = dayjs(values.end_date).format('YYYY-MM-DD');
        payload.coop_id = coop_id;
        payload.cooperative_id = cooperative_id;

        save(payload);
    }

    useEffect(() => {
        if(isSuccess) {
            resetForm();
            // show toast.
            alertify.success(data?.message)
        }

        if (isError) {
            alertify.error(error?.data?.message);
        }
    }, [isSuccess, isError, data])

    const resetForm = () => {
        reset({
            savings_product_id: "",
            start_date: "",
            end_date: "",
            amount: "",
            credit_interest: "",
            frequency: ""
        })
    }

    const doCancel = () => {
        resetForm();
    }

    return (
        <div className="modal fade" id="new-savings-modal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <form onSubmit={handleSubmit(onSubmit)} className={`modal-content dimmer ${isLoading ? 'active' : ''}`}>
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Savings Account Form</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                    </div>
                    <div className="loader" />
                    <div className="modal-body dimmer-content">
                        <div className="row clearfix">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Savings Product <span className="form-required">*</span></label>
                                    <Controller
                                        name="savings_product_id"
                                        control={control}
                                        render={({ field }) => (
                                            <Select 
                                                showSearch
                                                className="customSelect mt-1"
                                                options={products} 
                                                placeholder="Select Product Type"
                                                filterOption={filterOption}
                                                value={field.value}
                                                onChange={(value) => {
                                                    field.onChange(value);
                                                    const product = productsData?.data?.find(item => item.id === value);
                                                    if (product) {
                                                        setValue('credit_interest', product.credit_interest);
                                                    }
                                                }}
                                            />
                                            
                                        )}
                                    />
                                    {errors.savings_product_id && <div className="invalid-feedback">{errors.savings_product_id?.message}</div>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Credit Interest <span className="form-required">*</span></label>
                                    <Controller
                                        name="credit_interest"
                                        control={control}
                                        render={({ field }) => (
                                            <input
                                                className={`form-control ${
                                                errors?.credit_interest ? "is-invalid" : ""
                                                }`}
                                                type="text"
                                                onChange={field.onChange}
                                                value={field.value}
                                                autoComplete="true"
                                            />
                                        )}
                                    />
                                    {errors.credit_interest && <div className="invalid-feedback">{errors.credit_interest?.message}</div>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Frequency <span className="form-required">*</span></label>
                                    <Controller
                                        name="frequency"
                                        control={control}
                                        render={({ field }) => (
                                            <select 
                                                className={`form-control ${errors?.amount ? "is-invalid" : ""}`}
                                                onChange={field.onChange} value={field.value}
                                            >
                                                <option value="">Select</option>
                                                <option value="Daily">Daily</option>
                                                <option value="Weekly">Weekly</option>
                                                <option value="Monthly">Monthly</option>
                                            </select>
                                        )}
                                    />
                                    {errors.frequency && <div className="invalid-feedback">{errors.frequency?.message}</div>}
                                </div>
                            </div>
                           
                           
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Savings Amount <span className="form-required">*</span></label>
                                    <Controller
                                        name="amount"
                                        control={control}
                                        render={({ field }) => (
                                            <input
                                                className={`form-control ${
                                                errors?.amount ? "is-invalid" : ""
                                                }`}
                                                type="text"
                                                onChange={field.onChange}
                                                value={field.value}
                                                autoComplete="true"
                                            />
                                        )}
                                    />
                                    {errors.amount && <div className="invalid-feedback">{errors.amount?.message}</div>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Sart Date <span className="form-required">*</span></label>
                                    <Controller
                                        name="start_date"
                                        control={control}
                                        render={({ field }) => (
                                            <DatePicker
                                                className={`customInput w-full`} 
                                                name={'start_date'} 
                                                style={{width: '100%'}}
                                                onChange={field.onChange} 
                                                // onBlur={onBlur} 
                                                value={field.value ? dayjs(field.value) : undefined} 
                                            />
                                        )}
                                    />
                                    {errors.start_date && <div className="invalid-feedback">{errors.start_date?.message}</div>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>End Date <span className="form-required">*</span></label>
                                    <Controller
                                        name="end_date"
                                        control={control}
                                        render={({ field }) => (
                                            <DatePicker
                                                className={`customInput w-full`} 
                                                name={'end_date'} 
                                                style={{width: '100%'}}
                                                onChange={field.onChange} 
                                                // onBlur={onBlur} 
                                                value={field.value ? dayjs(field.value) : undefined} 
                                            />
                                        )}
                                    />
                                    {errors.end_date && <div className="invalid-feedback">{errors.end_date?.message}</div>}
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" onClick={doCancel} className="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    )
}