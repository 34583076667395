import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Elections } from './Elections';
import { ElectionResults } from './Results';

class VotingHome extends Component {
    constructor(props) {
        super(props);
        // this.handleBox = this.handleBox.bind(this);
    }
    
    render() {
        const { fixNavbar, activeCoop } = this.props;
        return (
            <>
                <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
                    <div className="container-fluid">
                        <div className="d-flex justify-content-between align-items-center">
                            <ul className="nav nav-tabs page-header-tab">
                                <li className="nav-item"><a className="nav-link active" id="Project-tab" data-toggle="tab" href="#Project-OnGoing">Polls</a></li>
                                <li className="nav-item"><a className="nav-link" id="Project-tab" data-toggle="tab" href="#Project-UpComing">Results</a></li>
                            </ul>
                            <div className="header-action d-md-flex">
                                <div className="input-group mr-2">
                                    <input type="text" className="form-control" placeholder="Search..." />
                                </div>
                                <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#election_form">
                                    <i className="fe fe-plus mr-2" />Add
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-body mt-3">
                    <div className="container-fluid">
                        <div className="tab-content">
                            <div className="tab-pane fade show active" id="Project-OnGoing" role="tabpanel">
                                <Elections activeCoop={activeCoop} />
                            </div>
                            <div className="tab-pane fade" id="Project-UpComing" role="tabpanel">
                                <ElectionResults activeCoop={activeCoop} />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar,
	activeCoop: state.coop.activeCoop,
})

export default connect(mapStateToProps)(VotingHome);