import React, { useState } from "react"
import { SketchPicker } from 'react-color';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import alertify from "alertifyjs";
import Ckeditor from "../../../common/ckeditor";

export const FormSchema = Yup.object().shape({
    name: Yup.string().required('Please enter the name'),
    location: Yup.string().required('Please enter a location'),
    email: Yup.string()
      .email("Please enter a valid email address")
      .required("Email is required"),
});

export const DamageForm = () => {
    const [color, setColor] = useState('#fff');
    const dispatch = useDispatch();

    const {
        control,
        setValue,
        reset,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(FormSchema),
        mode: 'onBlur'
    });

    const onSubmit = (values) => {
        
    }


    return (
        <form className="card" onSubmit={handleSubmit(onSubmit)}>
            <div className="card-body">
                <h3 className="card-title">Add Damage</h3>
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <div className="row">
                           
                            <div className="col">
                                <div className="form-group">
                                    <label className="form-label">Date <span className="form-required">*</span></label>
                                    <Controller
                                        name="name"
                                        control={control}
                                        render={({ field }) => (
                                            <input
                                              className={`form-control ${
                                                errors?.name ? "is-invalid" : ""
                                              }`}
                                              type="text"
                                              onChange={field.onChange}
                                              value={field.value}
                                              autoComplete="true"
                                            />
                                        )}
                                    />
                                    {errors.name && <div className="invalid-feedback">{errors.name?.message}</div>}

                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                            <label className="form-label">Reference No</label>
                            <Controller
                                name="email"
                                control={control}
                                render={({ field }) => (
                                    <input
                                        className={`form-control ${
                                        errors?.name ? "is-invalid" : ""
                                        }`}
                                        type="text"
                                        onChange={field.onChange}
                                        value={field.value}
                                        autoComplete="true"
                                    />
                                )}
                            />
                            {errors.email && <div className="invalid-feedback">{errors.email?.message}</div>}
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                            <label className="form-label">Attachments</label>
                            <div className="input-group">
                                <span className="input-group-append">
                                    <button className="btn btn-primary" type="button">Choose File</button>
                                </span>
                                <input type="text" className="form-control" placeholder="Select file..." />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                            <label className="form-label">Add Products <span className="form-required">*</span></label>
                            <select className="form-control">
                                <select></select>
                            </select>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-12">
                        <div className="form-group">
                            <label className="form-label">Products</label>
                            <table className="table table-bordered">
                                <thead>
                                    <th>Product</th>
                                    <th>Unit Cost</th>
                                    <th>Quantity</th>
                                    <th>Discount</th>
                                    <th>Taxes</th>
                                    <th>Subtotal</th>
                                    <th>Actions</th>
                                </thead>
                                <tfoot className="font-bold">
                                    <th colSpan={2}>Total</th>
                                    <th>0</th>
                                    <th>0.00</th>
                                    <th>0.00</th>
                                    <th>0.00</th>
                                    <th></th>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-12">
                        <label className="form-label">Note</label>
                        <Ckeditor />
                    </div>
                </div>
            </div>
            <div className="card-footer text-right">
                <button type="submit" className="btn btn-primary">Save</button>
            </div>
        </form>
    )
}