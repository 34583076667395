import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useCastVoteMutation, useGetContestantByPositionQuery, useGetElectionQuery } from '../../../services/election.service';
import { Empty, Select } from 'antd';
import { filterOption } from '../../../utils/helpers';
import InitialsAvatar from 'react-initials-avatar';
import { ContestantItem } from '../Components/ContestantItem';
import alertify from 'alertifyjs';

const ViewContestants = ({location}) => {
    const {search} = location;
	const electionId = new URLSearchParams(search).get('id');
    const {activeCoop } = useSelector(state => state.coop);
    const {data: electionData} = useGetElectionQuery({
        coopId: activeCoop?.id, electionId
    }, {
        skip: !electionId
    })
    const {isFixNavbar: fixNavbar} = useSelector(state => state.settings);

    const [positionId, setPosition] = useState(null);
    const [contestant, setContestant] = useState(null);

    const {data: contestantData, isFetching} = useGetContestantByPositionQuery({
        coopId: activeCoop?.id,
        position: positionId
    }, {
        skip: !positionId
    });

    const [vote, {isLoading, isSuccess, data, isError, error}] = useCastVoteMutation();

    const castVote = () => {
        vote({
            cooperative_id: activeCoop?.id,
            election_id: electionId,
            position_id: contestant.position_id,
            contestant_id: contestant.id,
            coop_id: contestant.coop_id
        })
    }

    useEffect(() => {
        if(isSuccess) {
            if (data && data.status){
                const closeEle = document.getElementsByClassName('close-cast-modal');

                Array.from(closeEle).forEach(function(element) {
                    element.addEventListener('click', () => {});
                });
                alertify.success(data.message);
            } else {
                alertify.error(data?.message);
            }
        }

        if (isError) {
            alertify.error(error.data.message);
        }

    }, [isSuccess, isError, data]);

    return (
        <div className={`section-body ${fixNavbar ? "marginTop" : ""} mt-3`}>
            <div className="container-fluid">
                <div className="row clearfix">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">{electionData?.data?.name}</h3>
                                <div className="card-options" style={{width: '25%'}}>
                                    <Select 
                                        showSearch
                                        className="customSelect mt-1"
                                        options={electionData?.data?.positions.map(item => ({value: item.id, label: item.name}))} 
                                        placeholder="Select Position"
                                        filterOption={filterOption}
                                        onChange={setPosition}
                                    />
                                </div>
                            </div>
                            <div className="card-body">
                                <span>
                                    {electionData?.data?.description}
                                </span>
                            </div>
                        </div>
                    </div>

                    {contestantData?.data.length < 1 && 
                        <div className="col-md-12">
                            <Empty />
                        </div>
                    }

                    {isFetching && 

                        <div className="col-md-12 dimmer active">
                            <div className="card dimmer-content">
                                <div className="card-body text-center ribbon">
                                    <div className='loader' />
                                </div>
                            </div>
                        </div>
                    }

                    {!isFetching && contestantData?.data.map(row => 
                        <ContestantItem 
                            contestant={row} 
                            key={`contestant-${row.id}`} 
                            showManifesto={() => setContestant(row)}
                        />
                    )}

                    
                    <div className="modal fade" id="manifesto-modal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className={`modal-content dimmer ${isLoading ? 'active' : ''}`}>
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">{contestant?.member?.firstname}'s Manifesto</h5>
                                    <button type="button" className="close close-cast-modal" data-dismiss="modal" onClick={() => setContestant(null)} aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="loader" />
                                <div className="modal-body dimmer-content">
                                    {contestant?.manifesto}
                                </div>
                                <div className='modal-footer d-flex justify-content-between'>
                                    <button type="button" className="btn btn-success" onClick={castVote}>
                                        Vote
                                    </button>
                                    <button type="button" className="btn btn-secondary close-cast-modal" data-dismiss="modal">
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="castVoteModal" tabIndex={-1} role="dialog" aria-labelledby="castVoteModal" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className={`modal-content dimmer ${isLoading ? 'active' : ''}`}>
                                <div className="loader" />
                                
                                <div className="modal-body dimmer-content">
                                    <div className="form-header text-center">
                                        <h3>Cast Vote</h3>
                                        <p>Are you sure want to vote for {contestant?.member.firstname} {contestant?.member.lastname}?</p>
                                    </div>
                                    <div className="modal-btn delete-action">
                                        <div className="row">
                                            <div className="col-6">
                                                <a 
                                                    href="javascript:;" onClick={() => setContestant(null)} 
                                                    data-dismiss="modal" className="btn btn-secondary btn-block close-cast-modal">Cancel</a>
                                            </div>
                                            <div className="col-6">
                                                <a
                                                    href="javascript:;"
                                                    onClick={castVote}
                                                    className="btn btn-primary btn-block"
                                                >
                                                    {isLoading ? 'Voting...' : 'Vote'}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    
                </div>
            </div>
        </div>
    )
}


export default ViewContestants;