
import React, { useEffect, useState } from "react";
import { useGetCoaCategoriesQuery, useGetCoaSubCatByCatQuery } from "../../../../services/cooperative-setup.service";
import { useDispatch } from "react-redux";
import { toggleDelete } from "../../../../redux/slices/ui.slice";
import { DELETE_COA_SUBCATEGORY, DELETE_COOP_COA_SUBCATEGORY } from "../../../../services/CONSTANTS";
import { Select, Tooltip } from "antd";
import { filterOption } from "../../../../utils/helpers";
import CoaSubCatForm from "./CoaSubCatForm";

export const ManageCoaSubCategory = ({coop}) => {
    const {data: categoryData} = useGetCoaCategoriesQuery(coop?.id);
    const [catID, setCatID] = useState(null);

    const {data, isFetching} = useGetCoaSubCatByCatQuery({coopId: coop?.id, id: catID}, {skip: !catID});
    const [category, setCategory] = useState(null);
    const [categories, setCategories] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        if (categoryData && categoryData.status) {
          setCategories(categoryData.data.map(item => ({value: item.id, label: `[${item.code}] ${item.name}`})));
        }
    }, [categoryData]);

    const doDelete = (e, item) => {
        // console.log(e.currentTarget)
        dispatch(
          toggleDelete({
            open: true,
            title: 'COA Category',
            url: coop ?  `${DELETE_COOP_COA_SUBCATEGORY}?subcategory_id=${item.id}&cooperative_id=${coop?.id}` : `${DELETE_COA_SUBCATEGORY}?subcategory_id=${item.id}`,
            // ele: JSON.stringify(e.currentTarget),
            parent: 'tr'
          })
        );
      }

    return (
        <div className='row'>
            <div className='col-md-8 col-sm-12'>
                <div className={`card dimmer ${isFetching ? 'active' : ''}`}>
                    <div className="card-header">
                        <h3 className="card-title">COA Sub Categories</h3>
                    </div>
                    <div className="loader" />
                    <div className="card-body dimmer-content">
                        <div className="row clearfix">

                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="form-group">
                                    <Select 
                                        showSearch
                                        className="customSelect mt-1"
                                        options={categories} 
                                        placeholder="Filter by Category"
                                        filterOption={filterOption}
                                        onChange={setCatID}
                                    />
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-striped table-vcenter table-hover mb-0">
                                    <thead>
                                        <tr>
                                            <th>Code</th>
                                            <th>Name</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.data.map(row => 
                                        <tr key={`cat-${row.id}`}>
                                            <td><div className="font-15">{row.code}</div></td>
                                            <td>{row.name}</td>
                                            <td>
                                                {row.status === 1 && <span className="tag tag-success">Active</span>}
                                                {row.status === 0 && <span className="tag tag-danger">Inactive</span>}
                                            </td>
                                            <td>
                                                <Tooltip title="Edit">
                                                    <button type="button" className="btn btn-icon" onClick={() => setCategory(row)}>
                                                    <i className="icon-pencil text-info" />
                                                    </button>
                                                </Tooltip>
                                                <Tooltip title="Delete">
                                                    <button type="button" className="btn btn-icon" data-target="#deleteModal" data-toggle="modal" onClick={(e) => doDelete(e, row)}>
                                                        <i className="icon-trash text-danger" />
                                                    </button>
                                                </Tooltip>
                                            </td>
                                        </tr>
                                        )}
                                        {data?.data.length === 0 &&
                                            <tr>
                                                <td colSpan={4} className='text-center'>No data available</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-md-4 col-sm-12'>    
                <CoaSubCatForm coop={coop} categories={categories} category={category} onCancel={() => setCategory(null)} />
            </div>
        </div>
    )
}