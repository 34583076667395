import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import * as Yup from "yup";
import alertify from "alertifyjs";
import { filterOption } from "../../../utils/helpers";
import { DatePicker, Select, Spin } from "antd";
import dayjs from "dayjs";
import { useGetCalcMethodsQuery } from "../../../services/utility.service";
import { useGetAllLoansQuery, useGetChargesQuery } from "../../../services/cooperative-setup.service";
import { useApplyForLoanMutation, useLazyGetCoopMembersQuery } from "../../../services/member.service";


const FormSchema = Yup.object().shape({
    loan_product_id: Yup.string().required('Please select account'),
    loan_amount: Yup.string().required('This field is required'),
    payment_start_date: Yup.string().required('This field is required'),
    calculation_method_id: Yup.string().required('This field is required'),
    charge_id: Yup.string().required('This field is required'),
    charge_amount: Yup.string().required('This field is required'),
    surety1: Yup.string().required('This field is required'),
    surety2: Yup.string().required('This field is required'),
    tenure: Yup.string().required('This field is required'),
});

export const LoanForm = ({coop_id, cooperative_id}) => {

    const [members, setMembers] = useState([]);

    const {data: loansData} = useGetAllLoansQuery(cooperative_id, {skip: !cooperative_id});
    const {data: calcMethodData} = useGetCalcMethodsQuery();
    const {data: chargeData} = useGetChargesQuery(cooperative_id, {skip: !cooperative_id});

    const [getMembers, {data: membersData, isFetching}] = useLazyGetCoopMembersQuery()
    
    const [save, {isLoading, isSuccess, isError, data, error}] = useApplyForLoanMutation();

    // useEffect(() => {
    //     if (cooperative_id) 
    //         getMembers({coopId: cooperative_id});

    // }, [cooperative_id]);

    useEffect(() => {
        if (membersData) {
            const filtered = membersData.data.data
                .reduce((result, item) => item.coop_id !== coop_id ? 
                    result.push({value: item.coop_id, label: item.firstname + ' ' + item.lastname + ' - ' + item.coop_id})
                    && result : result, []
                );
            
            setMembers(filtered);
        }

    }, [membersData]);


    const {
        control,
        reset,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(FormSchema),
        mode: 'onBlur',
        defaultValues: {
            tenure_type: 'Months(s)'
        }
    });

    const onSubmit = (values) => {
        const payload = {...values};
        payload.coop_id = coop_id;
        payload.cooperative_id = cooperative_id;
        payload.date = dayjs(values.payment_start_date).format('YYYY-MM-DD');
        save(payload);
    }

    useEffect(() => {
        if(isSuccess) {
            resetForm();
            // show toast.
            alertify.success(data?.message)
        }

        if (isError) {
            alertify.error(error?.data?.message);
        }
    }, [isSuccess, isError, data])

    const resetForm = () => {
        reset({})
    }

    const doCancel = () => {
        resetForm();
    }

    const updateFormFields = (id) => {
        const loan = loansData?.data.find(item => item.id === id);
        if (loan) {
            setValue('tenure', loan.tenure)
            setValue('tenure_type', loan.tenure_type)
            setValue('charge_id', loan.charge_id)
            setValue('charge_amount', loan.charge_amount)
            setValue('calculation_method_id', loan.calculation_method_id)
            setValue('source', loan.source)
            setValue('penal_charge', loan.penal_charge)
            setValue('upfront', loan.upfront)
            setValue('interest_rate', loan.max_int_rate)
        }
    }

    const fetchMember = (value) => {
        getMembers({coopId: cooperative_id, search: value, page:1});
    }
 

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={`${isLoading ? 'active' : ''}`}>
            <div className="row clearfix">
                <div className="col-md-6">
                    <div className="form-group">
                    <label>Loan Type <span className="form-required">*</span> </label>
                    <Controller
                        name="loan_product_id"
                        control={control}
                        render={({ field }) => (
                            <Select 
                                showSearch
                                className="customSelect mt-1"
                                options={loansData?.data.map(loan => ({value: loan.id, label: loan.name}))} 
                                placeholder="Select Product Type"
                                filterOption={filterOption}
                                value={field.value}
                                onChange={(value) => {
                                    field.onChange(value);
                                    updateFormFields(value);
                                }}
                            />
                            
                        )}
                    />
                    {errors.loan_product_id && <div className="invalid-feedback">{errors.loan_product_id?.message}</div>}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label>Loan Amount <span className="form-required">*</span></label>
                        <Controller
                            name="loan_amount"
                            control={control}
                            render={({ field }) => (
                                <input
                                    className={`form-control ${
                                    errors?.loan_amount ? "is-invalid" : ""
                                    }`}
                                    type="text"
                                    onChange={field.onChange}
                                    value={field.value}
                                    autoComplete="true"
                                />
                            )}
                        />
                        {errors.loan_amount && <div className="invalid-feedback">{errors.loan_amount?.message}</div>}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                    <label>Tenure </label>
                        <div className="row">
                            <div className="col-4">
                                <Controller
                                    name="tenure"
                                    control={control}
                                    render={({ field }) => (
                                        <input
                                            className={`form-control ${
                                            errors?.tenure ? "is-invalid" : ""
                                            }`}
                                            type="text"
                                            onChange={field.onChange}
                                            value={field.value}
                                            autoComplete="true"
                                        />
                                    )}
                                />
                                {errors.tenure && <div className="invalid-feedback">{errors.tenure?.message}</div>}
                            </div>
                            <div className="col-8">
                                <Controller
                                    name="tenure_type"
                                    control={control}
                                    render={({ field }) => (
                                        <Select 
                                            showSearch
                                            className="customSelect mt-1"
                                            options={[
                                                {value: 'Month(s)', label: 'Month(s)'},
                                                {value: 'Year(s)', label: 'Year(s)'},
                                            ]} 
                                            placeholder="Please Select"
                                            filterOption={filterOption}
                                            value={field.value}
                                            onChange={field.onChange}
                                        />
                                        
                                    )}
                                />
                                {errors.tenure_type && <div className="invalid-feedback">{errors.tenure_type?.message}</div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="form-group">
                        <label>Interest Rate </label>
                        <Controller
                            name="interest_rate"
                            control={control}
                            render={({ field }) => (
                                <input
                                    className={`form-control ${
                                    errors?.interest_rate ? "is-invalid" : ""
                                    }`}
                                    type="text"
                                    onChange={field.onChange}
                                    value={field.value}
                                    autoComplete="true"
                                />
                            )}
                        />
                        {errors.interest_rate && <div className="invalid-feedback">{errors.interest_rate?.message}</div>}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label>Penal Charge </label>
                        <Controller
                            name="penal_charge"
                            control={control}
                            render={({ field }) => (
                                <input
                                    className={`form-control ${
                                    errors?.penal_charge ? "is-invalid" : ""
                                    }`}
                                    type="text"
                                    onChange={field.onChange}
                                    value={field.value}
                                    autoComplete="true"
                                />
                            )}
                        />
                        {errors.penal_charge && <div className="invalid-feedback">{errors.penal_charge?.message}</div>}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label>Upfront (%) </label>
                        <Controller
                            name="upfront"
                            control={control}
                            render={({ field }) => (
                                <input
                                    className={`form-control ${
                                    errors?.upfront ? "is-invalid" : ""
                                    }`}
                                    type="text"
                                    onChange={field.onChange}
                                    value={field.value}
                                    autoComplete="true"
                                />
                            )}
                        />
                        {errors.upfront && <div className="invalid-feedback">{errors.upfront?.message}</div>}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label>Source </label>
                        <Controller
                            name="source"
                            control={control}
                            render={({ field }) => (
                                <Select 
                                    showSearch
                                    className="customSelect mt-1"
                                    options={[
                                        {value: 'Internal', label: 'Internal'},
                                        {value: 'External', label: 'External'},
                                    ]} 
                                    placeholder="Please Select"
                                    filterOption={filterOption}
                                    value={field.value}
                                    onChange={field.onChange}
                                />
                                
                            )}
                        />
                        {errors.source && <div className="invalid-feedback">{errors.source?.message}</div>}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label>Calculation Method </label>
                        <Controller
                            name="calculation_method_id"
                            control={control}
                            render={({ field }) => (
                                <Select 
                                    showSearch
                                    className="customSelect mt-1"
                                    options={calcMethodData?.data.map(mthd => ({value: mthd.id, label: mthd.name}))} 
                                    placeholder="Please Select"
                                    filterOption={filterOption}
                                    value={field.value}
                                    onChange={field.onChange}
                                />
                                
                            )}
                        />
                        {errors.calculation_method_id && <div className="invalid-feedback">{errors.calculation_method_id?.message}</div>}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label>Charge </label>
                        <Controller
                            name="charge_id"
                            control={control}
                            render={({ field }) => (
                                <Select 
                                    showSearch
                                    className="customSelect mt-1"
                                    options={chargeData?.data.map(charge => ({value: charge.id, label: charge.name}))} 
                                    placeholder="Please Select"
                                    filterOption={filterOption}
                                    value={field.value}
                                    onChange={field.onChange}
                                />
                                
                            )}
                        />
                        {errors.charge_id && <div className="invalid-feedback">{errors.charge_id?.message}</div>}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label>Charge Amount</label>
                        <Controller
                            name="charge_amount"
                            control={control}
                            render={({ field }) => (
                                <input
                                    className={`form-control ${
                                    errors?.charge_amount ? "is-invalid" : ""
                                    }`}
                                    type="text"
                                    onChange={field.onChange}
                                    value={field.value}
                                    autoComplete="true"
                                />
                            )}
                        />
                        {errors.charge_amount && <div className="invalid-feedback">{errors.charge_amount?.message}</div>}
                    </div>
                </div>
                
                <div className="col-md-6">
                    <div className="form-group">
                        <label>Payment start date <span className="form-required">*</span></label>
                        <Controller
                            name="payment_start_date"
                            control={control}
                            render={({ field }) => (
                                <DatePicker
                                    className={`customInput w-full`} 
                                    name={'payment_start_date'} 
                                    style={{width: '100%'}}
                                    onChange={field.onChange} 
                                    // onBlur={onBlur} 
                                    value={field.value ? dayjs(field.value) : undefined} 
                                />
                            )}
                        />
                        {errors.start_date && <div className="invalid-feedback">{errors.start_date?.message}</div>}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label>Repayment type </label>
                        <Controller
                            name="repayment_type"
                            control={control}
                            render={({ field }) => (
                                <Select 
                                    showSearch
                                    className="customSelect mt-1"
                                    options={[
                                        {value: 'Salary', label: 'Salary'},
                                        {value: 'Others', label: 'Others'},
                                    ]} 
                                    placeholder="Please Select"
                                    filterOption={filterOption}
                                    value={field.value}
                                    onChange={field.onChange}
                                />
                                
                            )}
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label>Surety 1 <span className="form-required">*</span></label>
                        <Controller
                            name="surety1"
                            control={control}
                            render={({ field }) => (
                                <Select 
                                    showSearch
                                    className="customSelect mt-1"
                                    options={members} 
                                    placeholder="Select surety"
                                    filterOption={false}
                                    value={field.value}
                                    onSearch={fetchMember}
                                    notFoundContent={isFetching ? <Spin size="small" /> : null}
                                    onChange={field.onChange}
                                />
                                
                            )}
                        />
                        {errors.surety1 && <div className="invalid-feedback">{errors.surety1?.message}</div>}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label>Surety 2 <span className="form-required">*</span></label>
                        <Controller
                            name="surety2"
                            control={control}
                            render={({ field }) => (
                                <Select 
                                    showSearch
                                    className="customSelect mt-1"
                                    options={members} 
                                    placeholder="Select surety"
                                    filterOption={false}
                                    value={field.value}
                                    onSearch={fetchMember}
                                    notFoundContent={isFetching ? <Spin size="small" /> : null}
                                    onChange={field.onChange}
                                />
                                
                            )}
                        />
                        {errors.surety2 && <div className="invalid-feedback">{errors.surety2?.message}</div>}
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-between">
                <button type="button" onClick={doCancel} className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                <button type="submit" className="btn btn-primary">Submit</button>
            </div>
        </form>
    )
}