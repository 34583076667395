import React from 'react'
import { useSelector } from 'react-redux';

const EshopCustomers = () => {
    const {isFixNavbar: fixNavbar} = useSelector(state => state.settings);

    return (
        <>
            <div>
                <div>
                    <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
                        
                        <div className="container-fluid">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Customers</h3>
                                    <div className="card-options">
                                        <form>
                                            <div className="input-group">
                                                <span className="input-group-btn ml-2">
                                                    <button className="btn btn-icon mr-1" type="submit"><span className="fa fa-file-pdf-o" /> PDF</button>
                                                    <button className="btn btn-icon mr-1" type="submit"><span className="fa fa-file-excel-o" /> EXCEL</button>
                                                </span>

                                                <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
                                                    <i className="fe fe-plus mr-2" />Add Customer</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table table-striped table-vcenter table-hover mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Customer Name</th>
                                                    <th>Email</th>
                                                    <th>Phone</th>
                                                    <th>Orders</th>
                                                    <th>Total Orders</th>
                                                    <th>Customer Since</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Abelard Cluff</td>
                                                    <td>acluffa@bizjournals.com</td>
                                                    <td>0701234567</td>
                                                    <td>31</td>
                                                    <td>N6,632.7</td>
                                                    <td>21/05/2023 1:25:10</td>
                                                    <td><span className='tag tag-success'>Active</span></td>
                                                    <td>
                                                        <button type="button" className="btn btn-icon" title="Edit"><i className="fa fa-edit" /></button>
                                                        <button type="button" className="btn btn-icon js-sweetalert" title="Delete" data-type="confirm"><i className="fa fa-trash-o text-danger" /></button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Humberto Franescozzi</td>
                                                    <td>hfrancescozzib@dmoz.com</td>
                                                    <td>1234567890</td>
                                                    <td>90</td>
                                                    <td>N2,704.74</td>
                                                    <td>15/05/2023 1:25:10</td>
                                                    <td><span className='tag tag-danger'>Block</span></td>
                                                    <td>
                                                        <button type="button" className="btn btn-icon" title="Edit"><i className="fa fa-edit" /></button>
                                                        <button type="button" className="btn btn-icon js-sweetalert" title="Delete" data-type="confirm"><i className="fa fa-trash-o text-danger" /></button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Randi Rodwell</td>
                                                    <td>rrodwellc@live.com</td>
                                                    <td>0701234567</td>
                                                    <td>17</td>
                                                    <td>N6,632.7</td>
                                                    <td>21/05/2023 1:25:10</td>
                                                    <td><span className='tag tag-success'>Active</span></td>
                                                    <td>
                                                        <button type="button" className="btn btn-icon" title="Edit"><i className="fa fa-edit" /></button>
                                                        <button type="button" className="btn btn-icon js-sweetalert" title="Delete" data-type="confirm"><i className="fa fa-trash-o text-danger" /></button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                {/* Modal */}
                <div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Add Journal Entry</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <div className="row clearfix">
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label>Date</label>
                                            <input type="text" className="form-control" placeholder="" />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Description</label>
                                            <input type="text" className="form-control" placeholder="" />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>GL Code</label>
                                            <select className='form-control'>
                                                <option>--Select--</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label>Debit</label>
                                            <input type="number" className="form-control" placeholder="" />
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label>Credit</label>
                                            <input type="number" className="form-control" placeholder="" />
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label>Date</label>
                                            <input type="text" className="form-control" placeholder="" />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Description</label>
                                            <input type="text" className="form-control" placeholder="" />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>GL Code</label>
                                            <select className='form-control'>
                                                <option>--Select--</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label>Debit</label>
                                            <input type="number" className="form-control" placeholder="" />
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label>Credit</label>
                                            <input type="number" className="form-control" placeholder="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary">Save changes</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default EshopCustomers