import dayjs from "dayjs";
import React from "react";
import { formatNumber } from "../../../utils/helpers";
import Card from "../../common/Card";
import { Empty } from "antd";

export const Activity = ({activites}) => {
    return (
        <Card title={`Recent Activity`}>
            <div className="card-body">
                <ul className="new_timeline mt-3">
                    {activites?.map(activity => 
                    <li key={`activity-${activity.id}`}>
                        <div className={`bullet ${activity.log_type === 'DEPOSIT' ? 'green' : 'pink'} `}/>
                        <div className="time">{dayjs(activity.created_at).format('DD-MM-YYYY hh:mma')}</div>
                        <div className="desc">
                            <h3 className="text-capitalize">{activity.log_type}</h3>
                            <h4>{formatNumber(activity.amount)}</h4>
                            {/* <p>
                                Approved by Mac Miller
                            </p> */}
                        </div>
                    </li>)}
                    {activites && activites.length === 0 && <Empty />}
                    {/* <li>
                        <div className="bullet pink" />
                        <div className="time">31-10-2022 11:00am</div>
                        <div className="desc">
                            <h3>Withdrawal Request</h3>
                            <h4>N20,0000</h4>
                        </div>
                    </li>
                    <li>
                        <div className="bullet green" />
                        <div className="time">01-10-2022 12:pm</div>
                        <div className="desc">
                            <h3>Deposit</h3>
                            <h4>N50,0000</h4>
                        </div>
                    </li>
                    <li>
                        <div className="bullet green" />
                        <div className="time">31-11-2022 11:00am</div>
                        <div className="desc">
                            <h3>Deposit</h3>
                            <h4>N50,0000</h4>
                        </div>
                    </li>
                    <li>
                        <div className="bullet orange" />
                        <div className="time">01-11-2022 11:00am</div>
                        <div className="desc">
                            <h3>Loan Request</h3>
                            <h4>N100,000.00</h4>
                        </div>
                    </li> */}
                </ul>
            </div>
        </Card>
    )
}