
import React from "react";
import { useDispatch } from "react-redux";
import { toggleDelete } from "../../../../redux/slices/ui.slice";
import { DELETE_COOP_PRODUCT_CATEGORY, DELETE_PRODUCT_CATEGORY } from "../../../../services/CONSTANTS";
import { Tooltip } from "antd";

const CategoryList = ({loading, data, onEdit, coop}) => {
    const dispatch = useDispatch();

    const doDelete = (e, item) => {
        // console.log(e.currentTarget)
        dispatch(
          toggleDelete({
            open: true,
            title: 'Product Category',
            url: coop ? `${DELETE_COOP_PRODUCT_CATEGORY}?category_id=${item.id}&cooperative_id=${coop?.id}` : `${DELETE_PRODUCT_CATEGORY}?category_id=${item.id}`,
            // ele: JSON.stringify(e.currentTarget),
            parent: 'tr'
          })
        );
    }
    return (
        <div className={`card dimmer ${loading ? 'active' : ''}`}>
            <div className="loader" />
            <div className="card-header">Categories</div>
            <div className="card-body dimmer-content">
                <div className="table-responsive">
                    <table className="table table-striped table-vcenter table-hover mb-0">
                        <thead>
                            <tr>
                                <th>Category Name</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.map(row => 
                                <tr key={row.id}>
                                    <td>{row.name}</td>
                                    <td>
                                        {row.status === 1 && <span className="tag tag-success">Active</span>}
                                        {row.status === 0 && <span className="tag tag-danger">Inactive</span>}
                                    </td>
                                    <td>
                                        <Tooltip title="Edit">
                                            <button type="button" className="btn btn-icon btn-sm btn-sm" onClick={() => onEdit(row)}>
                                                <i className="icon-pencil text-info" />
                                            </button>
                                        </Tooltip>
                                        <Tooltip title="Delete">
                                            <button type="button" className="btn btn-icon btn-sm" title="Delete" data-toggle="modal" data-target="#deleteModal" onClick={(e) => doDelete(e, row)}>
                                                <i className="icon-trash text-danger" />
                                            </button>
                                        </Tooltip>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default CategoryList;