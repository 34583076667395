import React, { useEffect, useState } from 'react'
import { DatePicker, Empty } from 'antd';
import { useLazyTrialBalanceReportQuery } from '../../services/utility.service';
import { useSelector } from 'react-redux';
import { doDownload, formatNumber } from '../../utils/helpers';
import dayjs from 'dayjs';
import { NavLink } from 'react-router-dom';
import { TRIAL_BALANCE } from '../../services/CONSTANTS';

const TrialBalance = () => {
    const {isFixNavbar: fixNavbar} = useSelector(state => state.settings);
    const {activeCoop} = useSelector(state => state.coop);
    const {token} = useSelector(state => state.user);
    const [isDownloading, setIsDownloading] = useState(false);
    const [filterData, setFilterData] = useState({
        id: activeCoop?.id,
        start: '',
        end: '',
        download: false
    })

    const [getResults, {data, isLoading, isError}] = useLazyTrialBalanceReportQuery();

    useEffect(() => {
        getResults(filterData);
    }, []);

    const download = async () => {
        setIsDownloading(true);
        const url = `${process.env.REACT_APP_API_URL}${TRIAL_BALANCE}?cooperative_id=${activeCoop?.id}&download=true&&start_date=${filterData.start}&end_date=${filterData.end}`
        await doDownload(url, `${activeCoop?.name}-trial-balance-${dayjs().unix()}.xlsx`, token);
        setIsDownloading(false)
    }

    return (
        <>
            <div className="container-fluid">
                <div className={`section-body mt-3 ${fixNavbar ? "marginTop" : ""}`}>
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-3 col-md-4 col-sm-6">
                                    <label>From</label>
                                    <DatePicker
                                        className={`customInput w-full`} 
                                        name={'start'} 
                                        placeholder='Start Date'
                                        style={{width: '100%'}}
                                        value={filterData.start !== '' ? dayjs(filterData.start) : undefined} 
                                        onChange={(value) => setFilterData({...filterData, start: dayjs(value).format('YYYY-MM-DD')})}
                                    />
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6">
                                    <label>To</label>
                                    <DatePicker
                                        className={`customInput w-full`} 
                                        name={'start'} 
                                        placeholder='End Date'
                                        style={{width: '100%'}}
                                        value={filterData.end !== '' ? dayjs(filterData.end) : undefined} 
                                        onChange={(value) => setFilterData({...filterData, end: dayjs(value).format('YYYY-MM-DD')})}
                                    />
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6">
                                    <label>&nbsp;</label>
                                    <button className="btn btn-sm btn-primary btn-block" onClick={() => getResults(filterData)}>Search</button>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6">
                                    <label>&nbsp;</label>
                                    <button className="btn btn-sm btn-secondary btn-block" onClick={download}>
                                        {isDownloading ?  <i className="fa fa-spinner fa-spin mr-2" />:  <i className="fe fe-download mr-2" />}
                                        Download
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`card dimmer ${isLoading ? 'active' : '' }`}>
                        <div className="card-body dimmer-content">
                            <div className="loader" />
                            <div className="table-responsive">
                                <table className="table reporting-table report table-hover table-striped table-vcenter mb-0">
                                    <thead>
                                        <tr>
                                            <th style={{width: '70%'}}></th>
                                            <th>Total Debit (NGN)</th>
                                            <th>Total Credit (NGN)</th>
                                        </tr>
                                    </thead>
                                    {data?.data.results.map((group, i) => 
                                        <tbody key={`trial-group-${i}`}>
                                            <tr >
                                                <td className='text-bold'>[{group.category_code}] {group.category_name}</td>
                                                <td className='text-right'></td>
                                                <td className='text-right'></td>
                                            </tr>
                                            {
                                                group.sub_results.map((category, i) => 
                                                    <>
                                                    <tr key={`trial-cat-${i}`}>
                                                        <td style={{paddingLeft: '3rem', fontWeight: '600'}}>[{category.subcategory_code}] {category.subcategory_name}</td>
                                                        <td className='text-right'></td>
                                                        <td className='text-right'></td>
                                                    </tr>
                                                    {category.coas.map((coa, i) => 
                                                        <>
                                                            <tr key={`trial-sub-cat-${i}`}>
                                                                <td style={{paddingLeft: '5rem', fontWeight: '500'}}>
                                                                    <NavLink to={`/reports/ledger-statement?code=${coa.coa_code}`}>[{coa.coa_code}] {coa.coa_name}</NavLink>
                                                                </td>
                                                                <td className='text-right'>{coa.results.dc === 'D' ? formatNumber(coa.results.balance) : 0}</td>
                                                                <td className='text-right'>{coa.results.dc === 'C' ? formatNumber(coa.results.balance) : 0}</td>
                                                            </tr>
                                                        </>
                                                    )}
                                                    </>
                                                )
                                            }
                                        </tbody>
                                    )}
                                    <tbody>
                                        <tr>
                                            <td></td>
                                            <td className="text-right">{formatNumber(data?.data.total.debitBalance) }</td>
                                            <td className="text-right">{formatNumber(data?.data.total.creditBalance) }</td>
                                        </tr>
                                    </tbody>
                                        
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TrialBalance;