import React, { useState } from "react"
import { SketchPicker } from 'react-color';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import alertify from "alertifyjs";

import Ckeditor from '../../../common/ckeditor';


export const ProductForm = () => {
    const [color, setColor] = useState('#fff');
    const dispatch = useDispatch();
    
    const {
        control,
        setValue,
        reset,
        handleSubmit,
        formState: { errors },
    } = useForm({
        // resolver: yupResolver(FormSchema),
        mode: 'onBlur'
    });

    const onSubmit = () => {}
  
    return (
        <form className="card" onSubmit={handleSubmit(onSubmit)}>
            <div className="card-body">
                <h3 className="card-title">Add New Product</h3>
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        
                        <div className="form-group">
                            <label className="form-label">Name</label>
                            <Controller
                                name="name"
                                control={control}
                                render={({ field }) => (
                                    <input
                                        className={`form-control ${
                                        errors?.name ? "is-invalid" : ""
                                        }`}
                                        type="text"
                                        onChange={field.onChange}
                                        value={field.value}
                                        autoComplete="true"
                                    />
                                )}
                            />
                            {errors.name && <div className="invalid-feedback">{errors.name?.message}</div>}
                        </div>
                    </div>
                    {/* <div className="col-sm-6 col-md-3">
                        <div className="form-group">
                            <label className="form-label">Username</label>
                            <input type="text" className="form-control is-invalid" placeholder="Username" defaultValue="michael23" />
                            <div className="invalid-feedback">Invalid feedback</div>
                        </div>
                    </div> */}
                    <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                            <label className="form-label">SKU <span className="form-required">*</span></label>
                            <Controller
                                name="email"
                                control={control}
                                render={({ field }) => (
                                    <input
                                        className={`form-control ${
                                        errors?.name ? "is-invalid" : ""
                                        }`}
                                        type="text"
                                        onChange={field.onChange}
                                        value={field.value}
                                        autoComplete="true"
                                    />
                                )}
                            />
                            {errors.email && <div className="invalid-feedback">{errors.email?.message}</div>}

                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                            <label className="form-label">Category</label>
                            <Controller
                                name="location"
                                control={control}
                                render={({ field }) => (
                                    <select 
                                        onChange={field.onChange}
                                        value={field.value}
                                        className="form-control custom-select"
                                    >
                                        <option value=''>Select Status</option>
                                        <option value='1'>Active</option>
                                        <option value='0'>Inactive</option>
                                    </select>
                                )}
                            />
                            {errors.location && <div className="invalid-feedback">{errors.location?.message}</div>}
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                            <label className="form-label">Status</label>
                            <Controller
                                name="status"
                                control={control}
                                render={({ field }) => (
                                    <select 
                                        onChange={field.onChange}
                                        value={field.value}
                                        className="form-control custom-select"
                                    >
                                        <option value=''>Select Status</option>
                                        <option value='1'>Active</option>
                                        <option value='0'>Inactive</option>
                                    </select>
                                )}
                            />
                            
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        
                        <div className="form-group">
                            <label className="form-label">Buying Price</label>
                            <Controller
                                name="name"
                                control={control}
                                render={({ field }) => (
                                    <input
                                        className={`form-control ${
                                        errors?.name ? "is-invalid" : ""
                                        }`}
                                        type="text"
                                        onChange={field.onChange}
                                        value={field.value}
                                        autoComplete="true"
                                    />
                                )}
                            />
                            {errors.name && <div className="invalid-feedback">{errors.name?.message}</div>}

                        </div>
                            
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                            <label className="form-label">Selling Price <span className="form-required">*</span></label>
                            <Controller
                                name="email"
                                control={control}
                                render={({ field }) => (
                                    <input
                                        className={`form-control ${
                                        errors?.name ? "is-invalid" : ""
                                        }`}
                                        type="text"
                                        onChange={field.onChange}
                                        value={field.value}
                                        autoComplete="true"
                                    />
                                )}
                            />
                            {errors.email && <div className="invalid-feedback">{errors.email?.message}</div>}

                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        
                        <div className="form-group">
                            <label className="form-label">Maximum Purchase Quantity <span className="form-required">*</span></label>
                            <Controller
                                name="name"
                                control={control}
                                render={({ field }) => (
                                    <input
                                        className={`form-control ${
                                        errors?.name ? "is-invalid" : ""
                                        }`}
                                        type="text"
                                        onChange={field.onChange}
                                        value={field.value}
                                        autoComplete="true"
                                    />
                                )}
                            />
                            {errors.name && <div className="invalid-feedback">{errors.name?.message}</div>}

                        </div>
                            
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                            <label className="form-label">Low Stock Quantity Warning <span className="form-required">*</span></label>
                            <Controller
                                name="email"
                                control={control}
                                render={({ field }) => (
                                    <input
                                        className={`form-control ${
                                        errors?.name ? "is-invalid" : ""
                                        }`}
                                        type="text"
                                        onChange={field.onChange}
                                        value={field.value}
                                        autoComplete="true"
                                    />
                                )}
                            />
                            {errors.email && <div className="invalid-feedback">{errors.email?.message}</div>}

                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-md-12">
                            <label for="reg_form">Select Images</label>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group">
                                <div className="fileinput fileinput-new" data-provides="fileinput">
                                    
                                    <div className="fileinput-new thumbnail" style={{ width: '200px', height: '150px' }}>
                                        <img src="http://www.placehold.it/200x150/EFEFEF/AAAAAA&amp;text=no+image" alt="" />
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group">
                                <div className="fileinput fileinput-new" data-provides="fileinput">
                                    
                                    <div className="fileinput-new thumbnail" style={{ width: '200px', height: '150px' }}>
                                        <img src="http://www.placehold.it/200x150/EFEFEF/AAAAAA&amp;text=no+image" alt="" />
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group">
                                <div className="fileinput fileinput-new" data-provides="fileinput">
                                    
                                    <div className="fileinput-new thumbnail" style={{ width: '200px', height: '150px' }}>
                                        <img src="http://www.placehold.it/200x150/EFEFEF/AAAAAA&amp;text=no+image" alt="" />
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group">
                                <div className="fileinput fileinput-new" data-provides="fileinput">
                                    
                                    <div className="fileinput-new thumbnail" style={{ width: '200px', height: '150px' }}>
                                        <img src="http://www.placehold.it/200x150/EFEFEF/AAAAAA&amp;text=no+image" alt="" />
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div className="col-sm-12 col-md-12">
                        <label className="form-label">Description</label>
                        <Ckeditor />
                    </div>
                </div>
            </div>
            <div className="card-footer text-right">
                <button type="submit" className="btn btn-primary">Save</button>
            </div>
        </form>
    )
}