
import React, { useState } from "react";
import CategoryForm from "./CategoryForm";
import { useDispatch, useSelector } from "react-redux";
import { useGetStaffCategoriesQuery } from "../../../services/setup.service";
import { Empty, Tooltip } from "antd";
import { toggleDelete } from "../../../redux/slices/ui.slice";
import { DELETE_STAFF_CATEGORY } from "../../../services/CONSTANTS";

const StaffCategories = () => {
    const {isFixNavbar: fixNavbar} = useSelector(state => state.settings);
    const [category, setCategory] = useState(null);
    const {data, isLoading, error} = useGetStaffCategoriesQuery();
    const dispatch = useDispatch();

    const doDelete = (e, item) => {
        // console.log(e.currentTarget)
        dispatch(
          toggleDelete({
            open: true,
            title: 'Staff Category',
            url: `${DELETE_STAFF_CATEGORY}?category_id=${item.id}`,
            // ele: JSON.stringify(e.currentTarget),
            parent: 'tr'
          })
        );
      }

    return (
        <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
            <div className="row">
                <div className="col-md-6 col-sm-12">
                    <div className={`card dimmer ${isLoading ? 'active' : ''}`}>
                        <div className="loader" />
                        <div className="card-header">Categories</div>
                        <div className="card-body dimmer-content">
                            <div className="table-responsive">
                                <table className="table table-striped table-vcenter table-hover mb-0">
                                    <thead>
                                        <tr>
                                            <th>Category Name</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.data.map(row => 
                                            <tr key={row.id}>
                                                <td>{row.name}</td>
                                                <td>
                                                    <Tooltip title="Edit">
                                                        <button type="button" className="btn btn-icon btn-sm btn-sm" onClick={() => setCategory(row)}>
                                                            <i className="icon-pencil text-info" />
                                                        </button>
                                                    </Tooltip>
                                                    <Tooltip title="Delete">
                                                        <button type="button" className="btn btn-icon btn-sm" title="Delete" data-toggle="modal" data-target="#deleteModal" onClick={(e) => doDelete(e, row)}>
                                                            <i className="icon-trash text-danger" />
                                                        </button>
                                                    </Tooltip>
                                                </td>
                                            </tr>
                                        )}
                                        
                                        {data?.data.length === 0 && <tr><td colSpan={2}>
                                            <Empty />
                                            </td></tr>}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-sm-12">
                    <CategoryForm category={category} onCancel={() => setCategory(null)} />
                </div>
            </div>
        </div>
    )
}

export default StaffCategories;