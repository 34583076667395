import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import * as Yup from "yup";
// import { useDispatch } from "react-redux";
import alertify from "alertifyjs";
import { useGenerateGLCodeQuery, useGetCoaSubCatByCatQuery, useSaveCoaMutation, useUpdateCoaMutation, } from "../../../../services/cooperative-setup.service";
import { filterOption } from "../../../../utils/helpers";
import { Select } from "antd";

export const FormSchema = Yup.object().shape({
    name: Yup.string().required('Please enter the name'),
    code: Yup.string().required('Please enter a code'),
    subcategory_id: Yup.string().required('Select Coa Category'),
    type: Yup.string().required('Select account type'),
    status: Yup.string().required('Select status'),
});

const LedgerForm = ({coop, ledger, onCancel, categories, editCategory, editSubcategories}) => {
    const [catID, setCatID] = useState(null);
    const [subCatID, setSubCatId] = useState(null);
    const [glCode, setGlCode] = useState(null);
    
    const {data: subCategoryData, isFetching: subCatLoading} = useGetCoaSubCatByCatQuery({id: catID, coopId: coop?.id}, {
        skip: !catID
    });

    const {data: glData, isFetching: glCodeLoading} = useGenerateGLCodeQuery({id: subCatID, coopId: coop?.id}, {
        skip: !subCatID
    });
    
    const [subCategories, setSubCategories] = useState(null);

    useEffect(() => {
        if (subCategoryData && subCategoryData.status) {
            setSubCategories(subCategoryData.data.map(item => ({value: item.id, label: `[${item.code}] ${item.name}`})));
        }
    }, [subCategoryData]);

    const [save, {isLoading, isSuccess, isError, data, error}] = useSaveCoaMutation();
    const [update, {
        isLoading: isUpdateLoading, 
        isSuccess: isUpdateSuccess, 
        isError: isUpdateError, 
        data: updateData, 
        error: updateError
    }] = useUpdateCoaMutation();

    const {
        control,
        reset,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(FormSchema),
        mode: 'onBlur'
    });

    useEffect(() => {
        if (ledger) {
            reset({
                name: ledger.name,
                code: ledger.code,
                type: ledger.type,
                subcategory_id: ledger.sub_category_id,
                status: ledger.status,
                id: ledger.id,
            })
            setGlCode(ledger.code);
        }
    }, [ledger]);

    useEffect(() => {
        if (glData && glData.status) {
            setValue('code',  glData.data);
            setGlCode(glData.data);
        } else if (glData) {
            setValue('code', '');
            setGlCode(null);
            alertify.error(glData?.message)
        }
    }, [glData]);


    const onSubmit = (values) => {
        if (coop) values.cooperative_id = coop?.id
        if (ledger) {
            update(values);
        } else {
            save(values);
        }
    }

    useEffect(() => {
        if(isSuccess || isUpdateSuccess) {
            resetForm();
            // show toast.
            alertify.success(data?.message || updateData?.message)
        }

        if (isError || isUpdateError) {
            alertify.error(error?.data?.message || updateError?.data?.message);
        }
    }, [isSuccess, isUpdateSuccess, isUpdateError, isError, data, updateData]);

    const resetForm = () => {
        reset({
          name: "",
          code: "",
          type: "",
          subcategory_id: "",
          status: "",
          id: "",
        })
        setCatID('')
        setSubCatId('')
        setGlCode('')
    }

    const doCancel = () => {
        resetForm();
        onCancel();
    }

    return (
        <div className={`card dimmer ${(isLoading || isUpdateLoading || subCatLoading || glCodeLoading) ? 'active' : ''}`} >
            <div className="card-header">
                <h3 className="card-title">Add/Edit COA Category</h3>
            </div>
            <div className="loader" />
            <form onSubmit={handleSubmit(onSubmit)} className="card-body dimmer-content">
                <div className="row clearfix">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="form-group">
                            <Select 
                                showSearch
                                className="customSelect mt-1"
                                options={categories} 
                                placeholder="Select COA Category"
                                filterOption={filterOption}
                                value={catID || editCategory}
                                onChange={(value) => setCatID(value)}
                            />
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="form-group">
                            <Select 
                                showSearch
                                className="customSelect mt-1"
                                options={subCategories || editSubcategories} 
                                placeholder="Select COA Sub Category"
                                filterOption={filterOption}
                                value={subCatID ? subCatID : ledger?.sub_category_id}
                                onChange={(val) => {
                                    setValue('subcategory_id', val);
                                    setSubCatId(val);
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="form-group">
                            <input
                                className={`form-control ${errors?.code ? "is-invalid" : ""}`}
                                type="text"
                                readOnly
                                value={glCode}
                                autoComplete="true"
                                placeholder="GL Code"
                            />
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="form-group">
                            <Controller
                                name="name"
                                control={control}
                                render={({ field }) => (
                                    <input
                                        className={`form-control ${errors?.name ? "is-invalid" : ""}`}
                                        type="text"
                                        onChange={field.onChange}
                                        value={field.value}
                                        autoComplete="true"
                                        placeholder="Ledger Name*"
                                    />
                                )}
                            />
                            {errors.name && <div className="invalid-feedback">{errors.name?.message}</div>}
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="form-group">
                            <Controller
                                name="type"
                                control={control}
                                render={({ field }) => (
                                    <select 
                                        onChange={field.onChange}
                                        value={field.value}
                                        className={`form-control ${errors?.type ? "is-invalid" : ""}`}
                                    >
                                        <option value=''>Select Type</option>
                                        <option value='D'>Dr</option>
                                        <option value='C'>Cr</option>
                                    </select>
                                )}
                            />
                            {errors.type && <div className="invalid-feedback">{errors.type?.message}</div>}
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="form-group">
                            <Controller
                                name="status"
                                control={control}
                                render={({ field }) => (
                                    <select 
                                        onChange={field.onChange}
                                        value={field.value}
                                        className={`form-control ${errors?.status ? "is-invalid" : ""}`}
                                    >
                                        <option value=''>Select Status</option>
                                        <option value='1'>Active</option>
                                        <option value='0'>Inactive</option>
                                    </select>
                                )}
                            />
                            {errors.status && <div className="invalid-feedback">{errors.status?.message}</div>}
                        </div>
                    </div>
                    <div className="col-12 d-flex justify-content-between">
                        
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={doCancel}
                        >
                            CANCEL
                        </button>
                        <button type="submit" className="btn btn-primary mr-3">
                            SAVE
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default LedgerForm;