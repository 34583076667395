import React from "react";

const CategoryForm = () => {
    return (
        <div className="card" >

            <div className="card-header">
                <h3 className="card-title">Add/Edit Category</h3>
            </div>
            <div className="card-body">
                <div className="row clearfix">
                    <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                            <label>Name</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter category name"
                            />
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                            <label>Status</label>
                            <select className="form-control">
                                <option>Select</option>
                                <option option="active">Active</option>
                                <option option="Inactive">Inactive</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                            <label>Description</label>
                            <textarea className="form-control" rows={4}> </textarea>
                        </div>
                    </div>
                </div>
                    
                <button type="button" className="btn btn-round btn-primary">
                    Save
                </button>{' '}&nbsp;&nbsp;
                <button type="button" className="btn btn-round btn-default">
                        Cancel
                </button>
            </div>
        </div>
    )
}

export default CategoryForm;