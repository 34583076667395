import React, { useEffect } from "react";
import alertify from "alertifyjs";
import { useDenyLoanMutation, useDenyTransactionOrderMutation } from "../../services/member.service";

export default function DenyTransactionModal({ item, type }) {
    const [denyTransaction, {isLoading, isSuccess, isError, data, error}] = useDenyTransactionOrderMutation();
    const [denyLoan, {
        isLoading: isLoanLoding, 
        isSuccess: isLoanSuccess, 
        isError: isLoanError, 
        data: loanData, 
        error: loanError
    }] = useDenyLoanMutation();
    
    useEffect(() => {
        if (isSuccess && data.status)  {
            document.getElementById('close-deny-modal').click();
            alertify.success(data?.message);
        }

        if(isError) {
            alertify.error(error.data.message)
        }


    }, [isSuccess, isError, error, data]);

    useEffect(() => {
        if (isLoanSuccess && loanData.status)  {
            document.getElementById('close-deny-modal').click();
            alertify.success(loanData?.message);
        }

        if(isLoanError) {
            alertify.error(loanError.data.message)
        }


    }, [isLoanSuccess, isLoanError, loanError, loanData]);


    const denyItem = () => {
        if (type === 'loan') {
            denyLoan(item.id);
        } else {

            denyTransaction({transaction_id: item.id})
        }
    }

    return (
        <div className="modal fade" id="denyModal" tabIndex={-1} role="dialog" aria-labelledby="denyModal" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className={`modal-content dimmer ${isLoading || isLoanLoding ? 'active' : ''}`}>
                    <div className="loader" />
                    
                    <div className="modal-body dimmer-content">
                        <div className="form-header text-center">
                            <h3>Deny {type === 'transaction' ? 'Transaction' : 'Loan' }</h3>
                            <p>Are you sure want to deny this transaction?</p>
                        </div>
                        <div className="modal-btn delete-action">
                            <div className="row">
                                <div className="col-6">
                                    <a 
                                        href="javascript:;"
                                        id="close-deny-modal" data-dismiss="modal" className="btn btn-secondary btn-block">Cancel</a>
                                </div>
                                <div className="col-6">
                                    <a
                                        href="javascript:;"
                                        onClick={denyItem}
                                        className="btn btn-primary btn-block"
                                    >
                                        {isLoading ? 'Please Wait...' : 'Confirm'}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
