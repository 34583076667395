
import React from "react";
const CategoryList = () => {
    return (
        <div className="card">
            <div className="card-header">Categories</div>
            <div className="card-body">
                <div className="table-responsive">
                    <table className="table table-striped table-vcenter table-hover mb-0">
                        <thead>
                            <tr>
                                <th>Category Name</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Category 1</td>
                                <td>
                                    <button type="button" className="btn btn-icon btn-sm btn-sm" data-toggle="tooltip" data-placement="top">
                                        <i className="icon-pencil text-info" />
                                    </button>
                                    <button type="button" className="btn btn-icon btn-sm" title="Delete" data-toggle="tooltip" data-placement="top">
                                        <i className="icon-trash text-danger" />
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td>Category 2</td>
                                <td>
                                    <button type="button" className="btn btn-icon btn-sm btn-sm" data-toggle="tooltip" data-placement="top">
                                        <i className="icon-pencil text-info" />
                                    </button>
                                    <button type="button" className="btn btn-icon btn-sm" title="Delete" data-toggle="tooltip" data-placement="top">
                                        <i className="icon-trash text-danger" />
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td>Category 3</td>
                                <td>
                                    <button type="button" className="btn btn-icon btn-sm btn-sm" data-toggle="tooltip" data-placement="top">
                                        <i className="icon-pencil text-info" />
                                    </button>
                                    <button type="button" className="btn btn-icon btn-sm" title="Delete" data-toggle="tooltip" data-placement="top">
                                        <i className="icon-trash text-danger" />
                                    </button>
                                </td>
                            </tr>
                            
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default CategoryList;