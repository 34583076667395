import React from "react";
import { useGetSuretyRequestQuery } from "../../../services/member.service";
import { Empty, Tooltip } from "antd";
import { formatNumber } from "../../../utils/helpers";
import { useDispatch } from "react-redux";
import { updateSuretyResponse } from "../../../redux/slices/user.slice";
import Card from "../../common/Card";

export const SuretyRequest = ({coop, memberId}) => {

    const {data, isLoading} = useGetSuretyRequestQuery({id: coop?.id, coopId: memberId}, {skip: !memberId});
    const dispatch = useDispatch();

    return (
        <Card title={`Surety Request`} loading={isLoading}>
            <div className="card-body dimmer-content">
                <div className="table-responsive">
                    <table className="table table-striped table-vcenter table-hover mb-0">
                        <thead>
                            <tr>
                                <th>Member Name</th>
                                <th>Loan Name</th>
                                <th>Loan Amount</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.data?.map(row =>
                                <tr key={`surety-req-${row.id}`}>
                                    <td>{row?.loan.member?.firstname} {row.loan.member?.lastname}</td>
                                    <td>{row.loan.product.name}</td>
                                    <td><div className="font-15">{formatNumber(row.loan.total_amount)}</div></td>
                                    <td>
                                        {row.status === 0 && <span className="tag tag-warning">Pending</span>}
                                        {row.status === 1 && <span className="tag tag-success">Approved</span>}
                                        {row.status === 2 && <span className="tag tag-danger">Denied</span>}
                                    </td>
                                    <td>
                                        {row.status === 0 && 
                                            <>
                                                <Tooltip title="Approve">
                                                    <button 
                                                        type="button" 
                                                        className="btn btn-icon text-success"
                                                        onClick={() => dispatch(updateSuretyResponse({type: 'approve', id: row.id}))} 
                                                        data-toggle="modal" data-target="#confirmSuretyModal"
                                                    >
                                                        <i className="fa fa-check" />
                                                    </button>
                                                </Tooltip>
                                            
                                                <Tooltip title="Deny">
                                                    <button 
                                                        type="button" 
                                                        className="btn btn-icon text-danger"
                                                        onClick={() => dispatch(updateSuretyResponse({type: 'deny', id: row.id}))} 
                                                        data-toggle="modal" data-target="#confirmSuretyModal"
                                                    >
                                                        <i className="fa fa-times" />
                                                    </button>
                                                </Tooltip>
                                            </>
                                        }
                                    </td>
                                </tr>
                            )}

                            {data?.data.length === 0 && <tr><td colSpan={5}><Empty /></td></tr>}
                            
                            
                        </tbody>
                    </table>
                </div>
            </div>
        </Card>
    )
}