import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ReturnsList } from './ReturnsList';

class ReturnOrders extends Component {

	render() {
		const { fixNavbar } = this.props;
		return (
			<div>
                <div>
                    <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
                        <div className="container-fluid">
                            <div className="d-flex justify-content-between align-items-center">
                                <ul className="nav nav-tabs page-header-tab">
                                    <li className="nav-item"><a className="nav-link active" id="Departments-tab" data-toggle="tab" href="#cooperative-list">Returns List</a></li>
                                    <li className="nav-item"><a className="nav-link" id="Departments-tab" data-toggle="tab" href="#cooperative-form">Add Return</a></li>
                                </ul>
                                {/* <div className="header-action">
                                    <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#addRole">
                                        <i className="fe fe-plus mr-2" /> Add
                                    </button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="section-body mt-3">
                        <div className="container-fluid">
                            <div className="tab-content mt-3">
                                <div className="tab-pane fade show active" id="cooperative-list" role="tabpanel">
									<ReturnsList />
                                </div>
                                <div className="tab-pane fade" id="cooperative-form" role="tabpanel">
                                    <div className="row clearfix">
										{/* <CooperativeForm /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                
            </div>
		);
	}
}
const mapStateToProps = state => ({
	fixNavbar: state.settings.isFixNavbar
})

const mapDispatchToProps = dispatch => ({})
export default connect(mapStateToProps, mapDispatchToProps)(ReturnOrders);