import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import * as Yup from "yup";
import alertify from "alertifyjs";
import { useCloseInvestmentTrackerMutation } from "../../../services/utility.service";

const FormSchema = Yup.object().shape({
    investment_income: Yup.string().required('Please enter an amount'),
});

export const CloseInvestmentTracker = ({cooperative_id, investment}) => {
    const [save, {isLoading, isSuccess, isError, error, data}] = useCloseInvestmentTrackerMutation()
    const {
        control,
        reset,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(FormSchema),
        mode: 'onBlur'
    });


    const onSubmit = (values) => {
        const payload = {...values};
        payload.cooperative_id = cooperative_id;
        payload.investment_id = investment.id;
        save(payload);
    }

    useEffect(() => {
        if(isSuccess) {
            resetForm();
            // show toast.
            alertify.success(data?.message)
        }

        if (isError) {
            alertify.error(error?.data?.message);
        }
    }, [isSuccess, isError, data])

    const resetForm = () => {
        reset({})
    }

    return (
        <div className="modal fade" id="close_investment_tracker" tabIndex={-1} role="dialog" aria-labelledby="close_investment_tracker" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <form onSubmit={handleSubmit(onSubmit)} className={`modal-content dimmer ${isLoading ? 'active' : ''}`}>
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Close Investment Tracker</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                    </div>
                    <div className="loader" /> 
                    <div className="modal-body dimmer-content">
                        <div className="row clearfix">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Investment Income</label>
                                    <Controller
                                        name="investment_income"
                                        control={control}
                                        render={({ field }) => (
                                            <input
                                                className={`form-control ${
                                                errors?.investment_income ? "is-invalid" : ""
                                                }`}
                                                type="text"
                                                onChange={field.onChange}
                                                placeholder="Enter amount "
                                                value={field.value}
                                            />
                                        )}
                                    />
                                    {errors.investment_income && <div className="invalid-feedback">{errors.investment_income?.message}</div>}
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" onClick={resetForm} className="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </div>
                </form>
            </div>
        </div>

    )
}