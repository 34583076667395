import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import SavingsList from './Savings/SavingsList';
import SavingForm from './Savings/SavingsForm';

const SavingsSetup = () => {

   const {isFixNavbar: fixNavbar} = useSelector(state => state.settings);
   const {activeCoop} = useSelector(state => state.coop);
    const [savings, setSavings] = useState(null)
    return (
        <>
            <div>
                <div>
                    <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
                        <div className="container-fluid">
                            <div className="d-flex justify-content-between align-items-center">
                                <ul className="nav nav-tabs page-header-tab">
                                    <li className="nav-item"><a className="nav-link active" id="savings-list-tab" data-toggle="tab" href="#setups-list">Setups</a></li>
                                    <li className="nav-item"><a className="nav-link" id="savings-form-tab" data-toggle="tab" href="#new-setup">Add New Setup</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="section-body mt-3">
                        <div className="container-fluid">
                            <div className="tab-content mt-3">
                                <div className="tab-pane fade show active" id="setups-list" role="tabpanel">
                                    <SavingsList 
                                        coop={activeCoop}
                                        onEdit={(val) => {
                                            setSavings(val);
                                            document.getElementById('savings-form-tab').click()
                                        }} 
                                    />
                                </div>
                                <div className="tab-pane fade" id="new-setup" role="tabpanel">
                                    <SavingForm 
                                        coop={activeCoop}
                                        item={savings} 
                                        onCancel={() => {
                                            setSavings(null);
                                            document.getElementById('savings-list-tab').click();

                                        }} 
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SavingsSetup