import React, {useEffect } from 'react';
import Menu from './Menu';
import { useDispatch, useSelector } from 'react-redux';
import DeleteModal from '../common/DeleteModal';
import SuretyResponseModal from '../common/SuretyResponsModal';
import { useGetAllCooperativesQuery } from '../../services/cooperative-setup.service';
import { setActiveCoop } from '../../redux/slices/cooperative.slice';
import { changeColors } from '../../redux/slices/ui.slice';

export const Layout = (props) => {
	const { user, isAuthenticated } = useSelector(state => state.user);
	const {deleteModal} = useSelector((state) => state.ui);
	const {data} = useGetAllCooperativesQuery();
	const dispatch = useDispatch();

	useEffect(() => {
		if (!isAuthenticated) {
			window.location.href = '/login'
		}
	}, [isAuthenticated])

	useEffect(() => {
		if (data && user?.cooperative_id !== 0) {
			const cooperative = data?.data.find(coop => coop.id === user?.cooperative_id);
			//set active coop for member
			if (cooperative) {
				dispatch(setActiveCoop(cooperative));
				if (cooperative.colors) {
					const colors = JSON.parse(cooperative.colors);
					dispatch(changeColors(JSON.parse(colors)));
				}
			}
		}
	}, [data, user])
	
	return (
		<div id="main_content">
			<Menu {...props} />
			<DeleteModal item={deleteModal} />
			<SuretyResponseModal />
		</div>
	);
}
