
import React from "react";
import { useSelector } from "react-redux";

const SavedDividend = () => {
    const {isFixNavbar: fixNavbar} = useSelector(state => state.settings);

    return (
        <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
             <div className="d-flex justify-content-between align-items-center">
                <ul className="nav nav-tabs page-header-tab">
                    <li className="nav-item">Details of saved dividends</li>
                </ul>
                <div className="header-action d-md-flex">
                    <div className="input-group mr-2">
                        <input type="text" className="form-control" placeholder="Search..." />
                    </div>
                    <button type="button" className="btn btn-primary"><i className="fe fe-search" /></button>
                </div>
            </div>
            <div className="row mt-15">
                <div className="col-md-12 col-sm-12">
                    <div className="table-responsive">
                        <table className="table table-vcenter table_custom spacing5 border-style mb-0">
                            <thead>
                                <tr>
                                    <th className="w40">S/N</th>
                                    <th>Date Computed</th>
                                    <th>From-To</th>
                                    <th>Div. AMT</th>
                                    <th>Savings(%)</th>
                                    <th>Loan Paid(%)</th>
                                    <th>Total Loas(%)</th>
                                    <th>X Variable(%)</th>
                                    <th>Y Variable(%)</th>
                                    <th>Done By</th>
                                    <th className="w40" />
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="text-center">
                                    <td colSpan={11}>No Data</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SavedDividend;