import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import * as Yup from "yup";
import { levels } from "../../../services/CONSTANTS";
import { filterOption } from "../../../utils/helpers";
import { useGetDepartmentsQuery } from "../../../services/cooperative-setup.service";
import { DatePicker, Select } from "antd";
import dayjs from "dayjs";
import { useGetCooperativeDepartmentsQuery } from "../../../services/member.service";

const FormSchema = Yup.object().shape({
    level: Yup.string().required('Please select level'),
    department_id: Yup.string().required('Please select department'),
    date_joined: Yup.string().required('Date joined field is required'),
    // official_phone: Yup.string().required(''),
});

export const WorkInfo = ({canSubmit, isChanging, onChange, coop, details, type}) => {

    const [departments, setDepartments] = useState([]);
    const {data: DepartmentsData1} = useGetDepartmentsQuery(coop?.id, {skip: type});
    const {data: DepartmentsData2} = useGetCooperativeDepartmentsQuery(coop?.code, {skip: !type});

    const {
        control,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(FormSchema),
        mode: 'onBlur'
    });

    useEffect(() => {
        if (isChanging) {
            document.getElementById('submit').click();
        }
    }, [isChanging]);

    useEffect(() => {
        if (DepartmentsData1) {
            setDepartments(DepartmentsData1?.data);
        }
        if (DepartmentsData2) {
            setDepartments(DepartmentsData2?.data);
        }
    }, [DepartmentsData1, DepartmentsData2])

    useEffect(() => {
        if (details) {
            const work = details.workInfo
            setValue('level', work.level)
            setValue('department_id', work.department_id)
            setValue('date_joined', details.date_joined)
            setValue('official_email', work.official_email)
            setValue('official_phone', work.official_phone)
        }
    }, [details]);

    const doSubmit = () => {
        canSubmit('WORK-INFO');
    }
    

    return (
        <form onSubmit={handleSubmit(doSubmit)} className="row clearfix">
            <div className="col-lg-4 col-md-4 col-sm-6">
                <div className="form-group">
                    <label>Level <span className="form-required">*</span></label>
                    <Controller
                        name="level"
                        control={control}
                        render={({ field }) => (
                            <Select 
                                showSearch
                                className="customSelect mt-1"
                                options={levels} 
                                placeholder="Select Level"
                                filterOption={filterOption}
                                value={field.value}
                                onChange={(value)=> {
                                    field.onChange(value);
                                    onChange('level', value)
                                }}
                            />
                            
                        )}
                    />
                </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6">
                <div className="form-group">
                    <label>Department / Company <span className="form-required">*</span></label>
                    <Controller
                        name="department_id"
                        control={control}
                        render={({ field }) => (
                            <Select 
                                showSearch
                                className="customSelect mt-1"
                                options={departments?.map(item => ({value: item.id, label: item.name}))} 
                                placeholder="Select Department"
                                filterOption={filterOption}
                                value={field.value}
                                onChange={(value)=> {
                                    field.onChange(value);
                                    onChange('department_id', value)
                                }}
                            />
                            
                        )}
                    />
                </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6">
                <div className="form-group">
                    <label>Date Joined  <span className="form-required">*</span></label>
                    <Controller
                        name="date_joined"
                        control={control}
                        render={({ field }) => (
                            <DatePicker
                                className={`customInput w-full`} 
                                name={'date_joined'} 
                                style={{width: '100%'}}
                                onChange={(val) => {
                                    field.onChange(val);
                                    onChange('date_joined', dayjs(val).format('YYYY-MM-DD'))
                                }}
                                // onBlur={onBlur} 
                                value={field.value ? dayjs(field.value) : undefined} 
                                />
                        )}
                    />
                    {errors.date_joined && <div className="invalid-feedback">{errors.date_joined?.message}</div>}
                </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                    <label>office Email </label>
                    <Controller
                        name="official_email"
                        control={control}
                        render={({ field }) => (
                            <input
                                className={`form-control`}
                                type="text"
                                onChange={(e) => {
                                    field.onChange(e);
                                    onChange('official_email', e.target.value)
                                }}
                                value={field.value}
                                autoComplete="true"
                            />
                        )}
                    />
                </div>
            </div>
            <div className="col-lg-6 col-md-4 col-sm-6">
                <div className="form-group">
                    <label>Office Phone</label>
                    <Controller
                        name="official_phone"
                        control={control}
                        render={({ field }) => (
                            <input
                                className={`form-control`}
                                type="text"
                                onChange={(e) => {
                                    field.onChange(e);
                                    onChange('official_phone', e.target.value)
                                }}
                                value={field.value}
                                autoComplete="true"
                            />
                        )}
                    />
                </div>
            </div>
            
            <button id="submit" type="submit" className="work-info-btn" style={{display: 'none'}}>Submit</button>
        </form>
    )
}