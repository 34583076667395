import React, { useEffect } from "react";
import { Select } from "antd";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import * as Yup from "yup";
import { filterOption } from "../../../utils/helpers";
import { useGetBanksQuery } from "../../../services/utility.service";


const FormSchema = Yup.object().shape({
    bank_id: Yup.string().required('Please select bank'),
    branch: Yup.string().required('Please select department'),
    account_type: Yup.string().required('Select account type'),
    account_no: Yup.string().required('Enter account number'),
    account_name: Yup.string().required('Enter account  name'),
});

export const BankDetails = ({canSubmit, isChanging, onChange, details}) => {

    const {data: banksData} = useGetBanksQuery();

    const {
        control,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(FormSchema),
        mode: 'onBlur'
    });

    useEffect(() => {
        if (isChanging) {
            document.getElementById('submit').click();
        }
    }, [isChanging]);

    useEffect(() => {
        if (details) {
            const bank = details.bankInfo;
            setValue('bank_id', parseInt(bank.bank_id));
            setValue('branch', bank.branch);
            setValue('account_no', bank.account_no);
            setValue('account_name', bank.account_name);
            setValue('account_type', bank.account_type);
            setValue('sort_code', bank.sort_code);
        }
    }, [details]);

    const doSubmit = () => {
        canSubmit('BANK-INFO');
    }


    return (
        <form onSubmit={handleSubmit(doSubmit)} className="row clearfix">
            <div className="col-lg-4 col-md-4 col-sm-6">
                <div className="form-group">
                    <label>Bank Name <span className="form-required">*</span></label>
                    <Controller
                        name="bank_id"
                        control={control}
                        render={({ field }) => (
                            <Select 
                                showSearch
                                className="customSelect mt-1"
                                options={banksData?.data.map(bank => ({value: bank.id, label: bank.name}))} 
                                placeholder="Select Bank"
                                filterOption={filterOption}
                                value={field.value}
                                onChange={(value)=> {
                                    field.onChange(value);
                                    onChange('bank_id', value)
                                }}
                            />
                            
                        )}
                    />
                </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6">
                <div className="form-group">
                    <label>Branch <span className="form-required">*</span></label>
                    <Controller
                        name="branch"
                        control={control}
                        render={({ field }) => (
                            <input
                                className={`form-control ${
                                errors?.branch ? "is-invalid" : ""
                                }`}
                                type="text"
                                onChange={(e) => {
                                    field.onChange(e);
                                    onChange('branch', e.target.value)
                                }}
                                value={field.value}
                                autoComplete="true"
                            />
                        )}
                    />
                    {errors.branch && <div className="invalid-feedback">{errors.branch?.message}</div>}
                </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6">
                <div className="form-group">
                    <label>Sort Code </label>
                    <Controller
                        name="sort_code"
                        control={control}
                        render={({ field }) => (
                            <input
                                className={`form-control ${errors?.sort_code ? "is-invalid" : ""}`}
                                type="text"
                                onChange={(e) => {
                                    field.onChange(e);
                                    onChange('sort_code', e.target.value)
                                }}
                                value={field.value}
                                autoComplete="true"
                            />
                        )}
                    />
                </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6">
                <div className="form-group">
                    <label>Account Type <span className="form-required">*</span></label>
                    <Controller
                        name="account_type"
                        control={control}
                        render={({ field }) => (
                            <select 
                                value={field.value} 
                                onChange={(e) => {
                                    field.onChange(e);
                                    onChange('account_type', e.target.value)
                                }}
                                className="form-control"
                            >
                                <option value="">-- Select --</option>
                                <option value="Savings">Savings</option>
                                <option value="Current">Current</option>
                            </select>
                        )}
                    />
                </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6">
                <div className="form-group">
                    <label>Account Name <span className="form-required">*</span></label>
                    <Controller
                        name="account_name"
                        control={control}
                        render={({ field }) => (
                            <input
                                className={`form-control ${
                                errors?.account_name ? "is-invalid" : ""
                                }`}
                                type="text"
                                onChange={(e) => {
                                    field.onChange(e);
                                    onChange('account_name', e.target.value)
                                }}
                                value={field.value}
                                autoComplete="true"
                            />
                        )}
                    />
                    {errors.account_name && <div className="invalid-feedback">{errors.account_name?.message}</div>}
                </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6">
                <div className="form-group">
                    <label>Account Number <span className="form-required">*</span></label>
                    <Controller
                        name="account_no"
                        control={control}
                        render={({ field }) => (
                            <input
                                className={`form-control ${
                                errors?.account_no ? "is-invalid" : ""
                                }`}
                                type="text"
                                onChange={(e) => {
                                    field.onChange(e);
                                    onChange('account_no', e.target.value)
                                }}
                                value={field.value}
                                autoComplete="true"
                            />
                        )}
                    />
                    {errors.account_no && <div className="invalid-feedback">{errors.account_no?.message}</div>}
                </div>
            </div>
            <button id="submit" type="submit" className="bank-details-btn" style={{display: 'none'}}>Submit</button>
        </form>
    )
}