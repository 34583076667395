import React, { useEffect, useState } from 'react'
import { useLazyGetAllSavingsQuery } from '../../../services/member.service';
import { DatePicker, Empty } from 'antd';
import { useSelector } from 'react-redux';
import InitialsAvatar from 'react-initials-avatar';
import { formatNumber } from '../../../utils/helpers';
import dayjs from 'dayjs';
import { Pagination } from '../../Pagination/Pagination';

const SavingsAccount = () => {
    const {isFixNavbar: fixNavbar} = useSelector(state => state.settings);
    const {activeCoop} = useSelector(state => state.coop);

    const [getResults, {data, isFetching}]= useLazyGetAllSavingsQuery();

    const [pagination, setPagination] = useState({
        total: 0,
        perPage: 0,
        currentPage: 1,
        first: 1,
        last: 1
    });

    const [filterData, setFilterData] = useState({
        coopId: activeCoop?.id,
        page: 1,
        start: '',
        end: ''
    });

    useEffect(() => {
        if (activeCoop) getResults(filterData);

    }, [activeCoop]);

    useEffect(() => {
        if (data?.status) {

            setPagination({
                total: data?.data?.meta?.total,
                perPage: data?.data?.meta?.per_page,
                currentPage: data?.data?.meta?.current_page,
                first: data.data.meta?.first_page,
                last: data.data.meta?.last_page
            })
        }
    }, [data]);

    const changeDateField = (e) => {
        const value = e.target.value;
        // let start = dayjs(), end = dayjs();
        switch (value) {
            case 'current-month':
                setFilterData({
                    ...filterData,
                    start: dayjs().startOf('M').format('YYYY-MM-DD'),
                    end: dayjs().format('YYYY-MM-DD')
                })
                break;
            case 'last-month':
                setFilterData({
                    ...filterData,
                    start: dayjs().subtract(1, 'M').startOf('M').format('YYYY-MM-DD'),
                    end: dayjs().subtract(1, 'M').endOf('M').format('YYYY-MM-DD')
                })
                break;
            case 'last-30-days':
                setFilterData({
                    ...filterData,
                    start: dayjs().subtract(30, 'days').format('YYYY-MM-DD'),
                    end: dayjs().format('YYYY-MM-DD')
                })
                break;
            case 'current-year':
                setFilterData({
                    ...filterData,
                    start: dayjs().startOf('y').format('YYYY-MM-DD'),
                    end: dayjs().format('YYYY-MM-DD')
                })
                break;
            case 'last-year':
                setFilterData({
                    ...filterData,
                    start: dayjs().subtract(1, 'y').startOf('y').format('YYYY-MM-DD'),
                    end: dayjs().subtract(1, 'y').endOf('y').format('YYYY-MM-DD')
                })
                break;
            default:
                setFilterData({
                    ...filterData,
                    start: '',
                    end: ''
                })
                break;
        }
    }

    return (
        <>
            <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-body">
                        <div className="row">
                                <div className="col-lg-3 col-md-4 col-sm-6">
                                    <div className="form-group">
                                        <select className="form-control show-tick" onChange={changeDateField}>
                                            <option value="">Filter By Date</option>
                                            <option value="current-month">Current Month</option>
                                            <option value="last-month">Last Month</option>
                                            <option value="last-30-days">Last 30 Days</option>
                                            <option value="current-year">Current Year</option>
                                            <option value="last-year">Last Year</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6">
                                    <DatePicker
                                        className={`customInput w-full`} 
                                        name={'start'} 
                                        placeholder='Start Date'
                                        style={{width: '100%'}}
                                        value={filterData.start !== '' ? dayjs(filterData.start) : undefined} 
                                        onChange={(value) => setFilterData({...filterData, start: dayjs(value).format('YYYY-MM-DD')})}
                                    />
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6">
                                    <DatePicker
                                        className={`customInput w-full`} 
                                        name={'start'} 
                                        placeholder='End Date'
                                        style={{width: '100%'}}
                                        value={filterData.end !== '' ? dayjs(filterData.end) : undefined} 
                                        onChange={(value) => setFilterData({...filterData, end: dayjs(value).format('YYYY-MM-DD')})}
                                    />
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6">
                                    <a href="#" className="btn btn-sm btn-primary btn-block" onClick={() => getResults(filterData)}>Search</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`section-body dimmer ${isFetching ? 'active' : ''}`}>
                <div className="container-fluid">
                    <div className='loader' />
                    <div className="table-responsive dimmer-content">
                        <table className="table table-vcenter table_custom spacing5 border-style mb-0">
                            <thead>
                                <tr>
                                    <th className="w40">#</th>
                                    <th>Name</th>
                                    <th>Savings Type</th>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                    <th>Ldg. Bal</th>
                                    <th>Avl. Bal</th>
                                    <th>Credit Int.</th>
                                    <th>Frequency</th>
                                    <th className="w40" />
                                </tr>
                            </thead>
                            <tbody>
                                {data?.data?.data.map(row =>
                                <tr key={row.id}>
                                    <td>
                                        <span className="avatar avatar-pink" data-toggle="tooltip" data-placement="top" data-original-title="Avatar Name">
                                            <InitialsAvatar name={`${row.user.firstname} ${row.user.lastname}`} />
                                        </span>
                                    </td>
                                    <td>{row.user.firstname} {row.user.lastname}</td>
                                    <td>{row.product.product.name}</td>
                                    <td>{row.start_date || 'N/A'}</td>
                                    <td>{row.end_date || 'N/A'}</td>
                                    <td><span>{formatNumber(row.balance)}</span></td>
                                    <td><span>{formatNumber(row.balance - row.lien)}</span></td>
                                    <td>{row.credit_interest}</td>
                                    <td>{row.frequency}</td>
                                </tr>)}
                                
                                {data?.data.meta.total === 0 && <tr><td colSpan={10}><Empty /></td></tr>}
                                
                            </tbody>
                        </table>
                    </div>
                    {pagination.last > 1 && 
                        <Pagination
                            {...pagination} 
                            changePage={(page) => {
                                getResults({
                                    coopId: activeCoop?.id,
                                    page,
                                    search: filterData.search,
                                    start: filterData.start,
                                    end: filterData.end
                                })
                                setFilterData({...filterData, page});
                            }} 
                        />
                    }
                </div>
            </div>
        </>
    )
}

export default SavingsAccount;