export const CoopMenu = [
    {
        "id": 1,
        "icon": "icon-users",
        "label": "Members",
        "to": "#!",
        content: [
            {
                "id": 2,
                "label": "List Members",
                "to": "/members"
            },
            {
                "id": 3,
                "label": "Add Member",
                "to": "/members#form"
            },
            {
                "id": 3,
                "label": "Pending Members",
                "to": "/members/pending"
            },
            {
                "id": 5,
                "label": "Import Members Data",
                "to": "/members#import"
            },
        ]
    },
    {
        "id": 'loan-savings',
        "label": "Loans & Savings"
    },
    {
        "id": 6,
        "icon": "fa fa-institution",
        "label": "Loans Manager",
        content: [
            {
                "id": 7,
                "label": "Loan Applications",
                "to": "/loans/applications"
            },
            {
                "id": 8,
                "label": "Application Forms",
                "to": "/loans/application-forms"
            },
            {
                "id": 13,
                "label": "Import Loan Data",
                "to": "/loans/import"
            },
        ]
    },
    {
        "id": 14,
        "icon": "icon-wallet",
        "label": "Savings Manager",
        "to": "#!",
        content: [
            {
                "id": 15,
                "label": "Savings",
                "to": "/savings/accounts"
            },
            {
                "id": 16,
                "label": "Change Requests",
                "to": '/savings/change-requests'
            },
            {
                "id": 17,
                "label": "Import Savings Data",
                "to": "/savings/import"
            },
        ]
    },
    {
        "id": 'finance-reporting',
        "label": "Finance & Reporting"
    },
    {
        "id": 18,
        "icon": "icon-share-alt",
        "label": "Deposits",
        "to": "/finance-reporting/deposits"
    },
    {
        "id": 19,
        "icon": "fe fe-corner-up-left",
        "label": "Withdrawals",
        "to": "/finance-reporting/withdrawals"
    },
    {
        "id": 20,
        "icon": "fe fe-file-minus",
        "label": "Monthly Dedct.",
        "to": "#!",
        content: [
            {
                "id": 21,
                "label": "Payroll Runs",
                "to": "/monthly-deductions/payroll-runs"
            },
            
        ]
    },
    {
        "id": 23,
        "icon": "fe fe-file-plus",
        "label": "Journal Entry",
        "to": "/finance-reporting/journal-entry"
    },
    {
        "id": 24,
        "icon": "fe fe-box",
        "label": "Asset Manager",
        "to": "#!",
        content: [
            {
                "id": 25,
                "label": "Asset Type",
                "to": "/finance-reporting/asset-types"
            },
            {
                "id": 26,
                "label": "Asset Setup",
                "to": "/finance-reporting/asset-setup"
            },
            // {
            //     "id": 27,
            //     "label": "Asset GL Setup",
            //     "to": "/finance-reporting/asset-gl-setup"
            // },
            
        ]
    },
    {
        "id": 28,
        "icon": "icon-calculator",
        "label": "Dividend Calaculator",
        "to": "#!",
        content: [
            {
                "id": 29,
                "label": "Compute Dividend",
                "to": "/dividend-calculator/compute"
            },
            {
                "id": 30,
                "label": "View saved dividend",
                "to": "/dividend-calculator/saved"
            },
            {
                "id": 31,
                "label": "Dividend GL Setup",
                "to": "/dividend-calculator/gl-setup"
            },
            
        ]
    },
    {
        "id": 57,
        "icon": "fe fe-file-text",
        "label": "Reports",
        "to": "/reports/trial-balance",
    },
    {
        "id": 'coop-setup',
        "label": "Cooperative Setup"
    },
    {
        "id": 53,
        "icon": "icon-rocket",
        "label": "Product Setup",
        "to": "#!",
        content: [
            {
                "id": 54,
                "label": "Category/Products",
                "to": "/setup/products"
            },
            {
                "id": 55,
                "label": "Savings",
                "to": "/setup/savings"
            },
            {
                "id": 56,
                "label": "Loans",
                "to": "/setup/loans"
            },
            // {
            //     "id": 57,
            //     "label": "Schemes",
            //     "to": "#"
            // },
        ]
    },
    {
        "id": 58,
        "icon": "fe fe-pocket",
        "label": "Charge Setup",
        "to": "/setup/charges",
    },
    {
        "id": 59,
        "icon": "icon-layers",
        "label": "Chart of Accounts",
        "to": "/setup/chart-of-accounts",
    },
    {
        "id": 60,
        "icon": "fe fe-bold",
        "label": "Bank Acct. Setup",
        "to": "/setup/bank-accounts",
    },
    {
        "id": 61,
        "icon": "fa fa-users",
        "label": "Dept. Setup",
        "to": "/setup/departments",
    },
    // {
    //     "id": 62,
    //     "icon": "icon-star",
    //     "label": "Subscription Setup",
    //     "to": "/setup/subscriptions",
    // },
    {
        "id": 63,
        "icon": "icon-present",
        "label": "Birthday Templates",
        "to": "/setup/birthday-cards",
        // children: [
        //     {
        //         "id": 64,
        //         "label": "History",
        //         "to": "#"
        //     },
        //     {
        //         "id": 65,
        //         "label": "Seed Products",
        //         "to": "#"
        //     },
        //     {
        //         "id": 66,
        //         "label": "Loan Repayment Type",
        //         "to": "#"
        //     },
        //     {
        //         "id": 67,
        //         "label": "Update Staff ID",
        //         "to": "#"
        //     }
        // ]
    }
];

export const AdminMenu = [
    {
        "id": 'admin-coop',
        "label": "Cooperative Setup"
    },
    {
        "id": 101,
        "icon": "fa fa-bank",
        "label": "Cooperatives",
        "to": "/setup/cooperatives",
    },
    {
        "id": 102,
        "icon": "icon-rocket",
        "label": "Product Setup",
        "to": "#!",
        content: [
            {
                "id": 103,
                "label": "Category/Products",
                "to": "/setup/products"
            },
            {
                "id": 104,
                "label": "Savings",
                "to": "/setup/savings"
            },
            {
                "id": 105,
                "label": "Loans",
                "to": "/setup/loans"
            },
            {
                "id": 106,
                "label": "Schemes",
                "to": "#"
            },
        ]
    },
    {
        "id": 77,
        "icon": "fe fe-pocket",
        "label": "Charge Setup",
        "to": "/setup/charges",
    },
    {
        "id": 107,
        "icon": "icon-layers",
        "label": "Chart of Accounts",
        "to": "/setup/chart-of-accounts",
    },
    {
        "id": 'system-setup',
        "label": "System Setup"
    },
    {
        "id": 108,
        "icon": "fe fe-users",
        "label": "System Users",
        "to": "/setup/users",
    },
    {
        "id": 109,
        "icon": "icon-shield",
        "label": "Access Control",
        "to": "/setup/access-control",
    },
    {
        "id": 110,
        "icon": "fa fa-group",
        "label": "Staff Categories",
        "to": "/setup/staff-categories",
    },
    {
        "id": 111,
        "icon": "fe fe-shopping-bag",
        "label": "E-Store",
        "to": "/e-shop/dashboard",
    },
    {
        "id": 'reports',
        "label": "Reports"
    },
    {
        "id": 112,
        "icon": "fe fe-file-text",
        "label": "Consolidated Report",
        "to": "/reports/consolidated-report",
    },
    // {
    //     "id": 'admin-header',
    //     "label": "Utilities"
    // },
];


export const MemberMenu = [
    {
        "id": 'loan-savings',
        "label": "Loans & Savings"
    },
    {
        "id": 112,
        "icon": "fa fa-institution",
        "label": "Loan Applications",
        content: [
            {
                "id": 113,
                "label": "Loans",
                "to": "/member/loans"
            },
            {
                "id": 123,
                "label": "Application Form",
                "to": "/member/loans/apply"
            },
            {
                "id": 124,
                "label": "Upload Form",
                "to": "/member/loans/upload"
            },
            {
                "id": 114,
                "label": "Surety Requests",
                "to": "/member/loans/surety"
            },
        ]
    },
    {
        "id": 115,
        "icon": "icon-wallet",
        "label": "Savings",
        "to": "#!",
        content: [
            {
                "id": 116,
                "label": "View Savings",
                "to": "/member/savings"
            },
            {
                "id": 117,
                "label": "View Requests",
                "to": "/member/savings/requests"
            },
        ]
    },
    {
        id: "reports",
        label: "Reports"
    },
    {
        "id": 118,
        "icon": "fe fe-file-text",
        "label": "Transaction History",
        "to": "/member/transaction-history",
    },
    {
        "id": 119,
        "icon": "fe fe-file-text",
        "label": "Loan Offset History",
        "to": "/member/loan-offset-history",
    },
    {
        "id": 'my-profile',
        "label": "My Profile"
    },
    {
        "id": 120,
        "icon": "icon-user",
        "label": "View Profile",
        "to": "/member/my-profile",
    },
    {
        "id": 121,
        "icon": "fe fe-edit",
        "label": "Edit Profile",
        "to": "/member/edit-profile",
    },
    {
        "id": 122,
        "icon": "fe fe-lock",
        "label": "Change Password",
        "to": "/member/change-password",
    },
]

export const EShopMenu = [
    {
        "id": 123,
        "icon": "fe fe-grid",
        "label": "Dashboard",
        "to": "/e-shop/dashboard",
    },
    {
        "id": 'product-stock',
        "label": "Product & Stock"
    },
    {
        "id": 124,
        "icon": "fe fe-box",
        "label": "Products",
        "to": "/e-shop/products",
    },
    {
        "id": 125,
        "icon": "icon-social-dropbox",
        "label": "Damages",
        "to": "/e-shop/damages",
    },
    {
        "id": 126,
        "icon": "fe fe-layers",
        "label": "Stock",
        "to": "/e-shop/stock",
    },
    {
        "id": 'eshop-orders',
        "label": "Orders"
    },
    {
        "id": 127,
        "icon": "fe fe-package",
        "label": "Online Orders",
        "to": "/e-shop/orders",
    },
    {
        "id": 128,
        "icon": "fe fe-truck",
        "label": "Return Orders",
        "to": "/e-shop/orders/returns",
    },
    {
        "id": 129,
        "icon": "fe fe-repeat",
        "label": "Return and Refunds",
        "to": "/e-shop/orders/refunds",
    },
    {
        "id": 'eshop-reports',
        "label": "Reports"
    },
    {
        "id": 130,
        "icon": "fa fa-line-chart",
        "label": "Sales Reports",
        "to": "/e-shop/reports/sales",
    },
    {
        "id": 131,
        "icon": "icon-bar-chart",
        "label": "Products Report",
        "to": "/e-shop/reports/products",
    },
    {
        "id": 132,
        "icon": "fe fe-file-text",
        "label": "Transactions",
        "to": "/e-shop/reports/transactions",
    },
    {
        "id": 133,
        "icon": "fe fe-users",
        "label": "Customers",
        "to": "/e-shop/reports/customers",
    },

]

export const ReportsMenu = [
    {
        "id": 'reports',
        "label": "Reports"
    },
    {
        "id": 68,
        "icon": "fe fe-file-text",
        "label": "Trial Balance",
        "to": "/reports/trial-balance",
    },
    {
        "id": 69,
        "icon": "fe fe-file-text",
        "label": "Income Statement",
        "to": "/reports/income-statement",
    },
    {
        "id": 70,
        "icon": "fe fe-file-text",
        "label": "Stmt. of Fin. Position",
        "to": "/reports/financial-position",
    },
    {
        "id": 71,
        "icon": "fe fe-file-text",
        "label": "Mem. Account Balance",
        "to": "/reports/members-balance",
    },
    {
        "id": 72,
        "icon": "fe fe-file-text",
        "label": "Ledger Statement",
        "to": "/reports/ledger-statement",
    },
    {
        "id": 73,
        "icon": "fe fe-file-text",
        "label": "Investment Trackers",
        "to": "/reports/investment-trackers",
    },
    {
        "id": 74,
        "icon": "fe fe-file-text",
        "label": "Dividend Calculator",
        "to": "/reports/dividend-calculator",
    },
    {
        "id": 75,
        "icon": "fe fe-file-text",
        "label": "Coop Analysis Report",
        "to": "/reports/analysis-report",
    },
    {
        "id": 76,
        "icon": "fe fe-arrow-left",
        "label": "Go Back",
        "to": "#",
    },


]