import React from "react";

export const Pagination = ({perPage, total, currentPage, last, changePage}) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(total / perPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <nav aria-label="Page navigation">
            <ul className="pagination mt-2 mb-0 justify-content-end">
                {currentPage > 1 && 
                <li className="page-item">
                    <a className="page-link" href="#" onClick={() => changePage(currentPage - 1)}>
                        Previous
                    </a>
                </li>}
                {pageNumbers.map(pgNumber => (
                <li className={`page-item ${currentPage === pgNumber ? 'active' : ''}`} key={pgNumber}>
                    <a className="page-link" href="#" onClick={() => changePage(pgNumber)}>
                        {pgNumber}
                    </a>
                </li>
                ))}
                {currentPage < last &&
                    <li className="page-item">
                        <a className="page-link" href="#" onClick={() => changePage(currentPage + 1)}>
                        Next
                        </a>
                    </li>
                }
            </ul>
        </nav>
    )
}