
import React from "react";
import Card from "../../common/Card";
import { useGetAllAssetTypesQuery } from "../../../services/cooperative-setup.service";
import { Empty, Tooltip } from "antd";
import { useDispatch } from "react-redux";
import { toggleDelete } from "../../../redux/slices/ui.slice";
import { DELETE_ASSET_TYPE } from "../../../services/CONSTANTS";

const AssetGlList = ({activeCoop, onEdit}) => {
    const {data, isLoading} = useGetAllAssetTypesQuery(activeCoop?.id, {skip: !activeCoop});
    const dispatch = useDispatch();

    const doDelete = (e, item) => {
        // console.log(e.currentTarget)
        dispatch(
          toggleDelete({
            open: true,
            title: 'Asset Type',
            url: `${DELETE_ASSET_TYPE}?cooperative_id=${activeCoop.id}&id=${item.id}`,
            // ele: JSON.stringify(e.currentTarget),
            parent: 'tr'
          })
        );
    }

    return (
        <Card title="Asset GLs" loading={isLoading}>
            <div className="card-body dimmer-content">
                <div className="table-responsive">
                    <table className="table table-striped table-vcenter table-hover mb-0">
                        <thead>
                            <tr>
                                <th>Asset Type</th>
                                <th>Fixed Asset</th>
                                <th>Dep. Expense</th>
                                <th>Acc. Depreciation</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.data.map(row => 
                                <tr key={row.id}>
                                    <td>{row.name}</td>
                                    <td>{row.fixed_asset.name}</td>
                                    <td>{row.dep_expense.name}</td>
                                    <td>{row.accumulated_dep.name}</td>
                                    <td>
                                        <Tooltip title="Edit">
                                            <button type="button" className="btn btn-icon btn-sm btn-sm" onClick={() => onEdit(row)}>
                                                <i className="icon-pencil text-info" />
                                            </button>
                                        </Tooltip>
                                        <Tooltip title="Delete">
                                            <button type="button" className="btn btn-icon btn-sm" data-target="#deleteModal" data-toggle="modal" onClick={(e) => doDelete(e, row)}>
                                                <i className="icon-trash text-danger" />
                                            </button>
                                        </Tooltip>
                                    </td>
                                </tr>
                            )}
                            {data?.data.length === 0 && <tr><td colSpan={6}><Empty /></td></tr>}
                            
                        </tbody>
                    </table>
                </div>
            </div>
        </Card>
    )
}

export default AssetGlList;