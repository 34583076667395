import React, { useEffect, useState } from "react";
import Steps from 'rsuite/Steps';
import Panel from 'rsuite/Panel';
import Button from 'rsuite/Button';

import 'rsuite/Steps/styles/index.css';
import { StaffInfo } from "./Forms/StaffInfo";
import { WorkInfo } from "./Forms/WorkInfo";
import { BankDetails } from "./Forms/BankDetails";
import { NokDetails } from "./Forms/NoKDetails";
import { EmergencyContact } from "./Forms/EmergencyContact";
import alertify from "alertifyjs";
import Card from "../common/Card";
import { useSaveMemberMutation, useSavePendingMemberMutation } from "../../services/member.service";

export const MemberForm = ({activeCoop, type, onSuccess}) => {
    const [step, setStep] = React.useState(0);
    const [isChanging, setIsChanging] = React.useState(false);
    const [saveMember, {isLoading, isSuccess, isError, data, error}] = useSaveMemberMutation();
    const [savePending, {
        isLoading: isPendingLoading, 
        isSuccess: isPendingSuccess, 
        isError: isPendingError, 
        data: pendingData, 
        error: pendingError
    }] = useSavePendingMemberMutation();

    const onChange = nextStep => {
        setStep(nextStep < 0 ? 0 : nextStep > 4 ? 4 : nextStep);
        setIsChanging(false);
    };

    const onNext = () => onChange(step + 1);
    const onPrevious = () => onChange(step - 1);

    const [formData, setFormData] = useState(
        {
            cooperative_id: activeCoop?.id,
            email: "",
            firstname: "",
            middlename: "",
            lastname: "",
            staff_id: "",
            date_joined: "",
            marital_status:  "",
            gender:  "",
            dob: "",
            religion: "",
            current_address:  "",
            role_id: "",
            savings_amount: "",
            country_id: "",
            state_id: "",
            level: "",
            department_id: "",
            official_email: "",
            official_phone: "",
            bank_id: "",
            branch: "",
            sort_code: "",
            account_type: "",
            account_name: "",
            account_no:  "",
            nok_name: "",
            nok_address: "",
            nok_phone:  "",
            nok_email: "",
            contact_name: "",
            contact_address:  "",
            contact_phone: "",
            contact_email: ""
        }
    )

    const doSubmit = () => {
        if (type) {
            savePending(formData);
        } else {
            saveMember(formData)
        }
    }
    
    useEffect(() => {
        if(isSuccess && data?.status) {
            // resetForm();
            alertify.success(data?.message);
        }

        if (isPendingSuccess) {
            // alertify.success(data?.message || pendingData?.data.message);
            onSuccess();
        }

        if (isError || isPendingError) {
            //showToast()
            alertify.error(error?.data.message || pendingError.data.message);
        }
    }, [isSuccess, isPendingSuccess, isError, isPendingError, data, pendingData]);

    return (
        <Card loading={isLoading || isPendingLoading}>
            <div className="card-body">
                <Steps current={step}>
                    <Steps.Item onClick={() => setStep(0)} title="Staff Info" />
                    <Steps.Item onClick={() => setStep(0)} title="Work" />
                    <Steps.Item onClick={() => setStep(0)} title="Bank" />
                    <Steps.Item onClick={() => setStep(0)} title="Next of Kin" />
                    <Steps.Item onClick={() => setStep(0)} title="Emergency" />
                    {/* <Steps.Item title="Documents" /> */}
                </Steps>
                <hr />
                <Panel>
                    {
                        {
                            0: <StaffInfo onChange={(field, value) => setFormData({...formData, [field]: value})} isChanging={isChanging} canSubmit={onNext} formData={formData} type={type} coopCode={activeCoop?.code}  />,
                            1: <WorkInfo coop={activeCoop} onChange={(field, value) => setFormData({...formData, [field]: value})} isChanging={isChanging} formData={formData} canSubmit={onNext} type={type} />,
                            2: <BankDetails onChange={(field, value) => setFormData({...formData, [field]: value})} isChanging={isChanging} canSubmit={onNext} formData={formData}  />,
                            3: <NokDetails onChange={(field, value) => setFormData({...formData, [field]: value})} isChanging={isChanging} canSubmit={onNext} formData={formData}  />,
                            4: <EmergencyContact onChange={(field, value) => setFormData({...formData, [field]: value})} isChanging={isChanging} canSubmit={doSubmit} formData={formData} />,
                            5: ''
                        }[step]
                    }
                </Panel>
            </div>
            <div className="card-footer d-flex justify-content-between text-right">
                <Button className="btn btn-secondary" onClick={onPrevious} disabled={step === 0}>
                    Previous
                </Button>
                {step < 4 ? 
                <Button className="btn btn-primary" onClick={() => setIsChanging(true)}>
                    Next
                </Button>
                : 
                <Button className="btn btn-primary" onClick={doSubmit}>
                    Submit
                </Button>
                }
            </div>
        </Card>
    )
}