import React from "react"
import { Loans } from "../Members/MemberDetails/Loans"
import { useSelector } from "react-redux";
import { MemberLoanList } from "../Members/MemberDetails/MemberLoanList";
import Card from "../common/Card";

export const MemberLoans = () => {
    const {isFixNavbar: fixNavbar} = useSelector(state => state.settings);
    const {user} = useSelector(state => state.user);
    const {activeCoop} = useSelector(state => state.coop);

    return (
        <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
            <Card title="Loan History">
                <MemberLoanList coop={activeCoop} member={user}  />
            </Card>
        </div>
    )
}