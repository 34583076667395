import React from "react";

import { useDispatch, useSelector } from "react-redux";
import alertify from "alertifyjs";
import { setActiveCoop } from "../../redux/slices/cooperative.slice";
import { updateRole } from "../../redux/slices/user.slice";
import { changeColors, resetColors } from "../../redux/slices/ui.slice";
import { useHistory } from 'react-router-dom';

export const UserSideBar = ({isOpenUserMenu, toggleUserMenu}) => {
    const {cooperatives} = useSelector(state => state.coop);
    const {user} = useSelector(state => state.user);
    const navigate = useHistory()
    const dispatch = useDispatch();
	
    function changeActiveCoop(e, role) {
		dispatch(setActiveCoop(e));
		dispatch(updateRole(role));

        if (e && e.colors) {
            const colors = JSON.parse(e.colors);
		    dispatch(changeColors(JSON.parse(colors)));
        } else {
            dispatch(resetColors());
        }


        navigate.push('/dashboard');

        alertify.success(`You are now viewing data for ${e?.name || 'Admin'}.`);
        
        document.getElementById('close-btn').click();
	}

    return (
        <div className={`user_div ${isOpenUserMenu && 'open'}`}>
            <h5 className="brand-name mb-4">
                SELECT COOP
                <p className="user_btn" id="close-btn" onClick={toggleUserMenu}>
                    <i className="icon-close" />
                </p>
            </h5>
            {/* <div className="card">
                <div className="card-body">
                    <div className="media">
                        <img
                            className="avatar avatar-xl mr-3"
                            src="/assets/images/user.png"
                            alt="avatar"
                        />
                        <div className="media-body">
                            <h5 className="m-0">Sara Hopkins</h5>
                            <p className="text-muted mb-0">Webdeveloper</p>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="card">
                <ul className="list-group">
                    <li className="list-group-item d-flex" onClick={() => changeActiveCoop(null, user?.role_name)}>
                        <div className="box-icon sm">
                            <img src="../assets/images/calicon-icon.png" className="avatar avatar-orange" />
                        </div>
                        <div className="ml-3">
                            <a href="#!">Back to Admin</a>
                            <div>info@calicon.com</div>
                            <div className="text-muted font-12">Lagos</div>
                        </div>
                    </li>
                    {cooperatives.map((coop, i) => (
                        <li className="list-group-item d-flex" key={`coop-${i}`} onClick={() => changeActiveCoop(coop, 'cooperative-admin')}>
                            <div className="box-icon sm">
                                {coop.logo ?
                                    <img src={coop.logo} className="avatar avatar-orange" />
                                :
                                    <img src="../assets/images/calicon-icon.png" className="avatar avatar-orange" />
                                }
                            </div>
                            <div className="ml-3">
                                <a href="#!">{coop.name}</a>
                                <div>{coop.email}</div>
                                <div className="text-muted font-12">{coop.location}</div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}