import React from "react"
import { useSelector } from "react-redux";
import { useGetOffsetHistoryQuery } from "../../../services/member.service";
import { Empty } from "antd";
import { formatNumber } from "../../../utils/helpers";
import dayjs from "dayjs";
var advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)

export const LoanOffsetHistory = ({coop_id, cooperative_id}) => {
    const {isFixNavbar: fixNavbar} = useSelector(state => state.settings);

    const {data, isLoading, error, isSuccess, isError} = useGetOffsetHistoryQuery({
        memberId: coop_id,
        coopId: cooperative_id
    });

    return (
            
        <div className={`table-responsive dimmer ${isLoading ? 'active' : ''}`}>
            <table className="table table-vcenter table_custom spacing5 border-style mb-0">
                <thead>
                    <tr>
                        <th>PRODUCT</th>
                        <th>LOAN AMT.</th>
                        <th>OFFSET AMT.</th>
                        <th>NEW LOAN AMT.</th>
                        <th>OFFSET TYPE</th>
                        <th>DATE</th>
                    </tr>
                </thead>
                <tbody className="dimmer-content">
                    {isLoading ? 
                        <tr>
                            <td colSpan={6}>
                                <div className="loader" />
                            </td>
                        </tr>
                    :
                    <>
                        {data?.data.length === 0 && <tr><td colSpan={6}><Empty /></td></tr>}
                        {data?.data.map(row => 
                            <tr key={`offset-${row.id}`}>
                                <td>{row.loan.product.name}</td>
                                <td><span>{formatNumber(row.initial_loan_amount)}</span></td>
                                <td><span>{formatNumber(row.offset_amount)}</span></td>
                                <td><span>{formatNumber(row.new_loan_amount)}</span></td>
                                <td>{row.type.name}	</td>
                                <td>
                                {dayjs(row.created_at).format('Do MMM YYYY, hh:mma')}    
                                </td>
                            </tr>
                        )}
                    </>
                    }
                </tbody>
            </table>
        </div>
                   
    )
}