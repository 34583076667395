import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import * as Yup from "yup";
// import { useDispatch } from "react-redux";
import alertify from "alertifyjs";
import { useGetAllCoaQuery, useGetChargesQuery, useGetProductCategoriesQuery, useGetProductsByCatQuery, useSaveSavingsProductMutation, useUpdateSavingsMutation } from "../../../../services/cooperative-setup.service";
import { filterOption } from "../../../../utils/helpers";
import { DatePicker, Select } from "antd";
import dayjs from "dayjs";

const FormSchema = Yup.object().shape({
    product_id: Yup.string().required('Please select product type'),
    opening_balance: Yup.string().required('This field is required'),
    closing_balance: Yup.string().required('This field is required'),
    credit_interest: Yup.string().required('This field is required'),
    start_date: Yup.string().required('This field is required'),
    end_date: Yup.string().required('This field is required'),
    charge_id: Yup.string().required('This field is required'),
    charge_amount: Yup.string().required('This field is required'),
    principal_gl: Yup.string().required('Select Principal GL'),
    member_balance_gl: Yup.string().required('Select member balance GL'),
    interest_expense_gl: Yup.string().required('Select GL account'),
    interest_payable_gl: Yup.string().required('Select GL account'),
    fixed_dep_gl: Yup.string().required('Select GL account'),
    status: Yup.string().required('Select status'),
});

const SavingForm = ({item, onCancel, coop}) => {
    const [catId, setCatID] = useState(null);
    const {data: postingGlData} = useGetAllCoaQuery(coop?.id);
    const {data: categories} = useGetProductCategoriesQuery(coop?.id);
    const {data: productsData} = useGetProductsByCatQuery({id: catId, coopId: coop?.id}, {skip: !catId});
    const {data: chargeData} = useGetChargesQuery();

    const [glAccounts, setGlAccounts] = useState([]);
    const [products, setProducts] = useState([]);
    const [charges, setCharges] = useState([]);

    const [save, {isLoading, isSuccess, isError, data, error}] = useSaveSavingsProductMutation();
    const [update, {
        isLoading: isUpdateLoading, 
        isSuccess: isUpdateSuccess, 
        isError: isUpdateError, 
        data: updateData, 
        error: updateError
    }] = useUpdateSavingsMutation();

    const {
        control,
        reset,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(FormSchema),
        mode: 'onBlur'
    });

    useEffect(() => {   
        if (categories) {
            const savings = categories.data.find(cat => cat.name === 'Savings');

            if (savings) setCatID(savings.id);
        }

    }, [categories]);

    useEffect(() => {
        if (postingGlData && postingGlData.status) {
          setGlAccounts(postingGlData.data.map(item => ({value: item.id, label: `[${item.code}] ${item.name}`})));
        }
    }, [postingGlData]);

    useEffect(() => {
        if (productsData && productsData.status) {
          setProducts(productsData.data.map(item => ({value: item.id, label: item.name})));
        }
    }, [productsData]);

    useEffect(() => {
        if (chargeData && chargeData.status) {
          setCharges(chargeData.data.map(item => ({value: item.id, label: item.name})));
        }
    }, [chargeData]);

    useEffect(() => {
        if (item) {
            resetForm(item);
        }
    }, [item]);


    const onSubmit = (values) => {
        const payload = {...values};
        payload.start_date = dayjs(values.start_date).format('YYYY-MM-DD')
        payload.end_date = dayjs(values.end_date).format('YYYY-MM-DD');
        
        if (coop) payload.cooperative_id = coop?.id;

        if (item) {
            update(payload);
        } else {
            save(payload);
        }
    }

    useEffect(() => {
        if(isSuccess || isUpdateSuccess) {
            resetForm();
            // show toast.
            alertify.success(data?.message || updateData?.message)
        }

        if (isError || isUpdateError) {
            alertify.error(error?.data?.message || updateError?.data?.message);
        }
    }, [isSuccess, isUpdateSuccess, isUpdateError, isError, data, updateData])

    const resetForm = (item = null) => {
        reset({
            product_id: item?.product_id || "",
            opening_balance: item?.opening_balance || "",
            closing_balance: item?.closing_balance || "",
            start_date: item?.start_date || "",
            end_date: item?.end_date || "",
            credit_interest: item?.credit_interest || "",
            charge_id: item?.charge_id || "",
            charge_amount: item?.charge_amount || "",
            principal_gl: item?.principal_gl || "",
            member_balance_gl: item?.member_balance_gl || "",
            interest_expense_gl: item?.interest_expense_gl || "",
            interest_payable_gl: item?.interest_payable_gl || "",
            fixed_dep_gl: item?.fixed_dep_gl || "",
            status: item?.status || "",
            id: item?.id || ""
        })
    }

    const doCancel = () => {
        resetForm();
        onCancel();
    }

    return (
        <div className={`card dimmer ${(isLoading || isUpdateLoading) ? 'active' : ''}`} >
            <div className="loader" />
            <div className="card-header">
                <h3 className="card-title">Add/Edit Setup</h3>
            </div>
            <form className="card-body dimmer-content" onSubmit={handleSubmit(onSubmit)}>
                <div className="row clearfix">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                            <label>Savings Type </label>
                            <Controller
                                name="product_id"
                                control={control}
                                render={({ field }) => (
                                    <Select 
                                        showSearch
                                        className="customSelect mt-1"
                                        options={products} 
                                        placeholder="Select Product Type"
                                        filterOption={filterOption}
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                    
                                )}
                            />
                            {errors.product_id && <div className="invalid-feedback">{errors.product_id?.message}</div>}
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                            <label>Credit Interest (%) </label>
                            <Controller
                                name="credit_interest"
                                control={control}
                                render={({ field }) => (
                                    <input
                                        className={`form-control ${
                                        errors?.credit_interest ? "is-invalid" : ""
                                        }`}
                                        type="text"
                                        onChange={field.onChange}
                                        value={field.value}
                                        autoComplete="true"
                                    />
                                )}
                            />
                            {errors.credit_interest && <div className="invalid-feedback">{errors.credit_interest?.message}</div>}
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                            <label>Opening Balance </label>
                            <Controller
                                name="opening_balance"
                                control={control}
                                render={({ field }) => (
                                    <input
                                        className={`form-control ${
                                        errors?.opening_balance ? "is-invalid" : ""
                                        }`}
                                        type="text"
                                        onChange={field.onChange}
                                        value={field.value}
                                        autoComplete="true"
                                    />
                                )}
                            />
                            {errors.opening_balance && <div className="invalid-feedback">{errors.opening_balance?.message}</div>}
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                            <label>Closing Balance </label>
                            <Controller
                                name="closing_balance"
                                control={control}
                                render={({ field }) => (
                                    <input
                                        className={`form-control ${
                                        errors?.closing_balance ? "is-invalid" : ""
                                        }`}
                                        type="text"
                                        onChange={field.onChange}
                                        value={field.value}
                                        autoComplete="true"
                                    />
                                )}
                            />
                            {errors.closing_balance && <div className="invalid-feedback">{errors.closing_balance?.message}</div>}
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                            <label>Start Date </label>
                            <Controller
                                name="start_date"
                                control={control}
                                render={({ field }) => (
                                    <DatePicker
                                        className={`customInput w-full`} 
                                        name={'start_date'} 
                                        style={{width: '100%'}}
                                        onChange={field.onChange} 
                                        // onBlur={onBlur} 
                                        value={field.value ? dayjs(field.value) : undefined} 
                                    />
                                )}
                            />
                            {errors.start_date && <div className="invalid-feedback">{errors.start_date?.message}</div>}
                            
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                            <label>End Date </label>
                            <Controller
                                name="end_date"
                                control={control}
                                render={({ field }) => (
                                    <DatePicker
                                        className={`customInput w-full`} 
                                        name={'end_date'} 
                                        style={{width: '100%'}}
                                        onChange={field.onChange} 
                                        // onBlur={onBlur} 
                                        value={field.value ? dayjs(field.value) : undefined} 
                                    />
                                )}
                            />
                            {errors.end_date && <div className="invalid-feedback">{errors.end_date?.message}</div>}
                            {/* <div className="input-icon mb-3">
                                <input type="text" className="form-control" placeholder="Search for..." />
                                <span className="input-icon-addon"><i className="fa fa-calendar"></i></span>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                            <label>Charge Type </label>
                            <Controller
                                name="charge_id"
                                control={control}
                                render={({ field }) => (
                                    <Select 
                                        showSearch
                                        className="customSelect mt-1"
                                        options={charges} 
                                        placeholder="Select Charge type"
                                        filterOption={filterOption}
                                        value={field.value}
                                        onChange={(value)=> {
                                            field.onChange(value);
                                            const charge = chargeData.data.find(c => c.id === value);
                                            if (charge)
                                                setValue('charge_amount', charge.amount);
                                        }}
                                    />
                                    
                                )}
                            />
                            {errors.charge_id && <div className="invalid-feedback">{errors.charge_id?.message}</div>}
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                            <label>Charge Amount </label>
                            <Controller
                                name="charge_amount"
                                control={control}
                                render={({ field }) => (
                                    <input
                                        readOnly
                                        className={`form-control ${errors?.charge_amount ? "is-invalid" : ""}`}
                                        type="text"
                                        value={field.value}
                                        autoComplete="true"
                                    />
                                )}
                            />
                            {errors.charge_amount && <div className="invalid-feedback">{errors.charge_amount?.message}</div>}
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                            <label>Principal GL </label>
                            <Controller
                                name="principal_gl"
                                control={control}
                                render={({ field }) => (
                                    <Select 
                                        showSearch
                                        className="customSelect mt-1"
                                        options={glAccounts} 
                                        placeholder="Select Principal GL"
                                        filterOption={filterOption}
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                    
                                )}
                            />
                            {errors.principal_gl && <div className="invalid-feedback">{errors.principal_gl?.message}</div>}
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                            <label>Member Balance GL </label>
                            <Controller
                                name="member_balance_gl"
                                control={control}
                                render={({ field }) => (
                                    <Select 
                                        showSearch
                                        className="customSelect mt-1"
                                        options={glAccounts} 
                                        placeholder="Select Gl Account"
                                        filterOption={filterOption}
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                    
                                )}
                            />
                            {errors.member_balance_gl && <div className="invalid-feedback">{errors.member_balance_gl?.message}</div>}
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                            <label>Interest Expense GL </label>
                            <Controller
                                name="interest_expense_gl"
                                control={control}
                                render={({ field }) => (
                                    <Select 
                                        showSearch
                                        className="customSelect mt-1"
                                        options={glAccounts} 
                                        placeholder="Select Gl Account"
                                        filterOption={filterOption}
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                    
                                )}
                            />
                            {errors.interest_expense_gl && <div className="invalid-feedback">{errors.interest_expense_gl?.message}</div>}
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                            <label>Interest Payable GL </label>
                            <Controller
                                name="interest_payable_gl"
                                control={control}
                                render={({ field }) => (
                                    <Select 
                                        showSearch
                                        className="customSelect mt-1"
                                        options={glAccounts} 
                                        placeholder="Select Gl Account"
                                        filterOption={filterOption}
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                    
                                )}
                            />
                            {errors.interest_payable_gl && <div className="invalid-feedback">{errors.interest_payable_gl?.message}</div>}
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                            <label>Fixed Dep. GL </label>
                            <Controller
                                name="fixed_dep_gl"
                                control={control}
                                render={({ field }) => (
                                    <Select 
                                        showSearch
                                        className="customSelect mt-1"
                                        options={glAccounts} 
                                        placeholder="Select Gl Account"
                                        filterOption={filterOption}
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                    
                                )}
                            />
                            {errors.fixed_dep_gl && <div className="invalid-feedback">{errors.fixed_dep_gl?.message}</div>}
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                            <label>Status </label>
                            <Controller
                                name="status"
                                control={control}
                                render={({ field }) => (
                                    <select 
                                        onChange={field.onChange}
                                        value={field.value}
                                        className={`form-control ${errors?.status ? "is-invalid" : ""}`}
                                    >
                                        <option value=''>Select Status</option>
                                        <option value='1'>Active</option>
                                        <option value='0'>Inactive</option>
                                    </select>
                                )}
                            />
                            {errors.status && <div className="invalid-feedback">{errors.status?.message}</div>}
                        </div>
                    </div>
                
                </div>
                <div className="d-flex justify-content-between">
                    <button type="button" className="btn btn-round btn-default" onClick={doCancel}>
                        Cancel
                    </button>{' '}&nbsp;&nbsp;
                    <button type="submit" className="btn btn-round btn-primary">
                        Submit
                    </button>
                </div>
                
            </form>
        </div>
    )
}

export default SavingForm;