import apiSlice from "./api/api";
import { CAST_VOTE, CONTESTANTS, ELECTIONS, GET_CONTESTANT, GET_CONTESTANT_BY_POSITION, GET_ELECTION, GET_POSITION, GET_POSITIONS_BY_ELECTION, POSITIONS, RESULT_BY_ELECTION, RESULT_BY_POSITION, UPDATE_CONTESTANT, UPDATE_ELECTION, UPDATE_POSITION } from "./CONSTANTS";

const electionApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Get Elections
    getElections: builder.query({
      query: ({coopId, page}) => ({
        url: `${ELECTIONS}?cooperative_id=${coopId}&page=${page}`,
        method: "GET",
      }),
      providesTags: ["Voting"],
    }),
    // Get Single Elections
    getElection: builder.query({
      query: ({coopId, electionId}) => ({
        url: `${GET_ELECTION}?cooperative_id=${coopId}&election_id=${electionId}`,
        method: "GET",
      }),
      providesTags: ["Voting"],
    }),
    // Save Election
    saveElection: builder.mutation({
      query: (data) => ({
        url: !data?.election_id ? ELECTIONS : UPDATE_ELECTION,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["Voting"],
    }),
    // GEt All Positions
    getPositions: builder.query({
      query: ({coopId, page}) => ({
        url: `${GET_POSITION}?cooperative_id=${coopId}&page=${page}`,
        method: "GET",
      }),
      providesTags: ["Voting"]
    }),
    // GET Positions By Election
    getPositionsByElection: builder.query({
      query: ({coopId, electionId}) => ({
        url: `${GET_POSITIONS_BY_ELECTION}?cooperative_id=${coopId}&election_id=${electionId}`,
        method: "GET",
      }),
      providesTags: ["Voting"]
    }),
    // Save Position
    savePosition: builder.mutation({
      query: (data) => ({
        url: !data?.position_id ? POSITIONS : UPDATE_POSITION,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["Voting"],
    }),
    // DELETE POSITION
    deletePosition: builder.mutation({
      query: (data) => ({
        url: `${POSITIONS}?cooperative_id=${data.coop_id}&position_id=${data.id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Voting']
    }),
    // GEt All Contestants
    getContestants: builder.query({
      query: ({coopId}) => ({
        url: `${GET_CONTESTANT}?cooperative_id=${coopId}`,
        method: "GET",
      }),
      providesTags: ["Voting"]
    }),
    // GEt Contestants by Position
    getContestantByPosition: builder.query({
      query: ({coopId, position}) => ({
        url: `${GET_CONTESTANT_BY_POSITION}?cooperative_id=${coopId}&position_id=${position}`,
        method: "GET",
      }),
      providesTags: ["Voting"]
    }),
    // Save Contestant
    saveContestant: builder.mutation({
      query: (data) => ({
        url: !data?.contestant_id ? CONTESTANTS : UPDATE_CONTESTANT,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["Voting"],
    }),
    // DELETE CONTESTANT
    deleteContestant: builder.mutation({
      query: (data) => ({
        url: `${CONTESTANTS}?cooperative_id=${data.coopId}&contestant_id=${data.id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Voting']
    }),
    getResultsByElection: builder.query({
      query: ({coopId, electionId}) => ({
        url: `${RESULT_BY_ELECTION}?cooperative_id=${coopId}&election_id=${electionId}`,
        method: 'GET'
      })
    }),
    getResultsByPosition: builder.query({
      query: ({coopId, positionId}) => ({
        url: `${RESULT_BY_POSITION}?cooperative_id=${coopId}&position_id=${positionId}`,
        method: 'GET'
      })
    }),
    castVote: builder.mutation({
      query: (data) => ({
        url: CAST_VOTE,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Voting']
    }),
  }),
});

export const {
  useCastVoteMutation,
  useGetElectionQuery,
  useGetResultsByElectionQuery,
  useGetResultsByPositionQuery,
  useGetContestantByPositionQuery,
  useGetContestantsQuery,
  useGetPositionsQuery,
  useGetElectionsQuery,
  useSaveElectionMutation,
  useSavePositionMutation,
  useSaveContestantMutation,
  useGetPositionsByElectionQuery,
  useDeletePositionMutation,
  useDeleteContestantMutation
} = electionApiSlice;
