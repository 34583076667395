import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import * as Yup from "yup";
import alertify from "alertifyjs";
import { filterOption } from "../../../utils/helpers";
import { DatePicker, Select, Spin } from "antd";
import { useCreateTransactionOrderMutation, useGetMemberSavingsQuery, useLazyGetCoopMembersQuery } from "../../../services/member.service";
import { useGetPaymentModesQuery } from "../../../services/utility.service";
import Card from "../../common/Card";
import dayjs from "dayjs";


const FormSchema = Yup.object().shape({
    coop_id: Yup.string().required('Please select a member'),
    savings_id: Yup.string().required('Please select account'),
    amount: Yup.string().required('This field is required'),
    date: Yup.string().required('This field is required'),
    payment_mode_id: Yup.string().required('This field is required'),
});



export const QuickDeposit = ({cooperative_id}) => {
    const [members, setMembers] = useState([]);
    const [coopId, setCoopId] = useState(null);

    const [save, {isLoading, isSuccess, isError, data, error}] = useCreateTransactionOrderMutation();
    const [getMembers, {data: membersData, isFetching}] = useLazyGetCoopMembersQuery();

    const {data: savingsData, isLoading: loadingSavings} = useGetMemberSavingsQuery({coop_id: coopId, id: cooperative_id}, {skip: !coopId});

    const {data: paymentModes} = useGetPaymentModesQuery();

    const {
        control,
        reset,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(FormSchema),
        mode: 'onBlur'
    });

    useEffect(() => {
        if (membersData) {
            setMembers(membersData.data.data.map(item => ({value: item.coop_id, label: item.firstname + ' ' + item.lastname + ' - ' + item.coop_id})));
        }

    }, [membersData]);

    const onSubmit = (values) => {
        const payload = {...values};
        payload.cooperative_id = cooperative_id;
        payload.date = dayjs(values.date).format('YYYY-MM-DD');
        payload.transaction_type = 'DEPOSIT';
        save(payload);
    }

    useEffect(() => {
        if(isSuccess) {
            resetForm();
            // show toast.
            alertify.success(data?.message)
        }

        if (isError) {
            alertify.error(error?.data?.message);
        }
    }, [isSuccess, isError, data])

    const resetForm = () => {
        reset({
            savings_id: "",
            amount: "",
            comment: "",
            payment_mode_id: "",
        })
    }

    const doCancel = () => {
        resetForm();
    }

    const fetchMember = (value) => {
        getMembers({coopId: cooperative_id, search: value, page:1});
    }

    return (
        <Card title={`Quick Deposit`}>
            <form onSubmit={handleSubmit(onSubmit)} className={`dimmer ${isLoading || loadingSavings ? 'active' : ''}`}>
                <div className="card-body dimmer-content">
                    <div className="loader" />
                    <div className="row clearfix">
                        <div className="col-lg-12 col-md-12">
                            <label>Member</label>
                            <Controller
                                name="coop_id"
                                control={control}
                                render={({ field }) => (
                                    <Select 
                                        showSearch
                                        className="customSelect mt-1"
                                        options={members} 
                                        placeholder="Select Member"
                                        filterOption={false}
                                        value={field.value}
                                        onSearch={fetchMember}
                                        notFoundContent={isFetching ? <Spin size="small" /> : null}
                                        onChange={(val) => {
                                            field.onChange(val);
                                            setCoopId(val);
                                        }}
                                    />
                                    
                                )}
                            />
                            {errors.coop_id && <div className="invalid-feedback">{errors.coop_id?.message}</div>}
                        </div>
                        <div className="col-md-12 col-lg-12">
                            <div className="form-group">
                                <label>Account <span className="form-required">*</span></label>
                                <Controller
                                    name="savings_id"
                                    control={control}
                                    render={({ field }) => (
                                        <Select 
                                            showSearch
                                            className="customSelect mt-1"
                                            options={savingsData?.data?.map(saving => ({value: saving.id, label: saving.product.product.name}))} 
                                            placeholder="Select Savings Account"
                                            filterOption={filterOption}
                                            value={field.value}
                                            onChange={field.onChange}
                                        />
                                        
                                    )}
                                />
                                {errors.savings_id && <div className="invalid-feedback">{errors.savings_id?.message}</div>}
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-12">
                            <div className="form-group">
                                <label>Amount <span className="form-required">*</span></label>
                                <Controller
                                    name="amount"
                                    control={control}
                                    render={({ field }) => (
                                        <input
                                            className={`form-control ${
                                            errors?.amount ? "is-invalid" : ""
                                            }`}
                                            type="text"
                                            onChange={field.onChange}
                                            value={field.value}
                                            autoComplete="true"
                                        />
                                    )}
                                />
                                {errors.amount && <div className="invalid-feedback">{errors.amount?.message}</div>}
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-12">
                            <div className="form-group">
                                <label>Date <span className="form-required">*</span></label>
                                <Controller
                                    name="date"
                                    control={control}
                                    render={({ field }) => (
                                        <DatePicker
                                            className={`customInput w-full`} 
                                            name={'date'} 
                                            style={{width: '100%'}}
                                            onChange={field.onChange} 
                                            // onBlur={onBlur} 
                                            value={field.value ? dayjs(field.value) : undefined} 
                                        />
                                    )}
                                />
                                {errors.start_date && <div className="invalid-feedback">{errors.start_date?.message}</div>}
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-12">
                            <div className="form-group">
                                <label>Payment Mode <span className="form-required">*</span></label>
                                <Controller
                                    name="payment_mode_id"
                                    control={control}
                                    render={({ field }) => (
                                        <Select 
                                            showSearch
                                            className="customSelect mt-1"
                                            options={paymentModes?.data?.map(item => ({value: item.id, label: item.name}))} 
                                            placeholder="Select Payment Mode"
                                            filterOption={filterOption}
                                            value={field.value}
                                            onChange={field.onChange}
                                        />
                                        
                                    )}
                                />
                                {errors.payment_mode_id && <div className="invalid-feedback">{errors.payment_mode_id?.message}</div>}
                            </div>
                        </div>
                    </div>
                        
                    <button type="submit" className="btn btn-round btn-primary">
                        Submit
                    </button>{' '}&nbsp;&nbsp;
                    <button type="button" className="btn btn-round btn-default" onClick={doCancel}>
                        Cancel
                    </button>
                </div>
            </form>
        </Card>
    )
}