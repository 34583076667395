import React, { useEffect, useState } from "react";
import alertify from "alertifyjs";
import { useSelector } from "react-redux";
import { DOWNLOAD_SAVINGS_SAMPLE } from "../../../services/CONSTANTS";
import { filterOption } from "../../../utils/helpers";
import { Select } from "antd";

export default function DownloadSampleSavings({coop}) {
    const [type, setType] = useState('NORMAL_BALANCES')
    const [loading, setLoading] = useState(false)
    const {token} = useSelector(state => state.user);

    const download = async () => {
        setLoading(true);
        const url = `${process.env.REACT_APP_API_URL}${DOWNLOAD_SAVINGS_SAMPLE}?cooperative_id=${coop?.id}&download_type=${type}`
        await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            }})
            .then((response) => response.blob())
            .then((myBlob) => {
                const objectURL = URL.createObjectURL(myBlob);
                const anchor = document.createElement("a");
                anchor.href = objectURL; // Set the href to the data URL
                anchor.target = "_self"; // Open in a new tab
                anchor.download = "sample-savings-import.xlsx"; // Specify the file name
      
                // Trigger a click event on the anchor element
                anchor.click();
                anchor.remove();
                
                document.getElementById('close-savings-modal').click();

            }).catch(err => console.log(err.message));
        setLoading(false)
    }

    return (
        <div className="modal fade" id="savingsSampleModal" tabIndex={-1} role="dialog" aria-labelledby="savingsSampleModal" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className={`modal-content dimmer ${loading ? 'active' : ''}`}>
                    <div className="loader" />
                    
                    <div className="modal-body dimmer-content">
                        <div className="form-header text-center">
                            <h3>Download Sample Savings</h3>
                        </div>
                        <div className="form-group">
                            <label>Select Savings Type </label>
                            <Select 
                                showSearch
                                className="customSelect mt-1"
                                options={[
                                    {value: 'NORMAL_BALANCES', label: 'Normal Balances'},
                                    {value: 'NORMAL_HISTORY', label: 'Normal History'},
                                    {value: 'TARGET_HISTORY', label: 'Target History'},
                                ]} 
                                placeholder="Select Savings Type"
                                filterOption={filterOption}
                                value={type}
                                onChange={(value) => setType(value)}
                            />
                        </div>
                        <div className="modal-btn delete-action">
                            <div className="row">
                                <div className="col-6">
                                    <a 
                                        href="javascript:;"
                                        id="close-savings-modal" 
                                        data-dismiss="modal" 
                                        className="btn btn-secondary btn-block"
                                    >
                                        Cancel
                                    </a>
                                </div>
                                <div className="col-6">
                                    <a
                                        href="javascript:;"
                                        onClick={download}
                                        className="btn btn-primary btn-block"
                                    >
                                        {loading ? 'Downloading...' : 'Download'}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
