import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import * as Yup from "yup";
import alertify from "alertifyjs";
import { Select } from "antd";
import { filterOption } from "../../../utils/helpers";

import { useGetBanksQuery } from "../../../services/utility.service";
import { useGetCoaCategoriesQuery, useSaveBankAccountMutation, useUpdateBankAccountMutation, useGetCoaSubCatByCatQuery, useGetCoaByCategoryQuery } from "../../../services/cooperative-setup.service";
import Card from "../../common/Card";

const FormSchema = Yup.object().shape({
    bank_id: Yup.string().required('Please select a bank'),
    account_no: Yup.string().required('Please enter an account number'),
    coa_id: Yup.string().required('Select a GL Account'),
    status: Yup.string().required('Select status'),
});

const BankAccountForm = ({account, onCancel, activeCoop}) => {
    const {data: bankData} = useGetBanksQuery();
    const [catID, setCatID] = useState(null);
    const [subCatId, setSubCatId] = useState(null);
    const [glAccounts, setGlAccounts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);

    const {data: catData, isFetching: catLoading} = useGetCoaCategoriesQuery(activeCoop?.id, {
        skip: !activeCoop
    });

    const {data: subCategoryData, isFetching: subCatLoading} = useGetCoaSubCatByCatQuery({id: catID, coopId: activeCoop?.id}, {
        skip: !catID
    });

    const {data: postingGlData} = useGetCoaByCategoryQuery({coopId: activeCoop?.id, id: subCatId}, {skip: !subCatId});

    useEffect(() => {
        if (catData && catData.status) {
            setCategories(catData.data.map(item => ({value: item.id, label: `[${item.code}] ${item.name}`})));
        }
    }, [catData]);

    useEffect(() => {
        if (subCategoryData && subCategoryData.status) {
            setSubCategories(subCategoryData.data.map(item => ({value: item.id, label: `[${item.code}] ${item.name}`})));
            // set sub coa id
            if(account) setSubCatId(account?.coa?.sub_category_id);
        }
    }, [subCategoryData]);
    
    const [save, {isLoading, isSuccess, isError, data, error}] = useSaveBankAccountMutation();
    const [update, {
        isLoading: isUpdateLoading, 
        isSuccess: isUpdateSuccess, 
        isError: isUpdateError, 
        data: updateData, 
        error: updateError
    }] = useUpdateBankAccountMutation();

    const {
        control,
        reset,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(FormSchema),
        mode: 'onBlur'
    });

    useEffect(() => {
        if (postingGlData && postingGlData.status) {
          setGlAccounts(postingGlData.data.map(item => ({value: item.id, label: `[${item.code}] ${item.name}`})));
        }
    }, [postingGlData]);

    useEffect(() => {
        if (account) {
            resetForm(account);
            setCatID(account?.coa?.subcategory?.category_id);
        }
    }, [account]);


    const onSubmit = async (values) => {
        values.cooperative_id = activeCoop?.id;

        if (account) {
            values.id = account.id;
            await update(values);
        } else {
            await save(values);
        }
    }

    useEffect(() => {
        if(isSuccess || isUpdateSuccess) {
            resetForm();
            // show toast.
            alertify.success(data?.message || updateData?.message)
        }

        if (isError || isUpdateError) {
            alertify.error(error?.data?.message || updateError?.data?.message);
        }
    }, [isSuccess, isUpdateSuccess, isUpdateError, isError, data, updateData])

    const resetForm = (account) => {
        reset({
            cooperative_id: activeCoop.id,
            bank_id: account?.bank_id || "",
            account_no: account?.account_no || "",
            coa_id: account?.coa_id || "",
            status: account?.status || ""
        })
        setCatID(null)
        setSubCatId(null)
    }

    const doCancel = () => {
        resetForm();
        onCancel();
    }

    return (
        <Card 
            title={`${account ? 'Edit' : 'Add New'} Bank Account`}
            loading={isLoading || isUpdateLoading || subCatLoading || catLoading}
        >
            <form className="card-body dimmer-content" onSubmit={handleSubmit(onSubmit)}>
                <div className="row clearfix">
                    <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                            <label>Bank Name <span className="form-required">*</span></label>
                            <Controller
                                name="bank_id"
                                control={control}
                                render={({ field }) => (
                                    <Select 
                                        showSearch
                                        className="customSelect mt-1"
                                        options={bankData?.data.map(bank => ({value: bank.id, label: bank.name}))} 
                                        placeholder="Select Bank"
                                        filterOption={filterOption}
                                        value={field.value || account?.bank_id}
                                        onChange={field.onChange}
                                    />
                                )}
                            />
                        </div>
                        {errors.bank_id && <div className="invalid-feedback">{errors.bank_id?.message}</div>}
                    </div>
                    <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                            <label>Account No. <span className="form-required">*</span></label>
                            <Controller
                                name="account_no"
                                control={control}
                                render={({ field }) => (
                                    <input
                                        className={`form-control ${errors?.account_no ? "is-invalid" : ""}`}
                                        type="text"
                                        onChange={field.onChange}
                                        value={field.value}
                                        autoComplete="true"
                                        placeholder=""
                                    />
                                )}
                            />
                            {errors.account_no && <div className="invalid-feedback">{errors.account_no?.message}</div>}
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                            <label>COA Category <span className="form-required">*</span></label>
                            <Select 
                                showSearch
                                className="customSelect mt-1"
                                options={categories} 
                                placeholder="Select COA Category"
                                filterOption={filterOption}
                                value={catID}
                                onChange={(value) => setCatID(value)}
                            />
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                            <label>Sub Category <span className="form-required">*</span></label>
                            <Select 
                                showSearch
                                className="customSelect mt-1"
                                options={subCategories} 
                                placeholder="Select COA  SubCategory"
                                filterOption={filterOption}
                                value={subCatId}
                                onChange={(value) => setSubCatId(value)}
                            />
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                            <label>GL Code <span className="form-required">*</span></label>
                            <Controller
                                name="coa_id"
                                control={control}
                                render={({ field }) => (
                                    <Select 
                                        showSearch
                                        className="customSelect mt-1"
                                        options={glAccounts} 
                                        placeholder="Select COA"
                                        filterOption={filterOption}
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                )}
                            />
                            {errors.coa_id && <div className="invalid-feedback">{errors.coa_id?.message}</div>}
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                            <label>Status <span className="form-required">*</span></label>
                            <Controller
                                name="status"
                                control={control}
                                render={({ field }) => (
                                    <select 
                                        onChange={field.onChange}
                                        value={field.value}
                                        className={`form-control ${errors?.status ? "is-invalid" : ""}`}
                                    >
                                        <option value=''>Select Status</option>
                                        <option value='1'>Active</option>
                                        <option value='0'>Inactive</option>
                                    </select>
                                )}
                            />
                            {errors.status && <div className="invalid-feedback">{errors.status?.message}</div>}
                        </div>
                    </div>
                </div>
                    
                <div className="d-flex justify-content-between">
                    <button type="button" className="btn btn-round btn-default" onClick={doCancel}>
                        Cancel
                    </button>{' '}&nbsp;&nbsp;
                    <button type="submit" className="btn btn-round btn-primary">
                        Save
                    </button>
                </div>
                
            </form>
        </Card>
    )
}

export default BankAccountForm;