import React, { useEffect, useState } from "react";
import { useLazyGetTransactionHistoryQuery } from "../../../services/member.service";
import Card from "../../common/Card";
import { Empty } from "antd";
import { doDownload, formatNumber } from "../../../utils/helpers";
import dayjs from "dayjs";
import { Pagination } from "../../Pagination/Pagination";
import { MEMBER_TRANSACTION_HISTORY } from "../../../services/CONSTANTS";
import { useSelector } from "react-redux";
var advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)

export const TransactionHistory = ({memberId, coop}) => {
    const [getTransactions, {data, isLoading}] = useLazyGetTransactionHistoryQuery();
    const [transactions, setTransactions] = useState([]);
    const {token} = useSelector(state => state.user);
    const [isDownloading, setIsDownloading] = useState(false);

    const [pagination, setPagination] = useState({
        total: 0,
        perPage: 0,
        currentPage: 1,
        first: 1,
        last: 1
    });

    useEffect(() => {
        if (memberId) {
            getTransactions({
                id: coop?.id, 
                coopId: memberId, 
                page: 1
            })
        }
    }, [memberId]);

    useEffect(() => {
        if (data?.status) {
            setTransactions(data?.data?.data);

            setPagination({
                total: data?.data?.meta?.total,
                perPage: data?.data?.meta?.per_page,
                currentPage: data?.data?.meta?.current_page,
                first: data.data.meta?.first_page,
                last: data.data.meta?.last_page
            })
        }
    }, [data])

    const download = async () => {
        setIsDownloading(true);
        const url = `${process.env.REACT_APP_API_URL}${MEMBER_TRANSACTION_HISTORY}?cooperative_id=${coop?.id}&coop_id=${memberId}&download=true`
        await doDownload(url, `transaction-history-${dayjs().unix()}.xlsx`, token);
        setIsDownloading(false)
    }

    return (
        <Card loading={isLoading} title="Transaction History" headerOptions={
            <div className="card-options">
                    <form>
                        <div className="input-group">
                            <input
                                type="text"
                                className="form-control form-control-sm"
                                placeholder="Search something..."
                                name="s"
                            />
                            <span className="input-group-btn ml-2">
                                <button className="btn btn-icon btn-sm" type="submit">
                                    <span className="fe fe-search" />
                                </button>
                            </span>
                            <span className="input-group-btn ml-2">
                                <button className="btn btn-icon btn-sm" type="button" onClick={download}>
                                    {isDownloading ?  <i className="fa fa-spinner fa-spin" />:  <i className="fe fe-download" />}
                                </button>
                            </span>
                        </div>
                    </form>
                </div>
            }>
            <div className="card-body p-0 dimmer-content">
                <div className="table-responsive">
                    <table className="table table-striped table-vcenter table-hover mb-0">
                        <thead>
                            <tr>
                                <th>Ref. No</th>
                                <th>Type</th>
                                <th>Description</th>
                                <th>Amount</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {transactions.map(row => 
                                <tr key={`transaction-${row.id}`}>
                                    <td>{row.reference_no}</td>
                                    <td>{row.type.name}</td>
                                    <td>{row.description}</td>
                                    <td><div className="font-15">{formatNumber(row.amount)}</div></td>
                                    <td>
                                        {dayjs(row.transaction_date).format('Do MMM YYYY, hh:mma')}
                                    </td>
                            
                                </tr>
                            )}
                            {data?.data.meta.total === 0 && <tr><td colSpan={5}><Empty /></td></tr>}
                            
                        </tbody>
                    </table>
                </div>
                {pagination.last > 1 && 
                    <Pagination 
                        {...pagination} 
                        changePage={(page) => {
                            getTransactions({
                                coopId: memberId,
                                page,
                                id: coop.id,
                            })
                        }} 
                    />
                }
            </div>
        </Card>
    )
}