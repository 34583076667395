import React from "react";
import Card from "../../common/Card";
import { Tabs } from "antd";
import { MemberLoanList } from "./MemberLoanList";
import { LoanForm } from "../../MemberArea/Components/LoanForm";
import { UploadLoanForm } from "../../MemberArea/Components/UploadLoanForm";
import { LoanOffsetHistory } from "../../MemberArea/Components/LoanOffsetHistory";
import { LoanOffsetForm } from "../../MemberArea/Components/LoanOffsetForm";

export const Loans = ({coop, member}) => {
    
    const tabs = [
        {
          key: '1',
          label: 'Loan History',
          children: <MemberLoanList coop={coop} member={member}  />
        },
        {
          key: '2',
          label: 'Apply',
          children: <LoanForm coop_id={member?.coop_id} cooperative_id={coop?.id} />,
        },
        {
            key: '3',
            label: 'Upload Loan Form',
            children: <UploadLoanForm coopId={coop?.id} />,
        },
        {
          key: '4',
          label: 'Loan Offset',
          children: <LoanOffsetHistory coop_id={member?.coop_id} cooperative_id={coop?.id}  />
        },
        {
            key: '5',
            label: 'Loan Offset Form',
            children: <LoanOffsetForm coop_id={member?.coop_id} cooperative_id={coop?.id}  />,
        },

    ];

    return (
        <Card>
            <div className="card-body">
                <Tabs defaultActiveKey="1" items={tabs} />
            </div>
        </Card>
    )
}