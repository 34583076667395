import React from 'react'
import { useSelector } from 'react-redux';

const ReturnAndRefunds = () => {
    const {isFixNavbar: fixNavbar} = useSelector(state => state.settings);

    return (
        <>
            <div>
                <div>
                    <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
                        <div className="container-fluid">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-4 col-sm-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control" placeholder="Order ID" />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-6">
                                            <div className="form-group">
                                                <select className="form-control show-tick">
                                                    <option>-- Status --</option>
                                                    <option>Pending</option>
                                                    <option>Confirmed</option>
                                                    <option>On the Way</option>
                                                    <option>Delivered</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-6">
                                            <div className="input-icon mb-3">
                                                <input type="text" className="form-control" placeholder="Customer" />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-6">
                                            <div className="input-icon mb-3">
                                                <input type="text" className="form-control" placeholder="Date" />
                                                <span className="input-icon-addon"><i className="fa fa-calendar"></i></span>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-6">
                                            <a href="fake_url" className="btn btn-sm btn-primary btn-block">Filter</a>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-6">
                                            <a href="fake_url" className="btn btn-outline btn-block">Clear Filter</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section-body mt-3">
                        <div className="container-fluid">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Return and Refunds</h3>
                                    <div className="card-options">
                                        <form>
                                            <div className="input-group">
                                                <span className="input-group-btn ml-2">
                                                    <button className="btn btn-icon" type="submit"><span className="fa fa-file-pdf-o" /> PDF</button>
                                                    <button className="btn btn-icon" type="submit"><span className="fa fa-file-excel-o" /> EXCEL</button>
                                                </span>

                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table table-striped table-vcenter table-hover mb-0">
                                            <thead>
                                                <tr>
                                                    <th>ORDER ID</th>
                                                    <th>CUSTOMER</th>
                                                    <th>AMOUNT</th>
                                                    <th>DATE</th>
                                                    <th>STATUS</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>#3262</td>
                                                    <td>Mark Payne</td>
                                                    <td>N30,000</td>
                                                    <td>17/02/2024</td>
                                                    <td><span className='tag tag-warning'>Pending</span></td>
                                                    <td>
                                                        <button type="button" className="btn btn-icon" title="Edit"><i className="fa fa-edit" /></button>
                                                        <button type="button" className="btn btn-icon js-sweetalert" title="Delete" data-type="confirm"><i className="fa fa-trash-o text-danger" /></button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>#3263</td>
                                                    <td>Jane Doe</td>
                                                    <td>N5,000</td>
                                                    <td>17/03/2024</td>
                                                    <td><span className='tag tag-success'>Delivered</span></td>
                                                    <td>
                                                        <button type="button" className="btn btn-icon" title="Edit"><i className="fa fa-edit" /></button>
                                                        <button type="button" className="btn btn-icon js-sweetalert" title="Delete" data-type="confirm"><i className="fa fa-trash-o text-danger" /></button>
                                                    </td>
                                                </tr>
                                                
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                
            </div>
        </>
    )
}
export default ReturnAndRefunds