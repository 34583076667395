import React from "react"
import { useDispatch } from "react-redux";
import { toggleDelete } from "../../../redux/slices/ui.slice";
import { DELETE_COOPERATIVE } from "../../../services/CONSTANTS";
import { Empty, Tooltip } from "antd";
import { useGetCooperativesQuery } from "../../../services/cooperative-setup.service";
import InitialsAvatar from 'react-initials-avatar';


export const CooperativeList = ({ onEdit}) => {
    const dispatch = useDispatch();
    
    const {data: cooperatives, isFetching} = useGetCooperativesQuery();
    
    const doDelete = (e, item) => {
        // console.log(e.currentTarget)
        dispatch(
          toggleDelete({
            open: true,
            title: 'Cooperative',
            url: `${DELETE_COOPERATIVE}?cooperative_id=${item.id}`,
            // ele: JSON.stringify(e.currentTarget),
            parent: 'tr'
          })
        );
    }

    return (
        <div className="row clearfix">
            <div className="col-12">
                
                <div className="table-responsive">
                    <table className="table table-hover table-vcenter table_custom text-nowrap spacing5 mb-0">
                        <tbody>
                            {isFetching ?
                                <tr>
                                    <td className="text-center">
                                        <div className="loader" />
                                    </td>
                                </tr>
                            :
                                cooperatives?.data?.data.length > 0 ?
                                cooperatives?.data?.data.map((coop, i) =>
                                    (<tr>
                                        <td className="w60">
                                            {coop.logo ?
                                                <img src={coop.logo} className="avatar avatar-orange" />
                                            :
                                            <span className="avatar avatar-pink" data-toggle="tooltip" data-placement="top" data-original-title="Avatar Name">
                                                <InitialsAvatar name={`${coop.name}`} />
                                            </span>
                                            }
                                        </td>
                                        <td>
                                            <div className="font-15">{coop.name}</div>
                                            <span className="text-muted">{coop.code}</span>
                                        </td>
                                        <td>
                                            {coop.status == 1 && <span className="tag tag-success">Active</span>}
                                            {coop.status == 0 && <span className="tag tag-danger">Inactive</span>}
                                        </td>
                                        <td>
                                            Members: <strong>{coop.meta.members_count}</strong>
                                        </td>
                                        <td>
                                            <span>{coop.location}</span>
                                        </td>
                                        <td>
                                            <Tooltip title="Edit">
                                                <button
                                                    onClick={() => onEdit(coop)}
                                                    type="button" 
                                                    className="btn btn-icon btn-sm btn-sm" 
                                                >
                                                    <i className="icon-pencil text-info" />
                                                </button>
                                            </Tooltip>
                                            <Tooltip title="Delete">
                                                <button 
                                                    type="button" 
                                                    onClick={(e) => doDelete(e, coop)}
                                                    className="btn btn-icon btn-sm" 
                                                    title="Delete" data-toggle="modal" data-target="#deleteModal"
                                                >
                                                        <i className="icon-trash text-danger" />
                                                </button>
                                            </Tooltip>
                                        </td>
                                    </tr>
                                ))
                            :
                                <tr>
                                    <td colSpan={6} className="text-center"><Empty /></td>
                                </tr>

                            }
                            
                            
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}