import React, { useEffect, useState } from "react"
import { useGetElectionsQuery } from "../../../services/election.service"
import { Empty } from "antd";
import { ElectionItem } from "./ElectionItem";
import { ElectionForm } from "./ElectionForm";
import { ManagePositions } from "../Positions";
import { ManageContestant } from "../Contestants";

export const Elections = ({activeCoop}) => {
    const [page, setPage] = useState(1)
    const [elections, setElections] = useState([]);
    const [election, setElection] = useState(null);

    const {data, isLoading} = useGetElectionsQuery({coopId: activeCoop?.id, page}, {skip: !activeCoop});
    
    useEffect(() => {
        if(data) setElections(data?.data.data);
    }, [data]);

    return (
        <div className={`row dimmer ${isLoading ? 'active' : ''}`}>    
            <div className="loader" />
            {elections.length === 0 &&
                <div className="dimmer-content" style={{width: '100%'}}>
                        <div className="col-md-12 m-auto"><Empty /></div>      
                </div>
            }

            {elections.map(row => 
                <ElectionItem 
                    key={row.id}   
                    item={row} 
                    onEdit={(data) => setElection(data)} 
                />
            )}    
            
            <ElectionForm 
                activeCoop={activeCoop} 
                details={election} 
                onCancel={() => setElection(election)} 
            />

            <ManagePositions election={election} coopId={activeCoop?.id} />

            <ManageContestant election={election} coopId={activeCoop?.id} />
        </div>
    )
}