import React from "react"
import { useSelector } from "react-redux";
import Card from "../common/Card";
import { LoanForm } from "./Components/LoanForm";

export const LoanApplication = () => {
    const {isFixNavbar: fixNavbar} = useSelector(state => state.settings);
    const {user} = useSelector(state => state.user);
    const {activeCoop} = useSelector(state => state.coop);

    return (
        <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
            <Card title="Loan Form">
                <div className="card-body">
                    <LoanForm coop_id={user?.coop_id} cooperative_id={activeCoop?.id} />
                </div>
            </Card>
        </div>
    )
}