import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AdminDashboard } from './AdminDashboard';
import { CoopDashboard } from './CoopDashboard';

class Dashboard extends Component {

	render() {
		const { fixNavbar, activeCoop, colors, user } = this.props;
		return (
			<>
				{activeCoop ? 
					<CoopDashboard user={user} colors={colors} fixNavbar={fixNavbar} /> 
				: 
					<AdminDashboard user={user} fixNavbar={fixNavbar} />
				}
			</>
		);
	}
}
const mapStateToProps = state => ({
	fixNavbar: state.settings.isFixNavbar,
	activeCoop: state.coop.activeCoop,
	colors: state.ui.colors,
	user: state.user.user
})

const mapDispatchToProps = dispatch => ({})
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);