import apiSlice from "./api/api";
import { BALANCE_SHEET, CONSOLIDATED_REPORT, GET_ACCOUNT_STATEMENT, GET_BANKS, GET_CALC_METHODS, GET_COOP_DASHBOARD_DATA, GET_COUNTRIES, GET_DASHBOARD_DATA, GET_DASHBOARD_TILE_DATA, GET_LEDGER_STATEMENT, GET_MEMBER_DASHBOARD, GET_STATES, INCOME_STATMENT, INVESTMENT_TRACKER, MEMBER_BALANCE, PAYMENT_MODE, SEND_REG_EMAIL, TRIAL_BALANCE } from "./CONSTANTS";

const utilityApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // get banks
    getBanks: builder.query({
      query: () => ({
        url: GET_BANKS,
        method: "GET",
      }),
    }),
     // get countries
    getCountries: builder.query({
      query: () => ({
        url: GET_COUNTRIES,
        method: "GET",
      }),
    }),
     // get states
    getStates: builder.query({
      query: (id) => ({
        url: `${GET_STATES}?country_id=${id}`,
        method: "GET",
      }),
    }),
    // get calculation methods
    getCalcMethods: builder.query({
      query: () => ({
        url: GET_CALC_METHODS,
        method: "GET",
      }),
    }),
    // get dashboard data
    getDashboarData: builder.query({
      query: () => ({
        url: GET_DASHBOARD_DATA,
        method: "GET",
      }),
    }),
    // get dashboard tile data
    getDashboarTileData: builder.query({
      query: () => ({
        url: GET_DASHBOARD_TILE_DATA,
        method: "GET",
      }),
    }),
    // get dashboard data
    getCoopDashboard: builder.query({
      query: (coop_id) => ({
        url: `${GET_COOP_DASHBOARD_DATA}?cooperative_id=${coop_id}`,
        method: "GET",
      }),
    }),
    // get dashboard data
    getMemberDashboard: builder.query({
      query: ({coop_id, userId}) => ({
        url: `${GET_MEMBER_DASHBOARD}?cooperative_id=${coop_id}&coop_id=${userId}`,
        method: "GET",
      }),
    }),
    // get consolidated report
    getConsolidatedReport: builder.query({
      query: () => ({
        url: CONSOLIDATED_REPORT,
        method: "GET",
      }),
    }),
    // get payment modes
    getPaymentModes: builder.query({
      query: () => ({
        url: PAYMENT_MODE,
        method: "GET",
      }),
    }),
    memberBalanceReport: builder.query({
      query: ({id, query, download}) => ({
        url: `/${MEMBER_BALANCE}?cooperative_id=${id}&download=${download}&query=${query}`,
        method: "GET",
      }),
    }),
    incomeStatmentReport: builder.query({
      query: ({id, start, end, download}) => ({
        url: `/${INCOME_STATMENT}?cooperative_id=${id}&download=${download}&start_date=${start}&end_date=${end}`,
        method: "GET",
      }),
    }),
    trialBalanceReport: builder.query({
      query: ({id, start, end, download}) => ({
        url: `/${TRIAL_BALANCE}?cooperative_id=${id}&download=${download}&start_date=${start}&end_date=${end}`,
        method: "GET",
      }),
    }),
    balanceSheetReport: builder.query({
      query: ({id, start, end, download}) => ({
        url: `/${BALANCE_SHEET}?cooperative_id=${id}&download=${download}&start_date=${start}&end_date=${end}`,
        method: "GET",
      }),
    }),
    getLedgerStatement: builder.query({
      query: ({id, start, end, code}) => ({
        url: `/${GET_LEDGER_STATEMENT}?cooperative_id=${id}&ledger_code=${code}&start_date=${start}&end_date=${end}`,
        method: "GET",
      }),
    }),
    getAccountStatement: builder.query({
      query: ({id, start, end, coopId}) => ({
        url: `/${GET_ACCOUNT_STATEMENT}?cooperative_id=${coopId}&subcategory_id=${id}&start_date=${start}&end_date=${end}`,
        method: "GET",
      }),
    }),
    getInvestmentTracker: builder.query({
      query: ({id, page}) => ({
        url: `${INVESTMENT_TRACKER}?cooperative_id=${id}&page=${page}`,
        method: 'GET',
      }),
      providesTags: ["InvestmentTracker"]
    }),
    saveInvestmentTracker: builder.mutation({
      query: (body) => ({
        url: INVESTMENT_TRACKER,
        method: "POST",
        body,
      }),
      invalidatesTags: ["InvestmentTracker"]
    }),
    closeInvestmentTracker: builder.mutation({
      query: (body) => ({
        url: INVESTMENT_TRACKER + "/close",
        method: "POST",
        body,
      }),
      invalidatesTags: ["InvestmentTracker"]
    }),
    sendRegistrationEmail: builder.query({
      query: (coopId) => ({
        url: `${SEND_REG_EMAIL}?cooperative_id=${coopId}`,
        method: "GET"
      }),
      invalidatesTags: ["InvestmentTracker"]
    })
  }),
});

export const {
  useGetMemberDashboardQuery,
  useGetCoopDashboardQuery,
  useGetInvestmentTrackerQuery,
  useSaveInvestmentTrackerMutation,
  useCloseInvestmentTrackerMutation,
  useLazyBalanceSheetReportQuery,
  useLazyTrialBalanceReportQuery,
  useLazyMemberBalanceReportQuery,
  useLazyGetLedgerStatementQuery,
  useLazyIncomeStatmentReportQuery,
  useGetBanksQuery,
  useGetCalcMethodsQuery,
  useGetDashboarDataQuery,
  useGetDashboarTileDataQuery,
  useGetConsolidatedReportQuery,
  useGetCountriesQuery,
  useGetStatesQuery,
  useGetPaymentModesQuery,
  useLazyGetAccountStatementQuery,
  useLazySendRegistrationEmailQuery,
} = utilityApiSlice;
