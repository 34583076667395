
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { AssetSetupTable } from "./AssetSetupTable";
import AssetSetupForm from "./AssetSetupForm";

const AssetSetup = () => {
    const {isFixNavbar: fixNavbar} = useSelector(state => state.settings);
    const {activeCoop} = useSelector(state => state.coop);

    const [asset, setAsset] = useState(null);

    const doEdit = (val) => {
        setAsset(val);
        document.getElementById('asset-form-tab').click()
    }

    return (
        <>
            <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>

                <div className="container-fluid">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <ul className="nav nav-tabs page-header-tab">
                            <li className="nav-item">
                                <a
                                    className="nav-link active"
                                    id="asset-list-tab"
                                    data-toggle="tab"
                                    href="#asset-list"
                                >
                                    Assets
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    id="asset-form-tab"
                                    data-toggle="tab"
                                    href="#asset-form"
                                >
                                    Asset Setup Form
                                </a>
                            </li>
                        </ul>
                        {/* <div className="header-action">
                            <button
                                type="button"
                                className="btn btn-primary"
                                data-toggle="modal"
                                data-target="#exampleModal"
                            >
                                <i className="fe fe-plus mr-2" />
                                Add
                            </button>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="section-body">
                <div className="container-fluid">
                    <div className="tab-content">
                        <div className="tab-pane fade show active" id="asset-list" role="tabpanel">
                            <AssetSetupTable onEdit={doEdit} activeCoop={activeCoop} />
                        </div>
                        <div className="tab-pane fade" id="asset-form" role="tabpanel">
                            <AssetSetupForm asset={asset} activeCoop={activeCoop} onCancel={() => {
                                setAsset(null);
                                document.getElementById('asset-list-tab').click()
                            }} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AssetSetup;