import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import Card from "../../common/Card";
import { Empty } from "antd";
import Columnchart from '../../common/columnchart';
import { QuickDeposit } from "./QuickDepoist";
import { useGetCoopDashboardQuery } from "../../../services/utility.service";
import { DashboardRowItem } from "./DashboardRowItem";
import ApproveTransactionModal from "../../common/ApproveTransactionModal";
import DenyTransactionModal from "../../common/DenyTransactionModal";
import { LoanDetails } from "../../MemberArea/Components/LoanDetails";

export const CoopAdminDashboard = ({fixNavbar, activeCoop}) => {
    
    const {data, isLoading} = useGetCoopDashboardQuery(activeCoop?.id, {skip: !activeCoop});
    const [selected, setSelected] = useState(null);
    const [type, setType] = useState(null);

    useEffect(() => {
        if (type === 'loan')
            document.getElementById('open-loan').click()

    }, [type])

    return (
        <div>
            <div className={`section-body ${fixNavbar ? "marginTop" : ""} mt-3`}>
                <div className="container-fluid">
                    <div className="row clearfix">
                        <div className="col-lg-12">
                            <div className={`section-body p-0 ${fixNavbar ? "mb-4 mt-3" : "mb-4"}`}>
                                <h4>Welcome to {activeCoop?.name}</h4>
                                {/* <small>
                                    Measure How Fast You’re Growing Monthly Recurring Revenue.{' '}
                                    <a href="fake_url">Learn More</a>
                                </small> */}
                            </div>
                        </div>
                    </div>
                    <div className="row clearfix">
                        <div className="col-6 col-md-3 col-xl-3">
                            <div className="card">
                                <div className="card-body ribbon">
                                    <div className="ribbon-box green">{data?.data?.dashboard?.pending}</div>
                                    <Link to="/members/pending" className="my_sort_cut text-muted">
                                        <i className="icon-users" />
                                        <span>Pending Members</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-3 col-xl-3">
                            <div className="card">
                                <div className="card-body ribbon">
                                    <div className="ribbon-box pink">{data?.data?.dashboard?.withdrawal}</div>
                                    <Link to="/finance-reporting/withdrawals" className="my_sort_cut text-muted">
                                        <i className="fe fe-corner-up-left" />
                                        <span>Withdrawals</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-3 col-xl-3">
                            <div className="card">
                                <div className="card-body ribbon">
                                    <div className="ribbon-box green">{data?.data?.dashboard?.deposit}</div>
                                    <Link to="/finance-reporting/deposits" className="my_sort_cut text-muted">
                                        <i className="icon-share-alt" />
                                        <span>Deposits</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-6 col-md-3 col-xl-3">
                            <div className="card">
                                <div className="card-body ribbon">
                                    <div className="ribbon-box orange">{data?.data?.dashboard?.request}</div>

                                    <Link to="/loans/applications" className="my_sort_cut text-muted">
                                        <i className="icon-wallet" />
                                        <span>New Loan Requests</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div className="section-body">
                <div className="container-fluid">
                    <div className="row clearfix row-deck">
                        <div className="col-xl-8 col-lg-12">
                            <Card title="Transactions Overview">
                                <div className="card-body text-center">
                                    <Columnchart series={data?.data?.transaction_overview?.overview || []} />
                                </div>
                            </Card>
                        </div>
                        <div className="col-xl-4 col-lg-12">
                            <QuickDeposit cooperative_id={activeCoop?.id} />
                        </div>
                    </div>
                    <div className="row clearfix">
                        <div className="col-xl-4 col-lg-12 col-md-6">
                            <Card title="Recent Deposits" customClass={`dimmer ${isLoading ? 'active' : ''}`} styles={{minHeight: '323px'}}>
                                <div className="loader" />
                                <table className="table dimmer-content card-table mt-2">
                                    <tbody>
                                    {!isLoading && data?.data?.deposits?.length > 0 ? 
                                        data?.data?.deposits.map((row, i) => 
                                            <DashboardRowItem
                                                key={`deposit-${i}`}
                                                name={row.member.firstname + ' '+ row.member.lastname}
                                                productName={row.savings.product.product.name}
                                                amount={row.amount}
                                                status={row.status}
                                                onClick={() => {
                                                    setSelected(row);
                                                    setType('transaction')
                                                }}
                                                type="transaction"
                                            />
                                        )
                                        :
                                            <tr><td colSpan={3}><Empty /></td></tr>
                                        }
                                    </tbody>
                                </table>
                            </Card>
                        </div>
                        <div className="col-xl-4 col-lg-12 col-md-6">
                            <Card title="Recent Withdrawals" customClass={`dimmer ${isLoading ? 'active' : ''}`} styles={{minHeight: '323px'}}>
                                <div className="loader" />
                                <table className="table dimmer-content card-table mt-2">
                                    <tbody>
                                    {!isLoading && data?.data?.withdrawals?.length > 0 ? 
                                        data?.data?.withdrawals.map((row, i) => 
                                            <DashboardRowItem
                                                key={`withdrawals-${i}`}
                                                name={row.member.firstname + ' '+ row.member.lastname}
                                                productName={row.savings.product.product.name}
                                                amount={row.amount}
                                                status={row.status}
                                                onClick={() => {
                                                    setSelected(row);
                                                    setType('transaction')
                                                }}
                                                type="transaction"

                                            />
                                        )
                                        :
                                            <tr><td colSpan={3}><Empty /></td></tr>
                                        }
                                    </tbody>
                                </table>
                            </Card>
                        </div>
                        <div className="col-xl-4 col-lg-12 col-md-12">
                            <Card 
                                title="Recent Loan Applications"
                                customClass={`dimmer ${isLoading ? 'active' : ''}`}
                                styles={{minHeight: '323px'}}
                            >
                                <div className="loader" />
                                <table className="table dimmer-content card-table mt-2">
                                    <tbody>
                                    {!isLoading && data?.data?.loan_applications?.length > 0 ? 
                                        data?.data?.loan_applications.map((row, i) => 
                                            <DashboardRowItem
                                                key={`loans-${i}`}
                                                name={row.member.firstname + ' '+ row.member.lastname}
                                                productName={row.product.name}
                                                amount={row.amount}
                                                status={row.status}
                                                onClick={() => {
                                                    setSelected(row);
                                                    setType('loan')
                                                }}
                                                type="loan"
                                            />
                                        )
                                        :
                                            <tr><td colSpan={3}><Empty /></td></tr>
                                        }
                                    </tbody>
                                </table>
                            </Card>
                        </div>
                    </div>
                </div>
                <button type="button" id="open-loan" style={{display: 'none'}} data-toggle="modal" data-target="#loan-details" />
            </div>
            {type && type === 'loan' && <LoanDetails loan={selected} />}
            <ApproveTransactionModal itemId={selected?.id} coopId={activeCoop?.id} type={type} />
            <DenyTransactionModal item={selected?.id} type={type} />

        </div>
    )
}