import React, { Component } from 'react';
import { connect } from 'react-redux';
import CountUp from 'react-countup';
import Barchart from '../../common/barchart';
import { Link } from 'react-router-dom';


class EShopDashboard extends Component {

	render() {
		const { fixNavbar } = this.props;
		return (
			<>
				<div className={`section-body ${fixNavbar ? "marginTop" : ""} mt-3`}>
					<div className="container-fluid">
						<div className="row clearfix eshop-dashboard">
							<div className="col-lg-12">
								<div className={`mb-4`}>
									<h4>Good Evening! Jason Poter</h4>
									{/* <small>
										John Doe
									</small> */}
								</div>
								<div className="mb-20">
									<h4 className="font-semibold text-xl mb-3 capitalize text-heading">Overview</h4>
									<div className="row">
										<div className="col-3 sm:col-6 xl:col-3">
											<div className="bg-admin-pink p-4 rounded-lg d-flex align-items-center gap-4">
												<div className="w-12 h-12 rounded-full d-flex align-items-center justify-content-center bg-white">
													<i className="fe fe-trending-up text-admin-pink text-2xl lab-font-size-24"></i>
												</div>
												<div>
													<h3 className="font-small tracking-wide capitalize text-white">Total Earnings</h3>
													<h4 className="font-semibold text-[22px] leading-[34px] text-white">100</h4>
												</div>
											</div>
										</div>
										<div className="col-3 sm:col-6 xl:col-3">
											<div className="bg-admin-orange p-4 rounded-lg d-flex align-items-center gap-4">
												<div className="w-12 h-12 rounded-full d-flex align-items-center justify-content-center bg-white">
													<i className="fa fa-cube text-admin-orange text-2xl lab-font-size-24"></i>
												</div>
												<div>
													<h3 className="font-small tracking-wide capitalize text-white">Total Orders</h3>
													<h4 className="font-semibold text-[22px] leading-[34px] text-white">90</h4>
												</div>
											</div>
										</div>
										<div className="col-3 sm:col-6 xl:col-3">
											<div className="bg-admin-purple p-4 rounded-lg d-flex align-items-center gap-4">
												<div className="w-12 h-12 rounded-full d-flex align-items-center justify-content-center bg-white">
													<i className="icon-users text-admin-purple text-2xl lab-font-size-24"></i>
												</div>
												<div>
													<h3 className="font-small tracking-wide capitalize text-white">Total Customers</h3>
													<h4 className="font-semibold text-[22px] leading-[34px] text-white">200</h4>
												</div>
											</div>
										</div>
										<div className="col-3 sm:col-6 xl:col-3">
											<div className="bg-admin-blue p-4 rounded-lg d-flex align-items-center gap-4">
												<div className="w-12 h-12 rounded-full d-flex align-items-center justify-content-center bg-white">
													<i className="fe fe-file-text text-admin-blue text-2xl lab-font-size-24"></i>
												</div>
												<div>
													<h3 className="font-small tracking-wide capitalize text-white">Total Products</h3>
													<h4 className="font-semibold text-[22px] leading-[34px] text-white">500</h4>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="d-flex align-items-center justify-content-between gap-3 mb-4">
									<h4 className="font-semibold text-xl capitalize text-heading">Order Statistics</h4>
									<div className="relative cursor-pointer">
										<div className="dp__main dp__theme_light" data-datepicker-instance="">
											<div>
												<div className="dp__input_wrap">
													<input className="form-control dp__pointer dp__input_readonly dp__input dp__input_icon_pad dp__input_reg shopperz-input box" inputmode="none" placeholder="Select Date Range" autocomplete="off" aria-label="Datepicker input" />
													<div>
														<span className="dp__input_icon">
															<i className="lab-line-calendar"></i>
															<i className="lab-line-chevron-down"></i>
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className='row mb-10'>
									<div className="col-3 sm:col-6 md:col-4 lg:col-6 xl:col-3 p-2">
										<div className="d-flex align-items-center gap-4 p-4 rounded-lg shadow-xs bg-white">
											<div className="bg-admin-orange/10 w-12 h-12 rounded-full d-flex align-items-center justify-content-center">
												<i className="fa fa-cube text-admin-orange text-2xl lab-font-size-24"></i>
											</div>
											<div>
												<h3 className="font-medium text-sm capitalize tracking-wide mb-1">Total Orders</h3>
												<h4 className="font-bold text-lg text-secondary">80</h4>
											</div>
										</div>
									</div>
									<div className="col-3 sm:col-6 md:col-4 lg:col-6 xl:col-3 p-2">
										<div className="d-flex align-items-center gap-4 p-4 rounded-lg shadow-xs bg-white">
											<div className="bg-admin-orange/10 w-12 h-12 rounded-full d-flex align-items-center justify-content-center">
												<i className="fa fa-cube text-admin-orange text-2xl lab-font-size-24"></i>
											</div>
											<div>
												<h3 className="font-medium text-sm capitalize tracking-wide mb-1">Pending</h3>
												<h4 className="font-bold text-lg text-secondary">80</h4>
											</div>
										</div>
									</div>
									<div className="col-3 sm:col-6 md:col-4 lg:col-6 xl:col-3 p-2">
										<div className="d-flex align-items-center gap-4 p-4 rounded-lg shadow-xs bg-white">
											<div className="bg-admin-orange/10 w-12 h-12 rounded-full d-flex align-items-center justify-content-center">
												<i className="fa fa-cube text-admin-orange text-2xl lab-font-size-24"></i>
											</div>
											<div>
												<h3 className="font-medium text-sm capitalize tracking-wide mb-1">Confirmed</h3>
												<h4 className="font-bold text-lg text-secondary">80</h4>
											</div>
										</div>
									</div>
									<div className="col-3 sm:col-6 md:col-4 lg:col-6 xl:col-3 p-2">
										<div className="d-flex align-items-center gap-4 p-4 rounded-lg shadow-xs bg-white">
											<div className="bg-admin-orange/10 w-12 h-12 rounded-full d-flex align-items-center justify-content-center">
												<i className="fa fa-cube text-admin-orange text-2xl lab-font-size-24"></i>
											</div>
											<div>
												<h3 className="font-medium text-sm capitalize tracking-wide mb-1">Ongoing</h3>
												<h4 className="font-bold text-lg text-secondary">80</h4>
											</div>
										</div>
									</div>
									<div className="col-3 sm:col-6 md:col-4 lg:col-6 xl:col-3 p-2">
										<div className="d-flex align-items-center gap-4 p-4 rounded-lg shadow-xs bg-white">
											<div className="bg-admin-orange/10 w-12 h-12 rounded-full d-flex align-items-center justify-content-center">
												<i className="fa fa-cube text-admin-orange text-2xl lab-font-size-24"></i>
											</div>
											<div>
												<h3 className="font-medium text-sm capitalize tracking-wide mb-1">Delivered</h3>
												<h4 className="font-bold text-lg text-secondary">80</h4>
											</div>
										</div>
									</div>
									<div className="col-3 sm:col-6 md:col-4 lg:col-6 xl:col-3 p-2">
										<div className="d-flex align-items-center gap-4 p-4 rounded-lg shadow-xs bg-white">
											<div className="bg-admin-orange/10 w-12 h-12 rounded-full d-flex align-items-center justify-content-center">
												<i className="fa fa-cube text-admin-orange text-2xl lab-font-size-24"></i>
											</div>
											<div>
												<h3 className="font-medium text-sm capitalize tracking-wide mb-1">Cancelled</h3>
												<h4 className="font-bold text-lg text-secondary">80</h4>
											</div>
										</div>
									</div>
									<div className="col-3 sm:col-6 md:col-4 lg:col-6 xl:col-3 p-2">
										<div className="d-flex align-items-center gap-4 p-4 rounded-lg shadow-xs bg-white">
											<div className="bg-admin-orange/10 w-12 h-12 rounded-full d-flex align-items-center justify-content-center">
												<i className="fa fa-cube text-admin-orange text-2xl lab-font-size-24"></i>
											</div>
											<div>
												<h3 className="font-medium text-sm capitalize tracking-wide mb-1">Returned</h3>
												<h4 className="font-bold text-lg text-secondary">80</h4>
											</div>
										</div>
									</div>
									<div className="col-3 sm:col-6 md:col-4 lg:col-6 xl:col-3 p-2">
										<div className="d-flex align-items-center gap-4 p-4 rounded-lg shadow-xs bg-white">
											<div className="bg-admin-orange/10 w-12 h-12 rounded-full d-flex align-items-center justify-content-center">
												<i className="fa fa-cube text-admin-orange text-2xl lab-font-size-24"></i>
											</div>
											<div>
												<h3 className="font-medium text-sm capitalize tracking-wide mb-1">Rejected</h3>
												<h4 className="font-bold text-lg text-secondary">80</h4>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className='col-lg-6'>
								<div className='card'>
									<div className='card-header'>Sales Summary</div>
									<div className='card-body'></div>
								</div>
							</div>
							<div className='col-lg-6'>
								<div className='card'>
									<div className='card-header'>Orders Summary</div>
									<div className='card-body'></div>
								</div>
							</div>
							<div className='col-lg-12'>
								<div className='card'>
									<div className='card-header'>Top Products</div>
									<div className='card-body'></div>
								</div>
							</div>
						</div>	
					</div>
				</div>
			</>
		);
	}
}
const mapStateToProps = state => ({
	fixNavbar: state.settings.isFixNavbar
})

const mapDispatchToProps = dispatch => ({})
export default connect(mapStateToProps, mapDispatchToProps)(EShopDashboard);