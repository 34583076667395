import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useLazyGetAllWithdrawalsQuery } from '../../services/member.service';
import { DatePicker, Empty, Tooltip } from 'antd';
import InitialsAvatar from 'react-initials-avatar';
import { formatNumber } from '../../utils/helpers';
import dayjs from "dayjs";
import ApproveTransactionModal from '../common/ApproveTransactionModal';
import DenyTransactionModal from '../common/DenyTransactionModal';
import Card from '../common/Card';
import { NavLink } from 'react-router-dom';
import { Pagination } from '../Pagination/Pagination';
var advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)

function Withdrawals () {
    const { fixNavbar } = useSelector(state => state.settings);
    const {activeCoop} = useSelector(state => state.coop);
    const [selectedItem, setSelected] = useState(null);
    const [getResults, {data, isFetching}] = useLazyGetAllWithdrawalsQuery(activeCoop?.id);
    const [filterData, setFilterData] = useState({
        coopId: activeCoop?.id,
        page: 1,
        start: '',
        end: ''
    })
    const [pagination, setPagination] = useState({
        total: 0,
        perPage: 0,
        currentPage: 1,
        first: 1,
        last: 1
    });

    useEffect(() => {
        if (activeCoop) getResults(filterData);

    }, [activeCoop]);

    useEffect(() => {
        if (data?.status) {

            setPagination({
                total: data?.data?.meta?.total,
                perPage: data?.data?.meta?.per_page,
                currentPage: data?.data?.meta?.current_page,
                first: data.data.meta?.first_page,
                last: data.data.meta?.last_page
            })
        }
    }, [data]);

    const changeDateField = (e) => {
        const value = e.target.value;
        // let start = dayjs(), end = dayjs();
        switch (value) {
            case 'current-month':
                setFilterData({
                    ...filterData,
                    start: dayjs().startOf('M').format('YYYY-MM-DD'),
                    end: dayjs().format('YYYY-MM-DD')
                })
                break;
            case 'last-month':
                setFilterData({
                    ...filterData,
                    start: dayjs().subtract(1, 'M').startOf('M').format('YYYY-MM-DD'),
                    end: dayjs().subtract(1, 'M').endOf('M').format('YYYY-MM-DD')
                })
                break;
            case 'last-30-days':
                setFilterData({
                    ...filterData,
                    start: dayjs().subtract(30, 'days').format('YYYY-MM-DD'),
                    end: dayjs().format('YYYY-MM-DD')
                })
                break;
            case 'current-year':
                setFilterData({
                    ...filterData,
                    start: dayjs().startOf('y').format('YYYY-MM-DD'),
                    end: dayjs().format('YYYY-MM-DD')
                })
                break;
            case 'last-year':
                setFilterData({
                    ...filterData,
                    start: dayjs().subtract(1, 'y').startOf('y').format('YYYY-MM-DD'),
                    end: dayjs().subtract(1, 'y').endOf('y').format('YYYY-MM-DD')
                })
                break;
            default:
                setFilterData({
                    ...filterData,
                    start: '',
                    end: ''
                })
                break;
        }
    }

    return (
        <>
            <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-3 col-md-4 col-sm-6">
                                    <div className="form-group">
                                        <select className="form-control show-tick" onChange={changeDateField}>
                                            <option value="">Filter By Date</option>
                                            <option value="current-month">Current Month</option>
                                            <option value="last-month">Last Month</option>
                                            <option value="last-30-days">Last 30 Days</option>
                                            <option value="current-year">Current Year</option>
                                            <option value="last-year">Last Year</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6">
                                    <DatePicker
                                        className={`customInput w-full`} 
                                        name={'start'} 
                                        placeholder='Start Date'
                                        style={{width: '100%'}}
                                        value={filterData.start !== '' ? dayjs(filterData.start) : undefined} 
                                        onChange={(value) => setFilterData({...filterData, start: dayjs(value).format('YYYY-MM-DD')})}
                                    />
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6">
                                    <DatePicker
                                        className={`customInput w-full`} 
                                        name={'start'} 
                                        placeholder='End Date'
                                        style={{width: '100%'}}
                                        value={filterData.end !== '' ? dayjs(filterData.end) : undefined} 
                                        onChange={(value) => setFilterData({...filterData, end: dayjs(value).format('YYYY-MM-DD')})}
                                    />
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6">
                                    <a href="#" className="btn btn-sm btn-primary btn-block" onClick={() => getResults(filterData)}>Search</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`section-body`}>
                <div className="container-fluid">
                    <Card 
                        loading={isFetching} 
                        title={`Results`} 
                        headerOptions={
                            <div className="card-options">
                                <span className="card-options-remove text-white">Bulk Actions</span>
                                <div className="item-action dropdown ml-2">
                                    <a href="fake_url" data-toggle="dropdown"><i className="fe fe-more-vertical" /></a>
                                    <div className="dropdown-menu dropdown-menu-right">
                                        <a href="fake_url" className="dropdown-item text-info"><i className="dropdown-icon fa fa-eye" /> Approve </a>
                                        <a href="fake_url" className="dropdown-item text-info"><i className="dropdown-icon fa fa-share-alt" /> Reject </a>
                                    </div>
                                </div>
                            </div>
                        }
                    >
                        <div className="table-responsive">
                            <table className="table table-hover table-striped table-vcenter text-nowrap mb-0">
                                <thead>
                                    <tr>
                                        <th className="w40">
                                            <label className="custom-control custom-checkbox">
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    name="example-checkbox1"
                                                    defaultValue="option1"
                                                />
                                                <span className="custom-control-label">
                                                    &nbsp;
                                                </span>
                                            </label>
                                        </th>
                                        <th>Member</th>
                                        <th>Amount</th>
                                        <th>Date</th>
                                        <th>Payment Method</th>
                                        {/* <th>Description</th> */}
                                        <th>Status</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                {data?.data?.data.map(row =>

                                    <tr key={row.id}>
                                        <td className="w40">
                                            <label className="custom-control custom-checkbox">
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    name="example-checkbox1"
                                                    defaultValue="option1"
                                                />
                                                <span className="custom-control-label">
                                                    &nbsp;
                                                </span>
                                            </label>
                                        </td>
                                        <td className="d-flex">
                                            <span
                                                className="avatar avatar-blue"
                                                data-toggle="tooltip"
                                                data-original-title="Avatar Name"
                                            >
                                                <InitialsAvatar name={`${row.member.firstname} ${row.member.lastname}`} />
                                            </span>
                                            <NavLink to={`/members/view?member_id=${row.member.id}`} className="ml-3">
                                                <h6 className="mb-0">{row.member.firstname} {row.member.lastname}</h6>
                                                <span className="text-muted">
                                                    {row.coop_id}
                                                </span>
                                            </NavLink>
                                        </td>
                                        <td><span>{formatNumber(row.amount)}</span></td>
                                        <td>
                                        {dayjs(row.created_at).format('Do MMM YYYY, hh:mma')}
                                        </td>
                                        <td>{row.payment_mode.name}</td>
                                        {/* <td>{row.description}</td> */}
                                        <td>
                                            {row.status === 0 && <span className='tag tag-warning'>Pending</span>}
                                            {row.status === 1 && <span className='tag tag-success'>Approved</span>}
                                            {row.status === 2 && <span className='tag tag-danger'>Denied</span>}
                                        </td>
                                        <td>
                                            {row.status === 0 &&
                                            <Tooltip title="Approve">
                                                <button type="button" className="btn btn-icon" onClick={() => setSelected(row.id)} data-target="#approveModal" data-toggle="modal">
                                                    <i className="fe fe-check-circle text-info" />
                                                </button>
                                            </Tooltip> }
                                            {row.status === 0 &&
                                            <Tooltip title="Deny">
                                                <button type="button" className="btn btn-icon" onClick={() => setSelected(row.id)} data-target="#denyModal" data-toggle="modal">
                                                    <i className="fe fe-x-circle text-danger" />
                                                </button>
                                            </Tooltip>}
                                        </td>
                                    </tr>
                                )}
                                    {data?.data.meta.total === 0 && <tr><td colSpan={8}><Empty /></td></tr>}

                                    
                                </tbody>
                            </table>
                        </div>
                        {pagination.last > 1 && 
                            <Pagination 
                                {...pagination} 
                                changePage={(page) => {
                                    getResults({
                                        coopId: activeCoop?.id,
                                        page,
                                        search: filterData.search,
                                    })
                                    setFilterData({...filterData, page});
                                }} 
                            />
                        }
                    </Card>
                </div>
            </div>
            <ApproveTransactionModal itemId={selectedItem} coopId={activeCoop?.id} />
            <DenyTransactionModal item={selectedItem} type="transaction" />
        </>
    )
}

export default Withdrawals;