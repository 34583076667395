
import React, { useState } from "react";
import CategoryList from "./CategoryList";
import CategoryForm from "./CategoryForm";

const ProductCategory = ({coop, categories, loading}) => {

    const [category, setCategory] = useState(null);
    
    return (
        <div className="row">
            <div className="col-md-6 col-sm-12">
                <CategoryList coop={coop} loading={loading} data={categories} onEdit={(val) => setCategory(val)} />
            </div>
            <div className="col-md-6 col-sm-12">
                <CategoryForm coop={coop} category={category} onCancel={() => setCategory(null)} />
            </div>
        </div>
    )
}

export default ProductCategory;