import React, { Component } from 'react'
import { connect } from 'react-redux';

class DividendCalculator extends Component {

    render() {
        const { fixNavbar } = this.props;
        return (
            <>
                <div>
                    <div className={`section-body mt-3 ${fixNavbar ? "marginTop" : ""}`}>
                
                        <div className="container-fluid">
                            <div className='card'>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-4 py-1"><strong>Total Revenue</strong></div>
                                        <div className="col-8 py-1">N45,000</div>
                                        <div className="col-4 py-1"><strong>Reserves:</strong></div>
                                        <div className="col-8 py-1"></div>
                                        <div className="col-4 py-1"><strong>Admin Fee</strong></div>
                                        <div className="col-8 py-1"><strong></strong></div>
                                        <div className="col-4 py-1"><strong>Net Revenue:</strong></div>
                                        <div className="col-8 py-1">340,000</div>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-bordered table-vcenter mb-0">
                                        <thead>
                                            <th></th>
                                            <th>Savings</th>
                                            <th>Loan</th>
                                            <th>Other Criteria</th>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Sharing ration</td>
                                                <td>50%</td>
                                                <td>40%</td>
                                                <td>10%</td>
                                            </tr>
                                            <tr>
                                                <td>Sharing Amount</td>
                                                <td>N78,900</td>
                                                <td>N68,0000</td>
                                                <td>N90,430</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                            <div className="card">
                                <div className="">
                                    <div className="table-responsive">
                                        <table className="table table-hover table-bordered table-striped table-vcenter mb-0">
                                            <thead>
                                                <tr>
                                                    <th>S/N</th>
                                                    <th>Name</th>
                                                    <th>Savings</th>
                                                    <th>Personal Loans</th>
                                                    <th>Other Criteria</th>
                                                    <th>Savings</th>
                                                    <th>Personal Loans</th>
                                                    <th>Other Criteria</th>
                                                    <th>Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>John Doe</td>
                                                    <td></td>
                                                    <td className='text-center'></td>
                                                    <td className='text-center'></td>
                                                    <td className='text-center'></td>
                                                    <td className='text-center'></td>
                                                    <td className='text-center'></td>
                                                    <td className='text-center'></td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>Jane Doe</td>
                                                    <td></td>
                                                    <td className='text-center'></td>
                                                    <td className='text-center'></td>
                                                    <td className='text-center'></td>
                                                    <td className='text-center'></td>
                                                    <td className='text-center'></td>
                                                    <td className='text-center'></td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>John Dash</td>
                                                    <td></td>
                                                    <td className='text-center'></td>
                                                    <td className='text-center'></td>
                                                    <td className='text-center'></td>
                                                    <td className='text-center'></td>
                                                    <td className='text-center'></td>
                                                    <td className='text-center'></td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>David Doe</td>
                                                    <td></td>
                                                    <td className='text-center'></td>
                                                    <td className='text-center'></td>
                                                    <td className='text-center'></td>
                                                    <td className='text-center'></td>
                                                    <td className='text-center'></td>
                                                    <td className='text-center'></td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>Mary Doe</td>
                                                    <td></td>
                                                    <td className='text-center'></td>
                                                    <td className='text-center'></td>
                                                    <td className='text-center'></td>
                                                    <td className='text-center'></td>
                                                    <td className='text-center'></td>
                                                    <td className='text-center'></td>
                                                </tr>
                                                
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar
})

const mapDispatchToProps = dispatch => ({})
export default connect(mapStateToProps, mapDispatchToProps)(DividendCalculator);