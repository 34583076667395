import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import * as Yup from "yup";
// import { useDispatch } from "react-redux";
import alertify from "alertifyjs";
import { useSaveCoaCategoryMutation, useUpdateCoaCategoryMutation } from "../../../../services/cooperative-setup.service";

export const FormSchema = Yup.object().shape({
    name: Yup.string().required('Please enter the name'),
    code: Yup.string().required('Please enter a code'),
    type: Yup.string().required('Select a type'),
    status: Yup.string().required('Select status'),
});

const CoaCatForm = ({category, onCancel, coop}) => {

    const [save, {isLoading, isSuccess, isError, data, error}] = useSaveCoaCategoryMutation();
    const [update, {
        isLoading: isUpdateLoading, 
        isSuccess: isUpdateSuccess, 
        isError: isUpdateError, 
        data: updateData, 
        error: updateError
    }] = useUpdateCoaCategoryMutation();

    const {
        control,
        reset,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(FormSchema),
        mode: 'onBlur'
    });

    useEffect(() => {
        if (category) {
            reset({
                name: category.name,
                code: category.code,
                type: category.type,
                status: category.status,
                id: category.id,
            })
        }
    }, [category]);


    const onSubmit = (values) => {
        if (coop) values.cooperative_id = coop?.id

        if (category) {
            update(values);
        } else {
            save(values);
        }
    }

    useEffect(() => {
        if(isSuccess || isUpdateSuccess) {
            resetForm();
            // show toast.
            alertify.success(data?.message || updateData?.message)
        }

        if (isError || isUpdateError) {
            alertify.error(error?.data?.message || updateError?.data?.message);
        }
    }, [isSuccess, isUpdateSuccess, isUpdateError, isError, data, updateData])

    const resetForm = () => {
        reset({
          name: "",
          code: "",
          type: "",
          status: "",
          id: "",
        })
    }

    const doCancel = () => {
        resetForm();
        onCancel();
    }

    return (
        <div className={`card dimmer ${(isLoading || isUpdateLoading) ? 'active' : ''}`} >
            <div className="card-header">
                <h3 className="card-title">Add/Edit COA Category</h3>
            </div>
            <div className="loader" />
            <form onSubmit={handleSubmit(onSubmit)} className="card-body dimmer-content">
                <div className="row clearfix">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="form-group">
                            <Controller
                                name="type"
                                control={control}
                                render={({ field }) => (
                                    <select 
                                        onChange={field.onChange}
                                        value={field.value}
                                        className={`form-control ${errors?.type ? "is-invalid" : ""}`}
                                    >
                                        <option value=''>Select Type</option>
                                        <option value='D'>Dr</option>
                                        <option value='C'>Cr</option>
                                    </select>
                                )}
                            />
                            {errors.type && <div className="invalid-feedback">{errors.type?.message}</div>}
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12">
                        <div className="form-group">
                            <Controller
                                name="code"
                                control={control}
                                render={({ field }) => (
                                    <input
                                        className={`form-control ${
                                        errors?.code ? "is-invalid" : ""
                                        }`}
                                        type="text"
                                        placeholder="Code"
                                        onChange={field.onChange}
                                        value={field.value}
                                        autoComplete="true"
                                    />
                                )}
                            />
                            {errors.code && <div className="invalid-feedback">{errors.code?.message}</div>}
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-12">
                        <div className="form-group">
                            <Controller
                                name="name"
                                control={control}
                                render={({ field }) => (
                                    <input
                                        className={`form-control ${
                                        errors?.name ? "is-invalid" : ""
                                        }`}
                                        type="text"
                                        placeholder="Name"
                                        onChange={field.onChange}
                                        value={field.value}
                                        autoComplete="true"
                                    />
                                )}
                            />
                            {errors.name && <div className="invalid-feedback">{errors.name?.message}</div>}
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="form-group">
                            <Controller
                                name="status"
                                control={control}
                                render={({ field }) => (
                                    <select 
                                        onChange={field.onChange}
                                        value={field.value}
                                        className={`form-control ${errors?.status ? "is-invalid" : ""}`}
                                    >
                                        <option value=''>Select Status</option>
                                        <option value='1'>Active</option>
                                        <option value='0'>Inactive</option>
                                    </select>
                                )}
                            />
                            {errors.status && <div className="invalid-feedback">{errors.status?.message}</div>}
                        </div>
                    </div>
                    <div className="col-12">
                        <button type="submit" className="btn btn-primary mr-3">
                            SAVE
                        </button>
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={doCancel}
                        >
                            CANCEL
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default CoaCatForm;