import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useLazyMemberBalanceReportQuery } from '../../services/utility.service';
import { doDownload, formatNumber } from '../../utils/helpers';
import dayjs from 'dayjs';
import { MEMBER_BALANCE } from '../../services/CONSTANTS';

const MembersAccountBalanceReport = () => {
    const {isFixNavbar: fixNavbar} = useSelector(state => state.settings);
    const {activeCoop} = useSelector(state => state.coop);
    const [search, setSearch] = useState('')
    const [isDownloading, setIsDownloading] = useState(false);
    const {token} = useSelector(state => state.user);

    const [getResults, {data, isFetcing, isError} ]= useLazyMemberBalanceReportQuery(activeCoop?.id, {skip: !activeCoop});

    useEffect(() => {
        getResults({
            id: activeCoop?.id,
            download: false,
            query: search
        })
    }, []);

    const download = async () => {
        setIsDownloading(true);
        const url = `${process.env.REACT_APP_API_URL}${MEMBER_BALANCE}?cooperative_id=${activeCoop?.id}&download=true&query=${search}`
        await doDownload(url, `${activeCoop?.name}-member-account-balance-${dayjs().unix()}.xlsx`, token);
        setIsDownloading(false)
    }
    return (
       
        <div className={`section-body mt-3 ${fixNavbar ? "marginTop" : ""}`}>
            
            <div className="container-fluid">
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <ul className="nav nav-tabs page-header-tab">
                        {/* <li className="nav-item"><a className="nav-link active" id="Resumes-tab" data-toggle="tab" href="#Resumes-list">List View</a></li>
                        <li className="nav-item"><a className="nav-link" id="Resumes-tab" data-toggle="tab" href="#Resumes-grid">Grid View</a></li> */}
                    </ul>
                    <div className="header-action d-md-flex">
                        <div className="input-group mr-2">
                            <input 
                                type="text" 
                                className="form-control" 
                                onChange={(e) => setSearch(e.target.value)} 
                                value={search}
                                placeholder="Search..." 
                            />
                        </div>
                        <button type="button" className="btn btn-primary mr-2" onClick={() => getResults({id: activeCoop?.id, query: search, download: false})}>
                            <i className="fe fe-search mr-2" />Search
                        </button>
                        <button type="button" className="btn btn-secondary btm-sm" onClick={download}>
                            {isDownloading ?  <i className="fa fa-spinner fa-spin mr-2" />:  <i className="fe fe-download mr-2" />}
                            Download
                        </button>
                    </div>
                </div>
                <div className={`card dimmer ${isFetcing ? 'active' : '' }`}>
                    <div className="card-body dimmer-content p-0">
                        <div className='loader' />
                        <div className="table-responsive">
                            <table className="table table-hover table-bordered table-striped table-vcenter mb-0">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Name</th>
                                        <th>Membership No.</th>
                                        <th>Savings</th>
                                        <th>Target Saving</th>
                                        <th>Loan</th>
                                        <th>Loan+Intr.</th>
                                        <th>Other Debits</th>
                                        <th>Share Capital</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.data.map(row =>
                                    <tr key={`member-balance-${row.id}`}>
                                        <td>{row.id}</td>
                                        <td>{row.firstname} {row.lastname}</td>
                                        <td>{row.coop_id}</td>
                                        <td className='text-center'>{formatNumber(row.meta.totalSavingsBalance)}</td>
                                        <td className='text-center'>{formatNumber(row.meta.totalTargetBalance)}</td>
                                        <td className='text-center'>{formatNumber(row.meta.totalLoanAmount)}</td>
                                        <td className='text-center'>{formatNumber(row.meta.totalLoanInterest)}</td>
                                        <td className='text-center'>0.00</td>
                                        <td className='text-center'>0.00</td>
                                    </tr>)}
                                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>               
    )
}

export default MembersAccountBalanceReport;