import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import * as Yup from "yup";
import alertify from "alertifyjs";
import { filterOption } from "../../../utils/helpers";
import { Select } from "antd";
import { useCreateTransactionOrderMutation } from "../../../services/member.service";
import { useGetPaymentModesQuery } from "../../../services/utility.service";


const FormSchema = Yup.object().shape({
    savings_id: Yup.string().required('Please select account'),
    amount: Yup.string().required('This field is required'),
    // date: Yup.string().required('This field is required'),
    payment_mode_id: Yup.string().required('This field is required'),
});

export const WithdrawalModal = ({savings, coop_id, cooperative_id}) => {
    const [save, {isLoading, isSuccess, isError, data, error}] = useCreateTransactionOrderMutation();

    const {data: paymentModes} = useGetPaymentModesQuery();

    const {
        control,
        reset,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(FormSchema),
        mode: 'onBlur'
    });

    const onSubmit = (values) => {
        const payload = {...values};
        payload.coop_id = coop_id;
        payload.cooperative_id = cooperative_id;
        // payload.date = dayjs(values.date).format('YYYY-MM-DD');
        payload.transaction_type = 'WITHDRAWAL';
        save(payload);
    }

    useEffect(() => {
        if(isSuccess) {
            resetForm();
            // show toast.
            alertify.success(data?.message)
        }

        if (isError) {
            alertify.error(error?.data?.message);
        }
    }, [isSuccess, isError, data])

    const resetForm = () => {
        reset({
            savings_id: "",
            amount: "",
            payment_mode_id: "",
            comment: ""
        })
    }

    const doCancel = () => {
        resetForm();
    }

    return (
        <div className="modal fade" id="withdrawal-modal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <form onSubmit={handleSubmit(onSubmit)} className={`modal-content dimmer ${isLoading ? 'active' : ''}`}>
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Withdrawal Form</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                    </div>
                    <div className="loader" />
                    <div className="modal-body dimmer-content">
                        <div className="row clearfix">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Account <span className="form-required">*</span></label>
                                    <Controller
                                        name="savings_id"
                                        control={control}
                                        render={({ field }) => (
                                            <Select 
                                                showSearch
                                                className="customSelect mt-1"
                                                options={savings?.map(saving => ({value: saving.id, label: saving.product.product.name}))} 
                                                placeholder="Select Savings Account"
                                                filterOption={filterOption}
                                                value={field.value}
                                                onChange={field.onChange}
                                            />
                                            
                                        )}
                                    />
                                    {errors.savings_id && <div className="invalid-feedback">{errors.savings_id?.message}</div>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Amount <span className="form-required">*</span></label>
                                    <Controller
                                        name="amount"
                                        control={control}
                                        render={({ field }) => (
                                            <input
                                                className={`form-control ${
                                                errors?.amount ? "is-invalid" : ""
                                                }`}
                                                type="text"
                                                onChange={field.onChange}
                                                value={field.value}
                                                autoComplete="true"
                                            />
                                        )}
                                    />
                                    {errors.amount && <div className="invalid-feedback">{errors.amount?.message}</div>}
                                </div>
                            </div>
                            
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Payment Mode <span className="form-required">*</span></label>
                                    <Controller
                                        name="payment_mode_id"
                                        control={control}
                                        render={({ field }) => (
                                            <Select 
                                                showSearch
                                                className="customSelect mt-1"
                                                options={paymentModes?.data?.map(item => ({value: item.id, label: item.name}))} 
                                                placeholder="Select Payment Mode"
                                                filterOption={filterOption}
                                                value={field.value}
                                                onChange={field.onChange}
                                            />
                                            
                                        )}
                                    />
                                    {errors.payment_mode_id && <div className="invalid-feedback">{errors.payment_mode_id?.message}</div>}
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" onClick={doCancel} className="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    )
}