import React, { useEffect, useState } from "react";
import { useDeleteContestantMutation, useGetContestantByPositionQuery, useGetPositionsByElectionQuery } from "../../../services/election.service";
import { Empty, Select, Tabs, Tooltip } from "antd";
import InitialsAvatar from 'react-initials-avatar';
import { ContestantForm } from "./ContestantForm";
import { filterOption } from "../../../utils/helpers";

export const ManageContestant = ({election, coopId}) => {
    const [contestant, setContestant] = useState(null);
    const [contestants, setContestants] = useState([]);
    const [positionId, setPositionId] = useState(null);
    const [activeTab, setActiveTab] = useState("1");
    const [deleteContestant, {}] = useDeleteContestantMutation();

    const {data: positionData} = useGetPositionsByElectionQuery({
        coopId, 
        electionId: election?.id
    }, {
        skip: !election,
        refetchOnMountOrArgChange: true,
    });

    const {data, isFetching} = useGetContestantByPositionQuery({
        coopId, position: positionId
    }, {
        skip: !positionId
    });

    const doDelete = (id) => {
        deleteContestant({coopId, id});
    }

    useEffect(() => {
        if (data) setContestants(data?.data);
    }, [data])

    return (
        <div className="modal fade" id="contestants_modal" tabIndex={-1} role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className={`modal-content dimmer ${isFetching ? 'active' : ''}`}>
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Manage Contestants for {election?.name}</h5>
                        <button type="button" id="election-modal" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body dimmer-content">
                        <div className="loader" />
                        <Tabs 
                            activeKey={activeTab} 
                            items={[
                                {
                                    label: 'Contestants',
                                    key: '1',
                                    children: (
                                        <div className="row">
                                            <div className="col-md-12">
                                                <Select 
                                                    showSearch
                                                    className="customSelect mt-1"
                                                    options={positionData?.data?.map(position => ({label: position.name, value: position.id}))} 
                                                    placeholder="Select Position"
                                                    filterOption={filterOption}
                                                    value={positionId}
                                                    onChange={(value) => setPositionId(value)}
                                                />
                                            </div>
                                            <div className="table-responsive todo_list">
                                                <table className="table table-hover table-striped table-vcenter mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th>Contestant</th>
                                                            <th>COOP ID</th>
                                                            <th className="w100"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {contestants.map(row =>
                                                            <tr key={`contestant-p-${row.id}`}>
                                                                <td>
                                                                    <span
                                                                        className="avatar avatar-blue w30 rounded-circle mr-2"
                                                                        data-toggle="tooltip"
                                                                        data-original-title="Avatar Name"
                                                                    >
                                                                        <InitialsAvatar name={`${row.member.firstname} ${row.member.lastname}`} />
                                                                    </span>
                                                                    <span>{row.member.firstname} {row.member.lastname}</span>
                                                                </td>
                                                                <td>{row.coop_id}</td>
                                                                <td>
                                                                    <div className="btn-group btn-sm">
                                                                        <Tooltip title="Edit">
                                                                            <button type="button" className="btn btn-icon btn-sm btn-sm" onClick={() => {
                                                                                setContestant(row);
                                                                                setActiveTab("2")
                                                                            }}>
                                                                                <i className="icon-pencil text-info" />
                                                                            </button>
                                                                        </Tooltip>
                                                                        <Tooltip title="Delete">
                                                                            <button type="button" 
                                                                                className="btn btn-icon btn-sm"
                                                                                // data-toggle="modal" 
                                                                                // data-target="#deleteModal" 
                                                                                onClick={(e) => doDelete(row.id)}
                                                                            >
                                                                                <i className="icon-trash text-danger" />
                                                                            </button>
                                                                        </Tooltip>
                                                                    </div>
                                                                </td>

                                                            </tr>
                                                        )}
                                                        {contestants.length === 0 && <tr><td colSpan={3}><Empty /></td></tr>}
                                                        
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    )
                                }, {
                                    label: contestant ? 'Edit Contestant' : 'Add Contestant',
                                    key: '2',
                                    children: (
                                        <ContestantForm 
                                            item={contestant} 
                                            electionId={election?.id} 
                                            coopId={coopId} 
                                            onCancel={() => setContestant(null)} 
                                            onSave={() => setActiveTab('1')}
                                            positions={positionData?.data}
                                        />
                                    )
                                }
                            ]}
                            onChange={(key) => setActiveTab(key)}
                        />

                    </div>
                </div>
            </div>
        </div>
    )
}