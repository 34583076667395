import React, { useEffect, useState } from "react";
import { useGetAllCoaQuery, useGetChargesQuery, useGetProductCategoriesQuery, useGetProductsByCatQuery, useSaveLoansMutation, useUpdateLoanMutation } from "../../../../services/cooperative-setup.service";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import * as Yup from "yup";
// import { useDispatch } from "react-redux";
import alertify from "alertifyjs";
import { filterOption } from "../../../../utils/helpers";
import { Select } from "antd";
import { useGetCalcMethodsQuery } from "../../../../services/utility.service";

const FormSchema = Yup.object().shape({
    product_id: Yup.string().required('Please select product type'),
    name: Yup.string().required('This field is required'),
    tenure: Yup.string().required('This field is required'),
    tenure_type: Yup.string().required('This field is required'),
    min_int_rate: Yup.string().required('This field is required'),
    max_int_rate: Yup.string().required('This field is required'),
    charge_id: Yup.string().required('This field is required'),
    charge_amount: Yup.string().required('This field is required'),
    principal_gl: Yup.string().required('Select Principal GL'),
    interest_receivable_gl: Yup.string().required('Select member balance GL'),
    loan_write_off_gl: Yup.string().required('Select GL account'),
    source_account: Yup.string().required('Select GL account'),
    calculation_method_id: Yup.string().required('This field is required'),
    status: Yup.string().required('Select status'),
});

const LoanSetupForm = ({item, onCancel, coop}) => {
    const [catId, setCatID] = useState(null);
    const {data: postingGlData} = useGetAllCoaQuery(coop?.id);
    const {data: categories} = useGetProductCategoriesQuery(coop?.id);
    const {data: productsData} = useGetProductsByCatQuery({id: catId, coopId: coop?.id}, {skip: !catId});
    const {data: chargeData} = useGetChargesQuery(coop?.id);
    const {data: calcMethodData} = useGetCalcMethodsQuery();

    const [glAccounts, setGlAccounts] = useState([]);
    const [products, setProducts] = useState([]);
    const [charges, setCharges] = useState([]);
    const [calcMethods, setCalcMethods] = useState([]);

    const [save, {isLoading, isSuccess, isError, data, error}] = useSaveLoansMutation();
    const [update, {
        isLoading: isUpdateLoading, 
        isSuccess: isUpdateSuccess, 
        isError: isUpdateError, 
        data: updateData, 
        error: updateError
    }] = useUpdateLoanMutation();

    const {
        control,
        register,
        reset,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(FormSchema),
        mode: 'onBlur'
    });

    useEffect(() => {   
        if (categories) {
            const savings = categories.data.find(cat => cat.name === 'Loans');

            if (savings) setCatID(savings.id);
        }

    }, [categories]);


    useEffect(() => {
        if (postingGlData && postingGlData.status) {
          setGlAccounts(postingGlData.data.map(item => ({value: item.id, label: `[${item.code}] ${item.name}`})));
        }
    }, [postingGlData]);

    useEffect(() => {
        if (productsData && productsData.status) {
          setProducts(productsData.data.map(item => ({value: item.id, label: item.name})));
        }
    }, [productsData]);

    useEffect(() => {
        if (chargeData && chargeData.status) {
          setCharges(chargeData.data.map(item => ({value: item.id, label: item.name})));
        }
    }, [chargeData]);

    useEffect(() => {
        if (calcMethodData && calcMethodData.status) {
          setCalcMethods(calcMethodData.data.map(item => ({value: item.id, label: item.name})));
        }
    }, [calcMethodData]);

    useEffect(() => {
        if (item) {
            resetForm(item);
        }
    }, [item]);


    const onSubmit = (values) => {
        if (coop) values.cooperative_id = coop.id;
        values.product_id           = parseInt(values.product_id)
        values.penal_charge         = parseInt(values.penal_charge)
        values.principal_gl         = parseInt(values.principal_gl)
        values.source_account       = parseInt(values.source_account)
        values.charge_id            = parseInt(values.charge_id)
        values.loan_write_off_gl    = parseInt(values.loan_write_off_gl)
        values.interest_receivable_gl    = parseInt(values.interest_receivable_gl)
        values.charge_amount    = parseFloat(values.charge_amount)
        values.calculation_method_id    = parseInt(values.calculation_method_id)
        values.status    = parseInt(values.status)
        values.tenure    = parseInt(values.tenure)
        values.max_int_rate    = parseInt(values.max_int_rate)
        values.min_int_rate    = parseInt(values.min_int_rate)

        if (item) {
            update(values);
        } else {
            save(values);
        }
    }

    useEffect(() => {
        if(isSuccess || isUpdateSuccess) {
            resetForm();
            // show toast.
            alertify.success(data?.message || updateData?.message)
        }

        if (isError || isUpdateError) {
            alertify.error(error?.data?.message || updateError?.data?.message);
        }
    }, [isSuccess, isUpdateSuccess, isUpdateError, isError, data, updateData])

    const resetForm = (item = null) => {
        reset({
            product_id: item?.product_id || "",
            name: item?.name || "",
            upfront: item?.upfront || "",
            tenure: item?.tenure || "",
            tenure_type: item?.tenure_type || "",
            min_int_rate: item?.min_int_rate || "",
            max_int_rate: item?.max_int_rate || "",
            source: item?.source || "",
            date_of_engagement: item?.date_of_engagement || 0,
            charge_type: item?.charge_type || "",
            charge_id: item?.charge_id || "",
            charge_amount: item?.charge_amount || "",
            lien: item?.lien || 0,
            lien_amount: item?.lien_amount || 0,
            interest_accrual: item?.interest_accrual || 0,
            accrual_type: item?.accrual_type || "",
            penal_charge: item?.penal_charge || "",
            penal_type: item?.penal_type || "",
            principal_gl: item?.principal_gl || "",
            loan_write_off_gl: item?.loan_write_off_gl || "",
            interest_receivable_gl: item?.interest_receivable_gl || "",
            source_account: item?.source_account || "",
            calculation_method_id: item?.calculation_method_id || "",
            status: item?.status || "",
            id: item?.id || ""
        })
        if (item) {
            setValue('lien', item.lien)
            setValue('date_of_engagement', item.date_of_engagement)
            setValue('interest_accrual', item.interest_accrual)
        }
    }

    const doCancel = () => {
        resetForm();
        onCancel();
    }

    return (
        <div className={`card dimmer ${(isLoading || isUpdateLoading) ? 'active' : ''}`} >
            <div className="loader" />

            <div className="card-header">
                <h3 className="card-title">Add/Edit Loan Setup</h3>
            </div>
            <form className="card-body" onSubmit={handleSubmit(onSubmit)}>
                <div className="row clearfix">
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="form-group">
                            <label>Loan Type </label>
                            <Controller
                                name="product_id"
                                control={control}
                                render={({ field }) => (
                                    <Select 
                                        showSearch
                                        className="customSelect mt-1"
                                        options={products} 
                                        placeholder="Select Product Type"
                                        filterOption={filterOption}
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                    
                                )}
                            />
                            {errors.product_id && <div className="invalid-feedback">{errors.product_id?.message}</div>}
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="form-group">
                            <label>Setup Name </label>
                            <Controller
                                name="name"
                                control={control}
                                render={({ field }) => (
                                    <input
                                        className={`form-control ${
                                        errors?.name ? "is-invalid" : ""
                                        }`}
                                        type="text"
                                        onChange={field.onChange}
                                        value={field.value}
                                        autoComplete="true"
                                    />
                                )}
                            />
                            {errors.name && <div className="invalid-feedback">{errors.name?.message}</div>}
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="form-group">
                        <label>Tenure </label>
                            <div className="row">
                                <div className="col-4">
                                    <Controller
                                        name="tenure"
                                        control={control}
                                        render={({ field }) => (
                                            <input
                                                className={`form-control ${
                                                errors?.tenure ? "is-invalid" : ""
                                                }`}
                                                type="text"
                                                onChange={field.onChange}
                                                value={field.value}
                                                autoComplete="true"
                                            />
                                        )}
                                    />
                                    {errors.tenure && <div className="invalid-feedback">{errors.tenure?.message}</div>}
                                </div>
                                <div className="col-8">
                                    <Controller
                                        name="tenure_type"
                                        control={control}
                                        render={({ field }) => (
                                            <Select 
                                                showSearch
                                                className="customSelect mt-1"
                                                options={[
                                                    {value: 'Month(s)', label: 'Month(s)'},
                                                    {value: 'Year(s)', label: 'Year(s)'},
                                                ]} 
                                                placeholder="Please Select"
                                                filterOption={filterOption}
                                                value={field.value}
                                                onChange={field.onChange}
                                            />
                                            
                                        )}
                                    />
                                    {errors.tenure_type && <div className="invalid-feedback">{errors.tenure_type?.message}</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="form-group">
                        <label>Min. Int. Rate </label>
                            <Controller
                                name="min_int_rate"
                                control={control}
                                render={({ field }) => (
                                    <input
                                        className={`form-control ${
                                        errors?.min_int_rate ? "is-invalid" : ""
                                        }`}
                                        type="text"
                                        onChange={field.onChange}
                                        value={field.value}
                                        autoComplete="true"
                                    />
                                )}
                            />
                            {errors.min_int_rate && <div className="invalid-feedback">{errors.min_int_rate?.message}</div>}
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="form-group">
                            <label>Max. Int. Rate </label>
                            <Controller
                                name="max_int_rate"
                                control={control}
                                render={({ field }) => (
                                    <input
                                        className={`form-control ${
                                        errors?.max_int_rate ? "is-invalid" : ""
                                        }`}
                                        type="text"
                                        onChange={field.onChange}
                                        value={field.value}
                                        autoComplete="true"
                                    />
                                )}
                            />
                            {errors.max_int_rate && <div className="invalid-feedback">{errors.max_int_rate?.message}</div>}
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="form-group">
                            <label>Upfront (%) </label>
                            <Controller
                                name="upfront"
                                control={control}
                                render={({ field }) => (
                                    <input
                                        className={`form-control ${
                                        errors?.upfront ? "is-invalid" : ""
                                        }`}
                                        type="text"
                                        onChange={field.onChange}
                                        value={field.value}
                                        autoComplete="true"
                                    />
                                )}
                            />
                            {errors.upfront && <div className="invalid-feedback">{errors.upfront?.message}</div>}
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="form-group">
                            <label>Source </label>
                            <Controller
                                name="source"
                                control={control}
                                render={({ field }) => (
                                    <Select 
                                        showSearch
                                        className="customSelect mt-1"
                                        options={[
                                            {value: 'Internal', label: 'Internal'},
                                            {value: 'External', label: 'External'},
                                        ]} 
                                        placeholder="Please Select"
                                        filterOption={filterOption}
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                    
                                )}
                            />
                            {errors.source && <div className="invalid-feedback">{errors.source?.message}</div>}
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="form-group">
                            <label>Penal Charge </label>
                            <Controller
                                name="penal_charge"
                                control={control}
                                render={({ field }) => (
                                    <input
                                        className={`form-control ${
                                        errors?.penal_charge ? "is-invalid" : ""
                                        }`}
                                        type="text"
                                        onChange={field.onChange}
                                        value={field.value}
                                        autoComplete="true"
                                    />
                                )}
                            />
                            {errors.penal_charge && <div className="invalid-feedback">{errors.penal_charge?.message}</div>}
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="form-group">
                            <label>Penal Type </label>
                            <Controller
                                name="penal_type"
                                control={control}
                                render={({ field }) => (
                                    <Select 
                                        showSearch
                                        className="customSelect mt-1"
                                        options={[
                                            {value: 'None', label: 'None'},
                                            {value: 'Flat', label: 'Flat'},
                                            {value: 'Percentage', label: 'Percentage'},
                                        ]} 
                                        placeholder="Please Select"
                                        filterOption={filterOption}
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                    
                                )}
                            />
                            {errors.penal_type && <div className="invalid-feedback">{errors.penal_type?.message}</div>}
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="form-group">
                            <label>Charge </label>
                            <Controller
                                name="charge_id"
                                control={control}
                                render={({ field }) => (
                                    <Select 
                                        showSearch
                                        className="customSelect mt-1"
                                        options={charges} 
                                        placeholder="Please Select"
                                        filterOption={filterOption}
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                    
                                )}
                            />
                            {errors.charge_id && <div className="invalid-feedback">{errors.charge_id?.message}</div>}
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="form-group">
                            <label>Charge Type </label>
                            <Controller
                                name="charge_type"
                                control={control}
                                render={({ field }) => (
                                    <Select 
                                        showSearch
                                        className="customSelect mt-1"
                                        options={[
                                            {value: 'None', label: 'None'},
                                            {value: 'Flat', label: 'Flat'},
                                            {value: 'Percentage', label: 'Percentage'},
                                        ]} 
                                        placeholder="Please Select"
                                        filterOption={filterOption}
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                    
                                )}
                            />
                            {errors.charge_type && <div className="invalid-feedback">{errors.charge_type?.message}</div>}
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="form-group">
                            <label>Charge Amount</label>
                            <Controller
                                name="charge_amount"
                                control={control}
                                render={({ field }) => (
                                    <input
                                        className={`form-control ${
                                        errors?.charge_amount ? "is-invalid" : ""
                                        }`}
                                        type="text"
                                        onChange={field.onChange}
                                        value={field.value}
                                        autoComplete="true"
                                    />
                                )}
                            />
                            {errors.charge_amount && <div className="invalid-feedback">{errors.charge_amount?.message}</div>}
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="form-group">
                            <div className="form-label">Date of Engagement</div>
                            <div className="custom-controls-stacked">
                                <label className="custom-control custom-radio custom-control-inline">
                                    <input 
                                        {...register('date_of_engagement')}
                                        type="radio" 
                                        value={1}
                                        className="custom-control-input" 
                                        name="date_of_engagement" 
                                        defaultValue={item?.date_of_engagement}
                                    />
                                    <span className="custom-control-label">Yes</span>
                                </label>
                                <label className="custom-control custom-radio custom-control-inline">
                                    <input 
                                        {...register('date_of_engagement')}
                                        type="radio" 
                                        value={0}
                                        className="custom-control-input" 
                                        name="date_of_engagement" 
                                        defaultValue={item?.date_of_engagement}
                                    />
                                    <span className="custom-control-label">No </span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="form-group">
                            <div className="form-label">Interest Accrual</div>
                            <div className="custom-controls-stacked">
                                <label className="custom-control custom-radio custom-control-inline">
                                    <input 
                                        {...register('interest_accrual')}
                                        type="radio" 
                                        value={1}
                                        className="custom-control-input" 
                                        name="interest_accrual" 
                                        defaultValue={item?.interest_accrual}

                                    />
                                    <span className="custom-control-label">Yes</span>
                                </label>
                                <label className="custom-control custom-radio custom-control-inline">
                                    <input 
                                        {...register('interest_accrual')}
                                        type="radio" 
                                        value={0}
                                        className="custom-control-input" 
                                        name="interest_accrual" 
                                        defaultValue={item?.interest_accrual}
                                    />
                                    <span className="custom-control-label">No </span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="form-group">
                            <label>Accrual Type </label>
                            <Controller
                                name="accrual_type"
                                control={control}
                                render={({ field }) => (
                                    <Select 
                                        showSearch
                                        className="customSelect mt-1"
                                        options={[
                                            {value: 'Daily', label: 'Daily'},
                                            {value: 'Monthly', label: 'Monthly'},
                                        ]} 
                                        placeholder="Please Select"
                                        filterOption={filterOption}
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                    
                                )}
                            />
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="form-group">
                            <div className="form-label">Lien</div>
                            <div className="custom-controls-stacked">
                                <label className="custom-control custom-radio custom-control-inline">
                                    <input 
                                        {...register('lien')}
                                        type="radio" 
                                        value={1}
                                        className="custom-control-input" 
                                        name="lien" 
                                        defaultValue={item?.lien}
                                    />
                                    <span className="custom-control-label">Yes</span>
                                </label>
                                <label className="custom-control custom-radio custom-control-inline">
                                    <input 
                                        {...register('lien')}
                                        type="radio" 
                                        value={0}
                                        className="custom-control-input" 
                                        name="lien" 
                                        defaultValue={item?.lien}
                                    />
                                    <span className="custom-control-label">No </span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="form-group">
                            <label>Lien Amount</label>
                            <Controller
                                name="lien_amount"
                                control={control}
                                render={({ field }) => (
                                    <input
                                        className={`form-control ${
                                        errors?.lien_amount ? "is-invalid" : ""
                                        }`}
                                        type="text"
                                        onChange={field.onChange}
                                        value={field.value}
                                        autoComplete="true"
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="form-group">
                            <label>Calculation Method </label>
                            <Controller
                                name="calculation_method_id"
                                control={control}
                                render={({ field }) => (
                                    <Select 
                                        showSearch
                                        className="customSelect mt-1"
                                        options={calcMethods} 
                                        placeholder="Please Select"
                                        filterOption={filterOption}
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                    
                                )}
                            />
                            {errors.calculation_method_id && <div className="invalid-feedback">{errors.calculation_method_id?.message}</div>}
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="form-group">
                            <label>Principal GL </label>
                            <Controller
                                name="principal_gl"
                                control={control}
                                render={({ field }) => (
                                    <Select 
                                        showSearch
                                        className="customSelect mt-1"
                                        options={glAccounts} 
                                        placeholder="Select Principal GL"
                                        filterOption={filterOption}
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                    
                                )}
                            />
                            {errors.principal_gl && <div className="invalid-feedback">{errors.principal_gl?.message}</div>}
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="form-group">
                            <label>Interest Receivable GL </label>
                            <Controller
                                name="interest_receivable_gl"
                                control={control}
                                render={({ field }) => (
                                    <Select 
                                        showSearch
                                        className="customSelect mt-1"
                                        options={glAccounts} 
                                        placeholder="Select GL Account"
                                        filterOption={filterOption}
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                    
                                )}
                            />
                            {errors.interest_receivable_gl && <div className="invalid-feedback">{errors.interest_receivable_gl?.message}</div>}
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="form-group">
                            <label>Loan Write-Off GL </label>
                            <Controller
                                name="loan_write_off_gl"
                                control={control}
                                render={({ field }) => (
                                    <Select 
                                        showSearch
                                        className="customSelect mt-1"
                                        options={glAccounts} 
                                        placeholder="Select GL Account"
                                        filterOption={filterOption}
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                    
                                )}
                            />
                            {errors.loan_write_off_gl && <div className="invalid-feedback">{errors.loan_write_off_gl?.message}</div>}
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="form-group">
                            <label>Source account </label>
                            <Controller
                                name="source_account"
                                control={control}
                                render={({ field }) => (
                                    <Select 
                                        showSearch
                                        className="customSelect mt-1"
                                        options={glAccounts} 
                                        placeholder="Select Principal GL"
                                        filterOption={filterOption}
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                    
                                )}
                            />
                            {errors.source_account && <div className="invalid-feedback">{errors.source_account?.message}</div>}
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4">
                        <div className="form-group">
                            <label>Status </label>
                            <Controller
                                name="status"
                                control={control}
                                render={({ field }) => (
                                    <select 
                                        onChange={field.onChange}
                                        value={field.value}
                                        className={`form-control ${errors?.status ? "is-invalid" : ""}`}
                                    >
                                        <option value=''>Select Status</option>
                                        <option value='1'>Active</option>
                                        <option value='0'>Inactive</option>
                                    </select>
                                )}
                            />
                            {errors.status && <div className="invalid-feedback">{errors.status?.message}</div>}
                        </div>
                    </div>
                
                </div>
                    
                <div className="d-flex justify-content-between">
                    <button type="button" className="btn btn-round btn-default" onClick={doCancel}>
                        Cancel
                    </button>{' '}&nbsp;&nbsp;
                    <button type="submit" className="btn btn-round btn-primary">
                        Submit
                    </button>
                </div>
            </form>
        </div>
    )
}

export default LoanSetupForm;