import React from "react"

export const ContestantItem = ({contestant, showManifesto}) => {
   
    return (
        <>
            <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="card">
                    <div className="card-body text-center ribbon">
                        {/* <span
                            className="avatar avatar-blue rounded-circle img-thumbnail w100"
                        >
                            <InitialsAvatar name={`${contestant.member.firstname} ${contestant.member.lastname}`} />
                        </span> */}
                        <img className="rounded-circle img-thumbnail w100" src={`/assets/images/sm/avatar${contestant.member.gender === 'Female' ? 1 : 4}.jpg`} alt="fake_url" />
                        <h6 className="mt-3 mb-0">{contestant.member.firstname} {contestant.member.lastname}</h6>
                        {/* <span className='mb-3'>dsfafds@asd.com</span> */}
                        <div className="mt-4">
                            <button className="btn btn-default btn-sm" data-toggle="modal" data-target="#manifesto-modal" onClick={() => showManifesto()}>
                                View Manifesto
                            </button>
                            <button className="btn btn-default btn-sm" data-toggle="modal" data-target="#castVoteModal" onClick={() => showManifesto()}>Vote</button>
                        </div>
                    </div>
                </div>
            </div>
            
            
        </>

    )
}