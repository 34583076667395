import React from "react";

const AssetGlForm = () => {
    return (
        <div className="card" >

            <div className="card-header">
                <h3 className="card-title">Add Asset GL</h3>
            </div>
            <div className="card-body">
                <div className="row clearfix">
                    
                    <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                            <label>Prov. for Div <span className="form-required">*</span></label>
                            <select className="form-control">
                                <option> - Select -</option>
                                <option>Account Payable</option>
                            </select>
                        </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                            <label>Retained Earnings <span className="form-required">*</span></label>
                            <select className="form-control">
                                <option> - Select -</option>
                                <option>Account Payable</option>
                            </select>
                        </div>
                    </div>                </div>
                    
                <button type="button" className="btn btn-round btn-primary">
                    Save
                </button>{' '}&nbsp;&nbsp;
                <button type="button" className="btn btn-round btn-default">
                        Cancel
                </button>
            </div>
        </div>
    )
}

export default AssetGlForm;