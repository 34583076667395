// Import all components here
// import React, { Component } from 'react'
import Dashboard from './HRMS/Dashboard/Dashboard';
import Users from './HRMS/Users/Users';
import Login from './Authentication/login';
import SignUp from './Authentication/signup';
import ForgotPassword from './Authentication/forgotpassword';
import NotFound from './Authentication/404';
import InternalServer from './Authentication/500';
import AccessControl from './Setup/AccessControl/AccessControl';
import ChartOfAccounts from './Setup/ChartOfAccount/ChartOfAccount';
import ClientSetup from './Setup/Products/ProductSetup';
import Cooperatives from './Setup/Cooperatives/CooperativeSetup';
import ProductSetup from './Setup/Products/ProductSetup';
import SavingsSetup from './Setup/Products/SavingsSetup';
import BirthdayTemplate from './Setup/BirthdayTemplates';
import LoansSetup from './Setup/Products/LoansSetup';
import { ConsolidatedReport } from './Reports/ConsolidatedReport';
import Members from './Members/Members';
import LoansList from './LoansAndSavings/Loans/LoansList';
import LoanApplications from './LoansAndSavings/Loans/Applications';
import ChargePage from './Setup/ChargeSetup/Charge';
import BankAccount from './Setup/BankSetup/BankAccount';
import DeptSetup from './Setup/DepartmentSetup/DeptSetup';
import AssetTypes from './FinanceAndReporting/AssetManager/AssetTypes';
import AssetGlSetup from './FinanceAndReporting/AssetGLSetup/AssetGlSetup';
import AssetSetup from './FinanceAndReporting/AssetSetup/AssetSetup';
import Deposits from './FinanceAndReporting/Deposits';
import Withdrawals from './FinanceAndReporting/Withdrawals';
import JournalEntry from './FinanceAndReporting/JournalEntry/JournalEntry';
import SavingsAccount from './LoansAndSavings/Savings/SavingsAccount';
import MemberDetails from './Members/MemberDetails/MemberDetails';
import AppChart from './AppPages/AppChart';
import { MemberLoans } from './MemberArea/Loans';
import { MemberSurety } from './MemberArea/MemberSurety';
import { TransactionHistory } from './MemberArea/TransactionHistory';
import { SavingsRequest } from './MemberArea/SavingsRequest';
import { MyProfile } from './MemberArea/MyProfile';
import { ChangePassword } from './MemberArea/ChangePassword';
import { EditProfile } from './MemberArea/EditProfile';
import { Savings } from './MemberArea/Savings';
import StaffCategories from './Setup/StaffCategories/StaffCategories';
import EShopDashboard from './E-Shop/Dashboard/Dashboard';
import EshopTransactions from './E-Shop/Reports/Transactions';
import SalesReport from './E-Shop/Reports/SalesReport';
import ProductsReport from './E-Shop/Reports/ProductsReport';
import Stock from './E-Shop/Products/Stock';
import Orders from './E-Shop/Orders/Orders';
import ReturnAndRefunds from './E-Shop/Orders/ReturnAndRefunds';
import ReturnOrders from './E-Shop/Orders/Returns/ReturnOrders';
import Damages from './E-Shop/Products/Damages';
import Products from './E-Shop/Products';
import EshopCustomers from './E-Shop/Reports/Customers';
import Voting from './Voting';
import DividendGlSetup from './Setup/DividendCalculator/GLSetup.js';
import SavedDividend from './Setup/DividendCalculator/SavedDividend.js';
import ComputeDividend from './Setup/DividendCalculator/ComputeDividend.js';
import { ImportLoan } from './LoansAndSavings/Loans/ImportLoan.js';
import { ImportSavings } from './LoansAndSavings/Savings/ImportSavings.js';
import PendingMembers from './Members/PendingMembers.js';
import { PayrollRuns } from './MonthlyDeductions/PayrollRuns.js';
import TrialBalance from './Reports/TrialBalance.js';
import MABReport from './Reports/MABReport.js';
import IncomeStatement from './Reports/IncomeStatement.js';
import StatementOfFinancialPosition from './Reports/StatementOfFinancialPosition.js';
import DividendCalculator from './Reports/DividendCalculator.js';
import InvestmentTracker from './Reports/InvestmentTracker.js';
import AnalysisReport from './Reports/AnalysisReport.js';
import { MemberLoanOffsetHistory } from './MemberArea/MemberLoanOffsetHistory.js';
import { LoanApplication } from './MemberArea/LoanApplication.js';
import { MemberLoanUpload } from './MemberArea/MemberLoanUpload.js';
import Elections from './MemberArea/Elections/index.js';
import ViewContestants from './MemberArea/Elections/ViewContestants.js';
import LedgerStatment from './Reports/LedgerStatement.js';

const Routes = [
    {
        path: "/dashboard",
        name: 'dashboard',
        exact: true,
        pageTitle: "Home",
        component: Dashboard
    },
    {
        path: "/setup/polls",
        name: 'setup-polls',
        exact: true,
        pageTitle: "Manage Polls",
        component: Voting
    },
    {
        path: "/setup/client",
        name: 'client-setup',
        exact: true,
        pageTitle: "Client Setup",
        component: ClientSetup
    },
    {
        path: "/setup/access-control",
        name: 'access-control',
        exact: true, 
        pageTitle: "Access Control",
        component: AccessControl
    },
    {
        path: "/setup/chart-of-accounts",
        name: 'chart-of-accounts',
        exact: true,
        pageTitle: "Chart of Accounts",
        component: ChartOfAccounts
    },
    {
        path: "/setup/users",
        name: 'system-users',
        exact: true,
        pageTitle: "System Users",
        component: Users
    },
    {
        path: "/setup/cooperatives",
        name: 'cooperative-setup',
        exact: true,
        pageTitle: "Manage Cooperatives",
        component: Cooperatives
    },
    {
        path: "/setup/products",
        name: 'products-setup',
        exact: true,
        pageTitle: "Manage Product Categories and Products",
        component: ProductSetup
    },
    {
        path: "/setup/savings",
        name: 'savings-setup',
        exact: true,
        pageTitle: "Savings Product Setup",
        component: SavingsSetup
    },
    {
        path: "/setup/loans",
        name: 'loans-setup',
        exact: true,
        pageTitle: "Loans Product Setup",
        component: LoansSetup
    },
    {
        path: "/setup/charges",
        name: 'charge-setup',
        exact: true,
        pageTitle: "Charge Setup",
        component: ChargePage
    },
    {
        path: "/setup/bank-accounts",
        name: 'bank-accounts',
        exact: true,
        pageTitle: "Bank Account Setup",
        component: BankAccount
    },
    {
        path: "/setup/gl-accounts",
        name: 'gl-accounts',
        exact: true,
        pageTitle: "GL Setup",
        component: ChargePage
    },
    {
        path: "/setup/departments",
        name: 'departments',
        exact: true,
        pageTitle: "Manage Departments",
        component: DeptSetup
    },
    {
        path: "/setup/staff-categories",
        name: 'staff-categories',
        exact: true,
        pageTitle: "Manage Staff Categories",
        component: StaffCategories
    },
    {
        path: "/setup/birthday-cards",
        name: 'birthday-cards',
        exact: true,
        pageTitle: "Manage Birthday Cards",
        component: BirthdayTemplate
    },
    {
        path: "/reports/consolidated-report",
        name: 'consolidated-report',
        exact: true,
        pageTitle: "Consolidated Report",
        component: ConsolidatedReport
    },
    {
        path: "/loans/applications",
        name: 'loan-application',
        exact: true,
        pageTitle: "Loan Applications",
        component: LoansList
    },
    {
        path: "/loans/import",
        name: 'loan-application',
        exact: true,
        pageTitle: "Import Loan Data",
        component: ImportLoan
    },
    {
        path: "/loans/application-forms",
        name: 'application-form',
        exact: true,
        pageTitle: "Uploaded Loan Applications",
        component: LoanApplications
    },
    {
        path: "/savings/accounts",
        name: 'savings-applications',
        exact: true,
        pageTitle: "Savings Accounts",
        component: SavingsAccount
    },
    {
        path: "/savings/change-requests",
        name: 'savings-request',
        exact: true,
        pageTitle: "Savings Change Request",
        component: SavingsRequest
    },
    {
        path: "/savings/import",
        name: 'savings-import',
        exact: true,
        pageTitle: "Import Savings Data",
        component: ImportSavings
    },
    {
        path: "/finance-reporting/deposits",
        name: 'deposits',
        exact: true,
        pageTitle: "Deposit Orders",
        component: Deposits
    },
    {
        path: "/finance-reporting/withdrawals",
        name: 'deposits',
        exact: true,
        pageTitle: "Withdrawal Requests",
        component: Withdrawals
    },
    {
        path: "/finance-reporting/journal-entry",
        name: 'journal-entry',
        exact: true,
        pageTitle: "Journal Entry",
        component: JournalEntry
    },
    {
        path: "/finance-reporting/asset-types",
        name: 'asset-types',
        exact: true,
        pageTitle: "Manage Asset Types",
        component: AssetGlSetup
    },
    {
        path: "/finance-reporting/asset-setup",
        name: 'asset-setup',
        exact: true,
        pageTitle: "Manage Asset",
        component: AssetSetup
    },
    // {
    //     path: "/finance-reporting/asset-gl-setup",
    //     name: 'asset-types',
    //     exact: true,
    //     pageTitle: "Manage Asset GL",
    //     component: AssetGlSetup
    // },
    {
        path: "/members",
        name: 'members',
        exact: true,
        pageTitle: "Members",
        component: Members
    },
    {
        path: "/members/pending",
        name: 'member-pending',
        exact: true,
        pageTitle: "Pending Members",
        component: PendingMembers
    },
    {
        path: "/members/view",
        name: 'member-view',
        exact: true,
        pageTitle: "Member Details",
        component: MemberDetails
    },
    {
        path: "/login",
        name: 'login',
        exact: true,
        pageTitle: "Login",
        component: Login
    },
    {
        path: "/register",
        name: 'register',
        exact: true,
        pageTitle: "Register",
        component: SignUp
    },
    {
        path: "/app-chat",
        name: 'app-chat',
        exact: true,
        pageTitle: "Friends Group",
        component: AppChart
    },
    {
        path: "/member/loans",
        name: 'member-loan',
        exact: true,
        pageTitle: "Loan Applications",
        component: MemberLoans
    },
    {
        path: "/member/loans/apply",
        name: 'loan-form',
        exact: true,
        pageTitle: "Loan Application Form",
        component: LoanApplication
    },
    {
        path: "/member/loans/upload",
        name: 'loan-form',
        exact: true,
        pageTitle: "Upload Loan Form",
        component: MemberLoanUpload
    },
    {
        path: "/member/loans/surety",
        name: 'member-surety',
        exact: true,
        pageTitle: "Surety Requests",
        component: MemberSurety
    },
    {
        path: "/member/transaction-history",
        name: 'transaction-history',
        exact: true,
        pageTitle: "Transaction History",
        component: TransactionHistory
    },
    {
        path: "/member/loan-offset-history",
        name: 'loan-offset-history',
        exact: true,
        pageTitle: "Loan Offset History",
        component: MemberLoanOffsetHistory
    },
    {
        path: "/member/savings/requests",
        name: 'savings-request',
        exact: true,
        pageTitle: "Savings Change Request",
        component: SavingsRequest
    },
    {
        path: "/member/elections",
        name: 'member-elections',
        exact: true,
        pageTitle: "Elections",
        component: Elections
    },
    {
        path: "/member/elections/contestants",
        name: 'member-contestants',
        exact: true,
        pageTitle: "Election Contestants",
        component: ViewContestants
    },
    {
        path: "/member/savings",
        name: 'member-savings',
        exact: true,
        pageTitle: "Savings Account Details",
        component: Savings
    },
    {
        path: "/member/my-profile",
        name: 'member-profile',
        exact: true,
        pageTitle: "My Profile",
        component: MyProfile
    },
    {
        path: "/member/edit-profile",
        name: 'edit-profile',
        exact: true,
        pageTitle: "Edit Profile",
        component: EditProfile
    },
    {
        path: "/member/change-password",
        name: 'member-password',
        exact: true,
        pageTitle: "Change Password",
        component: ChangePassword
    },
    {
        path: "/e-shop/dashboard",
        name: 'e-shop-dashboard',
        exact: true,
        pageTitle: "E-Shop Dashboard",
        component: EShopDashboard
    },
    {
        path: "/e-shop/reports/transactions",
        name: 'e-shop-transactions',
        exact: true,
        pageTitle: "E-Shop Transactions",
        component: EshopTransactions
    },
    {
        path: "/e-shop/reports/sales",
        name: 'e-shop-sales',
        exact: true,
        pageTitle: "Sales Report",
        component: SalesReport
    },
    {
        path: "/e-shop/reports/products",
        name: 'e-shop-products',
        exact: true,
        pageTitle: "Products Report",
        component: ProductsReport
    },
    {
        path: "/e-shop/stock",
        name: 'e-shop-stock',
        exact: true,
        pageTitle: "Stock Report",
        component: Stock
    },
    {
        path: "/e-shop/orders",
        name: 'orders',
        exact: true,
        pageTitle: "Online Orders",
        component: Orders
    },
    {
        path: "/e-shop/orders/returns",
        name: 'return-orders',
        exact: true,
        pageTitle: "Return Orders",
        component: ReturnOrders
    },
    {
        path: "/e-shop/orders/refunds",
        name: 'return-refunds',
        exact: true,
        pageTitle: "Return And Refunds",
        component: ReturnAndRefunds
    },
    {
        path: "/e-shop/damages",
        name: 'product-damages',
        exact: true,
        pageTitle: "Damages",
        component: Damages
    },
    {
        path: "/e-shop/products",
        name: 'products',
        exact: true,
        pageTitle: "Manage Products",
        component: Products
    },
    {
        path: "/e-shop/reports/customers",
        name: 'customers',
        exact: true,
        pageTitle: "Manage Customers",
        component: EshopCustomers
    },
    {
        path: "/dividend-calculator/gl-setup",
        name: 'dividend-gl-setup',
        exact: true,
        pageTitle: "Manage Dividend GL",
        component: DividendGlSetup
    },
    {
        path: "/dividend-calculator/saved",
        name: 'dividend-saved',
        exact: true,
        pageTitle: "Saved Dividend",
        component: SavedDividend
    },
    {
        path: "/dividend-calculator/compute",
        name: 'dividend-compute',
        exact: true,
        pageTitle: "Dividend Calculator",
        component: ComputeDividend
    },
    {
        path: "/monthly-deductions/payroll-runs",
        name: 'payroll-runs',
        exact: true,
        pageTitle: "Monthly deductions (payroll) ",
        component: PayrollRuns
    },
    {
        path: "/reports/trial-balance",
        name: 'trial-balance',
        exact: true,
        pageTitle: "Trial Balance ",
        component: TrialBalance
    },
    {
        path: "/reports/ledger-statement",
        name: 'chart-of-account',
        exact: true,
        pageTitle: "Ledger Statement",
        component: LedgerStatment
    },
    {
        path: "/reports/members-balance",
        name: 'member-account-report',
        exact: true,
        pageTitle: "Members Account Balance Report ",
        component: MABReport
    },
    {
        path: "/reports/income-statement",
        name: 'income-statement',
        exact: true,
        pageTitle: "Income Statement",
        component: IncomeStatement
    },
    {
        path: "/reports/financial-position",
        name: 'financial-position',
        exact: true,
        pageTitle: "Statement of Financial Position",
        component: StatementOfFinancialPosition
    },
    {
        path: "/reports/dividend-calculator",
        name: 'dividend-calculator',
        exact: true,
        pageTitle: "Dividend Calculator",
        component: DividendCalculator
    },
    {
        path: "/reports/investment-trackers",
        name: 'investment-trackers',
        exact: true,
        pageTitle: "Investment Trackers",
        component: InvestmentTracker
    },
    {
        path: "/reports/analysis-report",
        name: 'analysis-report',
        exact: true,
        pageTitle: "Coop Analysis Report",
        component: AnalysisReport
    },
];

export default Routes;