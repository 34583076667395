import dayjs from "dayjs";
import React, { useState } from "react"

export const ElectionItem = ({item, onEdit}) => {
    const [boxOpen, setBoxOpen] = useState(true);
    return (
        <div className="col-lg-4 col-md-4, col-sm-12">
            <div className={`card ${!boxOpen ? 'card-collapsed' : ""}`}>
                <div className="card-header">
                    <h3 className="card-title">{item?.name}</h3>
                    <div className="card-options">
                        <label className="custom-switch m-0">
                            <input type="checkbox" defaultValue={item?.status} className="custom-switch-input" defaultChecked />
                            <span className="custom-switch-indicator" />
                        </label>
                        <span className="card-options-collapse" data-toggle="card-collapse" onClick={() => setBoxOpen(!boxOpen)}><i className="fe fe-chevron-up" /></span>
                        <div className="item-action dropdown ml-2">
                            <a href="fake_url" data-toggle="dropdown"><i className="fe fe-more-vertical" /></a>
                            <div className="dropdown-menu dropdown-menu-right">
                                <a href="#" className="dropdown-item" data-toggle="modal" data-target="#election_form" onClick={() => onEdit(item)}>
                                    <i className="dropdown-icon fa fa-edit" /> Edit 
                                </a>
                                <a href="#" className="dropdown-item" data-toggle="modal" data-target="#positions_modal" onClick={() => onEdit(item)}>
                                    <i className="dropdown-icon fe fe-award" /> Manage Positions 
                                </a>
                                <a href="#" className="dropdown-item" data-toggle="modal" data-target="#contestants_modal" onClick={() => onEdit(item)}>
                                    <i className="dropdown-icon fe fe-user-plus" /> Manage Contestants 
                                </a>
                                <a className="dropdown-item" data-toggle="tab" href="#Project-UpComing">
                                    <i className="dropdown-icon fa fa-eye" /> View Results
                                </a>
                                <a href="#" className="dropdown-item"><i className="dropdown-icon fa fa-trash" /> Delete</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <span className="tag tag-green mb-3">Open</span>

                    <p>{item?.description}</p>
                    <div className="row">
                    <div className="col-5 py-1"><strong>Created:</strong></div>
                        <div className="col-7 py-1">{dayjs(item?.created_at).format('DD MMM YYYY HH:mma')}</div>
                        {/* <div className="col-5 py-1"><strong>Creator:</strong></div>
                        <div className="col-7 py-1">Nathan Guerrero</div> */}
                        <div className="col-5 py-1"><strong>Start Date:</strong></div>
                        <div className="col-7 py-1"><strong>{dayjs(item?.start_date).format('DD MMM YYYY')}</strong></div>
                        <div className="col-5 py-1"><strong>End Date:</strong></div>
                        <div className="col-7 py-1"><strong>{dayjs(item?.end_date).format('DD MMM YYYY')}</strong></div>
                        <div className="col-5 py-1"><strong>Votes:</strong></div>
                        <div className="col-7 py-1"><strong>0</strong></div>
                        <div className="col-5 py-1"><strong>Contestants:</strong></div>
                        <div className="col-7 py-1">
                            <div className="avatar-list avatar-list-stacked">
                                <img className="avatar avatar-sm" src="../assets/images/xs/avatar1.jpg" data-toggle="tooltip" data-original-title="Avatar Name" alt="fake_url" />
                                <img className="avatar avatar-sm" src="../assets/images/xs/avatar2.jpg" data-toggle="tooltip" data-original-title="Avatar Name" alt="fake_url" />
                                <img className="avatar avatar-sm" src="../assets/images/xs/avatar5.jpg" data-toggle="tooltip" data-original-title="Avatar Name" alt="fake_url" />
                                <span className="avatar avatar-sm">+8</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-footer">
                    <div className="clearfix">
                        <div className="float-left"><strong>47%</strong></div>
                        <div className="float-right"><small className="text-muted">Progress</small></div>
                    </div>
                    <div className="progress progress-xs">
                        <div className="progress-bar bg-blue" role="progressbar" style={{ width: '47%' }} aria-valuenow={47} aria-valuemin={0} aria-valuemax={100} />
                    </div>
                </div>
            </div>
        </div>
    )
}