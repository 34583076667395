
import React, { useState } from "react";
import { useSelector } from "react-redux";
import BankAccountList from "./BankAccountList";
import BankAccountForm from "./BankForm";

const BankAccount = () => {
    const {isFixNavbar: fixNavbar} = useSelector(state => state.settings);
    const {activeCoop} = useSelector(state => state.coop);

    const [account, setBankAccount] = useState(null);
    return (
        <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>

            <div className="row">
                <div className="col-md-8 col-sm-12">
                    <BankAccountList onEdit={(val) => setBankAccount(val)} activeCoop={activeCoop} />
                </div>
                <div className="col-md-4 col-sm-12">
                    <BankAccountForm account={account} onCancel={() => setBankAccount(null)} activeCoop={activeCoop} />
                </div>
            </div>
        </div>
    )
}

export default BankAccount;