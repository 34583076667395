
import React from "react";
import { useDispatch } from "react-redux";
import { useGetAllBanksQuery } from "../../../services/cooperative-setup.service";
import { toggleDelete } from "../../../redux/slices/ui.slice";
import { DELETE_BANK_ACCOUNT } from "../../../services/CONSTANTS";
import { Empty, Tooltip } from "antd";
import Card from "../../common/Card";

const BankAccountList = ({onEdit, activeCoop}) => {
    const dispatch = useDispatch();

    const {data, isLoading} = useGetAllBanksQuery(activeCoop?.id, {
        skip: !activeCoop
    });

    const doDelete = (e, item) => {
        // console.log(e.currentTarget)
        dispatch(
          toggleDelete({
            open: true,
            title: 'Bank Account',
            url: `${DELETE_BANK_ACCOUNT}?cooperative_id=${activeCoop.id}&bank_id=${item.id}`,
            // ele: JSON.stringify(e.currentTarget),
            parent: 'tr'
          })
        );
    }
    return (
        <Card 
            title={`Bank accounts`}
            loading={isLoading}
        >
            <div className="card-body dimmer-content">
                <div className="table-responsive">
                    <table className="table table-striped table-vcenter table-hover mb-0">
                        <thead>
                            <tr>
                                <th>Bank Name</th>
                                <th>Account No.</th>
                                <th>GL Code</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.data.map(row => 
                                <tr>
                                    <td>{row.bank.name}</td>
                                    <td>{row.account_no}</td>
                                    <td>{row.coa.code}</td>
                                    <td><span className="tag tag-success">Active</span></td>
                                    <td>
                                        <Tooltip title="Edit">
                                            <button type="button" className="btn btn-icon" onClick={() => onEdit(row)}>
                                                <i className="icon-pencil text-info" />
                                            </button>
                                        </Tooltip>
                                        <Tooltip title="Delete">
                                            <button type="button" className="btn btn-icon" data-target="#deleteModal" data-toggle="modal" onClick={(e) => doDelete(e, row)}>
                                                <i className="icon-trash text-info" />
                                            </button>
                                        </Tooltip>
                                    </td>
                                </tr>
                            )}
                            {data && data?.data.length === 0 && <tr><td colSpan={8}><Empty /></td></tr>}

                        </tbody>
                    </table>
                </div>
            </div>
        </Card>
    )
}

export default BankAccountList;