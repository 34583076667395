import { Tooltip } from "antd";
import React from "react";
import { useDeletePositionMutation, useSavePositionMutation } from "../../../services/election.service";


export const PositionItem = ({item, onEdit}) => {
    const [update, {isSuccess}] = useSavePositionMutation();
    const [deletePosition, {}] = useDeletePositionMutation();

    const updateStatus = (e) => {
        const checked = e.target.checked;
        update({
            cooperative_id: item.cooperative_id,
            election_id: item.election_id,
            position_id: item.id,
            name: item.name,
            description: item.description,
            status: checked ? 0 : 1
        })
    }

    const doDelete = () => {
        deletePosition({coop_id: item.cooperative_id, id: item.id});
    }

    return (
        <tr>
            <td>
                <label className="custom-control custom-checkbox">
                    <input 
                        type="checkbox" 
                        className="custom-control-input" 
                        name="example-checkbox1" 
                        defaultValue={item.status} 
                        defaultChecked={item.status === 0} 
                        onChange={updateStatus}
                    />
                    <span className="custom-control-label">{item.description}</span>
                </label>
            </td>
            <td className="text-right">{item.name}</td>
            <td>
                {item.status === 0 && <span className="tag tag-danger ml-0 mr-0">Inactive</span>}
                {item.status === 1 && <span className="tag tag-success ml-0 mr-0">Active</span>}
            </td>
            <td>
                <div className="btn-group btn-sm">
                    <Tooltip title="Edit">
                        <button type="button" className="btn btn-icon btn-sm btn-sm" onClick={() => onEdit(item)}>
                            <i className="icon-pencil text-info" />
                        </button>
                    </Tooltip>
                    <Tooltip title="Delete">
                        <button type="button" 
                            className="btn btn-icon btn-sm"
                            // data-toggle="modal" 
                            // data-target="#deleteModal" 
                            onClick={(e) => doDelete(e, item)}
                        >
                            <i className="icon-trash text-danger" />
                        </button>
                    </Tooltip>
                </div>
            </td>
        </tr>
    )
}