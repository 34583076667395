import React from "react"
import { useSelector } from "react-redux";
import { UploadLoanForm } from "./Components/UploadLoanForm";

export const MemberLoanUpload = () => {
    const {isFixNavbar: fixNavbar} = useSelector(state => state.settings);
    const {user} = useSelector(state => state.user);
    const {activeCoop} = useSelector(state => state.coop);

    return (
        <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
            <UploadLoanForm coopId={activeCoop?.id} />
        </div>
    )
}