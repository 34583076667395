import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import * as Yup from "yup";
import alertify from "alertifyjs";
import { useSaveStaffCatMutation, useUpdateStaffCatMutation } from "../../../services/setup.service";

export const FormSchema = Yup.object().shape({
    name: Yup.string().required('Please enter a name'),
    status: Yup.string().required('Please select the status'),
});


const CategoryForm = ({category, onCancel}) => {
    const [save, {isLoading, isSuccess, isError, data, error}] = useSaveStaffCatMutation();
    const [update, {
        isLoading: isUpdateLoading, 
        isSuccess: isUpdateSuccess, 
        isError: isUpdateError, 
        data: updateData, 
        error: updateError
    }] = useUpdateStaffCatMutation();

    const {
        control,
        reset,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(FormSchema),
        mode: 'onBlur'
    });

    useEffect(() => {
        if (category) {
            reset({
                name: category.name,
                status: category.status,
                id: category.id,
            })
        }
    }, [category]);


    const onSubmit = (values) => {
        if (category) {
            update(values);
        } else {
            save(values);
        }
    }

    useEffect(() => {
        if(isSuccess || isUpdateSuccess) {
            resetForm();
            // show toast.
            alertify.success(data?.message || updateData?.message)
        }

        if (isError || isUpdateError) {
            alertify.error(error?.data?.message || updateError?.data?.message);
        }
    }, [isSuccess, isUpdateSuccess, isUpdateError, isError, data, updateData])

    const resetForm = () => {
        reset({
          name: "",
          status: "",
          id: "",
        })
    }

    const doCancel = () => {
        resetForm();
        onCancel();
    }

    return (
        <div className={`card dimmer ${(isLoading || isUpdateLoading) ? 'active' : ''}`} >
            <div className="loader" />
            <div className="card-header">
                <h3 className="card-title">Add/Edit Category</h3>
            </div>
            <form className="card-body dimmer-content" onSubmit={handleSubmit(onSubmit)}>
                <div className="row clearfix">
                    <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                            <label>Name</label>
                            <Controller
                                name="name"
                                control={control}
                                render={({ field }) => (
                                    <input
                                        className={`form-control ${
                                        errors?.name ? "is-invalid" : ""
                                        }`}
                                        type="text"
                                        onChange={field.onChange}
                                        value={field.value}
                                        autoComplete="true"
                                    />
                                )}
                            />
                            {errors.name && <div className="invalid-feedback">{errors.name?.message}</div>}
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                            <label>Status</label>
                            <Controller
                                name="status"
                                control={control}
                                render={({ field }) => (
                                    <select 
                                        onChange={field.onChange}
                                        value={field.value}
                                        className={`form-control ${errors?.status ? "is-invalid" : ""}`}
                                    >
                                        <option value=''>Select Status</option>
                                        <option value='1'>Active</option>
                                        <option value='0'>Inactive</option>
                                    </select>
                                )}
                            />
                            {errors.status && <div className="invalid-feedback">{errors.status?.message}</div>}
                        </div>
                    </div>
                </div>
                    
                <div className="d-flex justify-content-between">
                    <button type="button" className="btn btn-round btn-default" onClick={doCancel}>
                        Cancel
                    </button>{' '}&nbsp;&nbsp;
                    <button type="submit" className="btn btn-round btn-primary">
                        Save
                    </button>
                </div>
            </form>
        </div>
    )
}

export default CategoryForm;