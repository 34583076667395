import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import { useSelector } from 'react-redux';
import { MemberTable } from './MemberTable';
import { MemberForm } from './MemberForm';
import { useLocation } from 'react-router-dom';
import { useGetMemberDashboardDataQuery } from '../../services/member.service';
import { ImportMembers } from './importMembers';

const Members = () => {
    const {isFixNavbar: fixNavbar} = useSelector(state => state.settings);
	const {activeCoop} = useSelector(state => state.coop);

	const {data} = useGetMemberDashboardDataQuery(activeCoop?.id,  {skip: !activeCoop});

	const location = useLocation();
	// const sparkline1 = React.createRef();
	const sparkline2 = React.createRef();
	const sparkline3 = React.createRef();
	const sparkline4 = React.createRef();
	const [totalMembers, setTotalMembers] = useState(0);
	const [totalPending, setTotalPending] = useState(0);
	const [totalMale, setTotalMale] = useState(0);
	const [totalFemale, setTotalFemale] = useState(0);
	const [member, setMember] = useState(null);

	useEffect(() => {
		const hash = location.hash;
		if (hash === '#form') {
			document.getElementById('member-form-tab').click();
		} else if(hash === '#import') {
			document.getElementById('member-import-tab').click();
		} else {
			document.getElementById('member-list-tab').click();
		}
	}, [location]);

	useEffect(() => {
		if (data) {
			// console.log(data);
			setTotalMembers(data.data.total)
			setTotalPending(data.data.pending)
			setTotalMale(data.data.male)
			setTotalFemale(data.data.female)
		}
	}, [data]);

		
		return (
			<>
				<div>
					<div>
						<div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
							<div className="container-fluid">
								<div className="d-flex justify-content-between align-items-center mb-3">
									<ul className="nav nav-tabs page-header-tab">
										<li className="nav-item">
											<a
												className="nav-link active"
												id="member-list-tab"
												data-toggle="tab"
												href="#member-list"
											>
												All Members
											</a>
										</li>
										<li className="nav-item">
											<a
												className="nav-link"
												id="member-form-tab"
												data-toggle="tab"
												href="#member-form"
											>
												Member Form
											</a>
										</li>
										<li className="nav-item">
											<a
												className="nav-link"
												id="member-import-tab"
												data-toggle="tab"
												href="#member-import"
											>
												Import Members
											</a>
										</li>
									</ul>
									{/* <div className="header-action">
										<button
											type="button"
											className="btn btn-primary"
											data-toggle="modal"
											data-target="#exampleModal"
										>
											<i className="fe fe-plus mr-2" />
											Add
										</button>
									</div> */}
								</div>
								<div className="row">
									<div className="col-lg-3 col-md-6">
										<div className="card">
											<div className="card-body w_sparkline">
												<div className="details">
													<span>Total Members</span>
													<h3 className="mb-0">
														<span className="counter">	<CountUp end={totalMembers} /></span>
													</h3>
												</div>
												<div className="w_chart">
													<div id="mini-bar-chart1" className="mini-bar-chart" />
												</div>
											</div>
										</div>
										{/* 
											<div className="w_chart">
													<span
														ref={this.sparkline1}
														id="mini-bar-chart1"
														className="mini-bar-chart"
													></span>
												</div>
										*/}
									</div>
									<div className="col-lg-3 col-md-6">
										<div className="card">
											<div className="card-body w_sparkline">
												<div className="details">
													<span>Pending Members</span>
													<h3 className="mb-0">
														<CountUp end={totalPending} />
														{/* <span >124</span> */}
													</h3>
												</div>
												<div className="w_chart">
													<span
														ref={sparkline2}
														id="mini-bar-chart2"
														className="mini-bar-chart"
													/>
												</div>
											</div>
										</div>
									</div>
									<div className="col-lg-3 col-md-6">
										<div className="card">
											<div className="card-body w_sparkline">
												<div className="details">
													<span>Male</span>
													<h3 className="mb-0 counter">	<CountUp end={totalMale} /></h3>
												</div>
												<div className="w_chart">
													<span
														ref={sparkline3}
														id="mini-bar-chart3"
														className="mini-bar-chart"
													/>
												</div>
											</div>
										</div>
									</div>
									<div className="col-lg-3 col-md-6">
										<div className="card">
											<div className="card-body w_sparkline">
												<div className="details">
													<span>Female</span>
													<h3 className="mb-0 counter">	<CountUp end={totalFemale} /></h3>
												</div>
												<div className="w_chart">
													<span
														ref={sparkline4}
														id="mini-bar-chart4"
														className="mini-bar-chart"
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="section-body">
							<div className="container-fluid">
								<div className="tab-content">
									<div className="tab-pane fade show active" id="member-list" role="tabpanel">
										<MemberTable activeCoop={activeCoop} onEdit={(data) => {
											setMember(data);
											document.getElementById('member-form-tab').click()
										}} />
									</div>
									<div className="tab-pane fade" id="member-form" role="tabpanel">
										<MemberForm activeCoop={activeCoop} member={member} />
									</div>
									<div className="tab-pane fade" id="member-import" role="tabpanel">
										<ImportMembers activeCoop={activeCoop} />
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
				
			</>
		);
	}

export default Members;