import dayjs from "dayjs";
import React from "react";
import { useDispatch } from "react-redux";
import { DELETE_USER } from "../../../services/CONSTANTS";
import { toggleDelete } from "../../../redux/slices/ui.slice";
import { Tooltip } from "antd";

export const UsersTable = ({data, onEdit}) => {
    const dispatch = useDispatch();

    const doDelete = (e, item) => {
        // console.log(e.currentTarget)
        dispatch(
          toggleDelete({
            open: true,
            title: 'User',
            url: `${DELETE_USER}?user_id=${item.id}`,
            // ele: JSON.stringify(e.currentTarget),
            parent: 'tr'
          })
        );
      }

    return (
        <div className="table-responsive">
            <table className="table table-striped table-hover table-vcenter text-nowrap mb-0">
                <thead>
                    <tr>
                        <th className="">COOPID</th>
                        <th>Name</th>
                        <th>Role</th>
                        <th>Status</th>
                        <th>Created Date</th>
                        <th className="w100">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {data?.map(row => 
                        <tr key={row.id}>
                            <td className="width45">
                                {row.coop_id}
                                {/* <span
                                    className="avatar avatar-blue"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    data-original-title="Avatar Name"
                                >
                                    NG
                                </span> */}
                            </td>
                            <td>
                                <h6 className="mb-0">{row.firstname} {row.lastname}</h6>
                                <span>{row.email}</span>
                            </td>
                            <td>
                                <span className="tag tag-danger">{row.role_name}</span>
                            </td>
                            <td>
                                {row.status === 1 && <span className="tag tag-success">Active</span>}
                                {row.status === 0 && <span className="tag tag-danger">Inactive</span>}
                            </td>
                            <td>{dayjs(row.created_at).format('DD MMM, YYYY HH:mm:ss')}</td>
                            <td>
                                <Tooltip title="Edit User">
                                    <button
                                        type="button"
                                        className="btn btn-icon"
                                        title="Edit"
                                        data-toggle="tooltip"
                                        data-original-title="Edit Record"
                                        onClick={() => onEdit(row)}
                                    >
                                        <i className="icon-pencil text-info" />
                                    </button>
                                </Tooltip>
                                <Tooltip title="Delete User">
                                    <button
                                        type="button"
                                        className="btn btn-icon"
                                        title="Delete"
                                        
                                        data-toggle="modal" data-target="#deleteModal" onClick={(e) => doDelete(e, row)}
                                    >
                                        <i className="icon-trash text-danger" />                                
                                    </button>
                                </Tooltip>
                            </td>
                        </tr>
                    )}
                    
                </tbody>
            </table>
        </div>
    )
}