import React, { useEffect, useState } from "react";
import { useGetAllBanksQuery } from "../../../services/cooperative-setup.service";
import dayjs from "dayjs";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import * as Yup from "yup";
// import { useDispatch } from "react-redux";
import alertify from "alertifyjs";
import { DatePicker, Select } from "antd";
import { useGetOffsetLoanDetailQuery, useGetOffsetMemberLoanQuery, useGetOffsetModeQuery, useGetOffsetTypeQuery, useSubmitLoanOffsetMutation } from "../../../services/member.service";
import { filterOption } from "../../../utils/helpers";


const FormSchema = Yup.object().shape({
    loan_id: Yup.string().required('Please select loan'),
    offset_mode: Yup.string().required('This field is required'),
    offset_type: Yup.string().required('This field is required'),
    loan_amount: Yup.string().required('This field is required'),
});

export const LoanOffsetForm = ({coop_id, cooperative_id}) => {
    const [loans, setLoans] = useState([]);
    const [loanId, setLoanId] = useState(null);
    const [offsetType, setOffsetType] = useState(null);
    const [offsetMode, setOffsetMode] = useState(null);
    const {data: offsetModeData} = useGetOffsetModeQuery();
    const {data: offsetTypeData} = useGetOffsetTypeQuery();
    const {data: banksData} = useGetAllBanksQuery(cooperative_id, {skip: !cooperative_id});
    const [save, {isLoading, isError, isSuccess, data, error }] = useSubmitLoanOffsetMutation();

    const {data: loanData, isFetching} = useGetOffsetLoanDetailQuery({
        coopId: cooperative_id,
        id: loanId
    }, {skip: !loanId});

    const {data: loansData} = useGetOffsetMemberLoanQuery({
        coopId: cooperative_id,
        memberId: coop_id
    }, {skip: !coop_id});

    useEffect(() => {
        if (loansData && loansData.status) {
          setLoans(loansData.data.map(item => ({value: item.id, label: item?.product.name})));
        }
    }, [loansData]);

    const {
        control,
        reset,
        getValues,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(FormSchema),
        mode: 'onBlur'
    });

    const onSubmit = (values) => {
        // console.log(values);
        values.coop_id = coop_id;
        values.cooperative_id = cooperative_id;
        values.offset_mode = parseInt(values.offset_mode);
        values.offset_type = parseInt(values.offset_type);
        save(values);
    }

    useEffect(() => {
        if(isSuccess) {
            resetForm();
            // show toast.
            alertify.success(data?.message)
        }

        if (isError) {
            alertify.error(error?.data?.message);
        }
    }, [isSuccess, isError, data]);

    useEffect(() => {
        if (loanData) {
            setValue('loan_amount', loanData.data.loan_amount); 
            setValue('loan_balance', loanData.data.loan_balance); 
        }
    }, [loanData]);

    const resetForm = () => {
        reset({
            loan_id: "",
            offset_mode: "",
            offset_type: "",
            offset_amount: "",
            loan_amount: "",
            loan_balance: "",
            bank_id: "",
            date: "",
            restructure_tenure: ""
        })
    }

    const doCancel = () => {
        resetForm();
    }
    

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={`dimmer ${isLoading || isFetching ? 'active' : ''}`}>
            <div className="loader" />
            <div className="row clearfix dimmer-content">
                <div className="col-md-4">
                    <div className="form-group">
                        <label>Loan <span className="form-required">*</span></label>
                        <Controller
                            name="loan_id"
                            control={control}
                            render={({ field }) => (
                                <Select 
                                    showSearch
                                    className="customSelect mt-1"
                                    options={loans} 
                                    placeholder="Select Loan"
                                    filterOption={filterOption}
                                    value={field.value}
                                    onChange={(value) => {
                                        field.onChange(value);
                                        setLoanId(value);
                                    }}
                                />
                            )}
                        />
                        {errors.loan_id && <div className="invalid-feedback">{errors.loan_id?.message}</div>}
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label>Offset Mode <span className="form-required">*</span></label>
                        <Controller
                            name="offset_mode"
                            control={control}
                            render={({ field }) => (
                                <Select 
                                    showSearch
                                    className="customSelect mt-1"
                                    options={offsetModeData?.data.map(mode => ({value: mode.id, label: mode.name}))} 
                                    placeholder="Select Offset Mode"
                                    filterOption={filterOption}
                                    value={field.value}
                                    onChange={(value) => {
                                        field.onChange(value);
                                        setOffsetMode(value)
                                    }}
                                />
                            )}
                        />
                        {errors.offset_mode && <div className="invalid-feedback">{errors.offset_mode?.message}</div>}
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label>Offset Type <span className="form-required">*</span></label>
                        <Controller
                            name="offset_type"
                            control={control}
                            render={({ field }) => (
                                <Select 
                                    showSearch
                                    className="customSelect mt-1"
                                    options={offsetTypeData?.data.map(mode => ({value: mode.id, label: mode.name}))} 
                                    placeholder="Select Offset Type"
                                    filterOption={filterOption}
                                    value={field.value}
                                    onChange={(value) => {
                                        // console.log(value)
                                        field.onChange(value);
                                        setOffsetType(value)
                                    }}
                                />
                            )}
                        />
                        {errors.offset_type && <div className="invalid-feedback">{errors.offset_type?.message}</div>}
                    </div>
                </div>
                
                
                <div className="col-md-4">
                    <div className="form-group">
                        <label>Loan Amount</label>
                        <Controller
                            name="loan_amount"
                            control={control}
                            render={({ field }) => (
                                <input
                                    className={`form-control`}
                                    type="text"
                                    onChange={field.onChange}
                                    value={field.value}
                                    autoComplete="true"
                                    readOnly
                                />
                            )}
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label>Loan Balance </label>
                        <Controller
                            name="loan_balance"
                            control={control}
                            render={({ field }) => (
                                <input
                                    className={`form-control`}
                                    type="text"
                                    onChange={field.onChange}
                                    value={field.value}
                                    autoComplete="true"
                                    readOnly
                                />
                            )}
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label>Amount to Offset </label>
                        <Controller
                            name="offset_amount"
                            control={control}
                            render={({ field }) => (
                                <input
                                    className={`form-control`}
                                    type="text"
                                    onChange={field.onChange}
                                    value={field.value}
                                    autoComplete="true"
                                    readOnly={(offsetType === 1 || offsetType === 3) ? false : true}
                                />
                            )}
                        />
                    </div>
                </div>
                {(offsetType === 1 || offsetType === 3) &&
                    <>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label>Repayment Start Date <span className="form-required">*</span></label>
                                <Controller
                                    name="date"
                                    control={control}
                                    render={({ field }) => (
                                        <DatePicker
                                            className={`customInput w-full`} 
                                            name={'date'} 
                                            style={{width: '100%'}}
                                            onChange={(date) => {
                                                field.onChange(dayjs(date).format('YYYY-MM-DD'));
                                            }} 
                                            // onBlur={onBlur} 
                                            value={field.value ? dayjs(field.value) : undefined} 
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label>Restructure Tenure</label>
                                <Controller
                                    name="restructure_tenure"
                                    control={control}
                                    render={({ field }) => (
                                        <input
                                            className={`form-control`}
                                            type="number"
                                            onChange={field.onChange}
                                            value={field.value}
                                            autoComplete="true"
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </>
                }
                {offsetMode === 1 &&
                    <div className="col-md-4">
                        <div className="form-group">
                            <label>Bank Account</label>
                            <Controller
                                name="bank_id"
                                control={control}
                                render={({ field }) => (
                                    <Select 
                                        showSearch
                                        className="customSelect mt-1"
                                        options={banksData?.data.map(mode => ({value: mode.id, label: mode.bank.name}))} 
                                        placeholder="Select Bank"
                                        filterOption={filterOption}
                                        value={field.value}
                                        onChange={(value) => {
                                            field.onChange(value);
                                        }}
                                    />
                                )}
                            />
                        </div>
                    </div>
                }
                
            </div>
            
            <div className="d-flex justify-content-between">
                <button type="button" onClick={doCancel} className="btn btn-secondary">Reset</button>
                <button type="submit" className="btn btn-primary">Submit</button>
            </div>
        </form>
            
    )
}