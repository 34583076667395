import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import * as Yup from "yup";
import alertify from "alertifyjs";
import { useChangeMemberPasswordMutation } from "../../../services/member.service";
import Card from "../../common/Card";

export const FormSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .max(20, "Password must be at most 20 characters")
      .when('id', (id, schema) => !id ? schema.required("Please enter a password") : schema),
    password_confirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match')

});

export const ChangePasswordComponent = ({memberId, coopId}) => {

    console.log(memberId, coopId)
    const [save, {isLoading, isSuccess, isError, data, error}] = useChangeMemberPasswordMutation();

    const {
        control,
        reset,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(FormSchema),
        mode: 'onBlur'
    });

    useEffect(() => {
        if(isSuccess) {
            reset({
                password: '',
                password_confirmation: ''
            });
            // show toast.
            alertify.success(data?.message)
        }

        if (isError) {
            alertify.error(error?.data?.message);
        }
    }, [isSuccess, isError, data])

    const submit = (values) => {
        const payload = {...values};
        payload.coop_id = memberId;
        payload.cooperative_id = coopId;
        save(payload);
    }

    return (
        <Card loading={isLoading} title="Change Password Form" >
            <form onSubmit={handleSubmit(submit)}>
                <div className="card-body dimmer-content">
                    <div className="row">
                        <div className="col-sm-6 col-md-6">
                            <div className="form-group">
                                <label className="form-label">New Password <span className="form-required">*</span></label>
                                <Controller
                                    name="password"
                                    control={control}
                                    render={({ field }) => (
                                        <input
                                            className={`form-control ${errors?.password ? "is-invalid" : ""}`}
                                            type="password"
                                            onChange={field.onChange}
                                            value={field.value}
                                            autoComplete="true"
                                            placeholder="Password *"
                                        />
                                    )}
                                />
                                {errors.password && <div className="invalid-feedback">{errors.password?.message}</div>}
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-6">
                            <div className="form-group">
                                <label className="form-label">Confirm Password <span className="form-required">*</span></label>
                                <Controller
                                    name="password_confirmation"
                                    control={control}
                                    render={({ field }) => (
                                        <input
                                            className={`form-control ${errors?.password_confirmation ? "is-invalid" : ""}`}
                                            type="password"
                                            onChange={field.onChange}
                                            value={field.value}
                                            autoComplete="true"
                                            placeholder="Confirm Password *"
                                        />
                                    )}
                                />
                                {errors.password_confirmation && <div className="invalid-feedback">{errors.password_confirmation?.message}</div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-footer text-right">
                    <button type="submit" className="btn btn-primary">Submit</button>
                </div>
            </form>
        </Card>
    )
}