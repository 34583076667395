
import React, { useState } from "react";
import CoaCatForm from "./CoaCatForm";
import { useGetCoaCategoriesQuery } from "../../../../services/cooperative-setup.service";
import { useDispatch } from "react-redux";
import { toggleDelete } from "../../../../redux/slices/ui.slice";
import { DELETE_COA_CATEGORY, DELETE_COOP_COA_CATEGORY } from "../../../../services/CONSTANTS";
import { Tooltip } from "antd";

export const ManageCoaCategory = ({coop}) => {
    const {data, isLoading} = useGetCoaCategoriesQuery(coop?.id);
    const [category, setCategory] = useState(null);

    const dispatch = useDispatch();

    const doDelete = (e, item) => {
        // console.log(e.currentTarget)
        dispatch(
          toggleDelete({
            open: true,
            title: 'COA Category',
            url: coop ? `${DELETE_COOP_COA_CATEGORY}?category_id=${item.id}&cooperative_id=${coop.id}` : `${DELETE_COA_CATEGORY}?category_id=${item.id}`,
            // ele: JSON.stringify(e.currentTarget),
            parent: 'tr'
          })
        );
      }

    return (
        <div className='row'>
            <div className='col-md-8 col-sm-12'>
                <div className={`card dimmer ${isLoading ? 'active' : ''}`}>
                    <div className="card-header">
                        <h3 className="card-title">COA Categories</h3>
                    </div>
                    <div className="loader" />
                    <div className="card-body dimmer-content">
                        <div className="table-responsive">
                            <table className="table table-striped table-vcenter table-hover mb-0">
                                <thead>
                                    <tr>
                                        <th>Code</th>
                                        <th>Name</th>
                                        <th>Type</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.data.map(row => 
                                    <tr key={`cat-${row.id}`}>
                                        <td><div className="font-15">{row.code}</div></td>
                                        <td>{row.name}</td>
                                        <td>{row.type === 'D' ? 'Debit' : 'Credit'}</td>
                                        <td>
                                            {row.status === 1 && <span className="tag tag-success">Active</span>}
                                            {row.status === 0 && <span className="tag tag-danger">Inactive</span>}
                                        </td>
                                        <td>
                                            <Tooltip title="Edit">
                                                <button type="button" className="btn btn-icon" onClick={() => setCategory(row)}>
                                                <i className="icon-pencil text-info" />
                                                </button>
                                            </Tooltip>
                                            <Tooltip title="Delete">
                                                <button type="button" className="btn btn-icon" data-target="#deleteModal" data-toggle="modal" onClick={(e) => doDelete(e, row)}>
                                                    <i className="icon-trash text-danger" />
                                                </button>
                                            </Tooltip>
                                        </td>
                                    </tr>
                                    )}
                                    {data?.data.length === 0 &&
                                            <tr>
                                                <td colSpan={5} className='text-center'>No data available</td>
                                            </tr>
                                        }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-md-4 col-sm-12'>    
                <CoaCatForm coop={coop} category={category} onCancel={() => setCategory(null)} />
            </div>
        </div>
    )
}