
import React from "react";
import { useSelector } from "react-redux";
import AssetGls from "./AssetGls";
import AssetGlForm from "./AssetGlForm";

const DividendGlSetup = () => {
    const {isFixNavbar: fixNavbar} = useSelector(state => state.settings);

    return (
        <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>

            <div className="row">
                <div className="col-md-8 col-sm-12">
                    <AssetGls />
                </div>
                <div className="col-md-4 col-sm-12">
                    <AssetGlForm />
                </div>
            </div>
        </div>
    )
}

export default DividendGlSetup;