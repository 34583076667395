import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { CooperativeList } from './CooperativeList';
import { CooperativeForm } from './CooperativeForm';

const Cooperatives = () => {
    const {isFixNavbar: fixNavbar} = useSelector(state => state.settings);
    const [selected, setSelected] = useState(null);

    return (
        <div>
            <div>
                <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
                    <div className="container-fluid">
                        <div className="d-flex justify-content-between align-items-center">
                            <ul className="nav nav-tabs page-header-tab">
                                <li className="nav-item"><a className="nav-link active" id="coop-list-tab" data-toggle="tab" href="#cooperative-list">Cooperative List</a></li>
                                <li className="nav-item"><a className="nav-link" id="coop-form-tab" data-toggle="tab" href="#cooperative-form">Cooperative Form</a></li>
                            </ul>
                            {/* <div className="header-action">
                                <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#addRole">
                                    <i className="fe fe-plus mr-2" /> Add
                                </button>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="section-body mt-3">
                    <div className="container-fluid">
                        <div className="tab-content mt-3">
                            <div className="tab-pane fade show active" id="cooperative-list" role="tabpanel">
                                <CooperativeList 
                                    onEdit={(val) => {
										setSelected(val);
										document.getElementById('coop-form-tab').click()
									}} 
                                />
                            </div>
                            <div className="tab-pane fade" id="cooperative-form" role="tabpanel">
                                <div className="row clearfix">
                                    <CooperativeForm coop={selected} onCancel={() => {
                                        document.getElementById('coop-list-tab').click();
                                        setSelected(null)
                                    }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            
        </div>
    );
}

export default Cooperatives;