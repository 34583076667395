
import React from "react";
import { useDispatch } from "react-redux";
import { toggleDelete } from "../../../redux/slices/ui.slice";
import { DELETE_CHARGE, DELETE_COOP_CHARGE } from "../../../services/CONSTANTS";
import { useGetChargesQuery } from "../../../services/cooperative-setup.service";
import { Tooltip } from "antd";
import Card from "../../common/Card";

const ChargeList = ({onEdit, coop}) => {
    const dispatch = useDispatch();

    const {data, isLoading} = useGetChargesQuery(coop?.id);

    const doDelete = (e, item) => {
        // console.log(e.currentTarget)
        dispatch(
          toggleDelete({
            open: true,
            title: 'Charge',
            url: coop ? `${DELETE_COOP_CHARGE}?charge_id=${item.id}&cooperative_id=${coop?.id}` : `${DELETE_CHARGE}?charge_id=${item.id}`,
            // ele: JSON.stringify(e.currentTarget),
            parent: 'tr'
          })
        );
    }
    return (
        <Card 
            title={`Charge Listing`}
            loading={isLoading}
        >
            <div className="card-body dimmer-content">
                <div className="table-responsive">
                    <table className="table table-striped table-vcenter table-hover mb-0">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Value (%)</th>
                                <th>Posting GL</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.data.map(row =>
                                <tr key={row.id}>
                                    <td>{row.name}</td>
                                    <td>{row.amount}</td>
                                    <td>{row.posting ? row.posting.code + ' ' + row.posting.name : row.posting_gl}</td>
                                    <td>
                                        {row.status === 1 && <span className="tag tag-success">Active</span>}
                                        {row.status === 0 && <span className="tag tag-danger">Inactive</span>}
                                    </td>
                                    <td>
                                        <Tooltip title="Edit">
                                            <button type="button" className="btn btn-icon" onClick={() => onEdit(row)}>
                                                <i className="icon-pencil text-info" />
                                            </button>
                                        </Tooltip>
                                        <Tooltip title="Delete">
                                            <button type="button" className="btn btn-icon" data-target="#deleteModal" data-toggle="modal" onClick={(e) => doDelete(e, row)}>
                                                <i className="icon-trash text-danger" />
                                            </button>
                                        </Tooltip>
                                    </td>
                                </tr>
                            )}
                            
                            
                            
                            
                        </tbody>
                    </table>
                </div>
            </div>
        </Card>
    )
}

export default ChargeList;