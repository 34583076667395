import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Activity } from './Activity';
import { Loans } from './Loans';
import { SuretyRequest } from './SuretyRequest';
import { SavingsTable } from './SavingsTable';
import { DepositModal } from '../../MemberArea/Components/DepositFormModal';
import { TransactionHistory } from './TransactionHistory';
import { ChangePassword, ChangePasswordComponent } from './ChangePasswordComponent';
import { WithdrawalModal } from '../../MemberArea/Components/WithdrawalModalForm';
import { useGetCoopMemberQuery, useGetMemberSavingsQuery } from '../../../services/member.service';
import alertify from 'alertifyjs';
import dayjs from 'dayjs';
import Card from '../../common/Card';
import { EditMemberForm } from './EditMemberForm';

function MemberDetails ({location}){
	
	const {search} = location;
	const memberId = new URLSearchParams(search).get('member_id');
	const {activeCoop} = useSelector(state => state.coop);
	const [member, setMember] = useState(null);
	const {isFixNavbar: fixNavbar} = useSelector(state => state.settings);

	const {data, isLoading, isError, error} = useGetCoopMemberQuery({coop_id: activeCoop?.id, member_id: memberId}, {skip: !memberId});

    const {data: savingsData} = useGetMemberSavingsQuery({coop_id: member?.coop_id, id: activeCoop?.id}, {skip: !memberId});

	useEffect(() => {
		if (data) {
			setMember(data?.data);
		}

		if (isError) {
			alertify.error(error.message);
		}
	}, [data]);


	return (
		<>
			<div>
				<div>
					<div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
						<div className="container-fluid">
							<div className="d-flex justify-content-between align-items-center mb-3">
								<ul className="nav nav-tabs page-header-tab">
									<li className="nav-item">
										<a
											className="nav-link active"
											id="Employee-tab"
											data-toggle="tab"
											href="#member-info"
										>
											Member Info
										</a>
									</li>
									<li className="nav-item">
										<a
											className="nav-link"
											id="Employee-tab"
											data-toggle="tab"
											href="#loans"
										>
											Loans
										</a>
									</li>
									<li className="nav-item">
										<a
											className="nav-link"
											id="Employee-tab"
											data-toggle="tab"
											href="#savings"
										>
											Savings
										</a>
									</li>
									<li className="nav-item">
										<a
											className="nav-link"
											id="Employee-tab"
											data-toggle="tab"
											href="#surety-request"
										>
											Surety Request
										</a>
									</li>
									<li className="nav-item">
										<a
											className="nav-link"
											id="Employee-tab"
											data-toggle="tab"
											href="#transactions"
										>
											Transactions
										</a>
									</li>
									<li className="nav-item">
										<a
											className="nav-link"
											id="Employee-tab"
											data-toggle="tab"
											href="#edit-profile"
										>
											Edit Profile
										</a>
									</li>
									<li className="nav-item">
										<a
											className="nav-link"
											id="Employee-tab"
											data-toggle="tab"
											href="#change-password"
										>
											Change Password
										</a>
									</li>
								</ul>
								{/* <div className="header-action">
									<button
										type="button"
										className="btn btn-primary"
										data-toggle="modal"
										data-target="#exampleModal"
									>
										<i className="fe fe-plus mr-2" />
										Add
									</button>
								</div> */}
							</div>
							
						</div>
					</div>
					<div className="section-body">
						<div className="container-fluid">
							<div className="row clearfix">
								<div className="col-lg-3 col-md-12">
									<div className="card c_grid c_yellow">
										<div className="card-body text-center px-0">
											<div className="circle">
												<img className="rounded-circle" src="../assets/images/sm/avatar1.jpg" alt="fake_url" />
											</div>
											<h6 className="mt-3 mb-0">{member?.firstname} {member?.lastname}</h6>
											<span>{member?.role.display_name}</span><br />
											<span>{member?.coop_id}</span>
											<ul className="mt-3 list-unstyled d-flex justify-content-center">
												</ul>
											<div className="btn-group">
												<button className="btn p-2 btn-success btn-sm" data-toggle="modal" data-target="#deposit-modal">Deposit</button>
												<button className="btn p-2 btn-danger btn-sm" data-toggle="modal" data-target="#withdrawal-modal">Withdrawal</button>
												<button className="btn p-2 btn-primary btn-sm">Form	</button>
											</div>
											<ul className="list-group text-left mb-3">
												<li className="list-group-item">
													<small className="text-muted">Gender: </small>
													<p className="mb-0">{member?.gender}</p>
												</li>
												<li className="list-group-item">
													<small className="text-muted">Address: </small>
													<p className="mb-0">{member?.current_address}</p>
												</li>
												<li className="list-group-item">
													<small className="text-muted">Date of birth: </small>
													<p className="mb-0">{dayjs(member?.dob).format('DD MMM YYYY')}</p>
												</li>
												<li className="list-group-item">
													<small className="text-muted">Date Joined: </small>
													<p className="mb-0">{dayjs(member?.date_joined).format('DD MMM YYYY')}</p>
												</li>
											</ul>
										</div>
									</div>
								</div>
								<div className="col-lg-9 col-md-12">
									<div className="tab-content">
										<div className="tab-pane fade show active" id="member-info" role="tabpanel">
											<Activity activites={member?.activity} />
											<div className="row">
												<div className="col-md-6 col-sm-12">
													<Card title={`Work Info`}>
														<div className="card-body">
															<div className="row">
																<div className="col-4 py-1"><strong>Department:</strong></div>
																<div className="col-8 py-1">{member?.workInfo.department?.name || 'N/A'}</div>
																<div className="col-4 py-1"><strong>Phone:</strong></div>
																<div className="col-8 py-1">{member?.workInfo.official_phone || 'N/A'}</div>
																<div className="col-4 py-1"><strong>Email:</strong></div>
																<div className="col-8 py-1">{member?.workInfo.official_email || 'N/A'}</div>
																<div className="col-4 py-1"><strong>Level:</strong></div>
																<div className="col-8 py-1">{member?.workInfo.level}</div>
															</div>
														</div>
													</Card>
												</div>
												<div className="col-md-6 col-sm-12">
													<Card title={`Next of Kin Info`}>
														<div className="card-body">
															<div className="row">
																<div className="col-4 py-1"><strong>Name:</strong></div>
																<div className="col-8 py-1">{member?.nokInfo.name}</div>
																<div className="col-4 py-1"><strong>Phone:</strong></div>
																<div className="col-8 py-1">{member?.nokInfo.phone || 'N/A'}</div>
																<div className="col-4 py-1"><strong>Email:</strong></div>
																<div className="col-8 py-1">{member?.nokInfo.email || 'N/A'}</div>
																<div className="col-4 py-1"><strong>Address:</strong></div>
																<div className="col-8 py-1">{member?.nokInfo.address || 'N/A'}</div>
															</div>
														</div>
													</Card>
												</div>
											</div>
										</div>
										
										<div className="tab-pane fade" id="loans" role="tabpanel">
											<Loans coop={activeCoop} member={member} />
										</div>
										<div className="tab-pane fade" id="savings" role="tabpanel">
											<SavingsTable savings={savingsData?.data} coop={activeCoop} memberId={member?.coop_id} />
										</div>
										<div className="tab-pane fade" id="surety-request" role="tabpanel">
											<SuretyRequest coop={activeCoop} memberId={member?.coop_id} />
										</div>
										<div className="tab-pane fade" id="transactions" role="tabpanel">
											<TransactionHistory coop={activeCoop} memberId={member?.coop_id} />
										</div>
										<div className="tab-pane fade" id="edit-profile" role="tabpanel">
											<EditMemberForm activeCoop={activeCoop} member={member} />
										</div>
										<div className="tab-pane fade" id="change-password" role="tabpanel">
											<ChangePasswordComponent coopId={activeCoop?.id} memberId={member?.coop_id} />
										</div>
									</div>
								</div>
							</div>
						</div>
						
					</div>

				</div>
			</div>
			<DepositModal savings={savingsData?.data} coop_id={member?.coop_id} cooperative_id={activeCoop?.id} />
			<WithdrawalModal savings={savingsData?.data} coop_id={member?.coop_id} cooperative_id={activeCoop?.id} />
		</>
	);
}


export default MemberDetails;