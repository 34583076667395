import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import * as Yup from "yup";

const FormSchema = Yup.object().shape({
    nok_name: Yup.string().required('This field is required'),
    nok_address: Yup.string().required('This field is required'),
    nok_phone: Yup.string().required('This field is required'),
    nok_email: Yup.string().required('This field is required'),
});

export const NokDetails = ({canSubmit, isChanging, onChange, details}) => {
    const {
        control,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(FormSchema),
        mode: 'onBlur'
    });

    useEffect(() => {
        if (isChanging) {
            document.getElementById('submit').click();
        }
    }, [isChanging]);

    useEffect(() => {
        if (details) {
            const nok = details.nokInfo;
            setValue('nok_name', nok.name)
            setValue('nok_address', nok.address)
            setValue('nok_phone', nok.phone)
            setValue('nok_email', nok.email)
        }
    }, [details]);


    const doSubmit = () => {
        canSubmit('NOK-INFO');
    }

    return (
        <form onSubmit={handleSubmit(doSubmit)} className="row clearfix">
            <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                    <label>Name  <span className="form-required">*</span></label>
                    <Controller
                        name="nok_name"
                        control={control}
                        render={({ field }) => (
                            <input
                                className={`form-control ${
                                errors?.nok_name ? "is-invalid" : ""
                                }`}
                                type="text"
                                onChange={(e) => {
                                    field.onChange(e);
                                    onChange('nok_name', e.target.value)
                                }}
                                value={field.value}
                                autoComplete="true"
                            />
                        )}
                    />
                    {errors.nok_name && <div className="invalid-feedback">{errors.nok_name?.message}</div>}
                </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                    <label>Address <span className="form-required">*</span> </label>
                    <Controller
                        name="nok_address"
                        control={control}
                        render={({ field }) => (
                            <input
                                className={`form-control ${
                                errors?.nok_address ? "is-invalid" : ""
                                }`}
                                type="text"
                                onChange={(e) => {
                                    field.onChange(e);
                                    onChange('nok_address', e.target.value)
                                }}
                                value={field.value}
                                autoComplete="true"
                            />
                        )}
                    />
                    {errors.nok_address && <div className="invalid-feedback">{errors.nok_address?.message}</div>}
                </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                    <label>Phone <span className="form-required">*</span> </label>
                    <Controller
                        name="nok_phone"
                        control={control}
                        render={({ field }) => (
                            <input
                                className={`form-control ${
                                errors?.nok_phone ? "is-invalid" : ""
                                }`}
                                type="text"
                                onChange={(e) => {
                                    field.onChange(e);
                                    onChange('nok_phone', e.target.value)
                                }}
                                value={field.value}
                                autoComplete="true"
                            />
                        )}
                    />
                    {errors.nok_phone && <div className="invalid-feedback">{errors.nok_phone?.message}</div>}
                </div>
            </div>
            <div className="col-lg-6 col-md-4 col-sm-6">
                <div className="form-group">
                    <label>Email<span className="form-required">*</span> </label>
                    <Controller
                        name="nok_email"
                        control={control}
                        render={({ field }) => (
                            <input
                                className={`form-control ${
                                    errors?.nok_phone ? "is-invalid" : ""
                                    }`}
                                type="text"
                                onChange={(e) => {
                                    field.onChange(e);
                                    onChange('nok_email', e.target.value)
                                }}
                                value={field.value}
                                autoComplete="true"
                            />
                        )}
                    />
                    {errors.nok_email && <div className="invalid-feedback">{errors.nok_email?.message}</div>}
                </div>
            </div>
            <button id="submit" type="submit" className="nok-details-btn" style={{display: 'none'}}>Submit</button>
        </form>
    )
}