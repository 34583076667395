import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import * as Yup from "yup";
import { useGetAllCoaQuery } from "../../../services/cooperative-setup.service";
import { DatePicker, Select } from "antd";
import { filterOption } from "../../../utils/helpers";
import dayjs from "dayjs";
import alertify from "alertifyjs";
import { useSaveInvestmentTrackerMutation } from "../../../services/utility.service";

const FormSchema = Yup.object().shape({
    invested_with: Yup.string().required('This field is required'),
    investment_amount: Yup.string().required('This field is required'),
    investment_income: Yup.string().required('This field is required'),
    start_date: Yup.string().required('This field is required'),
    due_date: Yup.string().required('This field is required'),
    gl_code: Yup.string().required('This field is required'),
    investment_interest_gl: Yup.string().required('This field is required'),
    status: Yup.string().required('This field is required'),
});

export const InvestmentTrackerForm = ({cooperative_id}) => {
    const {data: glData} = useGetAllCoaQuery(cooperative_id, {skip: !cooperative_id});
    const [save, {isLoading, isSuccess, isError, error, data}] = useSaveInvestmentTrackerMutation()
    const {
        control,
        reset,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(FormSchema),
        mode: 'onBlur'
    });


    const onSubmit = (values) => {
        const payload = {...values};
        payload.cooperative_id = cooperative_id;
        payload.start_date = dayjs(values.start_date).format('YYYY-MM-DD');
        payload.due_date = dayjs(values.due_date).format('YYYY-MM-DD');
        save(payload);
    }

    useEffect(() => {
        if(isSuccess) {
            resetForm();
            // show toast.
            alertify.success(data?.message)
        }

        if (isError) {
            alertify.error(error?.data?.message);
        }
    }, [isSuccess, isError, data])

    const resetForm = () => {
        reset({})
    }

    return (
        <div className="modal fade" id="investment_tracker_form" tabIndex={-1} role="dialog" aria-labelledby="investment_tracker_form" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <form onSubmit={handleSubmit(onSubmit)} className={`modal-content dimmer ${isLoading ? 'active' : ''}`}>
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Investment Tracker Form</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                    </div>
                    <div className="loader" /> 
                    <div className="modal-body dimmer-content">
                        <div className="row clearfix">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Investment</label>
                                    <Controller
                                        name="invested_with"
                                        control={control}
                                        render={({ field }) => (
                                            <input
                                                className={`form-control ${
                                                errors?.invested_with ? "is-invalid" : ""
                                                }`}
                                                type="text"
                                                onChange={field.onChange}
                                                placeholder="Invest With"
                                                value={field.value}
                                            />
                                        )}
                                    />
                                    {errors.invested_with && <div className="invalid-feedback">{errors.invested_with?.message}</div>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Amount</label>
                                    <Controller
                                        name="investment_amount"
                                        control={control}
                                        render={({ field }) => (
                                            <input
                                                className={`form-control ${
                                                errors?.investment_amount ? "is-invalid" : "" }`}
                                                type="text"
                                                onChange={field.onChange}
                                                value={field.value}
                                                autoComplete="true"
                                                placeholder="Investment Amount"
                                            />
                                        )}
                                    />
                                    {errors.investment_amount && <div className="invalid-feedback">{errors.investment_amount?.message}</div>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Income</label>
                                    <Controller
                                        name="investment_income"
                                        control={control}
                                        render={({ field }) => (
                                            <input
                                                className={`form-control ${
                                                errors?.investment_income ? "is-invalid" : ""
                                                }`}
                                                type="text"
                                                onChange={field.onChange}
                                                value={field.value}
                                                placeholder="Investment Income"
                                            />
                                        )}
                                    />
                                    {errors.investment_income && <div className="invalid-feedback">{errors.investment_income?.message}</div>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Start Date</label>
                                    <Controller
                                        name="start_date"
                                        control={control}
                                        render={({ field }) => (
                                            <DatePicker
                                                className={`customInput w-full`} 
                                                name={'start_date'} 
                                                style={{width: '100%'}}
                                                onChange={field.onChange} 
                                                // onBlur={onBlur} 
                                                value={field.value ? dayjs(field.value) : undefined} 
                                            />
                                        )}
                                    />
                                    {errors.start_date && <div className="invalid-feedback">{errors.start_date?.message}</div>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Due Date</label>
                                    <Controller
                                        name="due_date"
                                        control={control}
                                        render={({ field }) => (
                                            <DatePicker
                                                className={`customInput w-full`} 
                                                name={'due_date'} 
                                                style={{width: '100%'}}
                                                onChange={field.onChange} 
                                                // onBlur={onBlur} 
                                                value={field.value ? dayjs(field.value) : undefined} 
                                            />
                                        )}
                                    />
                                    {errors.due_date && <div className="invalid-feedback">{errors.due_date?.message}</div>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>GL Account</label>
                                    <Controller
                                        name="gl_code"
                                        control={control}
                                        render={({ field }) => (
                                            <Select 
                                                showSearch
                                                className="customSelect mt-1"
                                                options={glData?.data.map(coa => ({value: coa.id, label: coa.name}))} 
                                                placeholder="Select GL Account"
                                                filterOption={filterOption}
                                                value={field.value}
                                                onChange={field.onChange}
                                            />
                                            
                                        )}
                                    />
                                    {errors.gl_code && <div className="invalid-feedback">{errors.gl_code?.message}</div>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Investment Interest GL</label>
                                    <Controller
                                        name="investment_interest_gl"
                                        control={control}
                                        render={({ field }) => (
                                            <Select 
                                                showSearch
                                                className="customSelect mt-1"
                                                options={glData?.data.map(coa => ({value: coa.id, label: coa.name}))} 
                                                placeholder="Select GL Account"
                                                filterOption={filterOption}
                                                value={field.value}
                                                onChange={field.onChange}
                                            />
                                            
                                        )}
                                    />
                                    {errors.gl_code && <div className="invalid-feedback">{errors.gl_code?.message}</div>}
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Comment</label>
                                    <Controller
                                        name="comment"
                                        control={control}
                                        render={({ field }) => (
                                            <textarea 
                                                type="text" 
                                                rows={4} 
                                                className="form-control" 
                                                placeholder="Description"
                                                onChange={field.onChange}
                                            >
                                                {field.value}
                                            </textarea>
                                        )}
                                    />
                                </div>
                            </div>
                            
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Status</label>
                                    <Controller
                                        name="status"
                                        control={control}
                                        render={({ field }) => (
                                            <select 
                                                onChange={field.onChange}
                                                value={field.value}
                                                className={`form-control ${errors?.status ? "is-invalid" : ""}`}
                                            >
                                                <option value=''>Select Status</option>
                                                <option value='1'>Active</option>
                                                <option value='0'>Inactive</option>
                                            </select>
                                        )}
                                    />
                                    {errors.status && <div className="invalid-feedback">{errors.status?.message}</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" onClick={resetForm} className="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </div>
                </form>
            </div>
        </div>

    )
}