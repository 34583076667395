import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import LoansSetupList from './Loans/LoanSetupList';
import LoanSetupForm from './Loans/LoanSetupForm';

const LoansSetup = () => {

   const {isFixNavbar: fixNavbar} = useSelector(state => state.settings);
   const {activeCoop} = useSelector(state => state.coop);
    const [loan, setLoan] = useState(null);
    return (
        <>
            <div>
                <div>
                    <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
                        <div className="container-fluid">
                            <div className="d-flex justify-content-between align-items-center">
                                <ul className="nav nav-tabs page-header-tab">
                                    <li className="nav-item"><a className="nav-link active" id="loans-list-tab" data-toggle="tab" href="#setups-list">Setups</a></li>
                                    <li className="nav-item"><a className="nav-link" id="loan-form-tab" data-toggle="tab" href="#new-setup">Add New Setup</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="section-body mt-3">
                        <div className="container-fluid">
                            <div className="tab-content mt-3">
                                <div className="tab-pane fade show active" id="setups-list" role="tabpanel">
                                    <LoansSetupList 
                                        coop={activeCoop}
                                        onEdit={(val) => {
                                        setLoan(val);
                                        document.getElementById('loan-form-tab').click();
                                    }} />
                                </div>
                                <div className="tab-pane fade" id="new-setup" role="tabpanel">
                                    <LoanSetupForm 
                                        item={loan} 
                                        coop={activeCoop}
                                        onCancel={() => {
                                            setLoan(null);
                                            document.getElementById('loans-list-tab').click();

                                        }} 
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default LoansSetup