import React, { useState } from 'react'
import {  useSelector } from 'react-redux';

import { useGetElectionsQuery } from '../../../services/election.service';
import { Empty } from 'antd';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';

const Elections = () => {
 
    const {isFixNavbar: fixNavbar} = useSelector(state => state.settings);
    const {activeCoop } = useSelector(state => state.coop);
    const [page, setPage] = useState(1);

    const {data, isLoading} = useGetElectionsQuery({coopId: activeCoop?.id, page}, {skip: !activeCoop});
    const navigate = useHistory();
    
    return (
                
            <div className={`section-body ${fixNavbar ? "marginTop" : ""}`}>
                <div className={`container-fluid dimmer ${isLoading ? 'active' : ''}`}>
                    <div className="table-responsive taskboard">
                        <table className="table table-hover table-vcenter mb-0 table_custom spacing8 text-nowrap">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Election</th>
                                    <th>Contestants</th>
                                    <th>Duration</th>
                                    <th>Status</th>
                                    <th className="w200" />
                                </tr>
                            </thead>
                            <tbody className='dimmer'>
                                <div className='loader' />
                                {data?.data?.data.length === 0 && <tr><td colSpan={6}><Empty /></td></tr>}
                                {data?.data?.data.map((row, i) => 
                                    <tr key={`election-${row.id}`} style={{cursor: 'pointer'}} onClick={() => navigate.push(`/member/elections/contestants?id=${row.id}`)}>
                                        <td>{i + 1}</td>
                                        <td>
                                            <h6 className="mb-0">{row.name}</h6>
                                            {/* <span className="d-inline-block text-truncate">{row.description}</span> */}
                                        </td>
                                        <td>
                                            <ul className="list-unstyled team-info mb-0">
                                                <li><img src="../assets/images/xs/avatar1.jpg" data-toggle="tooltip" data-placement="top" title="Avatar" alt="Avatar" /></li>
                                                <li><img src="../assets/images/xs/avatar2.jpg" data-toggle="tooltip" data-placement="top" title="Avatar" alt="Avatar" /></li>
                                                <li><img src="../assets/images/xs/avatar5.jpg" data-toggle="tooltip" data-placement="top" title="Avatar" alt="Avatar" /></li>
                                            </ul>
                                        </td>
                                        <td>
                                            <div className="text-info">Start: {dayjs(row.start_date).format('DD MMM YYYY')}</div>
                                            <div className="text-pink">End: {dayjs(row.end_date).format('DD MMM YYYY')}</div>
                                        </td>
                                        <td>
                                            {row.status === 0 && <span className="tag tag-red">Inactive</span>}
                                            {row.status === 1 && <span className="tag tag-blue">In progress</span>}
                                        </td>
                                        <td>
                                            <div className="clearfix">
                                                <div className="float-left"><strong>0%</strong></div>
                                                <div className="float-right"><small className="text-muted">Progress</small></div>
                                            </div>
                                            <div className="progress progress-xs">
                                                <div className="progress-bar bg-azure" role="progressbar" style={{ width: '0%' }} aria-valuenow={42} aria-valuemin={0} aria-valuemax={100} />
                                            </div>
                                        </td>
                                    </tr> 
                                )}
                                
                            </tbody>
                        </table>
                    </div>
                        
                </div>
            </div>
        )
    }


export default (Elections);