
import React from "react";
import { useDispatch } from "react-redux";
import { useGetDepartmentsQuery } from "../../../services/cooperative-setup.service";
import { toggleDelete } from "../../../redux/slices/ui.slice";
import { DELETE_DEPARTMENT } from "../../../services/CONSTANTS";
import { Empty, Tooltip } from "antd";
import dayjs from "dayjs";
import Card from "../../common/Card";

const DeptList = ({onEdit, activeCoop}) => {

    const {data, isLoading} = useGetDepartmentsQuery(activeCoop?.id, {
        skip: !activeCoop
    });

    const dispatch = useDispatch();

    const doDelete = (e, item) => {
        // console.log(e.currentTarget)
        dispatch(
          toggleDelete({
            open: true,
            title: 'Department',
            url: `${DELETE_DEPARTMENT}?dept_id=${item.id}&cooperative_id=${activeCoop?.id}`,
            // ele: JSON.stringify(e.currentTarget),
            parent: 'tr'
          })
        );
    }
    
    return (
        <Card 
            title={`Departments`}
            loading={`${(isLoading) ? 'active' : ''}`}
        >
            <div className="card-body dimmer-content">
                <div className="table-responsive">
                    <table className="table table-striped table-vcenter table-hover mb-0">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Date Created</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                           
                            {data?.data.map(row => 
                                <tr key={row.id}>
                                    <td>{row.name}</td>
                                    <td>{dayjs(row.created_at).format('Do MMM YYYY, h:mma')}</td>
                                    <td>
                                        {row.status === 1 && <span className="tag tag-success">Active</span>}
                                        {row.status === 0 && <span className="tag tag-danger">Inactive</span>}
                                    </td>
                                    <td>
                                        <Tooltip title="Edit">
                                            <button type="button" className="btn btn-icon btn-sm btn-sm" onClick={() => onEdit(row)}>
                                                <i className="icon-pencil text-info" />
                                            </button>
                                        </Tooltip>
                                        <Tooltip title="Delete">
                                            <button type="button" className="btn btn-icon btn-sm" title="Delete" data-toggle="modal" data-target="#deleteModal" onClick={(e) => doDelete(e, row)}>
                                                <i className="icon-trash text-danger" />
                                            </button>
                                        </Tooltip>
                                    </td>
                                </tr>
                            )}
                            
                            {data?.data.length === 0 && <tr><td colSpan={4}>
                                <Empty />
                                </td></tr>}
                            
                        </tbody>
                    </table>
                </div>
            </div>
        </Card>
    )
}

export default DeptList;