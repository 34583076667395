import React, { useEffect, useState } from "react";
import { useGetElectionsQuery, useGetResultsByElectionQuery, useGetResultsByPositionQuery } from "../../services/election.service";
import { Empty, Select } from "antd";
import { filterOption } from "../../utils/helpers";
import InitialsAvatar from 'react-initials-avatar';

export const ElectionResults = ({activeCoop}) => {
    const [electionId, setElectionId] = useState(null);
    const [positionId, setPositionId] = useState(null);
    const [position, setPosition] = useState(null);
    const [positions, setPositions] = useState([]);
    const [contestants, setContestants] = useState([]);

    const {data: electionsData, isLoading} = useGetElectionsQuery({coopId: activeCoop?.id, page: 1}, {skip: !activeCoop});
    
    const {data: resultsByElection, isFetching: isFetchingElection} = useGetResultsByElectionQuery({
        coopId: activeCoop?.id, 
        electionId
    }, {
        skip: !electionId
    })

    const {data: resultsByPosition, isFetching: isFetchingPosition} = useGetResultsByPositionQuery({
        coopId: activeCoop?.id, 
        positionId
    }, {
        skip: !positionId
    })

    const doSearch = () => {

    }

    useEffect(() => {
        if (resultsByElection) setPositions(resultsByElection?.data.positions)
    }, [resultsByElection]);

    useEffect(() => {

        if (resultsByPosition) {
            setContestants(resultsByPosition?.data.contestants);
            setPosition(resultsByPosition?.data);
        }

    }, [resultsByPosition])

    return (
        <div className="row clearfix">
            <div className="col-12">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-5 col-md-5 col-sm-6">
                                <div className="input-group">
                                    <Select 
                                        showSearch
                                        className="customSelect mt-1"
                                        options={electionsData?.data?.data.map(position => ({label: position.name, value: position.id}))} 
                                        placeholder="Select Election"
                                        filterOption={filterOption}
                                        onChange={(value) => setElectionId(value)}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-5 col-sm-6">
                                <div className="input-group">
                                    <Select 
                                        showSearch
                                        className="customSelect mt-1"
                                        options={positions?.map(position => ({label: position.name, value: position.id}))} 
                                        placeholder="Select Position"
                                        filterOption={filterOption}
                                        onChange={(value) => setPositionId(value)}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-12">
                                <a href="#" onClick={doSearch} className="btn btn-sm btn-primary" >Search</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`card dimmer ${(isFetchingElection || isFetchingPosition) ? 'active' : ''}`}>
                    <div className="loader" />
                    <div className={`card-body dimmer-content`}>
                        <div className="table-responsive">
                            <table className="table table-hover table-striped table-vcenter mb-0">
                                <thead>
                                    <tr>
                                        <th>Contestant</th>
                                        <th>Position</th>
                                        <th className="w100">No. of Votes</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {contestants.length === 0 && <tr><td colSpan={3}><Empty /></td></tr>}

                                    {contestants.map(contestant => 
                                        <tr key={`contestant-${contestant.id}`}>
                                            <td>
                                                <span
                                                    className="avatar avatar-blue w30 rounded-circle mr-2"
                                                    data-toggle="tooltip"
                                                    data-original-title="Avatar Name"
                                                >
                                                    <InitialsAvatar name={`${contestant.member.firstname} ${contestant.member.lastname}`} />
                                                </span>
                                                <span>{contestant.member.firstname} {contestant.member.lastname}</span>
                                            </td>
                                            <td>{position?.name}</td>
                                            <td><span>{contestant.votes}</span></td>
                                        </tr>
                                    )}
                                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}