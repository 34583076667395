import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import * as Yup from "yup";
import alertify from "alertifyjs";

import { useGetRolesByTypeQuery, useGetRolesQuery, useSaveUserMutation, useUpdateUserMutation } from "../../../services/setup.service";

export const FormSchema = Yup.object().shape({
    coop_id: Yup.string().required('Please enter a COOP ID'),
    firstname: Yup.string().required('First name is required'),
    lastname: Yup.string().required('Last name is required'),
    email: Yup.string().email("Please enter a valid email address").required('Email is required'),
    role_id: Yup.string().required('Please elect the user role'),
    id: Yup.string(),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .max(20, "Password must be at most 20 characters")
      .when('id', (id, schema) => !id ? schema.required("Please enter a password") : schema),
    confirm_password: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match')

});

export const UserForm = ({user, onCancel}) => {
    const {data: roles} = useGetRolesByTypeQuery('CALICON');

    const [save, {isLoading, isSuccess, isError, data, error}] = useSaveUserMutation();
    const [update, {
        isLoading: isUpdateLoading, 
        isSuccess: isUpdateSuccess, 
        isError: isUpdateError, 
        data: updateData, 
        error: updateError
    }] = useUpdateUserMutation();

    const {
        control,
        reset,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(FormSchema),
        mode: 'onBlur'
    });

    useEffect(() => {
        if (user) {
            reset({
                firstname: user.firstname,
                lastname: user.lastname,
                coop_id: user.coop_id,
                email: user.email,
                role_id: user.role_id,
                status: user.status,
                id: user.id,
            })
        }
    }, [user]);


    const onSubmit = (values) => {
        if (user) {
            update(values);

        } else {
            save(values);
        }
    }

    useEffect(() => {
        if(isSuccess || isUpdateSuccess) {
            resetForm();
            // show toast.
            alertify.success(data?.message || updateData?.message)
        }

        if (isError || isUpdateError) {
            alertify.error(error?.data?.message || updateError?.data?.message);
        }
    }, [isSuccess, isUpdateSuccess, isUpdateError, isError, data, updateData])

    const resetForm = () => {
        reset({
          coop_id: "",
          firstname: "",
          lastname: "",
          role_id: "",
          email: "",
          password: "",
          confirm_password: "",
          status: "",
          id: "",
        })
    }

    const doCancel = () => {
        resetForm();
        onCancel();
    }

    return (
        <div className={`card dimmer ${(isLoading || isUpdateLoading) ? 'active' : ''}`}>
            <div className="loader" />
            <form className="card-body dimmer-content" onSubmit={handleSubmit(onSubmit)}>
                <div className="row clearfix">
                    <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                            <Controller
                                name="coop_id"
                                control={control}
                                render={({ field }) => (
                                    <input
                                        className={`form-control ${errors?.coop_id ? "is-invalid" : ""}`}
                                        type="text"
                                        onChange={field.onChange}
                                        value={field.value}
                                        autoComplete="true"
                                        placeholder="COOP ID *"
                                    />
                                )}
                            />
                            {errors.coop_id && <div className="invalid-feedback">{errors.coop_id?.message}</div>}
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                            <Controller
                                name="email"
                                control={control}
                                render={({ field }) => (
                                    <input
                                        className={`form-control ${errors?.email ? "is-invalid" : ""}`}
                                        type="email"
                                        onChange={field.onChange}
                                        value={field.value}
                                        autoComplete="true"
                                        placeholder="Email *"
                                    />
                                )}
                            />
                            {errors.email && <div className="invalid-feedback">{errors.email?.message}</div>}
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                            <Controller
                                name="firstname"
                                control={control}
                                render={({ field }) => (
                                    <input
                                        className={`form-control ${errors?.firstname ? "is-invalid" : ""}`}
                                        type="text"
                                        onChange={field.onChange}
                                        value={field.value}
                                        autoComplete="true"
                                        placeholder="First Name *"
                                    />
                                )}
                            />
                            {errors.firstname && <div className="invalid-feedback">{errors.firstname?.message}</div>}
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                            <Controller
                                name="lastname"
                                control={control}
                                render={({ field }) => (
                                    <input
                                        className={`form-control ${errors?.lastname ? "is-invalid" : ""}`}
                                        type="text"
                                        onChange={field.onChange}
                                        value={field.value}
                                        autoComplete="true"
                                        placeholder="Last Name *"
                                    />
                                )}
                            />
                            {errors.lastname && <div className="invalid-feedback">{errors.lastname?.message}</div>}
                        </div>
                    </div>
                    
                    <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                            <Controller
                                name="role_id"
                                control={control}
                                render={({ field }) => (
                                    <select 
                                        onChange={field.onChange}
                                        value={field.value}
                                        className={`form-control custom-select ${errors?.role_id ? "is-invalid" : ""}`}
                                    >
                                        <option value=''>Select Role</option>
                                        {roles?.data?.map(role => <option key={role.id} value={role.id}>{role.display_name}</option>)}
                                    </select>
                                )}
                            />
                            {errors.role_id && <div className="invalid-feedback">{errors.role_id?.message}</div>}
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                            <Controller
                                name="status"
                                control={control}
                                render={({ field }) => (
                                    <select 
                                        onChange={field.onChange}
                                        value={field.value}
                                        className="form-control custom-select"
                                    >
                                        <option value=''>Select Status</option>
                                        <option value='1'>Active</option>
                                        <option value='0'>Inactive</option>
                                    </select>
                                )}
                            />
                        </div>
                    </div>
                    
                    <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                            <Controller
                                name="password"
                                control={control}
                                render={({ field }) => (
                                    <input
                                        className={`form-control ${errors?.password ? "is-invalid" : ""}`}
                                        type="password"
                                        onChange={field.onChange}
                                        value={field.value}
                                        autoComplete="true"
                                        placeholder="Password *"
                                    />
                                )}
                            />
                            {errors.password && <div className="invalid-feedback">{errors.password?.message}</div>}
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                            <Controller
                                name="confirm_password"
                                control={control}
                                render={({ field }) => (
                                    <input
                                        className={`form-control ${errors?.confirm_password ? "is-invalid" : ""}`}
                                        type="password"
                                        onChange={field.onChange}
                                        value={field.value}
                                        autoComplete="true"
                                        placeholder="Confirm Password *"
                                    />
                                )}
                            />
                            {errors.confirm_password && <div className="invalid-feedback">{errors.confirm_password?.message}</div>}
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-between">
                    <button
                        type="button"
                        onClick={doCancel}
                        className="btn btn-secondary"
                    >
                        Cancel
                    </button>
                    <button type="submit" className="btn btn-primary">
                        Submit
                    </button>
                </div>
            </form>
        </div>
    )
}