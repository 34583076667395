import React, { useEffect, useState } from "react";
import alertify from "alertifyjs";
import { useApproveLoanMutation, useApproveTransactionOrderMutation } from "../../services/member.service";
import { useGetAllBanksQuery } from "../../services/cooperative-setup.service";
import { filterOption } from "../../utils/helpers";
import { Select } from "antd";

export default function ApproveTransactionModal({ itemId, coopId, type }) {
    const [bank_id, setBankId] = useState(null);
    const {data: banksData} = useGetAllBanksQuery(coopId, {skip: !coopId});

    const [approve, {isLoading, isSuccess, isError, data, error}] = useApproveTransactionOrderMutation();
    const [approveLoan, {
        isLoading: isLoanLoding, 
        isSuccess: isLoanSuccess, 
        isError: isLoanError, 
        data: loanData, 
        error: loanError
    }] = useApproveLoanMutation();
    
    useEffect(() => {
        if (isSuccess && data.status)  {
            document.getElementById('close-modal').click();
            alertify.success(data?.message);
        }

        if(isError) {
            alertify.error(error.data.message)
        }

    }, [isSuccess, isError, error, data]);

    useEffect(() => {
        if (isLoanSuccess && loanData.status)  {
            document.getElementById('close-approve-modal').click();
            alertify.success(loanData?.message);
        }

        if(isLoanError) {
            alertify.error(loanError.data.message)
        }

    }, [isLoanSuccess, isLoanError, loanError, loanData]);

    const approveItem = async () => {
        if (type === 'loan') {
            approveLoan({
                bank_id,
                loan_id: itemId,
                cooperative_id: coopId
            })
        } else {
            await approve({
                bank_id,
                transaction_id: itemId
            });
        }
    }

    return (
        <div className="modal fade" id="approveModal" tabIndex={-1} role="dialog" aria-labelledby="approveModal" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className={`modal-content dimmer ${isLoading || isLoanLoding ? 'active' : ''}`}>
                    <div className="modal-header">Select the bank account to post from</div>
                    <div className="loader" />
                    
                    <div className="modal-body dimmer-content">
                        <div className="form-group">
                            
                            <Select 
                                showSearch
                                className="customSelect mt-1"
                                options={banksData?.data.map(bank => ({value: bank.id, label: bank.bank.name}))} 
                                placeholder="Select Option"
                                filterOption={filterOption}
                                value={bank_id}
                                onChange={(val) => setBankId(val)}
                            />
                        </div>
                        <div className="modal-btn delete-action">
                            <div className="row">
                                <div className="col-6">
                                    <a 
                                        href="javascript:;"
                                        id="close-approve-modal" data-dismiss="modal" className="btn btn-secondary btn-block">Cancel</a>
                                </div>
                                <div className="col-6">
                                    <a
                                        href="javascript:;"
                                        onClick={approveItem}
                                        className="btn btn-primary btn-block"
                                    >
                                        {isLoading ? 'Submitting...' : 'Submit'}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
