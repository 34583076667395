import React, { useEffect, useState } from 'react'
import {useSelector } from 'react-redux';
import { useLazyBalanceSheetReportQuery } from '../../services/utility.service';
import { doDownload, formatNumber } from '../../utils/helpers';
import dayjs from 'dayjs';
import { DatePicker } from 'antd';
import { ViewCagtegoryBreakDown } from './Modals/ViewCategoryBreakDown';
import { BALANCE_SHEET } from '../../services/CONSTANTS';

const StatementOfFinancialPosition = () => {
    const {isFixNavbar: fixNavbar} = useSelector(state => state.settings);
    const {activeCoop} = useSelector(state => state.coop);
    const [selected, setSelected] = useState(null);
    const {token} = useSelector(state => state.user);
    const [isDownloading, setIsDownloading] = useState(false);

    const [filterData, setFilterData] = useState({
        id: activeCoop?.id,
        start: '',
        end: '',
        download: false
    })

    const [totals, setTotals] = useState({
        totalAssets: {previous: 0, current: 0},
        totalLiability: {previous: 0, current: 0},
        totalRetained: {previous: 0, current: 0}
    })
    const [getResults, {data, isLoading, isError}] = useLazyBalanceSheetReportQuery();

    useEffect(() => {
        getResults(filterData);
    }, []);

    useEffect(() => {
        if (data && data?.status) {
            calculateTotals(data?.data);
        }
    }, [data]);

    const calculateTotals = (data) => {
        let assetPrevious = 0, assetCurrent = 0, liabilityPrevious = 0, liabilityCurrent = 0;

        const results = data.results;

        for (let index = 0; index < results.length; index++) {
            const {totalCurrent, totalPrevious} = results[index];
            if(index < 2) {
                assetPrevious += totalPrevious;
                assetCurrent += totalCurrent;
            } else {
                liabilityCurrent += totalCurrent;
                liabilityPrevious += totalPrevious;
            }
        }

        const retainedCurrent = liabilityCurrent + data?.income.totalGrossProfit;
        const retainedPrevious = liabilityPrevious + data?.income.totalPreviousGrossProfit;

        setTotals({
            ...totals, 
            totalAssets: {previous: assetPrevious, current: assetCurrent},
            totalLiability: {previous: liabilityPrevious, current: liabilityCurrent},
            totalRetained: {previous: retainedPrevious, current: retainedCurrent}
        });
    }

    const download = async () => {
        setIsDownloading(true);
        const url = `${process.env.REACT_APP_API_URL}${BALANCE_SHEET}?cooperative_id=${activeCoop?.id}&download=true&&start_date=${filterData.start}&end_date=${filterData.end}`
        await doDownload(url, `${activeCoop?.name}-balance-sheet-${dayjs().unix()}.xlsx`, token);
        setIsDownloading(false)
    }

    return (
        <>
            <div className="container-fluid">
                <div className={`section-body mt-3 ${fixNavbar ? "marginTop" : ""}`}>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <label>From</label>
                                <DatePicker
                                    className={`customInput w-full`} 
                                    name={'start'} 
                                    placeholder='Start Date'
                                    style={{width: '100%'}}
                                    value={filterData.start !== '' ? dayjs(filterData.start) : undefined} 
                                    onChange={(value) => setFilterData({...filterData, start: dayjs(value).format('YYYY-MM-DD')})}
                                />
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <label>To</label>
                                <DatePicker
                                    className={`customInput w-full`} 
                                    name={'start'} 
                                    placeholder='End Date'
                                    style={{width: '100%'}}
                                    value={filterData.end !== '' ? dayjs(filterData.end) : undefined} 
                                    onChange={(value) => setFilterData({...filterData, end: dayjs(value).format('YYYY-MM-DD')})}
                                />
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <label>&nbsp;</label>
                                <button className="btn btn-sm btn-primary btn-block" onClick={() => getResults(filterData)}>Search</button>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <label>&nbsp;</label>
                                <button className="btn btn-sm btn-secondary btn-block" onClick={download}>
                                    {isDownloading ?  <i className="fa fa-spinner fa-spin mr-2" />:  <i className="fe fe-download mr-2" />}
                                    Download
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
        
                    <div className={`card dimmer ${isLoading ? 'active' : '' }`}>
                        <div className="card-body dimmer-content">
                            <div className='loader' />
                            <div className="table-responsive">
                                <table className="table reporting-table report table-hover table-striped table-vcenter mb-0">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th>{data?.data?.date.previous_year}</th>
                                            <th>{data?.data?.date.current_year}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className='bg-gray'>
                                            <th className="text-white h1 py-4 px-2" colSpan={4}>Assets</th>
                                        </tr>
                                    </tbody>
                                    {data?.data.results.map((group, i) => 
                                        i <= 1 &&
                                        <tbody key={`assets-group-${i}`}>
                                            <tr >
                                                <td className=''>{group.name}</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            {
                                                group.sub_results?.map((category, i) => 
                                                    <tr 
                                                        key={`assets-cat-${i}`} 
                                                        style={{cursor: 'pointer'}} 
                                                        onClick={() => setSelected(category)}
                                                        data-toggle="modal"
                                                        data-target="#category_breakdown_modal"
                                                    >
                                                        <td></td>
                                                        
                                                        <td>{category.subcategory_name}</td>
                                                        
                                                        <td className=''>{formatNumber(category.previousAmount.balance)}</td>
                                                        <td className=''>{formatNumber(category.currentAmount.balance)}</td>
                                                    </tr>
                                                )
                                            }
                                            <tr> 
                                                <td>&nbsp;</td> 
                                                <td>Total {group?.name}</td> 
                                                <td className=''>{formatNumber(group.totalPrevious)}</td>
                                                <td className=''>{formatNumber(group.totalCurrent)}</td>
                                            </tr>
                                        </tbody>
                                    )}
                                    <tbody className='text-white'>
                                        <tr className='bg-gray py-2'> 
                                            <td>&nbsp;</td> 
                                            <td className='h2'>Total Assets</td> 
                                            <td className=''>{formatNumber(totals.totalAssets.previous)}</td>
                                            <td className=''>{formatNumber(totals.totalAssets.current)}</td>
                                        </tr>
                                    </tbody>
                                    <tr> <td colSpan={4}>&nbsp;</td> </tr>
                                    <tr className='bg-gray'> 
                                        <th className='h1 text-white py-4 px-2' colSpan={4}>Liabilities and Owner's Equity</th> 
                                    </tr>
                                    {data?.data.results.map((group, i) => 
                                        i > 1 &&

                                        <tbody key={`liability-group-${i}`}>
                                            <tr >
                                                <td className=''>{group.name}</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            {
                                                group.sub_results?.map((category, i) => 
                                                    <tr 
                                                        key={`liability-cat-${i}`} 
                                                        style={{cursor: 'pointer'}} 
                                                        onClick={() => setSelected(category)}
                                                        data-toggle="modal"
                                                        data-target="#category_breakdown_modal"
                                                    >
                                                        <td></td>
                                                        
                                                        <td>{category.subcategory_name}</td>
                                                        
                                                        <td className=''>{formatNumber(category.previousAmount.balance)}</td>
                                                        <td className=''>{formatNumber(category.currentAmount.balance)}</td>
                                                    </tr>
                                                )
                                            }
                                            <tr> 
                                                <td>&nbsp;</td> 
                                                <td>Total {group?.name}</td> 
                                                <td className=''>{formatNumber(group.totalPrevious)}</td>
                                                <td className=''>{formatNumber(group.totalCurrent)}</td>
                                            </tr>
                                        </tbody>
                                    )}
                                    <tfoot className='bg-gray text-white px-2'>
                                        <tr className='py-2'> 
                                            <td className='h2 text-white' colSpan={2}>Retained Earnings</td> 
                                            <td className=''>{formatNumber(totals.totalRetained.previous)}</td>
                                            <td className=''>{formatNumber(totals.totalRetained.current)}</td>
                                        </tr>
                                        <tr className='py-2'> 
                                            <td className='h2 text-white' colSpan={2}>Total Liabilities and Owner's Equity	</td> 
                                            <td className=''>{formatNumber(totals.totalLiability.previous)}</td>
                                            <td className=''>{formatNumber(totals.totalLiability.current)}</td>
                                        </tr>
                                        
                                       
                                        <tr className='py-2'> 
                                            <td className='h2 text-white' colSpan={2}>Total Income	</td> 
                                            <td className=''>{formatNumber(data?.data.income.totalPreviousGrossProfit)}</td>
                                            <td className=''>{formatNumber(data?.data.income.totalGrossProfit)}</td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ViewCagtegoryBreakDown 
                name={selected?.subcategory_name} 
                accountId={selected?.subcategory_id} 
                cooperative_id={activeCoop?.id} 
                start={filterData?.start} 
                end={filterData?.end} 
            />
        </>
    )
}

export default StatementOfFinancialPosition;