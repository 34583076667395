
import React, { useEffect, useState } from "react";
import { useGetCoaCategoriesQuery, useGetCoaByCategoryQuery, useGetCoaSubCatByCatQuery } from "../../../../services/cooperative-setup.service";
import { useDispatch } from "react-redux";
import { toggleDelete } from "../../../../redux/slices/ui.slice";
import { DELETE_COA, DELETE_COOP_COA } from "../../../../services/CONSTANTS";
import { Select, Tooltip } from "antd";
import { filterOption } from "../../../../utils/helpers";
import LedgerForm from "./LedgerForm";

export const ManageCOA = ({coop}) => {
    const [catID, setCatID] = useState(null);
    const [subCatId, setSubCatId] = useState(null);
    const {data: categoryData, isFetching: catLoading} = useGetCoaCategoriesQuery(coop?.id);
    
    const {data: subCategoryData, isFetching: subCatLoading} = useGetCoaSubCatByCatQuery({id: catID, coopId: coop?.id}, {
        skip: !catID
    });
    const {data, isFetching: loading} = useGetCoaByCategoryQuery({id: subCatId, coopId: coop?.id}, {
        skip: !subCatId
    });
    
    const [ledgers, setLedgers] = useState([]);
    const [ledger, setLedger] = useState(null);
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        if (categoryData && categoryData.status) {
            setCategories(categoryData.data.map(item => ({value: item.id, label: `[${item.code}] ${item.name}`})));
        }
    }, [categoryData]);

    useEffect(() => {
        if (subCategoryData && subCategoryData.status) {
            setSubCategories(subCategoryData.data.map(item => ({value: item.id, label: `[${item.code}] ${item.name}`})));
        }
    }, [subCategoryData]);

    useEffect(() => {
        if (data) {
            setLedgers(data?.data);
        } else {
            setLedgers([])
        }
    }, [data]);

    const doDelete = (e, item) => {
        // console.log(e.currentTarget)
        dispatch(
          toggleDelete({
            open: true,
            title: 'COA',
            url: coop? `${DELETE_COOP_COA}?id=${item.id}&cooperative_id=${coop?.id}` : `${DELETE_COA}?id=${item.id}`,
            // ele: JSON.stringify(e.currentTarget),
            parent: 'tr'
          })
        );
      }

    return (
        <div className='row'>
            <div className='col-md-8 col-sm-12'>
                <div className={`card dimmer ${(loading || catLoading || subCatLoading) ? 'active' : ''}`}>
                    <div className="card-header">
                        <h3 className="card-title">Chart of Accounts 
                            <small>select a control account to list chart of accounts</small>
                        </h3>
                    </div>
                    <div className="loader" />
                    <div className="card-body dimmer-content">
                        <div className="row clearfix">

                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="form-group">
                                    <Select 
                                        showSearch
                                        className="customSelect mt-1"
                                        options={categories} 
                                        placeholder="Select COA Category"
                                        filterOption={filterOption}
                                        onChange={(value) => {
                                            setCatID(value);
                                            setLedgers([]);
                                        }}
                                    />
                                </div>
                            </div>
                            {subCategoryData && 
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="form-group">
                                        <Select 
                                            showSearch
                                            className="customSelect mt-1"
                                            options={subCategories} 
                                            placeholder="Filter COA Sub Category"
                                            filterOption={filterOption}
                                            onChange={(value) => {
                                                setSubCatId(value);
                                            }}
                                        />
                                    </div>
                                </div>
                            }
                            <div className="table-responsive">
                                <table className="table table-striped table-vcenter table-hover mb-0">
                                    <thead>
                                        <tr>
                                            <th>GL code</th>
                                            <th>Account Name</th>
                                            <th>Type</th>
                                            <th>Status</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {ledgers.map(row => 
                                        <tr key={`cat-${row.id}`}>
                                            <td><div className="font-15">{row.code}</div></td>
                                            <td>{row.name}</td>
                                            <td>{row.type === 'D' ? 'Debit' : 'Credit'}</td>
                                            <td>
                                                {row.status === 1 && <span className="tag tag-success">Active</span>}
                                                {row.status === 0 && <span className="tag tag-danger">Inactive</span>}
                                            </td>
                                            <td>
                                                <Tooltip title="Edit">
                                                    <button type="button" className="btn btn-icon" onClick={() => setLedger(row)}>
                                                    <i className="icon-pencil text-info" />
                                                    </button>
                                                </Tooltip>
                                                <Tooltip title="Delete">
                                                    <button type="button" className="btn btn-icon" data-target="#deleteModal" data-toggle="modal" onClick={(e) => doDelete(e, row)}>
                                                        <i className="icon-trash text-danger" />
                                                    </button>
                                                </Tooltip>
                                            </td>
                                        </tr>
                                        )}
                                        {ledgers.length === 0 &&
                                            <tr>
                                                <td colSpan={5} className='text-center'>No data available</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-md-4 col-sm-12'>    
                <LedgerForm 
                    ledger={ledger} 
                    categories={categories} 
                    editCategory={catID} 
                    coop={coop}
                    editSubcategories={subCategories} 
                    onCancel={() => setLedger(null)} 
                />
            </div>
        </div>
    )
}