
import React from "react";
import { useGetAllSavingsProdQuery } from "../../../../services/cooperative-setup.service";
import { useDispatch } from "react-redux";
import { toggleDelete } from "../../../../redux/slices/ui.slice";
import { DELETE_COOP_SAVINGS, DELETE_SAVINGS } from "../../../../services/CONSTANTS";
import { Tooltip } from "antd";
import dayjs from "dayjs";

const SavingsList = ({onEdit, coop}) => {
    const {data, isLoading} = useGetAllSavingsProdQuery(coop?.id);
    const dispatch = useDispatch();

    const doDelete = (e, item) => {
        // console.log(e.currentTarget)
        dispatch(
          toggleDelete({
            open: true,
            title: 'Savings Setup',
            url: coop ? `${DELETE_COOP_SAVINGS}?setup_id=${item.id}&cooperative_id=${coop?.id}` : `${DELETE_SAVINGS}?setup_id=${item.id}`,
            // ele: JSON.stringify(e.currentTarget),
            parent: 'tr'
          })
        );
    }

    return (
        <div className={`card dimmer ${isLoading ? 'active' : ''}`}>
            <div className="card-header">Savings Setups</div>
            <div className="loader" />
            <div className="card-body dimmer-content">
                <div className="table-responsive">
                    <table className="table table-striped table-vcenter table-hover mb-0">
                        <thead>
                            <tr>
                                <th>Type</th>
                                <th>Opening Bal.</th>
                                <th>Closing Bal.</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>Cr Int.</th>
                                <th>Charge</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.data.map(row =>
                                <tr key={row.id}>
                                    <td>{row.product.name}</td>
                                    <td>{row.opening_balance}</td>
                                    <td>{row.closing_balance}</td>
                                    <td>{dayjs(row.start_date).format('YYYY-MM-DD')}</td>
                                    <td>{dayjs(row.end_date).format('YYYY-MM-DD')}</td>
                                    <td>{row.credit_interest}</td>
                                    <td>{row.charge.name}</td>
                                    <td>
                                        <Tooltip title="Edit">
                                            <button type="button" className="btn btn-icon" onClick={() => onEdit(row)}>
                                                <i className="icon-pencil text-info" />
                                            </button>
                                        </Tooltip>
                                        <Tooltip title="Delete">
                                            <button type="button" className="btn btn-icon" data-target="#deleteModal" data-toggle="modal" onClick={(e) => doDelete(e, row)}>
                                                <i className="icon-trash text-info" />
                                            </button>
                                        </Tooltip>
                                    </td>
                                </tr>
                            )}
                            
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default SavingsList;