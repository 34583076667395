import React, { Component } from 'react'
import { connect } from 'react-redux';

class AnalysisReport extends Component {

    render() {
        const { fixNavbar } = this.props;
        return (
            <>
                <div>
                    <div className={`section-body mt-3 ${fixNavbar ? "marginTop" : ""}`}>
                
                        <div className="container-fluid">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-8">
                                            <div className="table-responsive">
                                                <table className="table table-bordered">
                                                    <tr>
                                                        <td></td>
                                                        <td className="text-center">Targets</td>
                                                        <td className="text-center">Perfomance</td>
                                                        <td className="text-center">Delta</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Number of Coops</td>
                                                        <td className="text-centr">10</td>
                                                        <td className="text-centr">5</td>
                                                        <td className="text-centr">-5</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Membership</td>
                                                        <td className="text-centr">10,000</td>
                                                        <td className="text-centr">0</td>
                                                        <td className="text-centr">-10,000</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Monthly Income</td>
                                                        <td className="text-centr">5,000,000</td>
                                                        <td className="text-centr">0</td>
                                                        <td className="text-centr">-5,000,000</td>
                                                    </tr>
                                                    <tr>
                                                        <td>% penetration</td>
                                                        <td className="text-centr">80%</td>
                                                        <td className="text-centr">5</td>
                                                        <td className="text-centr">-5</td>
                                                    </tr>
                                                    <tr>
                                                        <td>% Return on Savings</td>
                                                        <td className="text-centr">15%</td>
                                                        <td className="text-centr">0%</td>
                                                        <td className="text-centr">-15%</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive mt-5">
                                    <table className="table table-hover table-bordered table-striped table-vcenter mb-0">
                                            <thead>
                                                <tr>
                                                    <th>S/N</th>
                                                    <th>Name of each active coop</th>
                                                    <th>Prev. Months Membership</th>
                                                    <th>New members signed on within the month</th>
                                                    <th>Old members who exited this month</th>
                                                    <th>Total Membership</th>
                                                    <th>Head count of the parent company</th>
                                                    <th>% of Coop members to total staff</th>
                                                    <th>Prior Month YTD members savings</th>
                                                    <th>Prior Month Current YTD Income</th>
                                                    <th>% Return on Member Savings</th>
                                                    <th>Next week membership drive plan</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>COOP001</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>0</td>
                                                    <td></td>
                                                    <td>0%</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>0%</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>COOP002</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>0</td>
                                                    <td></td>
                                                    <td>0%</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>0%</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>COOP003</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>0%</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>0%</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>COOP004</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>0%</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>0%</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>COOP005</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>0%</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>0%</td>
                                                    <td></td>
                                                </tr>
                                                
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td></td>
                                                    <td>Total</td>
                                                    <td>0</td>
                                                    <td>0</td>
                                                    <td>0</td>
                                                    <td>0</td>
                                                    <td>0</td>
                                                    <td>0</td>
                                                    <td>0</td>
                                                    <td>0</td>
                                                    <td>0</td>
                                                    <td>0</td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar
})

const mapDispatchToProps = dispatch => ({})
export default connect(mapStateToProps, mapDispatchToProps)(AnalysisReport);