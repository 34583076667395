import React from "react"
import { useSelector } from "react-redux";
import { LoanOffsetHistory } from "./Components/LoanOffsetHistory";

export const MemberLoanOffsetHistory = () => {
    const {isFixNavbar: fixNavbar} = useSelector(state => state.settings);
    const {user} = useSelector(state => state.user);
    const {activeCoop} = useSelector(state => state.coop);

    return (
        <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
            <LoanOffsetHistory cooperative_id={activeCoop?.id} coop_id={user?.coop_id}  />
        </div>
    )
}