import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { PersonalInfo } from "./Components/PersonalInfo";
import { useGetCoopMemberQuery } from "../../services/member.service";
import { Activity } from "../Members/MemberDetails/Activity";
import Card from "../common/Card";
import alertify from 'alertifyjs';

export const MyProfile = () => {
    const {isFixNavbar: fixNavbar} = useSelector(state => state.settings);
    const {activeCoop} = useSelector(state => state.coop);
    const {user} = useSelector(state => state.user);
	const [member, setMember] = useState(null);
	const {data, isLoading, isError, error} = useGetCoopMemberQuery({coop_id: activeCoop?.id, member_id: user?.id});

    useEffect(() => {
		if (data) {
			setMember(data?.data);
		}

		if (isError) {
			alertify.error(error.message);
		}
	}, [data]);

    return (
        <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
            <div className="container-fluid px-0">
                <div className="row clearfix">
                    <div className="col-lg-3 col-md-12">
                        <PersonalInfo member={member} />
                    </div>
                    <div className="col-lg-9 col-md-12">
                        <Activity activites={member?.activity} />
                        <div className="row">
                            <div className="col-md-6 col-sm-12">
                                <Card title={`Work Info`}>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-4 py-1"><strong>Department:</strong></div>
                                            <div className="col-8 py-1">{member?.workInfo.department?.name || 'N/A'}</div>
                                            <div className="col-4 py-1"><strong>Phone:</strong></div>
                                            <div className="col-8 py-1">{member?.workInfo.official_phone || 'N/A'}</div>
                                            <div className="col-4 py-1"><strong>Email:</strong></div>
                                            <div className="col-8 py-1">{member?.workInfo.official_email || 'N/A'}</div>
                                            <div className="col-4 py-1"><strong>Level:</strong></div>
                                            <div className="col-8 py-1">{member?.workInfo.level}</div>
                                        </div>
                                    </div>
                                </Card>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <Card title={`Next of Kin Info`}>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-4 py-1"><strong>Name:</strong></div>
                                            <div className="col-8 py-1">{member?.nokInfo.name}</div>
                                            <div className="col-4 py-1"><strong>Phone:</strong></div>
                                            <div className="col-8 py-1">{member?.nokInfo.phone || 'N/A'}</div>
                                            <div className="col-4 py-1"><strong>Email:</strong></div>
                                            <div className="col-8 py-1">{member?.nokInfo.email || 'N/A'}</div>
                                            <div className="col-4 py-1"><strong>Address:</strong></div>
                                            <div className="col-8 py-1">{member?.nokInfo.address || 'N/A'}</div>
                                        </div>
                                    </div>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}