/* eslint eqeqeq: 0 */
import { combineReducers, configureStore } from "@reduxjs/toolkit";
// import { createStore, applyMiddleware } from 'redux';
// import thunk from 'redux-thunk';
import {
	persistStore,
	persistReducer,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
  } from "redux-persist";
  
import apiSlice from "../services/api/api";
import storage from 'redux-persist/lib/storage';
import hardSet from "redux-persist/es/stateReconciler/hardSet";

// import reducers from './reducers/rootReducer';
// import history from '../services/history';

import settings from "./slices/setting.slice";
import user from "./slices/user.slice";
import ui from "./slices/ui.slice";
import coop from "./slices/cooperative.slice";

const rootReducer = combineReducers({
	settings,
	ui,
	user,
	coop,
	[apiSlice.reducerPath]: apiSlice.reducer,
});

const persistConfig = {
	key: 'root',
	storage,
	version: 1,
	stateReconciler: hardSet,
  	blacklist: apiSlice.reducerPath
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
	serializableCheck: {
	 ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
	//  ignoredPaths: ['betslip'], 
   },
 }).concat(apiSlice.middleware),
});

const persistor = persistStore(store);

export default store;
export { persistor };

