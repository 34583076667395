import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useImportSavingsMutation } from "../../../services/member.service";
import Card from "../../common/Card";
import { useGetAllBanksQuery } from "../../../services/cooperative-setup.service";
import alertify from "alertifyjs";
import { Select } from "antd";
import { filterOption } from "../../../utils/helpers";
import { InboxOutlined } from '@ant-design/icons';
import { Upload } from 'antd';

import DownloadSampleSavings from "./DownloadSampleSavings";

const { Dragger } = Upload;

export const ImportSavings = () => {
    const fixNavbar = useSelector(state => state.settings.isFixNavbar);
    const {activeCoop} = useSelector(state => state.coop);
    const {data: banksData} = useGetAllBanksQuery(activeCoop?.id, {skip: !activeCoop});

    const [form, setForm] = useState({
        cooperative_id: activeCoop?.id,
        bank_id: '',
        type: '',
        file: null
    });
    const [doImport,  {data: importData, isSuccess, isLoading, isError, error}] = useImportSavingsMutation();

    useEffect(() => {
        if (isSuccess && importData) {
            if (importData.status) {
                alertify.success(importData.message);
            } else {
                alertify.error(importData.message);
            }
        }

        if (isError) alertify.error(error.data.message);
        
    }, [importData, isSuccess, isError, error]);

    const props = {
        name: 'file',
        multiple: false,
        maxCount: 1,
        accept: '.xlsx,.csv',
        beforeUpload(file) {
            // console.log('before upload', file);
            setForm({...form, file})
            return false;
        },
        onDrop(e) {
          console.log('Dropped files', e.dataTransfer.files);
        },
    };

    const submit = () => {
        if(!form.file) {
            alertify.error('Please select a file to import');
            return;
        }
        if (!form.bank_id) {
            alertify.error('Please select a bank');
            return;
        }
        if (!form.type) {
            alertify.error('Please select a savings type');
            return;
        }

        const formData = new FormData();
        formData.append('cooperative_id', form.cooperative_id);
        formData.append('file', form.file);
        formData.append('bank_id', form.bank_id);
        formData.append('type', form.type);

        doImport(formData);
    }


    return (
        <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
            <div className="container-fluid">
                <Card loading={isLoading} title={`Upload File`} headerOptions={
                    <div className="card-options">
                        <button type="button" className="btn btn-secondary btn-sm" data-target="#savingsSampleModal" data-toggle="modal">
                            <i className="fe fe-download mr-2" />
                            Download Sample
                        </button>
                    </div>
                }>

                    <div className="card-body dimmer-content">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Savings Type </label>
                                    <Select 
                                        showSearch
                                        className="customSelect mt-1"
                                        options={[
                                            {value: 'NORMAL_BALANCES', label: 'Normal Balances'},
                                            {value: 'NORMAL_HISTORY', label: 'Normal History'},
                                            {value: 'TARGET_HISTORY', label: 'Target History'},
                                        ]} 
                                        placeholder="Select Savings Type"
                                        filterOption={filterOption}
                                        value={form.type}
                                        onChange={(value) => setForm({...form, type: value})}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Bank </label>
                                    <Select 
                                        showSearch
                                        className="customSelect mt-1"
                                        options={banksData?.data.map(bank => ({value: bank.id, label: bank.bank.name}))} 
                                        placeholder="Select Bank Account"
                                        filterOption={filterOption}
                                        value={form.bank_id}
                                        onChange={(value) => setForm({...form, bank_id: value})}
                                    />
                                </div>
                            </div>
                        </div>
                        <Dragger {...props}>
                            <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            {/* <p className="ant-upload-hint">
                            Support for a single or bulk upload. Strictly prohibited from uploading company data or other
                            banned files.
                            </p> */}
                        </Dragger>
                    </div>
                    <div className="card-footer">
                        <button className="btn btn-info" onClick={submit}>Click to Upload Data</button>
                    </div>
                </Card>
            </div>
            <DownloadSampleSavings coop={activeCoop} />
        </div>
    )
}