import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import * as Yup from "yup";
import alertify from "alertifyjs";
import { useSaveContestantMutation } from "../../../services/election.service";
import { Select } from "antd";
import { filterOption } from "../../../utils/helpers";
import { useLazyGetCoopMembersQuery } from "../../../services/member.service";

const FormSchema = Yup.object().shape({
    coop_id: Yup.string().required('Please enter a name'),
    manifesto: Yup.string().required('This field is required'),
    position_id: Yup.string().required('This field is required'),
});


export const ContestantForm = ({electionId, coopId, item, onCancel, onSave, positions}) => {
    const [save, {data, isLoading, isSuccess, isError, error}] = useSaveContestantMutation();
    const [getMembers, {data: membersData}] = useLazyGetCoopMembersQuery()

    useEffect(() => {
        if (coopId) 
            getMembers({coopId});

    }, [coopId]);

    const {
        control,
        reset,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(FormSchema),
        mode: 'onBlur'
    });

    useEffect(() => {
        if (item) {
            setValue('coop_id', item.coop_id);
            setValue('manifesto', item.manifesto);
            setValue('position_id', item.position_id);
            setValue('status', item.status);
            setValue('contestant_id', item.id);
        }
    }, [item]);

    const onSubmit = (values) => {
        const payload = {...values};
        
        payload.election_id = electionId;
        payload.cooperative_id = coopId;
        
        save(payload);
    }

    useEffect(() => {
        if(isSuccess) {
            reset();
            // show toast.
            alertify.success(data?.message);
            onSave();
        }

        if (isError) {
            alertify.error(error?.data?.message);
        }
    }, [isSuccess, isError, data]);

    return (
        <form className={`dimmer ${isLoading ? 'active' : ''}`} onSubmit={handleSubmit(onSubmit)}>
            <dive className="loader" />
            <div className="row clearfix dimmer-content">
                <div className="col-md-12">
                    <div className="form-group">
                        <label>Position</label>
                        <Controller
                            name="position_id"
                            control={control}
                            render={({ field }) => (
                                <Select 
                                    showSearch
                                    className="customSelect mt-1"
                                    options={positions?.map(position => ({label: position.name, value: position.id}))} 
                                    placeholder="Select Position"
                                    filterOption={filterOption}
                                    value={field.value}
                                    onChange={field.onChange}
                                />
                            )}
                        />
                        {errors.position_id && <div className="invalid-feedback">{errors.position_id?.message}</div>}
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="form-group">
                        <label>Contestant</label>
                        <Controller
                            name="coop_id"
                            control={control}
                            render={({ field }) => (
                                <Select 
                                    showSearch
                                    className="customSelect mt-1"
                                    options={membersData?.data?.data.map(member => ({label: `${member.firstname} ${member.lastname}`, value: member.coop_id}))} 
                                    placeholder="Select Contestant"
                                    filterOption={filterOption}
                                    value={field.value}
                                    onChange={field.onChange}
                                />
                            )}
                        />
                        {errors.coop_id && <div className="invalid-feedback">{errors.coop_id?.message}</div>}
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="form-group">
                        <label>Manifesto</label>
                        <Controller
                            name="manifesto"
                            control={control}
                            render={({ field }) => (
                                <textarea 
                                    type="text" 
                                    rows={4} 
                                    className="form-control" 
                                    placeholder="Description"
                                    onChange={field.onChange}
                                    defaultValue={field.value}
                                >
                                    {field.value}
                                </textarea>
                            )}
                        />
                        {errors.manifesto && <div className="invalid-feedback">{errors.manifesto?.message}</div>}
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="form-group">
                        <label>Status</label>
                        <Controller
                            name="status"
                            control={control}
                            render={({ field }) => (
                                <select 
                                    onChange={field.onChange}
                                    value={field.value}
                                    className={`form-control ${errors?.status ? "is-invalid" : ""}`}
                                >
                                    <option value=''>Select Status</option>
                                    <option value='1'>Active</option>
                                    <option value='0'>Inactive</option>
                                </select>
                            )}
                        />
                        {errors.status && <div className="invalid-feedback">{errors.status?.message}</div>}
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-between">
                <button type="button" className="btn btn-secondary" onClick={() => {
                    onCancel()
                    reset();
                }}>Close</button>
                <button type="submit" className="btn btn-primary">Save changes</button>

            </div>
        </form>
    )
}