import React from "react"

export const DamageList = () => {

    return (
        <div className="row clearfix">
            <div className="col-12">
                <div className="card">
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-striped table-vcenter table-hover mb-0">
                                <thead>
                                    <tr>
                                        <th>DATE</th>
                                        <th>REFERENCE NO</th>
                                        <th>TOTAL</th>
                                        <th>NOTE</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>17/02/2024</td>
                                        <td>RF3434</td>
                                        <td>N30,000</td>
                                        <td>Damaged Item</td>
                                        <td>
                                            <button type="button" className="btn btn-icon" title="Edit"><i className="fa fa-edit" /></button>
                                            <button type="button" className="btn btn-icon js-sweetalert" title="Delete" data-type="confirm"><i className="fa fa-trash-o text-danger" /></button>
                                        </td>
                                    </tr>
                                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}