import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import environmentConfig from "../../_configs/environment.config";
import { logoutUser } from "../../redux/slices/user.slice";
import { resetColors } from "../../redux/slices/ui.slice";
import { setActiveCoop } from "../../redux/slices/cooperative.slice";

const baseQuery = fetchBaseQuery({
  baseUrl: environmentConfig.API_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    // headers.set("Content-Type", "application/json");
    const typedGetState = getState();
    const token = typedGetState.user.token;
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const customBaseQuery = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  // console.log(result, "res");
  if (result.error && result.error.status === 406) {
    api.dispatch(resetColors());
    api.dispatch(setActiveCoop(null));
    api.dispatch(logoutUser());

    return;
  } else if (result.error && result.error.status === 401) {
    api.dispatch(resetColors());
    api.dispatch(setActiveCoop(null));
    api.dispatch(logoutUser());
    window.location.href = '/login'
  }
  return result;
};

export default customBaseQuery;
