import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import * as Yup from "yup";
import alertify from "alertifyjs";

import { useSaveRoleMutation, useUpdateRoleMutation } from "../../../services/setup.service";

export const FormSchema = Yup.object().shape({
    display_name: Yup.string().required('Please enter a role name'),
    belong_to: Yup.string().required('Please select the role type'),

});

export const RolesForm = ({role}) => {

    const [saveRole, {isLoading, isSuccess, isError, data, error}] = useSaveRoleMutation();
    const [updateRole, {
        isLoading: isUpdateLoading, 
        isSuccess: isUpdateSuccess, 
        isError: isUpdateError, 
        data: updateDate, 
        error: updateError
    }] = useUpdateRoleMutation();

    const {
        control,
        reset,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(FormSchema),
        mode: 'onBlur'
    });

    useEffect(() => {
        if (role) {
            reset({
                display_name: role.display_name,
                belong_to: role.belong_to,
                status: role.status,
                id: role.id,
            })
        }
    }, [role]);


    const onSubmit = (values) => {
        if (role) {
            updateRole(values);
        } else {
            saveRole(values);
        }
    }

    useEffect(() => {
        if(isSuccess && data?.status) {
            resetForm();
            alertify.success(data?.message)
        }

        if (isError) {
            //showToast()
            alertify.error(data?.message)
        }
    }, [isSuccess, isError,data])

    const resetForm = () => {
        reset({
          display_name: "",
          belong_to: "",
          status: "",
          id: "",
        })
    }

    return (
        <div className="modal fade" id="addRole" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <form className="modal-dialog" onSubmit={handleSubmit(onSubmit)} role="document">
                <div className={`modal-content dimmer ${(isLoading || isUpdateLoading) ? 'active' : ''}`}>
                    <div className="loader" />
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Add New Role</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                    </div>
                    <div className="modal-body dimmer-content">
                        <div className="row clearfix">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <Controller
                                        name="display_name"
                                        control={control}
                                        render={({ field }) => (
                                            <input
                                              className={`form-control ${
                                                errors?.display_name ? "is-invalid" : ""
                                              }`}
                                              type="text"
                                              onChange={field.onChange}
                                              value={field.value}
                                              autoComplete="true"
                                            />
                                        )}
                                    />
                                    {errors.display_name && <div className="invalid-feedback">{errors.display_name?.message}</div>}
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <Controller
                                        name="belong_to"
                                        control={control}
                                        render={({ field }) => (
                                            <select 
                                                onChange={field.onChange}
                                                value={field.value}
                                                className={`form-control custom-select ${
                                                    errors?.belong_to ? "is-invalid" : ""}`}
                                            >
                                                <option value=''>Select Parent Role</option>
                                                <option value='CALICON'>Calicon</option>
                                                <option value='COOPERATIVE'>Cooperative</option>
                                            </select>
                                        )}
                                    />
                                    {errors.belong_to && <div className="invalid-feedback">{errors.belong_to?.message}</div>}

                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <Controller
                                        name="status"
                                        control={control}
                                        render={({ field }) => (
                                            <select 
                                                onChange={field.onChange}
                                                value={field.value}
                                                className="form-control custom-select"
                                            >
                                                <option value=''>Select Status</option>
                                                <option value='1'>Active</option>
                                                <option value='0'>Inactive</option>
                                            </select>
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" disabled={isLoading} className="btn btn-secondary" onClick={resetForm} data-dismiss="modal">Close</button>
                        <button type="submit" disabled={isLoading} className="btn btn-primary">
                            Save changes
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}