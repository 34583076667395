import { createSlice } from "@reduxjs/toolkit";


export const cooperativeSlice = createSlice({
  name: "coop",
  initialState: { 
    cooperatives: [],
    activeCoop: null,
  },
  reducers: {
    setCooperatives: (state, { payload }) => {
      const newState = { ...state };
      newState.cooperatives = payload;
      return newState;
    },
    setActiveCoop: (state, {payload}) => {
      state.activeCoop = payload
      return state;
    }
  },
});

export const { setCooperatives, setActiveCoop } = cooperativeSlice.actions;
export default cooperativeSlice.reducer;
