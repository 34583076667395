import React from "react"
import { useSelector } from "react-redux";
import { SuretyRequest } from "../Members/MemberDetails/SuretyRequest";

export const MemberSurety = () => {
    const {isFixNavbar: fixNavbar} = useSelector(state => state.settings);
    const {user} = useSelector(state => state.user);
    const {activeCoop} = useSelector(state => state.coop);
    return (
        <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
            <SuretyRequest coop={activeCoop} memberId={user?.coop_id} />
        </div>
    )
}