import React, { useEffect, useState } from 'react'
import { trialBalance } from '../../utils/dump';
import { useSelector } from 'react-redux';
import { useLazyGetLedgerStatementQuery } from '../../services/utility.service';
import { DatePicker, Select } from 'antd';
import dayjs from 'dayjs';
import { useGetAllCoaQuery } from '../../services/cooperative-setup.service';
import { filterOption, formatNumber } from '../../utils/helpers';

const LedgerStatment = ({location}) => {
    const {search} = location;
	const code = new URLSearchParams(search).get('code');
    const {isFixNavbar: fixNavbar} = useSelector(state => state.settings);
    const {activeCoop} = useSelector(state => state.coop);
    const [results, setResults] = useState(null);
    const [filterData, setFilterData] = useState({
        id: activeCoop?.id,
        start: '',
        end: '',
        code: ''
    });
    const {data: glData} = useGetAllCoaQuery(activeCoop?.id, {skip: !activeCoop});

    const [getResults, {data, isFetching, isError}] = useLazyGetLedgerStatementQuery();

    useEffect(() => {
        if (code) {
            const payload = {...filterData, code};
            setFilterData(payload);
            getResults(payload);
        }
    }, [code]);

    useEffect(() => {
        if (data) setResults(data?.data);
    }, [data]);

        return (
            <>
                <div className="container-fluid">
                    <div className={`section-body mt-3 ${fixNavbar ? "marginTop" : ""}`}>
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <div className="form-group">
                                            <label>Ledger Account</label>
                                            <Select 
                                                showSearch
                                                className="customSelect"
                                                options={glData?.data.map(coa => ({value: coa.code, label: `${coa.code} - ${coa.name}`}))} 
                                                placeholder="Select GL Account"
                                                filterOption={filterOption}
                                                value={filterData.code}
                                                onChange={(value) => setFilterData({...filterData, code: value})}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <label>From</label>
                                        <DatePicker
                                            className={`customInput w-full`} 
                                            name={'start'} 
                                            placeholder='Start Date'
                                            style={{width: '100%'}}
                                            value={filterData.start !== '' ? dayjs(filterData.start) : undefined} 
                                            onChange={(value) => setFilterData({...filterData, start: dayjs(value).format('YYYY-MM-DD')})}
                                        />
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <label>To</label>
                                        <DatePicker
                                            className={`customInput w-full`} 
                                            name={'start'} 
                                            placeholder='End Date'
                                            style={{width: '100%'}}
                                            value={filterData.end !== '' ? dayjs(filterData.end) : undefined} 
                                            onChange={(value) => setFilterData({...filterData, end: dayjs(value).format('YYYY-MM-DD')})}
                                        />
                                    </div>
                                    <div className="col-lg-1 col-md-2 col-sm-6">
                                        <label>&nbsp;</label>
                                        <button className="btn btn-sm btn-primary btn-block" onClick={() => getResults(filterData)}>Search</button>
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-6">
                                        <label>&nbsp;</label>
                                        <button className="btn btn-sm btn-secondary btn-block" onClick={() => getResults({...filterData, download: true})}>Download</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`card dimmer ${isFetching ? 'active' : '' }`}>
                            <div className="card-body dimmer-content">
                                <div className="loader" />
                                <div className="table-responsive">
                                    <table className="table reporting-table table-hover table-striped table-vcenter mb-0">
                                        <thead>
                                            <tr>
                                                <th>Ref #</th>
                                                <th>Date</th>
                                                <th>Description</th>
                                                <th>Debit</th>
                                                <th>Credit</th>
                                                <th>Balance</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td colSpan={5} className='font-bold font-italic'>Opening Balance</td>
                                                <td>{formatNumber(results?.opening_balance)}</td>
                                            </tr>
                                            {results?.results?.length > 0 ?
                                                results?.results.map((row, i) => 
                                                <tr key={`row-${row.transaction_code}-${i}`}>
                                                    <td className='text-center'>{i + 1}</td>
                                                    <td className='text-center'>{dayjs(row.transaction_date).format('DD/MM/YYYY')}</td>
                                                    <td>{row.description}</td>
                                                    <td className=''>{row.debit_credit === 'D' ? formatNumber(row.transaction_amount) : ' - '}</td>
                                                    <td className=''>{row.debit_credit === 'C' ? formatNumber(row.transaction_amount) : ' - '}</td>
                                                    <td>{formatNumber(row.balance)}</td>
                                                </tr>)
                                                :
                                                <tr>
                                                    <td colSpan={6} className='text-center text-italic'>No data available</td>
                                                </tr>
                                            }
                                            
                                            <tr>
                                                <td colSpan={5} className='font-bold font-italic'>Closing Balance</td>
                                                <td>{formatNumber(results?.final_balance)}</td>
                                            </tr>
                                        </tbody>
                                            
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }


export default LedgerStatment;