
import React from "react";
import CategoryList from "./CategoryList";
import CategoryForm from "./CategoryForm";
const ProductCategory = () => {
    return (
        <div className="row">
            <div className="col-md-6 col-sm-12">
                <CategoryList />
            </div>
            <div className="col-md-6 col-sm-12">
                <CategoryForm />
            </div>
        </div>
    )
}

export default ProductCategory;