import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { MemberForm } from '../Members/MemberForm';
import { useGetCooperativeQuery } from '../../services/member.service';
import './signup.css';

export default function SignUp({location}) {
	const [activeCoop, setActiveCoop] = useState(null);
	const [success, setSuccess] = useState(false);
	const coopId = new URLSearchParams(location.search).get('coop');
	const {data} = useGetCooperativeQuery(coopId, {skip: !coopId});

	useEffect(() => {
		if(data) {
			setActiveCoop(data?.data);
		}
	}, [data]);

	return (
		<div className="register auth">
			<div className="auth_left">
				<Link className="header-brand" to="/">
					<img src="../assets/images/calicon-logo.png" className='' />
				</Link>
			</div>
			<div className="auth_right">
				<div className="card">
					<div className="text-center mb-2 p-4">
						<Link className="header-brand" to="/">
							<img src="../assets/images/calicon-logo.png" className='' />
						</Link>
					</div>
					<div className="card-body">
						<div className="card-title">{activeCoop?.name} MEMBERSHIP FORM</div>
						{success ?
							<div class="card text-center">
								<div style={{borderRadius:200, height:200, width:200, backgroundColor: '#F8FAF5', margin: 'auto'}}>
									<i class="checkmark">✓</i>
								</div>
								<h1>Success</h1> 
								<p>We received your membership request;<br/> we'll be in touch shortly!</p>
							</div>
						:
							<MemberForm type="pending" activeCoop={activeCoop} onSuccess={() => setSuccess(!success)} />
						}
					</div>
				</div>
			</div>
		</div>
	);
}
