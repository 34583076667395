import { DatePicker, Empty, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Card from '../../common/Card';
import { JournalEntryForm } from '../../common/JournalEntryForm';
import { useLazyGetAllJournalEntryQuery } from '../../../services/cooperative-setup.service';
import dayjs from 'dayjs';
import { formatNumber } from '../../../utils/helpers';
import { toggleDelete } from '../../../redux/slices/ui.slice';
import { JOURNAL_ENTRY } from '../../../services/CONSTANTS';

const JournalEntry = () => {
    const {isFixNavbar: fixNavbar} = useSelector(state => state.settings);
    const {activeCoop} = useSelector(state => state.coop);
    const [journal, setJournal] = useState(null);
    const [journals, setJournals] = useState([]);
    const [filterData, setFilterData] = useState({
        startDate: dayjs().startOf('month').format('YYYY-MM-DD'),
        endDate: dayjs().format('YYYY-MM-DD'),
        page: 1,
        coopId: activeCoop?.id
    })
    const [getEntries, {data, isFetching}] = useLazyGetAllJournalEntryQuery();
    const dispatch = useDispatch();

    useEffect(() => {
        getEntries(filterData);
    }, []);

    useEffect(() => {
        if(data) setJournals(data?.data.data);
    }, [data]);

    const doDelete = (e, item) => {
        // console.log(e.currentTarget)
        dispatch(
          toggleDelete({
            open: true,
            title: 'Journal Entry',
            url: `${JOURNAL_ENTRY}?cooperative_id=${activeCoop.id}&id=${item.id}`,
            // ele: JSON.stringify(e.currentTarget),
            parent: 'tr'
          })
        );
    }

    const changePeriod = e => {
        const value = e.target.value;
        switch (value) {
            case 'last-month':
                setFilterData({
                    ...filterData,
                    startDate: dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
                    endDate: dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
                })
                break;
            case 'last-30-days': 
                setFilterData({
                    ...filterData,
                    startDate: dayjs().subtract(30, 'days').format('YYYY-MM-DD'),
                    endDate: dayjs().format('YYYY-MM-DD'),
                })
                break;
            case 'current-year': 
                setFilterData({
                    ...filterData,
                    startDate: dayjs().startOf('year').format('YYYY-MM-DD'),
                    endDate: dayjs().format('YYYY-MM-DD'),
                })
                break;
            case 'last-year': 
                setFilterData({
                    ...filterData,
                    startDate: dayjs().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'),
                    endDate: dayjs().subtract(1, 'year').endOf('year').format('YYYY-MM-DD'),
                })
                break;
        
            default:
                setFilterData({
                    ...filterData,
                    startDate: dayjs().startOf('month').format('YYYY-MM-DD'),
                    endDate: dayjs().format('YYYY-MM-DD')
                })
                break;
        }
    }

    const doEdit = (transactionCode) => {
        const edits = journals.filter(item => item.transaction_code === transactionCode);
        if (edits) {
            setJournal(edits);
        }
    }
    return (
        <>
            <div>
                <div>
                    <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
                        <div className="container-fluid">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-4 col-sm-6">
                                            <div className="form-group">
                                                <select className="form-control show-tick" onChange={changePeriod}>
                                                    <option value="current-month">Current Month</option>
                                                    <option value="last-month">Last Month</option>
                                                    <option value="last-30-days">Last 30 Days</option>
                                                    <option value="current-year">Current Year</option>
                                                    <option value="last-year">Last Year</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-6">
                                            <DatePicker
                                                className={`customInput w-full`} 
                                                name={'date_purchased'} 
                                                style={{width: '100%'}}
                                                value={dayjs(filterData.startDate)} 
                                                onChange={(date) => setFilterData({...filterData, startDate: dayjs(date).format('YYYY-MM-DD')})}
                                            />
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-6">
                                            <DatePicker
                                                className={`customInput w-full`} 
                                                name={'date_purchased'} 
                                                style={{width: '100%'}}
                                                value={dayjs(filterData.endDate)} 
                                                onChange={(date) => setFilterData({...filterData, endDate: dayjs(date).format('YYYY-MM-DD')})}
                                            />
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-6">
                                            <button 
                                                type="button" onClick={() => getEntries(filterData)} 
                                                className="btn btn-sm btn-primary btn-block">Filter</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section-body mt-3">
                        <div className="container-fluid">
                            <Card title="Journal Entry" loading={isFetching} headerOptions={
                                <div className="card-options">
                                    <form>
                                        <div className="input-group">
                                            <span className="input-group-btn mr-2">
                                                <button className="btn btn-icon mr-2" type="submit"><span className="fa fa-file-pdf-o" /> PDF</button>
                                                <button className="btn btn-icon" type="submit"><span className="fa fa-file-excel-o" /> EXCEL</button>
                                            </span>

                                            <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#journal-form"><i className="fe fe-plus mr-2" />Add New</button>
                                        </div>
                                    </form>
                                </div>
                            }>
                                <div className="card-body dimmer-content">
                                    <div className="table-responsive">
                                        <table className="table table-striped table-vcenter table-hover mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Description</th>
                                                    <th>GL Code</th>
                                                    <th>Debit</th>
                                                    <th>Credit</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {journals.length === 0 && <tr><td colSpan={6}><Empty /></td></tr>}
                                                {journals.map(row => 
                                                    (<tr key={`journal-${row.id}`}>
                                                        <td>{row.date}</td>
                                                        <td><div className="font-15">{row.description}</div></td>
                                                        <td>{row.gl_code}</td>
                                                        <td>{formatNumber(row.debit)}</td>
                                                        <td>{formatNumber(row.credit)}</td>
                                                        <td>
                                                            <Tooltip title="Edit">
                                                                <button 
                                                                    type="button" 
                                                                    className="btn btn-icon btn-sm btn-sm"
                                                                    data-toggle="modal" data-target="#journal-form"
                                                                    onClick={() => doEdit(row.transaction_code)}
                                                                >
                                                                    <i className="icon-pencil text-info" />
                                                                </button>
                                                            </Tooltip>
                                                            <Tooltip title="Delete">
                                                                <button 
                                                                    type="button" 
                                                                    className="btn btn-icon btn-sm" 
                                                                    data-target="#deleteModal" data-toggle="modal" 
                                                                    onClick={(e) => doDelete(e, row)}
                                                                >
                                                                    <i className="icon-trash text-danger" />
                                                                </button>
                                                            </Tooltip>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </div>

                </div>
                {/* Journal Entry Modal Form */}
                <JournalEntryForm coopId={activeCoop?.id} onCancel={() => setJournal(null)} journal={journal} />
            </div>
        </>
    )
}
export default JournalEntry