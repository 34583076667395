import React, { useEffect, useState } from "react";
import { useImportMembersMutation } from "../../services/member.service";
import Card from "../common/Card";
import alertify from "alertifyjs";
import { DOWNLOAD_SAMPLE_MEMBERS_IMPORT } from "../../services/CONSTANTS";
import { useSelector } from "react-redux";
import { Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

const { Dragger } = Upload;

export const ImportMembers = ({activeCoop}) => {
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [doImport,  {data: importData, isSuccess, isLoading}] = useImportMembersMutation();
    const {token} = useSelector(state => state.user);

    useEffect(() => {
        if (isSuccess) {
            if (importData.status) {
                alertify.success(importData.message);
            } else {
                alertify.error(importData.message);
            }
        }
        
    }, [importData]);

    const download = async () => {
        setLoading(true);
        const url = `${process.env.REACT_APP_API_URL}${DOWNLOAD_SAMPLE_MEMBERS_IMPORT}?cooperative_id=${activeCoop?.id}`
        await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            }})
            .then((response) => response.blob())
            .then((myBlob) => {
                const objectURL = URL.createObjectURL(myBlob);
                const anchor = document.createElement("a");
                anchor.href = objectURL; // Set the href to the data URL
                anchor.target = "_self"; // Open in a new tab
                anchor.download = "sample-member-import.xlsx"; // Specify the file name
                // Trigger a click event on the anchor element
                anchor.click();
                anchor.remove();
            }).catch(err => console.log(err.message));
        setLoading(false)
    }

    const submit = () => {
        if(!file) {
            alertify.error('Please select a file to import');
            return;
        }
        const formData = new FormData();
        formData.append('cooperative_id', activeCoop?.id);
        formData.append('file', file);

        doImport(formData);
    }

    const props = {
        name: 'file',
        multiple: false,
        maxCount: 1,
        accept: '.xlsx',
        beforeUpload(file) {
            // console.log('before upload', file);
            setFile(file);
            return false;
        },
        onDrop(e) {
          console.log('Dropped files', e.dataTransfer.files);
        },
    };

    return (
        
        <Card loading={isLoading} title={`Upload File`} headerOptions={
            <div className="card-options">
                <button type="button" onClick={() => download()} className="btn btn-secondary btn-sm">
                    {loading ?  <i className="fa fa-spinner fa-spin mr-2" /> :  <i className="fe fe-download mr-2" />}
                    Download Sample
                </button>
            </div>
        }>
            <div className="card-body dimmer-content">
                <div className="panel panel-success">
                    <div className="panel-heading">
                        <h3 className="panel-title">Important note</h3>
                    </div>
                    <div className="panel-body">
                        <ul>
                            <li>
                                Select the file containing the Staff IDs to update
                            </li>
                            <li>
                                Click on Upload File to update Staff ID to upload and update the Staff IDs of the selected member
                            </li>
                        </ul>
                    </div>
                </div>
                <Dragger {...props}>
                    <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    {/* <p className="ant-upload-hint">
                    Support for a single or bulk upload. Strictly prohibited from uploading company data or other
                    banned files.
                    </p> */}
                </Dragger>
            </div>
            <div className="card-footer text-right">
                <button onClick={submit} className="btn btn-info">Upload Data</button>
            </div>
        </Card>
            
    )
}