import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DOWNLOAD_MONTHLY_PAYROLL } from "../../services/CONSTANTS";
import { useUploadMonthlyDeductionMutation } from "../../services/cooperative-setup.service";
import { DatePicker } from "antd";
import alertify from "alertifyjs";
import dayjs from "dayjs";
import { Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

const { Dragger } = Upload;

export const PayrollRuns = () => {
    const fixNavbar = useSelector(state => state.settings.isFixNavbar);
    const [open, setOpen] = useState(true);
    const [close, setClose] = useState(false);
    const {activeCoop} = useSelector(state => state.coop);
    const [downloadForm, setDownloadForm] = useState({
        start_date: "",
        end_date: "",
        cooperative_id: activeCoop?.id,
        repayment_type: "",
    });

    const [uploadForm, setUploadForm] = useState({
        start_date: "",
        end_date: "",
        repayment_type: "",
        file: null
    });

    const [loading, setLoading] = useState(false);
    const [doImport,  {data: importData, isSuccess, isError, error, isLoading}] = useUploadMonthlyDeductionMutation();
    const {token} = useSelector(state => state.user);

    useEffect(() => {
        if (isSuccess && importData) {
            if (importData.status) {
                alertify.success(importData.message);
                setUploadForm({
                    start_date: "",
                    end_date: "",
                    repayment_type: "",
                    file: null
                })
            } else {
                alertify.error(importData.message);
            }
        }

        if (isError) {
            alertify.error(error.data.message);
        }
        
    }, [importData, isError, isSuccess, error]);

    const download = async (e) => {
        e.preventDefault();

        if(!downloadForm.start_date){
            alertify.error('Please select a start date');
            return;
        }

        if(!downloadForm.end_date) {
            alertify.error('Please select an end date');
            return; 
        }

        if(!downloadForm.repayment_type) {
            alertify.error('Please select repayment type')
            return;
        }

        setLoading(true);
        const url = `${process.env.REACT_APP_API_URL}${DOWNLOAD_MONTHLY_PAYROLL}?cooperative_id=${activeCoop?.id}`
        await fetch(url, {
            method: 'POST',
            body: JSON.stringify(downloadForm),
            headers: {
                'Authorization': `Bearer ${token}`,
                "Content-Type": "application/json",
            }})
            .then((response) => response.blob())
            .then((myBlob) => {
                setDownloadForm({
                    start_date: "",
                    end_date: "",
                    cooperative_id: activeCoop?.id,
                    repayment_type: "",
                })
                const objectURL = URL.createObjectURL(myBlob);
                const anchor = document.createElement("a");
                anchor.href = objectURL; // Set the href to the data URL
                anchor.target = "_self"; // Open in a new tab
                anchor.download = `${downloadForm.start_date}-${downloadForm.end_date}-monthly-payroll.xlsx`; // Specify the file name
      
                // Trigger a click event on the anchor element
                anchor.click();
                anchor.remove();
            }).catch(err => console.log(err.message));
        setLoading(false)
    }

    const submit = (e) => {
        e.preventDefault();

        if(!uploadForm.file) {
            alertify.error('Please select a file to import');
            return;
        }
        if(!uploadForm.start_date){
            alertify.error('Please select a start date');
            return;
        }

        if(!uploadForm.end_date) {
            alertify.error('Please select an end date');
            return; 
        }

        if(!uploadForm.repayment_type) {
            alertify.error('Please select repayment type')
            return;
        }

        const formData = new FormData();
        formData.append('cooperative_id', activeCoop?.id);
        formData.append('start_date', uploadForm?.start_date);
        formData.append('end_date', uploadForm?.end_date);
        formData.append('repayment_type', uploadForm?.repayment_type);
        formData.append('file', uploadForm.file);

        doImport(formData);
    }

    const props = {
        name: 'file',
        multiple: false,
        maxCount: 1,
        accept: '.xlsx',
        beforeUpload(file) {
            // console.log('before upload', file);
            setUploadForm({...uploadForm, file})            
            return false;
        },
        onDrop(e) {
          console.log('Dropped files', e.dataTransfer.files);
        },
    };

    return (
        <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        {
                        !close ?
                            <div className={`card  ${!open ? 'card-collapsed' : ""}`}>
                                <div className="card-status bg-blue"></div>
                                <div className="card-header">
                                    <h3 className="panel-title">Important note</h3>
                                    <div className="card-options">
                                        <span className="card-options-collapse" onClick={() => setOpen(!open)}>
                                            <i className="fe fe-chevron-up"></i>
                                        </span>
                                        <span className="card-options-remove" onClick={() => setClose(!close)}><i className="fe fe-x"></i></span>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <ul>
                                        <li>
                                            Select From/ to date to select a month to download schedule or upload a schedule
                                        </li>
                                        <li>
                                            To download for a duration, select the first day of the month for the From date and the last day of the month for the To date (same for upload)
                                        </li>
                                        <li>
                                            Click the Select files to select the monthly payroll deduction sheet to upload
                                        </li>
                                        <li>
                                            Click Submit upload to upload the file
                                        </li>
                                        <li>
                                            Only excel files (<strong>XLS, XLSX</strong>) are allowed for file upload.
                                        </li>
                                        <li>
                                            Uploaded excel file must be in the format it was downloaded.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            : " " }
                        </div>
                    <div className="col-lg-12">
                        <form className={`card dimmer ${loading ? 'active' : ''}`} onSubmit={download}>
                            <div className="card-body dimmer-content">
                                <div className="loader" />
                                <h3 className="card-title">Download Monthly Payroll</h3>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="form-label">Start Date <span className="form-required">*</span></label>
                                            <DatePicker 
                                                className={`customInput w-full`} 
                                                name={'start_date'} 
                                                style={{width: '100%'}}
                                                onChange={(date) => {
                                                    setDownloadForm({...downloadForm, start_date: dayjs(date).format('YYYY-MM-DD')});
                                                }} 
                                                // onBlur={onBlur} 
                                                value={downloadForm.start_date ? dayjs(downloadForm.start_date) : undefined} 
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-4">
                                        <div className="form-group">
                                            <label className="form-label">End Date <span className="form-required">*</span></label>
                                            <DatePicker 
                                                className={`customInput w-full`} 
                                                name={'end_date'} 
                                                style={{width: '100%'}}
                                                onChange={(date) => {
                                                    setDownloadForm({...downloadForm, end_date: dayjs(date).format('YYYY-MM-DD')});
                                                }} 
                                                // onBlur={onBlur} 
                                                value={downloadForm.end_date ? dayjs(downloadForm.end_date) : undefined} 
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-4">
                                        <div className="form-group">
                                            <label className="form-label">Repayment Type <span className="form-required">*</span></label>
                                            <select className="form-control" onChange={(e) => setDownloadForm({...downloadForm, repayment_type: e.target.value})}>
                                                <option value="">Select Repayment Type</option>
                                                <option value="Others">Others</option>
                                                <option value="Salary">Salary</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer text-right">
                                <button type="submit" className="btn btn-primary">Download</button>
                            </div>
                        </form>
                        
                    </div>

                    <div className="col-lg-12">
                        <form className={`card dimmer ${isLoading ? 'active' : ''}`} onSubmit={submit}>
                            <div className="card-body dimmer-content">
                                <h3 className="card-title">Upload Monthly Payroll</h3>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="form-label">Start Date <span className="form-required">*</span></label>
                                            <DatePicker 
                                                className={`customInput w-full`} 
                                                name={'start_date'} 
                                                style={{width: '100%'}}
                                                onChange={(date) => {
                                                    setUploadForm({...uploadForm, start_date: dayjs(date).format('YYYY-MM-DD')});
                                                }} 
                                                // onBlur={onBlur} 
                                                value={uploadForm.start_date ? dayjs(uploadForm.start_date) : undefined} 
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-4">
                                        <div className="form-group">
                                            <label className="form-label">End Date <span className="form-required">*</span></label>
                                            <DatePicker 
                                                className={`customInput w-full`} 
                                                name={'end_date'} 
                                                style={{width: '100%'}}
                                                onChange={(date) => {
                                                    setUploadForm({...uploadForm, end_date: dayjs(date).format('YYYY-MM-DD')});
                                                }} 
                                                // onBlur={onBlur} 
                                                value={uploadForm.end_date ? dayjs(uploadForm.end_date) : undefined} 
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-4">
                                        <div className="form-group">
                                            <label className="form-label">Repayment Type <span className="form-required">*</span></label>
                                            <select className="form-control" onChange={(e) => setUploadForm({...uploadForm, repayment_type: e.target.value})}>
                                                <option value="">Select Repayment Type</option>
                                                <option value="Others">Others</option>
                                                <option value="Salary">Salary</option>
                                            </select>
                                        </div>
                                    </div>
                                    <Dragger {...props}>
                                        <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                        </p>
                                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                        {/* <p className="ant-upload-hint">
                                        Support for a single or bulk upload. Strictly prohibited from uploading company data or other
                                        banned files.
                                        </p> */}
                                    </Dragger>
                                </div>
                            </div>
                            <div className="loader" />

                            <div className="card-footer text-right">
                                <button type="submit" className="btn btn-primary">Upload</button>
                            </div>
                        </form>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}