import { createSlice } from "@reduxjs/toolkit";

const initialState = { 
  deleteModal: {
    open: false,
    url: null,
    title: null,
    item: null
  },
  alert: {type: null, show: false, message: ''},
  settings: null,
  colors: {
    primary_color: '#4276A4',
    secondary_color: '#2D5F8B'
  }
}

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    toggleDelete: (state, { payload }) => {
      const newState = { ...state };
      newState.deleteModal = payload;
      return newState;
    },
    changeColors: (state, {payload}) => {
      const newState = {...state};
      newState.colors = payload;
      return newState;
    },
    resetColors: (state) => {
      const newState = {...state};
      newState.colors = initialState.colors
      return newState;
    }
  },
});

export const { toggleDelete, changeColors, resetColors } = uiSlice.actions;
export default uiSlice.reducer;
