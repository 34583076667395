import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetUploadedFormsQuery, useLazyGetDownloadFormLinkQuery } from '../../../services/member.service';
import { Empty, Tooltip } from 'antd';
import InitialsAvatar from 'react-initials-avatar';
import { NavLink } from 'react-router-dom';
import dayjs from 'dayjs';
import alertify from 'alertifyjs';


const LoanApplications = () => {

	const fixNavbar = useSelector(state => state.settings.isFixNavbar);
	const {activeCoop} = useSelector(state => state.coop);
	const [page, setPage] = useState(1);
	const [forms, setForms] = useState([]);
	const [pagination, setPagination] = useState({});

	const {data, isLoading} = useGetUploadedFormsQuery({coopId: activeCoop?.id, page});

	const [download, {
		data: downloadData, 
		isSuccess,
		isLoading: downlaodLoading, 
		error, isError
	}] = useLazyGetDownloadFormLinkQuery()

	useEffect(() => {
		if (data) {
			setForms(data?.data?.data);
			setPagination(data?.data?.meta);
		}
	}, [data]);

	useEffect(() => {
		if (isSuccess) {
			if (downloadData && downloadData?.status) {
				const a = document.createElement("a");
				a.href = downloadData?.data;
				a.setAttribute(`download`, downloadData?.data);
				a.click();

                a.remove();
			} else {
				alertify.error(downloadData.message);
			}
		}

		if (isError) {
			alertify.error(error.message)
		}

	}, [downloadData, error, isError]);

	return (
		<div className={`section-body ${fixNavbar ? "marginTop" : ""} mt-3`}>
			<div className="container-fluid">
				<div className={`table-responsive dimmer ${isLoading ? 'active' : ''}`} id="users" style={{minHeight: 300}}>
					<div className='loader' />
					<table className="table table-hover dimmer-content table-vcenter text-nowrap table_custom border-style list" >
						<tbody>
							{data?.data.length === 0 && <tr><td colSpan={5}>
								<Empty /></td></tr>}
							{forms.map(row => 
							<tr key={`loan-form-${row.id}`}>
								<td className="text-center width40">
									<span
										className="avatar avatar-blue"
										data-toggle="tooltip"
										data-original-title="Avatar Name"
									>
										<InitialsAvatar name={`${row.member.firstname} ${row.member.lastname}`} />
									</span>
								</td>
								<td>
									<div>
										<NavLink to={`/members/view?member_id=${row.member.id}`} className="">
										{`${row.member.firstname} ${row.member.lastname}`}
										</NavLink>
									</div>
								</td>
								<td className="hidden-sm">
									<div className="text-muted">
										Loan Form - {row.member.firstname} {row.member.lastname}-{dayjs().unix()}.pdf
									</div>
								</td>
								<td className="hidden-xs">
									<div className="text-muted">Treated</div>
								</td>
								<td className="text-right">
									<Tooltip title="View Form">

									<a
										className="btn btn-sm btn-link"
										href={row.file_name}
										target='_blank'
									>
										<i className="fa fa-file-text-o" />
									</a>
									</Tooltip>
									<Tooltip title="Download form">
									<a
										className="btn btn-sm btn-link"
										href="javascript:;"
										onClick={() => download(
											{coopId: activeCoop?.id, id: row.id}
										)}
									>
										<i className="fa fa-download" />
									</a>
									</Tooltip>
								</td>
							</tr>
							)}
							
							
						</tbody>
					</table>
				</div>
			</div>
		</div>
			
	);
}

export default LoanApplications;