import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useLazyGetAccountStatementQuery } from "../../../services/utility.service";
import { formatNumber } from "../../../utils/helpers";


export const ViewCagtegoryBreakDown = ({cooperative_id, accountId, name, start, end}) => {
    const [getAccountStatement, {isFetching, data}] = useLazyGetAccountStatementQuery()
    const navigate = useHistory();

    useEffect(() => {
        if(accountId)
            getAccountStatement({coopId: cooperative_id, id: accountId, start, end})
    }, [accountId]);

    const goTo = (id) => {
        document.getElementById('close-breakdown').click();
        setTimeout(() => {
            navigate.push(`/reports/ledger-statement?code=${id}`)
        }, 300)
    }

    return (
        <div className="modal fade" id="category_breakdown_modal" tabIndex={-1} role="dialog" aria-labelledby="category_breakdown_modal" aria-hidden="true">
            <div className={`modal-dialog modal-lg dimmer ${isFetching ? 'active': ''}`} role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Account Statement For {name}</h5>
                        <button type="button" id="close-breakdown" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="loader" /> 
                    <div className="modal-body dimmer-content">
                        <div className="table-responsive">
                            <table className="table table-bordered table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th width="">Ledger Account</th>
                                        <th width="">{data?.data?.year?.current_year}</th>
                                        <th width="">{data?.data?.year?.previous_year}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!isFetching && data?.data?.results.map((row, i) =>
                                        <tr key={`account-${row.code}`}>
                                            <td>
                                                <a 
                                                    href="javascript:;"
                                                    onClick={() => {
                                                        goTo(row.code)
                                                    }}
                                                >
                                                        [{row.code}] {row.name}
                                                </a>
                                            </td>
                                            <td>{formatNumber(row.currentYearBalance)}</td>
                                            <td>{formatNumber(row.previousYearBalance)}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}