import apiSlice from "./api/api";
import {
  LOGIN,
  GET_USER,
  RESET_PASSWORD,
} from "./CONSTANTS";
import { updateUser } from "../redux/slices/user.slice";

const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Login user
    login: builder.mutation({
      query: (userData) => ({
        url: LOGIN,
        body: userData,
        method: "POST",
      }),
      onQueryStarted: async (credentials, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          const {token, user, member} = data.data;
          
          const userObject = {...user, ...member};
          // delete user.token;
          // console.log(token, 'login');

          dispatch(
            updateUser({
              token: token.token,
              user: userObject,
              isAuthenticated: true,
              role: user.role_name
            })
          );
        } catch (error) {
          return;
        }
      },
      transformResponse: (response) => {
        // console.log(response, "rtk");
        return response;
      },
      // invalidatesTags: ["User"],
    }),

    // Change password
    resetPassword: builder.mutation({
      query: (data) => ({
        url: RESET_PASSWORD,
        body: data,
        method: "PATCH",
      }),
    }),

    // get user details
    getUserDetails: builder.query({
      query: (id) => ({
        url: `${GET_USER}?user_id=${id}`,
        method: "GET",
      }),
      providesTags: ["User"],
    }),
  }),
});

export const {
  useGetUserDetailsQuery,
  useLoginMutation,
  useResetPasswordMutation,
} = authApiSlice;
