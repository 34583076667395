import { setCooperatives } from "../redux/slices/cooperative.slice";
import apiSlice from "./api/api";
import { APPROVE_ASSET, BIRTHDAY_TEMPLATE, DENY_ASSET, GENERATE_GL_CODE, GET_ALL_ASSET_TYPES, GET_ALL_COA, GET_ALL_COOP_COA, GET_ALL_COOP_LOANS, GET_ALL_COOP_SAVINGS, GET_ALL_COOPERATIVES, 
  GET_ALL_DEPARTMENTS, GET_ALL_LOANS_PROD, GET_ALL_SAVINGS_PROD, GET_ASSET_TYPES, GET_ASSETS, GET_BANK_ACCOUNTS, GET_CHARGES, GET_COA_BY_SUBCAT, GET_COA_CATEGORIES, 
  GET_COA_SUBCATEGORIES, GET_COA_SUBCATS_BY_CATEGORY, GET_COOP_CHARGES, GET_COOP_COA_BY_SUBCAT, GET_COOP_COA_CATEGORIES, 
  GET_COOP_COA_SUBCATEGORIES, GET_COOP_COA_SUBCATS_BY_CATEGORY, GET_COOP_LOANS_PROD_ID, GET_COOP_PRODUCT_CATEGORIES, GET_COOP_PRODUCTS, GET_COOP_PRODUCTS_BY_CAT, 
  GET_COOPERATIVES_PAGE, GET_LOANS_PROD_ID, GET_PRODUCT_CATEGORIES, GET_PRODUCTS, GET_PRODUCTS_BY_CAT, GET_SAVINGS_PROD_ID,
  GET_SINGLE_LOAN, GET_SINGLE_SAVINGS, JOURNAL_ENTRY, SAVE_ASSET, SAVE_ASSET_TYPE, SAVE_BANK_ACCOUNT, SAVE_CHARGE, SAVE_COA, SAVE_COA_CATEGORY, SAVE_COA_SUBCATEGORY, 
  SAVE_COOP_CHARGE, SAVE_COOP_COA, SAVE_COOP_COA_CATEGORY, SAVE_COOP_COA_SUBCATEGORY, SAVE_COOP_LOANS, SAVE_COOP_PRODUCT, 
  SAVE_COOP_PRODUCT_CATEGORY, SAVE_COOP_SAVINGS, SAVE_COOPERATIVE, SAVE_DEPARTMENT, SAVE_LOANS, SAVE_PRODUCT, SAVE_PRODUCT_CATEGORY, 
  SAVE_SAVINGS, UPDATE_ASSET, UPDATE_ASSET_TYPE, UPDATE_BANK_ACCOUNT, UPDATE_CHARGE, UPDATE_COA, UPDATE_COA_CATEGORY, UPDATE_COA_SUBCATEGORY, UPDATE_COOP_CHARGE, 
  UPDATE_COOP_COA, UPDATE_COOP_COA_CATEGORY, UPDATE_COOP_COA_SUBCATEGORY, UPDATE_COOP_LOANS, UPDATE_COOP_PRODUCT, 
  UPDATE_COOP_PRODUCT_CATEGORY, UPDATE_COOP_SAVINGS, UPDATE_COOPERATIVE, UPDATE_DEPARTMENT, UPDATE_JOURNAL_ENTRY, UPDATE_LOANS, UPDATE_PRODUCT, 
  UPDATE_PRODUCT_CATEGORY, UPDATE_SAVINGS, 
  UPLOAD_MONTHLY_PAYROLL
} from "./CONSTANTS";

const coopSetupApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    
    // Get Product Categories
    getProductCategories: builder.query({
      query: (coopId) => ({
        url: coopId ? `${GET_COOP_PRODUCT_CATEGORIES}?cooperative_id=${coopId}` : GET_PRODUCT_CATEGORIES,
        method: "GET",
      }),
      providesTags: ["ProductCategories"],
    }),
    // Save Product Category
    saveProductCat: builder.mutation({
      query: (data) => ({
        url: data.cooperative_id ? `${SAVE_COOP_PRODUCT_CATEGORY}` : SAVE_PRODUCT_CATEGORY,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["ProductCategories"],
    }),
    // Update Product Cagtegory
    updateProductCat: builder.mutation({
      query: (data) => ({
        url: data.cooperative_id ? `${UPDATE_COOP_PRODUCT_CATEGORY}?category_id=${data.id}` : `${UPDATE_PRODUCT_CATEGORY}?category_id=${data.id}`,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["ProductCategories"]
    }),
    // Get Products
    getProducts: builder.query({
      query: (coopId) => ({
        url: coopId ? `${GET_COOP_PRODUCTS}?cooperative_id=${coopId}` : GET_PRODUCTS,
        method: "GET",
      }),
      providesTags: ["Products"],
    }),
    // Get Products
    getProductsByCat: builder.query({
      query: ({coopId, id}) => ({
        url: coopId ?  `${GET_COOP_PRODUCTS_BY_CAT}?category_id=${id}&cooperative_id=${coopId}` : `${GET_PRODUCTS_BY_CAT}?category_id=${id}`,
        method: "GET",
      }),
      providesTags: ["Products"],
    }),
    // Save Product
    saveProduct: builder.mutation({
      query: (data) => ({
        url: data.cooperative_id ? `${SAVE_COOP_PRODUCT}` : SAVE_PRODUCT,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["Products"],
    }),
    // Update Product
    updateProduct: builder.mutation({
      query: (data) => ({
        url: data?.cooperative_id ? `${UPDATE_COOP_PRODUCT}?product_id=${data.id}` : `${UPDATE_PRODUCT}?product_id=${data.id}`,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["Products"]
    }),
    // Get Cooperatives
    getAllCooperatives: builder.query({
      query: () => ({
        url: GET_ALL_COOPERATIVES,
        method: "GET",
      }),
      onQueryStarted: async (credentials, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;          

          dispatch(
            setCooperatives(data?.data)
          );
          return data;
        } catch (error) {
          return;
        }
      },
      transformResponse: (response) => {
        // console.log(response, "rtk");
        return response;
      },
      providesTags: ["Cooperatives"],
    }),
    // Get Cooperatives (Paginated)
    getCooperatives: builder.query({
      query: () => ({
        url: GET_COOPERATIVES_PAGE,
        method: "GET",
      }),
      providesTags: ["Cooperatives"],
    }),
    // Save Cooperative
    saveCooperative: builder.mutation({
      query: (data) => ({
        url: SAVE_COOPERATIVE,
        body: data,
        method: "POST",
        // headers: {
        //   'Content-Type': "multipart/form-data",
        //   "Accept": "application/json"
        // },
        formData: true,
      }),
      invalidatesTags: ["Cooperatives"],
    }),
    // Update Cooperatives
    updateCooperative: builder.mutation({
      query: ({data, id}) => ({
        url: `${UPDATE_COOPERATIVE}?cooperative_id=${id}`,
        body: data,
        method: "POST",
        // headers: {'Content-Type': "multipart/form-data"},
        formData: true,
      }),
      invalidatesTags: ["Cooperatives"]
    }),
    // get coa categories
    getCoaCategories: builder.query({
      query: (coopId) => ({
        url: coopId ? `${GET_COOP_COA_CATEGORIES}?cooperative_id=${coopId}` : GET_COA_CATEGORIES,
        method: "GET",
      }),
      providesTags: ["CoaCategories"],
    }),
    // Save COA Category
    saveCoaCategory: builder.mutation({
      query: (data) => ({
        url: data.cooperative_id ? SAVE_COOP_COA_CATEGORY : SAVE_COA_CATEGORY,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["CoaCategories"],
    }),
    // Update COA Category
    updateCoaCategory: builder.mutation({
      query: (data) => ({
        url: data.cooperative_id ? `${UPDATE_COOP_COA_CATEGORY}?category_id=${data.id}` : `${UPDATE_COA_CATEGORY}?category_id=${data.id}`,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["CoaCategories"],
    }),
    // get coa sub categories
    getCoaSubCategories: builder.query({
      query: (coopId) => ({
        url: coopId ? `${GET_COOP_COA_SUBCATEGORIES}?cooperative_id=${coopId}` : GET_COA_SUBCATEGORIES,
        method: "GET",
      }),
      providesTags: ["CoaSubCategories"],
    }),
    // get coa sub categories by category
    getCoaSubCatByCat: builder.query({
      query: ({coopId, id}) => ({
        url: coopId ? `${GET_COOP_COA_SUBCATS_BY_CATEGORY}?category_id=${id}&cooperative_id=${coopId}` : `${GET_COA_SUBCATS_BY_CATEGORY}?category_id=${id}`,
        method: "GET",
      }),
      providesTags: ["CoaSubCategories"],
    }),
    // Save COA Sub Category
    saveCoaSubCategory: builder.mutation({
      query: (data) => ({
        url: data.cooperative_id ? SAVE_COOP_COA_SUBCATEGORY : SAVE_COA_SUBCATEGORY,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["CoaSubCategories"],
    }),
    // Update COA Category
    updateCoaSubCategory: builder.mutation({
      query: (data) => ({
        url: data.cooperative_id ? `${UPDATE_COOP_COA_SUBCATEGORY}?subcategory_id=${data.id}` : `${UPDATE_COA_SUBCATEGORY}?subcategory_id=${data.id}`,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["CoaSubCategories"],
    }),
    // get coa 
    getAllCoa: builder.query({
      query: (coopId) => ({
        url: coopId ? `${GET_ALL_COOP_COA}?cooperative_id=${coopId}` : GET_ALL_COA,
        method: "GET",
      }),
      providesTags: ["COA"],
    }),
    // get coa by sub category
    getCoaByCategory: builder.query({
      query: ({coopId, id}) => ({
        url: coopId ? `${GET_COOP_COA_BY_SUBCAT}?subcategory_id=${id}&cooperative_id=${coopId}` : `${GET_COA_BY_SUBCAT}?subcategory_id=${id}`,
        method: "GET",
      }),
      providesTags: ["COA"],
    }),
     // get generate gl code
     generateGLCode: builder.query({
      query: ({id, coopId}) => ({
        url: `${GENERATE_GL_CODE}?subcategory_id=${id}&cooperative_id=${coopId}`,
        method: "GET",
      }),
      providesTags: ["COA"],
    }),
    // Save COA
    saveCoa: builder.mutation({
      query: (data) => ({
        url: data.cooperative_id ? SAVE_COOP_COA : SAVE_COA,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["COA"],
    }),
    // Update COA
    updateCoa: builder.mutation({
      query: (data) => ({
        url: data.cooperative_id ? `${UPDATE_COOP_COA}?id=${data.id}` : `${UPDATE_COA}?id=${data.id}`,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["COA"],
    }),
    // get charges
    getCharges: builder.query({
      query: (coopId) => ({
        url: coopId ? `${GET_COOP_CHARGES}?cooperative_id=${coopId}` : GET_CHARGES,
        method: "GET",
      }),
      providesTags: ["Charges"],
    }),
    // Save Charge
    saveCharges: builder.mutation({
      query: (data) => ({
        url: data.cooperative_id ? SAVE_COOP_CHARGE : SAVE_CHARGE,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["Charges"],
    }),
    // Update Charge
    updateCharge: builder.mutation({
      query: (data) => ({
        url: data.cooperative_id ? `${UPDATE_COOP_CHARGE}?charge_id=${data.id}` : `${UPDATE_CHARGE}?charge_id=${data.id}`,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["Charges"],
    }),
    // get savings products
    getAllSavingsProd: builder.query({
      query: (coopId) => ({
        url: coopId ? `${GET_ALL_COOP_SAVINGS}?cooperative_id=${coopId}` : GET_ALL_SAVINGS_PROD,
        method: "GET",
      }),
      providesTags: ["SavingProductSetup"],
    }),
    // get single savings product
    getSingleSaving: builder.query({
      query: (id) => ({
        url: `${GET_SINGLE_SAVINGS}?setup_id=${id}`,
        method: "GET",
      }),
      providesTags: ["SavingProductSetup"],
    }),
    // get savings product id
    getSavingsByProduct: builder.query({
      query: ({id, coopId}) => ({
        url: coopId ? `${GET_SAVINGS_PROD_ID}?product_id=${id}&cooperative_id=${coopId}` : `${GET_SAVINGS_PROD_ID}?product_id=${id}`,
        method: "GET",
      }),
      providesTags: ["SavingProductSetup"],
    }),
    // Save Savings Product
    saveSavingsProduct: builder.mutation({
      query: (data) => ({
        url: data.cooperative_id ? SAVE_COOP_SAVINGS : SAVE_SAVINGS,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["SavingProductSetup"],
    }),
    // Update Savings
    updateSavings: builder.mutation({
      query: (data) => ({
        url: data.cooperative_id ? `${UPDATE_COOP_SAVINGS}?setup_id=${data.id}` : `${UPDATE_SAVINGS}?setup_id=${data.id}`,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["SavingProductSetup"],
    }),
    // get loans products
    getAllLoans: builder.query({
      query: (coopId) => ({
        url: coopId ? `${GET_ALL_COOP_LOANS}?cooperative_id=${coopId}` : GET_ALL_LOANS_PROD,
        method: "GET",
      }),
      providesTags: ["LoanProductSetup"],
    }),
    // get single loan product
    getSingleLoan: builder.query({
      query: (id) => ({
        url: `${GET_SINGLE_LOAN}?setup_id=${id}`,
        method: "GET",
      }),
      providesTags: ["LoanProductSetup"],
    }),
    // get loans product id
    getLoansByProduct: builder.query({
      query: ({id, coopId}) => ({
        url: coopId ? `${GET_COOP_LOANS_PROD_ID}?product_id=${id}&cooperative_id=${coopId}` : `${GET_LOANS_PROD_ID}?product_id=${id}`,
        method: "GET",
      }),
      providesTags: ["LoanProductSetup"],
    }),
    // Save Loans Product
    saveLoans: builder.mutation({
      query: (data) => ({
        url: data.cooperative_id ? SAVE_COOP_LOANS : SAVE_LOANS,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["LoanProductSetup"],
    }),
    // Update Loan
    updateLoan: builder.mutation({
      query: (data) => ({
        url: data.cooperative_id ? `${UPDATE_COOP_LOANS}?setup_id=${data.id}` : `${UPDATE_LOANS}?setup_id=${data.id}`,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["LoanProductSetup"],
    }),
     // get loans product id
     getAllBanks: builder.query({
      query: (id) => ({
        url: `${GET_BANK_ACCOUNTS}?cooperative_id=${id}`,
        method: "GET",
      }),
      providesTags: ["BankAccounts"],
    }),
    // Save Bank Account
    saveBankAccount: builder.mutation({
      query: (data) => ({
        url: SAVE_BANK_ACCOUNT,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["BankAccounts"],
    }),
    // Update Bank Account
    updateBankAccount: builder.mutation({
      query: (data) => ({
        url: `${UPDATE_BANK_ACCOUNT}?id=${data.id}`,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["BankAccounts"],
    }),
    // get departments
    getDepartments: builder.query({
      query: (id) => ({
        url: `${GET_ALL_DEPARTMENTS}?cooperative_id=${id}`,
        method: "GET",
      }),
      providesTags: ["Departments"],
    }),
    // Save Department
    saveDepartment: builder.mutation({
      query: (data) => ({
        url: SAVE_DEPARTMENT,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["Departments"],
    }),
    // Update Department
    updateDepartment: builder.mutation({
      query: (data) => ({
        url: `${UPDATE_DEPARTMENT}?dept_id=${data.id}`,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["Departments"],
    }),
    // get all asset types
    getAllAssetTypes: builder.query({
      query: (id) => ({
        url: `${GET_ALL_ASSET_TYPES}?cooperative_id=${id}`,
        method: "GET",
      }),
      providesTags: ["AssetTypes"],
    }),
    // get asset types
    getAssetTypes: builder.query({
      query: ({id, page}) => ({
        url: `${GET_ASSET_TYPES}?cooperative_id=${id}&page=${page}`,
        method: "GET",
      }),
      providesTags: ["AssetTypes"],
    }),
    // Save Asset Type
    saveAssetType: builder.mutation({
      query: (data) => ({
        url: SAVE_ASSET_TYPE,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["AssetTypes"],
    }),
    // Update Asset Type
    updateAssetType: builder.mutation({
      query: (data) => ({
        url: `${UPDATE_ASSET_TYPE}?id=${data.id}`,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["AssetTypes"],
    }),
    // get all assets
    getAllAssets: builder.query({
      query: (id) => ({
        url: `${GET_ASSETS}?cooperative_id=${id}`,
        method: "GET",
      }),
      providesTags: ["Assets"],
    }),
    // Save Asset
    saveAsset: builder.mutation({
      query: (data) => ({
        url: SAVE_ASSET,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["Assets"],
    }),
    // Update Asset Type
    updateAsset: builder.mutation({
      query: (data) => ({
        url: `${UPDATE_ASSET}?id=${data.id}`,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["Assets"],
    }),
    // Approve Asset
    approveAsset: builder.mutation({
      query: (data) => ({
        url: APPROVE_ASSET,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["Assets"],
    }),
    // Deny Asset
    denyAsset: builder.mutation({
      query: (data) => ({
        url: DENY_ASSET,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["Assets"],
    }),
    // get all journal entry
    getAllJournalEntry: builder.query({
      query: ({coopId, startDate, endDate, page}) => ({
        url: `${JOURNAL_ENTRY}?cooperative_id=${coopId}&start_date=&end_date=&page=${page}`,
        method: "GET",
      }),
      providesTags: ["JournalEntry"],
    }),
    // Save Journal Entry
    saveJournalEntry: builder.mutation({
      query: (data) => ({
        url: JOURNAL_ENTRY,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["JournalEntry"],
    }),
    // Update Journal Entry
    updateJournalEntry: builder.mutation({
      query: (data) => ({
        url: UPDATE_JOURNAL_ENTRY,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["JournalEntry"],
    }),
    //upload monthly deduction 
    uploadMonthlyDeduction: builder.mutation({
      query: (data) => ({
        url: UPLOAD_MONTHLY_PAYROLL,
        method: "POST",
        body: data,
        formData: true,
      }),
    }),
    // get all birthday template
    getBirthdayTemplate: builder.query({
      query: (coopId) => ({
        url: `${BIRTHDAY_TEMPLATE}?cooperative_id=${coopId}`,
        method: "GET",
      }),
      providesTags: ["BirthdayTemplates"],
    }),
    // upload birthday template
    uploadBirthdayTemplate: builder.mutation({
      query: (data) => ({
        url: BIRTHDAY_TEMPLATE,
        method: "POST",
        body: data,
        formData: true,
      }),
      invalidatesTags: ["BirthdayTemplates"]
    }),
  }),
});

export const {
  useGetBirthdayTemplateQuery,
  useUploadBirthdayTemplateMutation,
  useUploadMonthlyDeductionMutation,
  useLazyGetAllJournalEntryQuery,
  useSaveJournalEntryMutation,
  useUpdateJournalEntryMutation,
  useGetAllAssetsQuery,
  useSaveAssetMutation,
  useUpdateAssetMutation,
  useApproveAssetMutation,
  useDenyAssetMutation,
  useGetAllAssetTypesQuery,
  useGetAssetTypesQuery,
  useSaveAssetTypeMutation,
  useUpdateAssetTypeMutation,
  useGetProductCategoriesQuery,
  useGetProductsQuery,
  useGetProductsByCatQuery,
  useGetAllCooperativesQuery,
  useGetCooperativesQuery,
  useGetCoaCategoriesQuery,
  useSaveProductCatMutation,
  useSaveProductMutation,
  useSaveCooperativeMutation,
  useUpdateProductCatMutation,
  useUpdateProductMutation,
  useUpdateCooperativeMutation,
  useSaveCoaCategoryMutation,
  useUpdateCoaCategoryMutation,
  useGetCoaSubCategoriesQuery,
  useGetCoaSubCatByCatQuery,
  useSaveCoaSubCategoryMutation,
  useUpdateCoaSubCategoryMutation,
  useGetAllCoaQuery,
  useGetCoaByCategoryQuery,
  useGenerateGLCodeQuery,
  useSaveCoaMutation,
  useUpdateCoaMutation,
  useGetChargesQuery,
  useSaveChargesMutation,
  useUpdateChargeMutation,
  useGetAllSavingsProdQuery,
  useGetSingleSavingQuery,
  useGetSavingsByProductQuery,
  useSaveSavingsProductMutation,
  useUpdateSavingsMutation,
  useGetAllLoansQuery,
  useGetSingleLoanQuery,
  useGetLoansByProductQuery,
  useSaveLoansMutation,
  useUpdateLoanMutation,
  useGetAllBanksQuery,
  useSaveBankAccountMutation,
  useUpdateBankAccountMutation,
  useGetDepartmentsQuery,
  useSaveDepartmentMutation,
  useUpdateDepartmentMutation,
} = coopSetupApiSlice;
