import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import * as Yup from "yup";
import { DatePicker, Select } from "antd";
import dayjs from "dayjs";
import { useGetRolesByTypeQuery } from "../../../services/setup.service";
import { useGetCountriesQuery, useGetStatesQuery } from "../../../services/utility.service";
import { filterOption } from "../../../utils/helpers";
import { useSelector } from "react-redux";

const FormSchema = Yup.object().shape({
    firstname: Yup.string().required('Please enter first name'),
    lastname: Yup.string().required('Please enter last name'),
    // email: Yup.string().email().required('Enter a valid email address'),
    phone: Yup.string().required('Phone number is required'),
    gender: Yup.string().required('Please select gender'),
    current_address: Yup.string().required('Enter member current address'),
    savings_amount: Yup.string().required('Enter member savings amount'),
    // cooperative_code: Yup.string(),
    // staff_id: Yup.string()
    //                   .transform((val,orig) => orig == "" ? undefined : val)
    //                   .typeError('Staff ID is required')
    // .when('cooperative_code', (cooperative_code, schema) => {
    //   if (!cooperative_code) { return schema.required('This field is required'); }
    //   return schema;
    // }),
    // role_id: Yup.string()
    //         .transform((val,orig) => orig == "" ? undefined : val)
    //         .typeError('Role ID is required')
    // .when('cooperative_code', (cooperative_code, schema) => {
    //     if (!cooperative_code) { return schema.required('This field is required'); }
    //     return schema;
    // }),

});

export const StaffInfo = ({canSubmit, isChanging, onChange, type, details, coopCode}) => {
    const [countryId, setCountry] = useState(null);
    const [countries, setCountries] = useState([]);
    const {user} = useSelector(state => state.user);

    const {data: RolesData, isSuccess, isLoading, isError} = useGetRolesByTypeQuery('COOPERATIVE', {
        skip: type,
        refetchOnMountOrArgChange: true
    })
    const {data: CountriesData} = useGetCountriesQuery(undefined, { pollingInterval: 10000, skip: countries.length });
    const {data: StatesData} = useGetStatesQuery(countryId, {skip: !countryId});

    const {
        control,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(FormSchema),
        mode: 'onBlur'
    });

    useEffect(() => {
        if (isChanging) {
            document.getElementById('submit').click();
        }
    }, [isChanging]);

    useEffect(() => {
        if (CountriesData) setCountries(CountriesData?.data);
    }, [CountriesData]);

    const doSubmit = (values) => {
        console.log('submit')
        canSubmit('STAFF-INFO');
    }


    useEffect(() => {
        if (details) {
            setValue('firstname', details.firstname);
            setValue('lastname', details.lastname);
            setValue('middlename', details.middlename);
            setValue('email', details.user?.email);
            setValue('staff_id', details.staff_id);
            setValue('phone', details.phone);
            setValue('marital_status', details.marital_status);
            setValue('gender', details.gender);
            setValue('dob', details.dob);
            setValue('role_id', details.role_id);
            setValue('savings_amount', details.savings_amount);
            setValue('religion', details.religion);
            setValue('current_address', details.current_address);
            setValue('country_id', details.country_id || 0);
            setValue('state_id', details.state_id || 0);

            if(details.country_id) setCountry(details.country_id);
        }
        if (type && coopCode) {
            setValue("cooperative_code", coopCode);
            onChange('cooperative_code', coopCode)
        }
    }, [details, type, coopCode]);

    return (
        <form onSubmit={handleSubmit(doSubmit)} className={`row clearfix dimmer-content`}>
            <div className="col-lg-4 col-md-4 col-sm-6">
                <div className="form-group">
                    <label>First Name <span className="form-required">*</span></label>
                    <Controller
                        name="firstname"
                        control={control}
                        render={({ field }) => (
                            <input
                                className={`form-control ${
                                errors?.firstname ? "is-invalid" : ""   
                                }`}
                                type="text"
                                onChange={(e) => {
                                    field.onChange(e);
                                    onChange('firstname', e.target.value)
                                }}
                                value={field.value}
                                autoComplete="true"
                            />
                        )}
                    />
                    {errors.firstname && <div className="invalid-feedback">{errors.firstname?.message}</div>}
                </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6">
                <div className="form-group">
                    <label>Other Names </label>
                    <Controller
                        name="middlename"
                        control={control}
                        render={({ field }) => (
                            <input
                                className={`form-control`}
                                type="text"
                                onChange={(e) => {
                                    field.onChange(e);
                                    onChange('middlename', e.target.value)
                                }}
                                value={field.value}
                                autoComplete="true"
                            />
                        )}
                    />
                </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6">
                <div className="form-group">
                    <label>Last Name <span className="form-required">*</span></label>
                    <Controller
                        name="lastname"
                        control={control}
                        render={({ field }) => (
                            <input
                                className={`form-control ${
                                errors?.lastname ? "is-invalid" : ""
                                }`}
                                type="text"
                                onChange={(e) => {
                                    field.onChange(e);
                                    onChange('lastname', e.target.value)
                                }}
                                value={field.value}
                                autoComplete="true"
                            />
                        )}
                    />
                    {errors.lastname && <div className="invalid-feedback">{errors.lastname?.message}</div>}
                </div>
            </div>
            {!type && 
            <div className="col-lg-4 col-md-4 col-sm-6">
                <div className="form-group">
                    <label>Staff ID <span className="form-required">*</span></label>
                    <Controller
                        name="staff_id"
                        control={control}
                        render={({ field }) => (
                            <input
                                className={`form-control ${
                                errors?.staff_id ? "is-invalid" : ""
                                }`}
                                type="text"
                                onChange={(e) => {
                                    field.onChange(e);
                                    onChange('staff_id', e.target.value)
                                }}
                                readOnly={details?.staff_id === user?.coop_id}
                                value={field.value}
                                autoComplete="true"
                            />
                        )}
                    />
                    {errors.staff_id && <div className="invalid-feedback">{errors.staff_id?.message}</div>}
                </div>
            </div>}
            <div className="col-lg-4 col-md-4 col-sm-6">
                <div className="form-group">
                    <label>Email <span className="form-required">*</span></label>
                    <Controller
                        name="email"
                        control={control}
                        render={({ field }) => (
                            <input
                                className={`form-control ${
                                errors?.email ? "is-invalid" : ""
                                }`}
                                type="text"
                                onChange={(e) => {
                                    field.onChange(e);
                                    onChange('email', e.target.value)
                                }}
                                value={field.value}
                                autoComplete="true"
                            />
                        )}
                    />
                    {errors.email && <div className="invalid-feedback">{errors.email?.message}</div>}
                </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6">
                <div className="form-group">
                    <label>Phone  <span className="form-required">*</span></label>
                    <Controller
                        name="phone"
                        control={control}
                        render={({ field }) => (
                            <input
                                className={`form-control ${
                                errors?.phone ? "is-invalid" : ""
                                }`}
                                type="text"
                                onChange={(e) => {
                                    field.onChange(e);
                                    onChange('phone', e.target.value)
                                }}
                                value={field.value}
                                autoComplete="true"
                            />
                        )}
                    />
                    {errors.phone && <div className="invalid-feedback">{errors.phone?.message}</div>}
                </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6">
                <div className="form-group">
                    <label>Martial Status</label>
                    <Controller
                        name="marital_status"
                        control={control}
                        render={({ field }) => (
                            <select 
                                value={field.value} 
                                onChange={(e) => {
                                    field.onChange(e);
                                    onChange('marital_status', e.target.value)
                                }}
                                className="form-control"
                            >
                                <option value="">-- Select --</option>
                                <option value="Single">Single</option>
                                <option value="Married">Married</option>
                                <option value="Divorced">Divorced</option>
                            </select>
                        )}
                    />
                    
                </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6">
                <div className="form-group">
                    <label>Gender</label>
                    <Controller
                        name="gender"
                        control={control}
                        render={({ field }) => (
                            <select 
                                value={field.value} 
                                onChange={(e) => {
                                    field.onChange(e);
                                    onChange('gender', e.target.value)
                                }}
                                className={`form-control custom-select ${errors?.gender ? "is-invalid" : ""}`}
                            >
                                <option value="">-- Select --</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                            </select>
                        )}
                    />
                    {errors.gender && <div className="invalid-feedback">{errors.gender?.message}</div>}
                </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6">
                <div className="form-group">
                    <label>Date of Birth</label>
                    <Controller
                        name="dob"
                        control={control}
                        render={({ field }) => (
                            <DatePicker
                                className={`customInput w-full`} 
                                name={'dob'} 
                                style={{width: '100%'}}
                                onChange={(val) => {

                                    field.onChange(val);
                                    onChange('dob', dayjs(val).format('YYYY-MM-DD'))
                                }}
                                // onBlur={onBlur} 
                                value={field.value ? dayjs(field.value) : undefined} 
                            />
                        )}
                    />
                    {errors.dob && <div className="invalid-feedback">{errors.dob?.message}</div>}
                </div>
            </div>
            {!type && 
            <div className="col-lg-4 col-md-4 col-sm-6">
                <div className="form-group">
                    <label>Member Role <span className="form-required">*</span></label>
                    <Controller
                        name="role_id"
                        control={control}
                        render={({ field }) => (
                            <select 
                                onChange={(e) => {
                                    field.onChange(e);
                                    onChange('role_id', e.target.value)
                                }}
                                value={field.value}
                                disabled={user?.role_name === "cooperative-member"}
                                className={`form-control custom-select ${errors?.role_id ? "is-invalid" : ""}`}
                            >
                                <option value=''>Select Member Role</option>
                                {RolesData?.data?.map(role => <option key={role.id} value={role.id}>{role.display_name}</option>)}
                            </select>
                        )}
                    />
                    {errors.role_id && <div className="invalid-feedback">{errors.role_id?.message}</div>}
                </div>
            </div>}
            <div className="col-lg-4 col-md-4 col-sm-6">
                <div className="form-group">
                    <label>Savings Amount <span className="form-required">*</span></label>
                    <Controller
                        name="savings_amount"
                        control={control}
                        render={({ field }) => (
                            <input
                                className={`form-control ${
                                errors?.savings_amount ? "is-invalid" : ""
                                }`}
                                type="text"
                                onChange={(e) => {
                                    field.onChange(e);
                                    onChange('savings_amount', e.target.value)
                                }}
                                value={field.value}
                                autoComplete="true"
                            />
                        )}
                    />
                    {errors.savings_amount && <div className="invalid-feedback">{errors.savings_amount?.message}</div>}
                </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6">
                <div className="form-group">
                    <label>Religion</label>
                    <Controller
                        name="religion"
                        control={control}
                        render={({ field }) => (
                            <input
                                className={`form-control`}
                                type="text"
                                onChange={(e) => {
                                    field.onChange(e);
                                    onChange('religion', e.target.value)
                                }}
                                value={field.value}
                                autoComplete="true"
                            />
                        )}
                    />
                </div>
            </div>
        
        
            <div className="col-lg-4 col-md-4 col-sm-6">
                <div className="form-group">
                    <label>Country</label>
                    <Controller
                        name="country_id"
                        control={control}
                        render={({ field }) => (
                            <Select 
                                showSearch
                                className="customSelect mt-1"
                                options={countries.map(country => ({value: country.id, label: country.name}))} 
                                placeholder="Select Charge type"
                                filterOption={filterOption}
                                value={field.value}
                                onChange={(value)=> {
                                    field.onChange(value);
                                    setCountry(value);
                                    onChange('country_id', value)
                                }}
                            />
                            
                        )}
                    />
                </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6">
                <div className="form-group">
                    <label>State <span className="form-required">*</span></label>
                    <Controller
                        name="state_id"
                        control={control}
                        render={({ field }) => (
                            <Select 
                                showSearch
                                className="customSelect mt-1"
                                options={StatesData?.data.map(state => ({value: state.id, label: state.name}))} 
                                placeholder="Select Charge type"
                                filterOption={filterOption}
                                value={field.value}
                                onChange={(val) => {
                                    field.onChange(val);
                                    onChange('state_id', val)
                                }}
                            />
                        )}
                    />
                </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6">
                <div className="form-group">
                    <label>Current Address <span className="form-required">*</span></label>
                    <Controller
                        name="current_address"
                        control={control}
                        render={({ field }) => (
                            <input
                                className={`form-control ${
                                errors?.current_address ? "is-invalid" : ""
                                }`}
                                type="text"
                                onChange={(e) => {
                                    field.onChange(e);
                                    onChange('current_address', e.target.value)
                                }}
                                value={field.value}
                                autoComplete="true"
                            />
                        )}
                    />
                    {errors.current_address && <div className="invalid-feedback">{errors.current_address?.message}</div>}
                </div>
            </div>
            <button 
                id="submit" 
                type="submit" 
                className="staff-info-btn" 
                style={{display: 'none'}}
            >
                Submit
            </button>
        </form>
    )
}