import React from "react"

export const ProductList = () => {

    return (
        <div className="row clearfix">
            <div className="col-12">
                <div className="card">
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-striped table-vcenter table-hover mb-0">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Category</th>
                                        <th>Buying Price</th>
                                        <th>Selling Prices</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><img src="../assets/images/gallery/1.jpg" alt="Avatar" className="w30 rounded-circle mr-2" /> <span>Item 1</span></td>
                                        <td>Cat 1</td>
                                        <td>N30,000</td>
                                        <td>N31,500</td>
                                        <td><span className="tag tag-success">Active</span></td>
                                        <td>
                                            <button type="button" className="btn btn-icon" title="Edit"><i className="fa fa-edit" /></button>
                                            <button type="button" className="btn btn-icon js-sweetalert" title="Delete" data-type="confirm"><i className="fa fa-trash-o text-danger" /></button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><img src="../assets/images/gallery/2.jpg" alt="Avatar" className="w30 rounded-circle mr-2" /> <span>Item 2</span></td>
                                        <td>Cat 1</td>
                                        <td>N30,000</td>
                                        <td>N31,500</td>
                                        <td><span className="tag tag-success">Active</span></td>
                                        <td>
                                            <button type="button" className="btn btn-icon" title="Edit"><i className="fa fa-edit" /></button>
                                            <button type="button" className="btn btn-icon js-sweetalert" title="Delete" data-type="confirm"><i className="fa fa-trash-o text-danger" /></button>
                                        </td>
                                    </tr>
                                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}