import React, { useEffect, useState } from "react";
import Card from "../../common/Card";
import { Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { useUploadLoanFormMutation } from "../../../services/member.service";
import alertify from "alertifyjs";

const { Dragger } = Upload;

export const UploadLoanForm = ({coopId}) => {

    const [file, setFile] = useState(null);
    const [doUpload,  {data: importData, isSuccess, isLoading, isError, error}] = useUploadLoanFormMutation();

    const props = {
        name: 'file',
        multiple: false,
        maxCount: 1,
        accept: '.pdf',
        // action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
        // onChange(info) {
        //   const { status } = info.file;
        //   if (status !== 'uploading') {
        //     console.log(info.file, info.fileList);
        //   }
        //   if (status === 'done') {
        //     message.success(`${info.file.name} file uploaded successfully.`);
        //   } else if (status === 'error') {
        //     message.error(`${info.file.name} file upload failed.`);
        //   }
        // },
        beforeUpload(file) {
            // console.log('before upload', file);
            setFile(file);
            return false;
        },
        onDrop(e) {
          console.log('Dropped files', e.dataTransfer.files);
        },
    };

    useEffect(() => {
        if (isSuccess && importData) {
            if (importData.status) {
                alertify.success(importData.message);
            } else {
                alertify.error(importData.message);
            }
        }

        if (isError ){
            alertify.error(error.data.message);
        }
        
    }, [importData, isError, error, isSuccess]);


    const submit = () => {
        if(!file) {
            alertify.error('Please select a file to import');
            return;
        }
        const formData = new FormData();
        formData.append('cooperative_id', coopId);
        formData.append('file', file);

        doUpload(formData);
    }

    return (
        <Card title="Upload Loan Form" loading={isLoading}>
            <div className="card-body">
                <Dragger {...props}>
                    <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    {/* <p className="ant-upload-hint">
                    Support for a single or bulk upload. Strictly prohibited from uploading company data or other
                    banned files.
                    </p> */}
                </Dragger>
            </div>
            <div className="card-footer">
                <button type="button" onClick={submit} className="btn btn-primary">Submit</button>
            </div>
        </Card>
               
    )
}