import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import * as Yup from "yup";

const FormSchema = Yup.object().shape({
    contact_name: Yup.string().required('This field is required'),
    contact_address: Yup.string().required('This field is required'),
    contact_phone: Yup.string().required('This field is required'),
    contact_email: Yup.string().required('This field is required'),

});

export const EmergencyContact = ({canSubmit, isChanging, onChange, details}) => {
    const {
        control,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(FormSchema),
        mode: 'onBlur'
    });

    useEffect(() => {
        if (isChanging) {
            document.getElementById('submit').click();
        }
    }, [isChanging]);

    useEffect(() => {
        if(details) {
            const emergencyInfo = details.emergencyInfo;
            setValue('contact_address', emergencyInfo.address)
            setValue('contact_name', emergencyInfo.name)
            setValue('contact_phone', emergencyInfo.phone)
            setValue('contact_email', emergencyInfo.email)
        }
    }, [details]);

    const doSubmit = () => {
        canSubmit('CONTACT-INFO');
    }

    return (
        <form onSubmit={handleSubmit(doSubmit)} className="row clearfix">
            <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                    <label>Name  <span className="form-required">*</span></label>
                    <Controller
                        name="contact_name"
                        control={control}
                        render={({ field }) => (
                            <input
                                className={`form-control ${
                                errors?.contact_name ? "is-invalid" : ""
                                }`}
                                type="text"
                                onChange={(e) => {
                                    field.onChange(e);
                                    onChange('contact_name', e.target.value)
                                }}
                                value={field.value}
                                autoComplete="true"
                            />
                        )}
                    />
                    {errors.contact_name && <div className="invalid-feedback">{errors.contact_name?.message}</div>}
                </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                    <label>Address <span className="form-required">*</span> </label>
                    <Controller
                        name="contact_address"
                        control={control}
                        render={({ field }) => (
                            <input
                                className={`form-control ${
                                errors?.contact_address ? "is-invalid" : ""
                                }`}
                                type="text"
                                onChange={(e) => {
                                    field.onChange(e);
                                    onChange('contact_address', e.target.value)
                                }}
                                value={field.value}
                                autoComplete="true"
                            />
                        )}
                    />
                    {errors.contact_address && <div className="invalid-feedback">{errors.contact_address?.message}</div>}
                </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                    <label>Phone <span className="form-required">*</span> </label>
                    <Controller
                        name="contact_phone"
                        control={control}
                        render={({ field }) => (
                            <input
                                className={`form-control ${
                                errors?.contact_phone ? "is-invalid" : ""
                                }`}
                                type="text"
                                onChange={(e) => {
                                    field.onChange(e);
                                    onChange('contact_phone', e.target.value)
                                }}
                                value={field.value}
                                autoComplete="true"
                            />
                        )}
                    />
                    {errors.contact_phone && <div className="invalid-feedback">{errors.contact_phone?.message}</div>}
                </div>
            </div>
            <div className="col-lg-6 col-md-4 col-sm-6">
                <div className="form-group">
                    <label>Email <span className="form-required">*</span></label>
                    <Controller
                        name="contact_email"
                        control={control}
                        render={({ field }) => (
                            <input
                                className={`form-control ${
                                errors?.contact_email ? "is-invalid" : ""
                                }`}
                                type="text"
                                onChange={(e) => {
                                    field.onChange(e);
                                    onChange('contact_email', e.target.value)
                                }}
                                value={field.value}
                                autoComplete="true"
                            />
                        )}
                    />
                    {errors.contact_email && <div className="invalid-feedback">{errors.contact_email?.message}</div>}

                </div>
            </div>
            <button id="submit" type="submit" className="emergency-contact-btn" style={{display: 'none'}}>Submit</button>
        </form>
    )
}