
import React, { useState } from "react";
import { useSelector } from "react-redux";
import AssetGLSetupForm from "./AssetGLSetupForm";
import AssetGlList from "./AssetGlList";

const AssetGlSetup = () => {
    const {isFixNavbar: fixNavbar} = useSelector(state => state.settings);
    const {activeCoop} = useSelector(state => state.coop);
    const [assetType, setAssetType] = useState(null);

    return (
        <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>

            <div className="row">
                <div className="col-md-8 col-sm-12">
                    <AssetGlList activeCoop={activeCoop} onEdit={(e) => setAssetType(e)} />
                </div>
                <div className="col-md-4 col-sm-12">
                    <AssetGLSetupForm coopId={activeCoop?.id} asset={assetType} onCancel={() => setAssetType(null)} />
                </div>
            </div>
        </div>
    )
}

export default AssetGlSetup;