import React from "react";
import { useSelector } from "react-redux"

const Card = ({loading, title, children, headerOptions, customClass, styles = {} }) => {
    const {colors} = useSelector(state => state.ui);

    return (
        <div className={`card dimmer ${loading ? 'active' : ''} ${customClass}`}  style={styles}>
            {(title || headerOptions) &&
            <div className="card-header" style={{backgroundColor: colors?.primary_color}}>
                <h3 className="card-title">{title}</h3>
                {headerOptions}
            </div>}
            {children}
            <div className="loader" />
        </div>
    )
}

export default Card;