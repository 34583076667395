import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import * as Yup from "yup";
import alertify from "alertifyjs";
import { useSavePositionMutation } from "../../../services/election.service";

const FormSchema = Yup.object().shape({
    name: Yup.string().required('Please enter a name'),
    status: Yup.string().required('This field is required'),
});


export const PositionForm = ({electionId, coopId, item, onCancel, onSave}) => {
    const [save, {data, isLoading, isSuccess, isError, error}] = useSavePositionMutation();

    const {
        control,
        reset,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(FormSchema),
        mode: 'onBlur'
    });

    useEffect(() => {
        if (item) {
            setValue('name', item.name);
            setValue('description', item.description);
            setValue('status', item.status);
            setValue('position_id', item.id);
        }
    }, [item]);

    const onSubmit = (values) => {
        const payload = {...values};
        
        payload.election_id = electionId
        payload.cooperative_id = coopId
        
        save(payload);
    }

    useEffect(() => {
        if(isSuccess) {
            reset();
            // show toast.
            alertify.success(data?.message);
            onSave()
        }

        if (isError) {
            alertify.error(error?.data?.message);
        }
    }, [isSuccess, isError, data]);

    return (
        <form className={`dimmer ${isLoading ? 'active' : ''}`} onSubmit={handleSubmit(onSubmit)}>
            <dive className="loader" />
            <div className="row clearfix dimmer-content">
                <div className="col-md-12">
                    <div className="form-group">
                        <label>Name</label>
                        <Controller
                            name="name"
                            control={control}
                            render={({ field }) => (
                                <input
                                    className={`form-control ${
                                    errors?.name ? "is-invalid" : ""
                                    }`}
                                    type="text"
                                    onChange={field.onChange}
                                    value={field.value}
                                    autoComplete="true"
                                    placeholder="Election Name"
                                />
                            )}
                        />
                        {errors.name && <div className="invalid-feedback">{errors.name?.message}</div>}
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="form-group">
                        <label>Description</label>
                        <Controller
                            name="description"
                            control={control}
                            render={({ field }) => (
                                <textarea 
                                    type="text" 
                                    rows={4} 
                                    className="form-control" 
                                    placeholder="Description"
                                    onChange={field.onChange}
                                    defaultValue={field.value}
                                >
                                    {field.value}
                                </textarea>
                            )}
                        />
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="form-group">
                        <label>Status</label>
                        <Controller
                            name="status"
                            control={control}
                            render={({ field }) => (
                                <select 
                                    onChange={field.onChange}
                                    value={field.value}
                                    className={`form-control ${errors?.status ? "is-invalid" : ""}`}
                                >
                                    <option value=''>Select Status</option>
                                    <option value='1'>Active</option>
                                    <option value='0'>Inactive</option>
                                </select>
                            )}
                        />
                        {errors.status && <div className="invalid-feedback">{errors.status?.message}</div>}
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-between">
                <button type="button" className="btn btn-secondary" onClick={() => {
                    onCancel()
                    reset();
                }}>Close</button>
                <button type="submit" className="btn btn-primary">Save changes</button>

            </div>
        </form>
    )
}