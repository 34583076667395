import React, { useEffect } from "react";
import alertify from "alertifyjs";
import { useApproveAssetMutation, useDenyAssetMutation } from "../../services/cooperative-setup.service";
import { useApproveChangeRequestMutation, useApprovePendingMemberMutation, useDenyChangeRequestMutation, useLazyDenyMemberQuery } from "../../services/member.service";

export default function ApproveDenyAsset({ id, type, action, coopId, title }) {
    const [denyAsset, {isLoading, isSuccess, isError, data, error}] = useDenyAssetMutation();

    const [denyMember, {
        isLoading: memDenyLoading, 
        isSuccess: memDenySuccess, 
        isError: memDenyIsError, 
        data: memDenyData, 
        error: memDenyErr
    }] = useLazyDenyMemberQuery();

    const [approveMember, {
        isLoading: memApproveLoading, 
        isSuccess: memApproveSuccess, 
        isError: memApproveIsErr, 
        data: memApproveData, 
        error: memApproveErro
    }] = useApprovePendingMemberMutation();

    const [approveAsset, {
        isLoading: isLoanLoding, 
        isSuccess: isLoanSuccess, 
        isError: isLoanError, 
        data: loanData, 
        error: loanError
    }] = useApproveAssetMutation();

    const [approveChangeRequest, {
        isLoading: loadingRequest, 
        isSuccess: requestSuccess, 
        isError: requestError, 
        data: requestData, 
        error: approveRequestError
    }] = useApproveChangeRequestMutation();

    const [denyChangeRequest, {
        isLoading: denyRequestLoading, 
        isSuccess: denyRequestSuccess, 
        isError: isDenyError, 
        data: denyRequestData, 
        error: denyRequestErr
    }] = useDenyChangeRequestMutation();

    
    useEffect(() => {
        if (isSuccess && data.status)  {
            document.getElementById('close-asset-modal').click();
            alertify.success(data?.message);
        }

        if(isError) {
            alertify.error(error.data.message)
        }


    }, [isSuccess, isError, error, data]);

    useEffect(() => {
        if (isLoanSuccess && loanData.status)  {
            document.getElementById('close-asset-modal').click();
            alertify.success(loanData?.message);
        }

        if(isLoanError) {
            alertify.error(loanError.data.message)
        }


    }, [isLoanSuccess, isLoanError, loanError, loanData]);

    useEffect(() => {
        if (memDenySuccess && memDenyData.status)  {
            document.getElementById('close-asset-modal').click();
            alertify.success(memDenyData?.message);
        }

        if(memDenyIsError) {
            alertify.error(memDenyErr.data.message)
        }

        if (memApproveSuccess && memApproveData.status)  {
            document.getElementById('close-asset-modal').click();
            alertify.success(memApproveData?.message);
        }

        if(memApproveIsErr) {
            alertify.error(memApproveErro.data.message)
        }

        if (requestData && requestData.status) {
            document.getElementById('close-asset-modal').click();
            alertify.success(requestData?.message);
        }

        if (denyRequestData && denyRequestData.status) {
            document.getElementById('close-asset-modal').click();
            alertify.success(denyRequestData?.message);
        }

        if(approveRequestError || denyRequestErr) {
            alertify.error(approveRequestError.data.message || denyRequestErr?.data.message)
        }


    }, [memDenySuccess, memDenyIsError, memDenyData, memDenyErr, memApproveSuccess, memApproveErro, 
        memApproveData, memApproveIsErr, requestData, denyRequestData, approveRequestError, denyRequestErr]);


    const onConfirm = () => {
        switch (type) {
            case 'asset':
                updateAsset()
                break;
            case 'pending-member': 
                updatePendingMember()
                break;
            case 'change-request':
                updateChangeRequest()
            default:
                break;
        }
        
    }

    const updateChangeRequest = () => {
        if (action === 'approve') {
            approveChangeRequest({
                coopId,
                requestId: id
            });
        } else {
            denyChangeRequest({
                coopId,
                requestId: id
            })
        }
    }

    const updateAsset = () => {
        if (action === 'approve') {
            approveAsset({
                cooperative_id: coopId,
                asset_id: id
            });
        } else {
            denyAsset({
                cooperative_id: coopId,
                asset_id: id
            })
        }
    }

    const updatePendingMember = () => {
        if (action === 'approve') {
            approveMember({
                cooperative_id: coopId,
                member_id: id
            });
        } else {
            denyMember({
                coopId,
                memberId: id
            })
        }
    }

    return (
        <div className="modal fade" id="assetModal" tabIndex={-1} role="dialog" aria-labelledby="denyModal" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className={`modal-content dimmer ${isLoading || isLoanLoding || loadingRequest || denyRequestLoading ? 'active' : ''}`}>
                    <div className="loader" />
                    
                    <div className="modal-body dimmer-content">
                        <div className="form-header text-center">
                            <h3>{action === 'approve' ? 'Approve' : 'Deny' } {title}</h3>
                            <p>{`Are you sure want to ${action} this asset?`}</p>
                        </div>
                        <div className="modal-btn delete-action">
                            <div className="row">
                                <div className="col-6">
                                    <a 
                                        href="javascript:;"
                                        id="close-asset-modal" data-dismiss="modal" className="btn btn-secondary btn-block">Cancel</a>
                                </div>
                                <div className="col-6">
                                    <a
                                        href="javascript:;"
                                        onClick={onConfirm}
                                        className="btn btn-primary btn-block"
                                    >
                                        {isLoading ? 'Please Wait...' : 'Confirm'}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
