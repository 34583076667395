import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { PersonalInfo } from "./Components/PersonalInfo";
import { ChangePasswordComponent } from "../Members/MemberDetails/ChangePasswordComponent";
import { useGetCoopMemberQuery } from "../../services/member.service";
import alertify from "alertifyjs";

export const ChangePassword = () => {
    const {isFixNavbar: fixNavbar} = useSelector(state => state.settings);
    const {activeCoop} = useSelector(state => state.coop);
    const {user} = useSelector(state => state.user);
    const [member, setMember] = useState(null);
	const {data, isLoading, isError, error} = useGetCoopMemberQuery({coop_id: activeCoop?.id, member_id: user?.id});

    useEffect(() => {
		if (data) {
			setMember(data?.data);
		}

		if (isError) {
			alertify.error(error.message);
		}
	}, [data]);
    
    return (
        <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
            <div className="container-fluid px-0">
                <div className="row clearfix">
                    <div className="col-lg-3 col-md-12">
                        <PersonalInfo member={member} />
                    </div>
                    <div className="col-lg-9 col-md-12">
                        <ChangePasswordComponent coopId={activeCoop?.id} memberId={user?.coop_id} />
                    </div>
                </div>
            </div>
        </div>
    )
}