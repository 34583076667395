import apiSlice from "./api/api";
import {
  GET_ROLES,
  SEARCH_ROLES,
  SAVE_ROLES,
  UPDATE_ROLES,
  GET_USERS,
  SAVE_USER,
  UPDATE_USER,
  GET_STAFF_CATEGORIES,
  SAVE_STAFF_CATEGORY,
  UPDATE_STAFF_CATEGORY,
} from "./CONSTANTS";

const setupApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Get Roles
    getRoles: builder.query({
      query: () => ({
        url: GET_ROLES,
        method: "GET",
      }),
      providesTags: ["Roles"],
    }),
    // Get Roles By Type
    getRolesByType: builder.query({
      query: (type) => ({
        url: `${SEARCH_ROLES}?query=${type}`,
        method: "GET",
      }),
      providesTags: ["Roles"],
    }),
    // Save Roles
    saveRole: builder.mutation({
      query: (data) => ({
        url: SAVE_ROLES,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["Roles"],
    }),
    // Update Roles
    updateRole: builder.mutation({
      query: (data) => ({
        url: `${UPDATE_ROLES}?role_id=${data.id}`,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["Roles"]
    }),
    // Delete Roles
    deleteItem: builder.mutation({
      query: (url) => ({
        url,
        method: "DELETE",
      }),
      invalidatesTags: [
        "Roles", "Cooperatives", "StaffCategories", "Users", "Members", "Departments", "LoanOffset",
        "ProductCategories", "Products", "Charges", "COA", "SavingProductSetup", "JournalEntry", "Voting",
        "CoaCategories", "CoaSubCategories", "BankAccounts", "LoanProductSetup", "AssetTypes", "Assets", "BirthdayTemplates"
      ]
    }),

    // Get Admin Users
    getUsers: builder.query({
      query: () => ({
        url: GET_USERS,
        method: "GET",
      }),
      providesTags: ["Users"],
    }),
    // Save Admin User
    saveUser: builder.mutation({
      query: (data) => ({
        url: SAVE_USER,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["Users"],
    }),
    // Update User Details
    updateUser: builder.mutation({
      query: (data) => ({
        url: `${UPDATE_USER}?user_id=${data.id}`,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["Users"]
    }),
    // Get Staff Categories
    getStaffCategories: builder.query({
      query: () => ({
        url: GET_STAFF_CATEGORIES,
        method: "GET",
      }),
      providesTags: ["StaffCategories"],
    }),
    // Save Staff Category
    saveStaffCat: builder.mutation({
      query: (data) => ({
        url: SAVE_STAFF_CATEGORY,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["StaffCategories"],
    }),
    // Update staff Cagtegory
    updateStaffCat: builder.mutation({
      query: (data) => ({
        url: `${UPDATE_STAFF_CATEGORY}?category_id=${data.id}`,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["StaffCategories"]
    }),
  }),
});

export const {
  useGetRolesQuery,
  useGetRolesByTypeQuery,
  useGetUsersQuery,
  useGetStaffCategoriesQuery,
  useSaveUserMutation,
  useSaveRoleMutation,
  useSaveStaffCatMutation,
  useUpdateRoleMutation,
  useUpdateUserMutation,
  useUpdateStaffCatMutation,
  useDeleteItemMutation
} = setupApiSlice;
