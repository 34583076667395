
export const filterOption = (input, option) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase());



export const formatNumber = number =>
  !number
    ? '₦' + 0
    : '₦' + parseFloat(number).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

export const doDownload = async (url, filename, token) => {
  await fetch(url, {
    method: 'GET',
    headers: {
        'Authorization': `Bearer ${token}`,
    }})
    .then((response) => response.blob())
    .then((myBlob) => {
        const objectURL = URL.createObjectURL(myBlob);
        const anchor = document.createElement("a");
        anchor.href = objectURL; // Set the href to the data URL
        anchor.target = "_self"; // Open in a new tab
        anchor.download = filename; // Specify the file name

        // Trigger a click event on the anchor element
        anchor.click();
        anchor.remove();
    }).catch(err => console.log(err.message));
}