import React from "react";
import { MemberDashboard } from "./MemberDashboard";
import { CoopAdminDashboard } from "./CoopAdminDashboard";
import { useSelector } from "react-redux";

export const CoopDashboard = ({fixNavbar, colors, user}) => {
    const {activeCoop} = useSelector(state => state.coop);
    return (
        user?.role_name === 'cooperative-member' ? 
            <MemberDashboard activeCoop={activeCoop} user={user} fixNavbar={fixNavbar} /> 
        : 
            <CoopAdminDashboard fixNavbar={fixNavbar} activeCoop={activeCoop} />
    )
}