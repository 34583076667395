
import React, { useState } from "react";
import { useSelector } from "react-redux";
import DeptList from "./DeptList";
import DeptForm from "./DeptForm";

const DeptSetup = () => {
    const {isFixNavbar: fixNavbar} = useSelector(state => state.settings);
    const {activeCoop} = useSelector(state => state.coop);

    const [dept, setDept] = useState(null)
    return (
        <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>

            <div className="row">
                <div className="col-md-8 col-sm-12">
                    <DeptList onEdit={(val) => setDept(val)} activeCoop={activeCoop} />
                </div>
                <div className="col-md-4 col-sm-12">
                    <DeptForm dept={dept} activeCoop={activeCoop} onCancel={() => setDept(null)} />
                </div>
            </div>
        </div>
    )
}

export default DeptSetup;