import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLazyGetLoanListQuery, useMarkLoanAsCompletedMutation, useMarkLoanAsIncompleteMutation } from '../../../services/member.service';
import { DatePicker, Empty } from 'antd';
import InitialsAvatar from 'react-initials-avatar';
import { formatNumber } from '../../../utils/helpers';
import { DELETE_LOAN, DOWNLOAD_LOAN_FORM } from '../../../services/CONSTANTS';
import { toggleDelete } from '../../../redux/slices/ui.slice';
import { NavLink } from 'react-router-dom';
import ApproveTransactionModal from '../../common/ApproveTransactionModal';
import DenyTransactionModal from '../../common/DenyTransactionModal';
import { LoanDetails } from '../../MemberArea/Components/LoanDetails';
import dayjs from 'dayjs';
import { Pagination } from '../../Pagination/Pagination';

function LoansList () {
    const {isFixNavbar: fixNavbar} = useSelector(state => state.settings);
    const {token} = useSelector(state => state.user);
    const {activeCoop} = useSelector(state => state.coop);
    const [selectedItem, setSelected] = useState(null);
    const [filterData, setFilterData] = useState({
        coopId: activeCoop?.id,
        page: 1,
        start: '',
        end: ''
    })
    const dispatch = useDispatch();
    const [getResults, {data, isFetching}] = useLazyGetLoanListQuery();
    const [markComplete, {isLoading: loadingComplete, isSuccess: completeSuccess }] = useMarkLoanAsCompletedMutation()
    const [markInComplete, {isLoading: loadingIncomplete, isSuccess: inCompleteSuccess }] = useMarkLoanAsIncompleteMutation()

    useEffect(() => {
        if (activeCoop) getResults(filterData);

    }, [activeCoop]);

    const [pagination, setPagination] = useState({
        total: 0,
        perPage: 0,
        currentPage: 1,
        first: 1,
        last: 1
    });

    useEffect(() => {
        if (data?.status) {
            setPagination({
                total: data?.data?.meta?.total,
                perPage: data?.data?.meta?.per_page,
                currentPage: data?.data?.meta?.current_page,
                first: data.data.meta?.first_page,
                last: data.data.meta?.last_page
            })
        }
    }, [data]);

    const deleteLoan = (e, id) => {
        dispatch(
          toggleDelete({
            open: true,
            title: 'Loan Application',
            url: `${DELETE_LOAN}?loan_id=${id}`,
            // ele: JSON.stringify(e.currentTarget),
            parent: 'tr'
          })
        );
    }

    const downloadLoan = async (row) => {
        // setLoading(true);
        const url = `${process.env.REACT_APP_API_URL}${DOWNLOAD_LOAN_FORM}?loan_id=${row.id}`
        await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            }})
            .then((response) => response.blob())
            .then((myBlob) => {
                const objectURL = URL.createObjectURL(myBlob);
                const anchor = document.createElement("a");
                anchor.href = objectURL; // Set the href to the data URL
                anchor.target = "_self"; // Open in a new tab
                anchor.download = `${row.member.firstname} ${row.member.lastname}-${row.product.name}-form.pdf`; // Specify the file name
      
                // Trigger a click event on the anchor element
                anchor.click();
                anchor.remove();
            }).catch(err => console.log(err.message));
        // setLoading(false)
    }

    const changeDateField = (e) => {
        const value = e.target.value;
        // let start = dayjs(), end = dayjs();
        switch (value) {
            case 'current-month':
                setFilterData({
                    ...filterData,
                    start: dayjs().startOf('M').format('YYYY-MM-DD'),
                    end: dayjs().format('YYYY-MM-DD')
                })
                break;
            case 'last-month':
                setFilterData({
                    ...filterData,
                    start: dayjs().subtract(1, 'M').startOf('M').format('YYYY-MM-DD'),
                    end: dayjs().subtract(1, 'M').endOf('M').format('YYYY-MM-DD')
                })
                break;
            case 'last-30-days':
                setFilterData({
                    ...filterData,
                    start: dayjs().subtract(30, 'days').format('YYYY-MM-DD'),
                    end: dayjs().format('YYYY-MM-DD')
                })
                break;
            case 'current-year':
                setFilterData({
                    ...filterData,
                    start: dayjs().startOf('y').format('YYYY-MM-DD'),
                    end: dayjs().format('YYYY-MM-DD')
                })
                break;
            case 'last-year':
                setFilterData({
                    ...filterData,
                    start: dayjs().subtract(1, 'y').startOf('y').format('YYYY-MM-DD'),
                    end: dayjs().subtract(1, 'y').endOf('y').format('YYYY-MM-DD')
                })
                break;
            default:
                setFilterData({
                    ...filterData,
                    start: '',
                    end: ''
                })
                break;
        }
    }

    return (
        <>
            <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-3 col-md-4 col-sm-6">
                                    <div className="form-group">
                                        <select className="form-control show-tick" onChange={changeDateField}>
                                            <option value="">Filter By Date</option>
                                            <option value="current-month">Current Month</option>
                                            <option value="last-month">Last Month</option>
                                            <option value="last-30-days">Last 30 Days</option>
                                            <option value="current-year">Current Year</option>
                                            <option value="last-year">Last Year</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6">
                                    <DatePicker
                                        className={`customInput w-full`} 
                                        name={'start'} 
                                        placeholder='Start Date'
                                        style={{width: '100%'}}
                                        value={filterData.start !== '' ? dayjs(filterData.start) : undefined} 
                                        onChange={(value) => setFilterData({...filterData, start: dayjs(value).format('YYYY-MM-DD')})}
                                    />
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6">
                                <DatePicker
                                        className={`customInput w-full`} 
                                        name={'start'} 
                                        placeholder='End Date'
                                        style={{width: '100%'}}
                                        value={filterData.end !== '' ? dayjs(filterData.end) : undefined} 
                                        onChange={(value) => setFilterData({...filterData, end: dayjs(value).format('YYYY-MM-DD')})}
                                    />
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6">
                                    <a href="#" className="btn btn-sm btn-primary btn-block" onClick={() => getResults(filterData)}>Search</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`section-body dimmer ${(isFetching || loadingComplete || loadingIncomplete) ? 'active' : ''}`}>
                <div className="container-fluid">
                    <div className='loader' />
                    <div className="table-responsive dimmer-content" style={{ minHeight: '300px'}}>
                        <table className="table table-vcenter table_custom spacing5 border-style mb-0">
                            <thead>
                                <tr>
                                    <th className="w40">#</th>
                                    <th>Name</th>
                                    <th>Loan</th>
                                    <th>Amount</th>
                                    <th>Tenure</th>
                                    <th>Rate</th>
                                    <th>Charges</th>
                                    <th>Source</th>
                                    <th>Upfront</th>
                                    <th>Status</th>
                                    <th className="w40" />
                                </tr>
                            </thead>
                            <tbody>
                                {data?.data?.data.map(row =>
                                    <tr key={`loan-list-${row.id}`}>
                                        <td>
                                            <span className="avatar avatar-pink" data-toggle="tooltip" data-placement="top" data-original-title="Avatar Name">
                                                <InitialsAvatar name={`${row.member.firstname} ${row.member.lastname}`} />
                                            </span>
                                        </td>
                                        <td>
                                            <NavLink to={`/members/view?member_id=${row.member.id}`} className="ml-3">
                                                {row.member.firstname} {row.member.lastname}
                                            </NavLink>
                                        </td>
                                        <td>{row.product.name}</td>
                                        <td><span>{formatNumber(row.loan_amount)}</span></td>
                                        <td>{row.tenure} {row.tenure_type}</td>
                                        <td>{row.interest_rate}%</td>
                                        <td>{formatNumber(row.charge_amount)}</td>
                                        <td>{row.source}</td>
                                        <td>{row.upfront}</td>
                                        <td>
                                            {row.status === 0 && <span className='tag tag-warning'>Pending</span>}
                                            {row.status === 1 && <span className='tag tag-success'>Approved</span>}
                                            {row.status === 2 && <span className='tag tag-danger'>Rejected</span>}
                                        </td>
                                        <td>
                                            <div className="item-action dropdown ml-2">
                                                <a href="#" data-toggle="dropdown"><i className="fe fe-more-vertical" /></a>
                                                <div className="dropdown-menu dropdown-menu-right">
                                                    <button type="button" className="btn btn-icon dropdown-item text-info" onClick={() => setSelected(row)} data-target="#loan-details" data-toggle="modal">
                                                        <i className="dropdown-icon fa fa-eye " /> View Details
                                                    </button>
                                                    <button type="button" className="btn btn-icon dropdown-item text-info" onClick={() => downloadLoan(row)}>
                                                        <i className="dropdown-icon fa fa-download " /> Download form 
                                                    </button>
                                                    {row.is_paid === 0 && 
                                                        <button type="button" className="btn btn-icon dropdown-item text-info" onClick={() => markComplete(row.id)}>
                                                            <i className="dropdown-icon fa fa-calendar-check-o" /> Mark as Completed 
                                                        </button>
                                                    }
                                                    {row.is_paid === 1 && 
                                                        <button type="button" className="btn btn-icon dropdown-item text-info" onClick={() => markInComplete(row.id)}>
                                                            <i className="dropdown-icon fa fa-angle-double-right text-warning" /> Mark as Running 
                                                        </button>
                                                    }
                                                    {row.status === 0 &&
                                                        <button type="button" className="btn btn-icon dropdown-item " onClick={() => setSelected(row)} data-target="#approveModal" data-toggle="modal">
                                                            <i className="dropdown-icon fe fe-check-circle text-circle" /> Approve 
                                                        </button>
                                                    }
                                                    {row.status === 0 &&
                                                        <button type="button" className="btn btn-icon dropdown-item text-info" onClick={() => setSelected(row)} data-target="#denyModal" data-toggle="modal">
                                                            <i className="dropdown-icon fe fe-x-circle" /> Reject 
                                                        </button>
                                                    }
                                                    <div className="dropdown-divider"></div>
                                                    <button type="button" className="dropdown-item text-danger" onClick={(e) => deleteLoan(e, row.id)} data-target="#deleteModal" data-toggle="modal">
                                                        <i className="dropdown-icon icon-trash" /> Delete Loan 
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                                {data?.data.meta.total === 0 && <tr><td colSpan={11}><Empty /></td></tr>}
                                
                                
                            </tbody>
                        </table>
                    </div>
                    {pagination.last > 1 && 
                        <Pagination
                            {...pagination} 
                            changePage={(page) => {
                                getResults({
                                    coopId: activeCoop?.id,
                                    page,
                                    search: filterData.search,
                                    start: filterData.start,
                                    end: filterData.end
                                })
                                setFilterData({...filterData, page});
                            }} 
                        />
                    }
                </div>
            </div>
            <LoanDetails loan={selectedItem} />
            <ApproveTransactionModal itemId={selectedItem?.id} coopId={activeCoop?.id} type={'loan'} />
            <DenyTransactionModal item={selectedItem} type={'loan'} />
        </>
    )
}

export default LoansList;