import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

import { useImportLoansMutation } from "../../../services/member.service";
import { DOWNLOAD_LOAN_SAMPLE } from "../../../services/CONSTANTS";
import Card from "../../common/Card";
import alertify from "alertifyjs";
import { doDownload } from "../../../utils/helpers";

const { Dragger } = Upload;


export const ImportLoan = () => {
    const fixNavbar = useSelector(state => state.settings.isFixNavbar);
    const {activeCoop} = useSelector(state => state.coop);

    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [doImport,  {data: importData, isSuccess, isLoading, error}] = useImportLoansMutation();
    const {token} = useSelector(state => state.user);

    useEffect(() => {
        if (isSuccess && importData) {
            if (importData.status) {
                alertify.success(importData.message);
            } else {
                alertify.error(importData.message);
            }
        }

        if (error)
            alertify.error(error.data.message)
        
    }, [importData, error]);

    const props = {
        name: 'file',
        multiple: false,
        maxCount: 1,
        accept: '.xlsx,.csv',
        beforeUpload(file) {
            // console.log('before upload', file);
            setFile(file);
            return false;
        },
        onDrop(e) {
          console.log('Dropped files', e.dataTransfer.files);
        },
    };

    const download = async () => {
        setLoading(true);
        const url = `${process.env.REACT_APP_API_URL}${DOWNLOAD_LOAN_SAMPLE}?cooperative_id=${activeCoop?.id}`
        await doDownload(url, "sample-loan-import.xlsx", token);
        setLoading(false)
    }

    const submit = () => {
        if(!file) {
            alertify.error('Please select a file to import');
            return;
        }
        const formData = new FormData();
        formData.append('cooperative_id', activeCoop?.id);
        formData.append('file', file);

        doImport(formData);
    }

    return (
        <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
            <div className="container-fluid">
                <Card loading={isLoading} title={`Upload File`} headerOptions={
                    <div className="card-options">
                        <button type="button" onClick={() => download()} className="btn btn-secondary btn-sm">
                            {loading ?  <i className="fa fa-spinner fa-spin mr-2" /> :  <i className="fe fe-download mr-2" />}
                            Download Sample
                        </button>
                    </div>
                }>
                    <div className="card-body dimmer-content">
                        <div className="panel panel-success">
                            <div className="panel-heading">
                                <h3 className="panel-title">Important note</h3>
                            </div>
                            <div className="panel-body">
                                <ul>
                                    <li>
                                        Select the file containing the Staff IDs to update
                                    </li>
                                    <li>
                                        Click on Upload File to update Staff ID to upload and update the Staff IDs of the selected member
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <Dragger {...props}>
                            <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            {/* <p className="ant-upload-hint">
                            Support for a single or bulk upload. Strictly prohibited from uploading company data or other
                            banned files.
                            </p> */}
                        </Dragger>
                    </div>
                    <div className="card-footer text-right">
                        <button onClick={submit} className="btn btn-info">Upload Data</button>
                    </div>
                </Card>
            </div>
        </div>
    )
}