import React from "react"
import { SavingsTable } from "../Members/MemberDetails/SavingsTable"
import { useSelector } from "react-redux";
import { useGetMemberSavingsQuery } from "../../services/member.service";
import { DepositModal } from "./Components/DepositFormModal";
import { WithdrawalModal } from "./Components/WithdrawalModalForm";

export const Savings = () => {
    const {isFixNavbar: fixNavbar} = useSelector(state => state.settings);
    const {user} = useSelector(state => state.user);
    const {activeCoop} = useSelector(state => state.coop);

    const {data: savingsData, isLoading} = useGetMemberSavingsQuery({coop_id: user?.coop_id, id: activeCoop?.id}, {skip: !user});


    return (
        <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
            <SavingsTable savings={savingsData?.data} coop={activeCoop} memberId={user?.coop_id} loading={isLoading} />
            <DepositModal savings={savingsData?.data} coop_id={user?.coop_id} cooperative_id={activeCoop?.id} />
			<WithdrawalModal savings={savingsData?.data} coop_id={user?.coop_id} cooperative_id={activeCoop?.id} />
        </div>
    )
}